import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    NavLink,
    Link,
} from "react-router-dom";
import Sidebar from "./Sidebar";
import Select, { components } from "react-select";
import { getBackofficeUsers, postChangeSuscriptionStatus, loggedIn } from "./API Functions";
import MaterialTable from "material-table";


const options = [
    { value: "new", label: "Lo más nuevo" },
    { value: "old", label: "Lo más antiguo" },
    { value: "relevant", label: "Relevante" },
];

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

const Backoffice = props => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const [condition, setCondition] = useState(true);

    useEffect(() => {
        loggedIn();
        setLoading(true);
        loadUsers(0);
    }, [])

    function changeSuscriptioStatus(user_id, suscription_id, value) {
        const data = {
            suscription_id: suscription_id,
            suscription_status: value,
            user_id: user_id,
            new_register: true
        };
        postChangeSuscriptionStatus(data).then(res => {
            if (res) {
                window.location.reload();
            } else {
            }
        });
    };

    function loadUsers(plan_id) {
        setLoading(true);
        getBackofficeUsers().then(res => {
            if (!res) {
                window.location.replace("/login")
            } else {
                let users = [];
                res.map((user, index) => {
                    if (user.suscription) {
                        if (!user.suscription.status) {
                            if (user.suscription.plan_id == plan_id) {
                                users.push(user);
                            }
                        }
                    }
                    setUsers(users);
                })
            }
            setTimeout(() => {
                setLoading(false);
            }, 700);
        })
    }
    return (
        <div className="backoffice">
            <Sidebar />
            <div className="backofficeContainer">
                <div className="backofficeContainer-header">
                    <div className="backofficeContainer-header__about">
                        <h3 className="backofficeContainer-header__title">Solicitudes</h3>
                        <h3 className="backofficeContainer-header__titleAlt">{users ? users.length : 0}</h3>
                    </div>
                </div>
                <div className="backofficeContainer-headerMin">
                    <div style={{ display: "flex" }} className="backofficeContainer-headerMin__item">
                        <a className={condition ? "backofficeContainer-headerMin-tab active" : "backofficeContainer-headerMin-tab"} onClick={() => { loadUsers(0); setCondition(!condition) }}>Pendientes</a>
                        <a className={condition ? "backofficeContainer-headerMin-tab" : "backofficeContainer-headerMin-tab active"} onClick={() => { loadUsers(1); setCondition(!condition) }}>Canceladas</a>
                    </div>
                    <div style={{ display: "flex" }} className="backofficeContainer-headerMin__item">
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect inputSelect-backoffice"
                            options={options}
                            placeholder="Ciudad"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 12,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                        />
                    </div>
                </div>
                <div className="backofficeTable">
                    <hr />
                    <MaterialTable
                        columns={[
                            { title: "Vende", field: "tipo" },
                            {
                                title: "Negocio",
                                field: "negocio",
                            },
                            { title: "Nombre", field: "nombre" },
                            { title: "Correo", field: "email" },
                            {
                                title: "URL", field: "url", headerStyle: {
                                    textAlign: 'center',
                                }
                            },
                            { title: "Teléfono", field: "numero" },
                            {
                                title: "Whatsapp", field: "whatsapp", headerStyle: {
                                    textAlign: 'center',
                                }
                            },
                            {
                                title: "Delivery", field: "envio", headerStyle: {
                                    textAlign: 'center',
                                }
                            },
                            {
                                title: "Acciones", field: "actions", headerStyle: {
                                    textAlign: 'center',
                                }
                            }
                        ]}
                        title={"Solicitudes"}
                        data={users.map((user, index) => ({
                            tipo: user.tipo ? user.tipo.detalle == 'Contratar' ? 'Servicios' : user.tipo.detalle == 'Comer' ? 'Comida' : user.tipo.detalle == "Comprar" ? "Productos" : null : '---',
                            negocio: user.negocio ? user.negocio.nombre : '---',
                            nombre: user.nombre,
                            email: user.correo,
                            numero: user.negocio.whatsap !== null ? user.negocio.whatsap : '---',
                            url: user.negocio ? user.negocio.website : '---',
                            whatsapp: user.negocio ? user.negocio.whatsap !== null ? <span className="dotYes">•</span> : <span className="dotNo">◦</span> : '-',
                            envio: user.envio ? user.envio.id == 1 ? <span className="dotYes">•</span> : <span className="dotNo">◦</span> : '-',
                            actions: (
                                user.suscription ?
                                    <div className="approvedBtns">
                                        <a onClick={() => { changeSuscriptioStatus(user.id, user.suscription.id, true) }}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5 10L9 14L17 6"
                                                    stroke="#FF3C14"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </a>
                                        {user.suscription.plan_id !== 1 ?
                                            <a onClick={() => { changeSuscriptioStatus(user.id, user.suscription.id, false) }}>
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.2422 5.75735L5.75691 14.2426"
                                                        stroke="#ACACB5"
                                                        stroke-width="1.5"
                                                        stroke-miterlimit="10"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M14.2431 14.2426L5.75781 5.75735"
                                                        stroke="#ACACB5"
                                                        stroke-width="1.5"
                                                        stroke-miterlimit="10"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </a> : null}
                                    </div> :
                                    '---'
                            )
                        }))}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [15, 20, 30, 50],
                            emptyRowsWhenPaging: false,
                            paging: users.length > 20 ? true : false,
                            showTitle: false,
                            search: true
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage:

                                    loading ?
                                        <>
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{
                                                    marginRight:
                                                        "5px"
                                                }}
                                            />

                                            <span>Cargando...</span>
                                        </>
                                        :
                                        'No hay datos para mostrar.'

                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Backoffice;
