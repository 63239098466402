import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import Cookies from 'universal-cookie';
import cookieIcon from "../images/cookie.png"

const cookies = new Cookies();

function CookiesPop() {
    const ref = useRef();
    const [isToggled, setToggled] = useState(true);

    const toggleTrueFalse = () => setToggled(!isToggled);

    function hideGetModalPro() {
        var two_days = new Date();
        var today = new Date();
        two_days.setDate(today.getDate() + 15);

        cookies.set('showCookieModal', true, {
            path: '/',
            expires: two_days
        });

        setToggled(false)
    }


    return (
        <>
            <Zoom duration={300}>
                <div ref={ref} className={isToggled ? "cookiesModal" : "cookiesModal-hidden"}>
                    <svg onClick={() => hideGetModalPro()} className="cookiesModal-close" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.2422 5.75732L5.75691 14.2426" stroke="#888888" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.2431 14.2426L5.75781 5.75732" stroke="#888888" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <img height="20" src={cookieIcon} />
                    <p>
                        Al navegar por este sitio web, aceptas nuestra <a href="/cookies">política de cookies</a>.
                    </p>
                </div>
            </Zoom>

        </>
    );
}


export default CookiesPop;