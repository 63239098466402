import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

import GlobalFonts from "./fonts/Fonts.js";
import "./App.scss";
import Home from "./components/Home.js";
import Perfil from "./components/Perfil.js";
import Vender from "./components/Vender.js";
import Vender2 from "./components/Vender-2.js";
import Settings from "./components/Settings.js";
import Settings2 from "./components/Settings-2.js";
import Settings3 from "./components/Settings-3.js";
import Settings4 from "./components/Settings-4.js";
import Settings5 from "./components/Settings-5.js";
import NewSettings from "./components/NewSettings.js";
import Catalogo from "./components/Catalogo.js";
import NuevoProductoStyled from "./components/NewProduct/NuevoProducto.js";
import Login from "./components/Login.js";
import Backoffice from "./components/Backoffice.js";
import Backoffice2 from "./components/Backoffice2.js";
import Backoffice3 from "./components/Backoffice3.js";
import Category from "./components/Category.js";
import CategoryProfiles from "./components/Categories/CategoryProfiles.js";
import Recover from "./components/Recover.js";
import Search from "./components/Search.js";
import ErrorSearch from "./components/ErrorSearch.js";
import Page404 from "./components/Page404.js";
import About from "./components/About.js";
import Vender3 from "./components/Vender-3.js";
import CreatePassword from "./components/CreatePassword.js";
import RangoPrecios from "./components/Backoffice/RangoPrecios.js";
import Ciudades from "./components/Backoffice/Ciudades.js";
import Julio from "./components/Julio.js";
import WaitingList from "./components/Backoffice/Waiting List.js";
import Gracias from "./components/Gracias.js";
import ThankYou from "./components/ThankYou.js";
import ProductoModal from "./components/ProductoModal.js";
import Blacklist from "./components/Backoffice/Blacklist.js";
import DraftView from "./components/DraftView.js";
import Pro from "./components/Pro.js";
import ProCheckout from "./components/ProCheckout.js";
import Cupones from "./components/Backoffice/Cupones.js";
import ThankYouPro from "./components/ThankYouPro.js";
import Stats from "./components/Stats.js";
import Terminos from "./components/Terminos.js";
import Privacidad from "./components/Privacidad.js";
import Cookies from "./components/Cookies.js";
import Location from "./components/Location.js";
import Documentacion from "./components/Documentacion.js";
import ScrollToTop from "./components/ScrollToTop.js";
import Flags from "./components/Backoffice/Flags.js";
import Estados from "./components/Backoffice/Estados.js";
import EmptyStateProfile from "./components/EmptyStateProfile.js";
import EmptyStateFree from "./components/EmptyStateFree.js";
import SettingsReady from "./components/SettingsReady.js";
import EmptyStateCatalogo from "./components/EmptyStateCatalogo.js";
import EmptyStateCatalogoPro from "./components/EmptyStateCatalogoPro.js";
import { StyledDemo } from "./components/NewProduct/Crop.js";
import Blocked from "./components/Blocked.js";
import Reportes from "./components/Reportes.js";
import Ayuda from "./components/Ayuda.js";
import CategoryScreen from "./components/Categories/CategoryScreen.js";
import  {Helmet, HelmetProvider } from 'react-helmet-async';



function App() {
  return (
    <HelmetProvider>
    <CookiesProvider>
      <div>
        <Helmet>
          <title>Okki | Vende lo que haces, compra lo que quieres.</title>
        </Helmet>
        <GlobalFonts />
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/vender" component={Vender} />
            <Route path="/vender2" component={Vender2} />
            <Route path="/vender3" component={Vender3} />
            <Route path="/login" component={Login} />

            <Route path="/settings" component={Settings} />
            <Route path="/settings2" component={Settings2} />
            <Route path="/settings3" component={Settings3} />
            <Route path="/settings4" component={Settings4} />
            <Route path="/settings5" component={Settings5} />
            <Route path="/newsettings" component={NewSettings} />

            <Route path="/nuevo-producto" component={NuevoProductoStyled} />
            <Route path="/producto/" component={ProductoModal} exact />
            <Route path="/ready" component={SettingsReady} />
            <Route path="/draft-view" component={DraftView} />
            <Route path="/pro" component={Pro} />
            <Route path="/pro-checkout" component={ProCheckout} />
            <Route path="/search/:keywords" component={Search} />
            <Route path="/error" component={ErrorSearch} />

            <Route path="/catalogo" component={Catalogo} />
            <Route path="/:okkiDomain/productos/:productoId/:nombreProducto" component={ProductoModal} exact />
            <Route path="/editar-producto" component={NuevoProductoStyled} exact />

            <Route path="/landing" component={Julio} exact />
            <Route path="/thanks" component={ThankYou} />
            <Route path="/gracias" component={Gracias} />

            <Route path="/category" component={Category} />
            <Route path="/category-profiles" component={CategoryProfiles} />

            <Route path="/404" component={Page404} />
            <Route path="/nosotros" component={About} />
            <Route path="/cookies" component={Cookies} />
            <Route path="/location" component={Location} />
            <Route path="/empty" component={EmptyStateProfile} />
            <Route path="/crop_test" component={StyledDemo} />
            <Route path="/blocked" component={Blocked} />

            <Route path="/comer" component={CategoryScreen} />
            <Route path="/comprar" component={CategoryScreen} />
            <Route path="/contratar" component={CategoryScreen} />

            <Route path="/category/comprar" component={Reportes} />
            <Route path="/category/contratar" component={Reportes} />

            <Route path="/faqs" component={Documentacion} />
            <Route path="/documentacion" component={Documentacion} />
            <Route path="/thankyoupro" component={ThankYouPro} />
            <Route exact path="/ayuda" render={() => (window.location = "https://www.notion.so/Asistente-Okki-59177efb67264912a4794da38a5b9fdc")} />
            <Route path="/terminos" component={Terminos} />
            <Route path="/privacidad" component={Privacidad} />
            <Route path="/stats" component={Stats} />
            <Route path="/password/new" component={CreatePassword} />
            <Route path="/password/create" component={CreatePassword} />
            <Route path="/password/recover" component={Recover} />

            <Route path="/backoffice" component={Backoffice} />
            <Route path="/waiting-list" component={WaitingList} />
              <Route path="/backoffice2" component={Backoffice2} />
              <Route path="/backoffice3" component={Backoffice3} />
              <Route path="/cupones" component={Cupones} />
              <Route path="/reportes" component={Reportes} />
              <Route path="/blacklist" component={Blacklist} />
              <Route path="/rango-precios" component={RangoPrecios} />
              <Route path="/ciudades" component={Ciudades} />
              <Route path="/estados" component={Estados} />
              
              <Route path="/flags" component={Flags} />

            <Route path="/:nombre_negocio" component={Perfil} />

            

          </Switch>
        </Router>
      </div>
    </CookiesProvider>
    </HelmetProvider>
  );
}

export default App;
