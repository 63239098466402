import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Navbar from "../Navbar";
import itemThumb from "../../images/itemThumb.svg";
import itemProfile from "../../images/itemProfile.svg";
import Fade from "react-reveal/Fade";
import covercomer from "../../images/comer-hero.svg";
import covercontratar from "../../images/contratar-hero.svg";
import covercomprar from "../../images/comprar-hero.svg";
import { bucket_route, getFilters, user_token, getCiudades, loadNextPagePost, getCategoriasTipo, getRangoPrecios, getCategoriasCategorias, loadNextPage } from "../API Functions";
import Select, { components } from "react-select";
import selectIcon from "../../images/select_icon.svg";
import Help from "../Help";
import ScrollTop from "../ScrollTop";
import Footer from "../Footer";
import ProductList from "../Products/ProductsList";
import InfiniteScroll from "react-infinite-scroll-component";
import useCategoryProfiles from "./UseCategoryProfiles";
import CategoryProfiles from "./CategoryProfiles";
import Negocios from "../Negocios/Negocios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const options = [
    { value: "new", label: "Lo más nuevo" },
    { value: "old", label: "Lo más antiguo" },
    { value: "relevant", label: "Relevante" },
];

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

function CategoryScreen() {
    const [isToggled, setToggled] = useState(false);
    const [tipo_id, setTipoId] = useState("");
    const [categorias, setCategorias] = useState([]);
    const [negocios, setNegocios] = useState([]);
    const [productos, setProductos] = useState([]);

    const [rangos, setRangos] = useState([])
    const [envios, setEnvios] = useState([])

    const [order_id, setOrderId] = useState(null);
    const [envio_id, setEnvioId] = useState("");
    const [state, setState] = useState("");
    const [rango_id, setRangoId] = useState("");
    const [categoria_id, setCategoriaId] = useState("");

    const toggleTrueFalse = () => setToggled(!isToggled);

    const [isToggledMore, setToggledMore] = useState(false);
    const toggleMore = () => setToggledMore(!isToggledMore);

    const [categoriasPage, setCategoriasPage] = useState([]);
    const [categoriasNextPage, setCategoriasNextPage] = useState([]);

    const [productosPage, setProductosPage] = useState([]);
    const [productosNextPage, setProductosNextPage] = useState([]);

    const [negociosPage, setNegociosPage] = useState([]);
    const [negociosNextPage, setNegociosNextPage] = useState([]);



    const emptyOption = {
        value: 0,
        label: 'Ver todo'
    }

    const sort_options = [
        {
            value: 0,
            label: 'Lo más antiguo'
        },
        {
            value: 1,
            label: 'Lo más reciente'
        }
    ]


    useEffect(() => {
        const pathname = window.location.pathname;
        var tipo_id = 0;
        if (pathname.includes('comer')) {
            tipo_id = 2;
        } else if (pathname.includes('contratar')) {
            tipo_id = 1;
        } else {
            tipo_id = 3;
        }
        setTipoId(tipo_id);
        const state = cookies.get('state');
        if(state){
            loadData(tipo_id,order_id,envio_id,rango_id,categoria_id,state);
            setState(state);
        }else{
            loadData(tipo_id,order_id,envio_id,rango_id,categoria_id,"");
        }
        loadFilters();
        loadCategories(tipo_id);
    }, [])
    function loadCategories(tipo_id){
        getCategoriasCategorias(tipo_id).then(res=>{
            if(res){
                setCategorias(res.data);
                setCategoriasPage(res);
                setCategoriasNextPage(res.next_page_url);
            }
        })
    }
    function loadData(tipo_id, order_id, envio_id, rango_id, categoria_id, state) {
        const data = {
            tipo_id: tipo_id,
            order_id: order_id ? order_id : "",
            envio_id: envio_id ? envio_id : "",
            rango_id: rango_id ? rango_id : "",
            categoria_id: categoria_id ? categoria_id : "",
            state : state ? state : ""
        }
        console.log(data)
        getCategoriasTipo(data).then(res => {
            if (res) {
                console.log(res, 'test');

                setNegocios(res.negocios.data);
                setNegociosPage(res.negocios);
                setNegociosNextPage(res.negocios.next_page_url);

                setProductos(res.productos.data);
                setProductosPage(res.productos);
                setProductosNextPage(res.productos.next_page_url);
            }
        })
        
    }

    function loadFilters() {
        getFilters().then(res => {
            if (res) {
                const rangos_option = [];
                rangos_option.push(emptyOption);
                res.rangos.map(rango => {
                    rango = {
                        ...rango,
                        value: rango.id,
                        label: rango.detalle
                    }
                    rangos_option.push(rango);
                })
                setRangos(rangos_option);

                const envios_option = [];
                envios_option.push(emptyOption);
                res.envios.map(envio => {
                    envio = {
                        ...envio,
                        value: envio.id,
                        label: envio.nombre
                    }
                    envios_option.push(envio);
                })
                setEnvios(envios_option);
            }
        })
    }

    function loadMoreProductos() {
        const data = {
            tipo_id: tipo_id,
            order_id: order_id ? order_id : "",
            envio_id: envio_id ? envio_id : "",
            rango_id: rango_id ? rango_id : "",
            categoria_id: categoria_id ? categoria_id : "",
            state : state ? state : ""
        }
        loadNextPagePost(productosNextPage, data).then(res => {
            if (res) {
                setProductosPage(res.productos);
                setProductosNextPage(res.productos.next_page_url);
                const productos_o = productos.concat(res.productos.data)
                setProductos(productos_o);
            }
        })
    }

    function loadMoreCategories() {
        
        loadNextPage(categoriasNextPage).then(res => {
            if (res) {
                setCategoriasPage(res);
                setCategoriasNextPage(res.next_page_url);
                const categorias_o = categorias.concat(res.data)
                setCategorias(categorias_o);
            }
        })
    }

    function loadMoreNegocios() {
        loadNextPagePost(negociosNextPage).then(res => {
            if (res) {
                console.log(res);
            }
        })
    }



    function filterByDate(value) {
        setOrderId(value.value);
        loadData(tipo_id, value.value, envio_id, rango_id, categoria_id);
    }
    function filterByRango(value) {
        setRangoId(value.value)
        loadData(tipo_id, order_id, envio_id, value.value, categoria_id);
    }
    function filterByEnvio(value) {
        setEnvioId(value.value)
        loadData(tipo_id, order_id, value.value, rango_id, categoria_id);
    }
    function filterByCategoria(value) {
        setCategoriaId(value)
        loadData(tipo_id, order_id, value.value, rango_id, value);
    }
    return (
        <div>
            <Navbar negocio={[]} />
            <div className="home-cover container">
                <div className="twelve columns category-cover">
                    <Fade delay={500}>
                        {
                            tipo_id == 1 ? <img className="home-cover__img" src={covercontratar} /> : 
                            tipo_id == 2 ? <img className="home-cover__img" src={covercomer} /> : 
                            <img className="home-cover__img" src={covercomprar} />
                            
                        }
                    </Fade>
                </div>
            </div>
            <div className="home-gallery search-header">
                <div className="home-gallery__headerResults container row">
                    <div className="four columns search-header__breadcrumbAlt2">
                        <h3 className="titleMin">{tipo_id == 1 ? 'Contratar' : tipo_id == 2 ? 'Comer' : 'Comprar'}</h3>
                        <div className="onlyMobile moreOptionsToggle" onClick={toggleTrueFalse}>
                            {isToggled ? 
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="42" height="42" rx="6" fill="#081027" />
                                    <path d="M25.2422 16.7573L16.7569 25.2426" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M25.2431 25.2426L16.7578 16.7573" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                : 
                                <svg onClick={toggleTrueFalse} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="41" height="41" rx="5.5" fill="white" stroke="#E8E8EE" />
                                    <path d="M14 17H28" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16 21H26" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18 25H24" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                        </div>
                    </div>
                    <div className="eight columns home-gallery__headerSelects onlyDesktop">
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect"
                            options={sort_options}
                            onChange={filterByDate}
                            placeholder="Reciente"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                            styles={{
                                dropdownIndicator: (provided, state) => ({
                                    ...provided,
                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                })
                            }}
                        />
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect"
                            options={envios}
                            onChange={filterByEnvio}
                            placeholder="Pick n Go"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                            styles={{
                                dropdownIndicator: (provided, state) => ({
                                    ...provided,
                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                })
                            }}
                        />
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect"
                            options={rangos}
                            onChange={filterByRango}
                            placeholder="$$"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                            styles={{
                                dropdownIndicator: (provided, state) => ({
                                    ...provided,
                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                })
                            }}
                        />
                    </div>
                    <div className="u-center four columns onlyMobile">
                        <div className="">
                            <Select
                                classNamePrefix="inputSelect"
                                className="inputSelect inputSelectSearch"
                                options={sort_options}
                                onChange={filterByDate}
                                placeholder="Reciente"
                                components={{ DropdownIndicator }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 6,
                                    colors: {
                                        ...theme.colors,
                                        primary50: "#ccc",
                                        primary25: "#F6F6F6",
                                        primary: "black",
                                    },
                                })}
                                styles={{
                                    dropdownIndicator: (provided, state) => ({
                                        ...provided,
                                        transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="four columns onlyMobile">
                        {isToggled ?
                            <Fade top>
                                <div className="home-gallery__headerSelects home-gallery__headerSelectsMini">
                                    <Select
                                        classNamePrefix="inputSelect"
                                        className="inputSelect"
                                        options={envios}
                                        onChange={filterByEnvio}
                                        placeholder="Pick n Go"
                                        components={{ DropdownIndicator }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 6,
                                            colors: {
                                                ...theme.colors,
                                                primary50: "#ccc",
                                                primary25: "#F6F6F6",
                                                primary: "black",
                                            },
                                        })}
                                        styles={{
                                            dropdownIndicator: (provided, state) => ({
                                                ...provided,
                                                transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                            })
                                        }}
                                    />
                                    <Select
                                        classNamePrefix="inputSelect"
                                        className="inputSelect"
                                        options={rangos}
                                        onChange={filterByRango}
                                        placeholder="$$"
                                        components={{ DropdownIndicator }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 6,
                                            colors: {
                                                ...theme.colors,
                                                primary50: "#ccc",
                                                primary25: "#F6F6F6",
                                                primary: "black",
                                            },
                                        })}
                                        styles={{
                                            dropdownIndicator: (provided, state) => ({
                                                ...provided,
                                                transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                            })
                                        }}
                                    />
                                </div>
                            </Fade> : <div></div>
                        }
                    </div>
                </div>
                <hr className="fullDivider" style={{ margin: 0 }} />

                <div className="search-header__tags row container">
                    <a className={categoria_id > 0 ? "itemTag" : "itemTag itemTag-active"} onClick={() => { filterByCategoria(0) }}>Todo</a>
                    {categorias.map((categoria, index) => (
                        <>
                            <a className={categoria_id == categoria.id ? "itemTag itemTag-active" : "itemTag"} onClick={() => { filterByCategoria(categoria.id) }}>
                                {categoria.detalle}
                            </a>
                        </>

                    ))

                    }
                    <a className={categoriasNextPage ?
                        "itemTagButton onlyDesktop"
                        :
                        "itemTagButton onlyDesktop noDisplay"
                        } onClick={loadMoreCategories}>Ver más</a>
                </div>
                <hr className="fullDivider" style={{ margin: 0 }} />
                <Negocios
                    negocios={negocios}
                />
                <hr className="fullDivider" />

            </div>
            <div className="home-gallery container">
                <InfiniteScroll
                    dataLength={productos.length}
                    next={() => { loadMoreProductos() }}
                    hasMore={productosPage.currentPage !== productosNextPage}
                    >
                        <Fade cascade>
                        <ProductList
                        productos = {productos}
                        />
                        </Fade>

                </InfiniteScroll>
            </div>

            <ScrollTop />

            <Footer />
        </div>
    );
}

export default CategoryScreen;
