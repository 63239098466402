import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import cover from "../images/404Cover.svg";


function Page404() {
    return (
        <div>
            <div className="home-gallery container">
                <img className="page404-cover" src={cover} />

                <div className="row">
                    <div className="six columns">
                        <h3 className="titleMin page404-title">Can you hear me, Major Tom?</h3>
                        <p className="page404-message">
                            Hicimos una búsqueda a 40,400 millones de años luz de la Tierra y no encontramos rastro de ningún sitio web.
    </p>
                        <br />
                        <Link className="planContainer-form__thanks-button page404-btn" to="/">
                            <svg style={{ marginRight: "10px" }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 5L4 10L9 15" stroke="#111111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4 10L16 10" stroke="#111111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                      Regresar a casa
                    </Link>

                    </div>
                    <div className="six columns">


                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page404;
