import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import vender1 from "../images/registro1.svg";
import Fade from "react-reveal/Fade";
import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import NavbarMin from "./NavbarMin";
import { getTipos, loggedIn } from "./API Functions";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";


function Vender() {
  const [Loading, setLoading] = useState(false);
  const [Tipos, setTipos] = useState([]);
  const [tipo_id, setTipoID] = useState("");
  const [tipoError, setTipoError] = useState("");
  const [Continuar, setContinuar] = useState(false);

  useEffect(() => {
    getTipos().then((res) => {
      if (res) {
        setTipos(res);
      }
    });
  }, []);

  function handleChange(id) {
    setTipoID(id);
  }

  function validateFields() {
    let tipoError = "";
    if (tipo_id == "") {
      tipoError = "Selecciona una opción";
    }
    if (tipoError) {
      setTipoError(tipoError);
      return false;
    } else {
      setTipoError(tipoError);
      return true;
    }
  }
  function handleContinue() {
    setLoading(true);
    const isValid = validateFields();
    if (isValid) {
      setContinuar(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  return (
    <div>
      <Helmet>
        <title>Registro | Okki</title>
      </Helmet>
      {Continuar ? (
        <Redirect
          to={{
            pathname: "/vender2",
            state: { tipo_id: tipo_id },
          }}
        />
      ) : null}
      <div className="onlyDesktop">
        <NavbarMin />
      </div>
      <div className="onlyMobile">
        <div className="modalItem-respNav">


          <Link to="/" className="okkiLogoMobile">
            <div>
              <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M45.0822 39.9559C46.2785 40.0157 47.2804 39.0587 47.2804 37.8625V35.8139C47.2804 35.5597 47.5794 35.4251 47.7738 35.5896L52.5439 39.4924C52.9327 39.8064 53.3963 39.9709 53.8748 39.9709C54.4879 39.9709 55.086 39.7017 55.4897 39.2083C56.2224 38.3111 56.0878 36.9952 55.1907 36.2625L51.1533 32.9578C50.8542 32.7185 50.8542 32.2699 51.1533 32.0307L55.1907 28.726C56.0878 27.9933 56.2224 26.6774 55.4897 25.7802C54.757 24.883 53.4411 24.7485 52.5439 25.4812L47.7738 29.384C47.5794 29.5485 47.2804 29.3989 47.2804 29.1597V27.1559C47.2804 26.0344 46.4131 25.0625 45.2916 25.0027C44.0953 24.9428 43.0935 25.8999 43.0935 27.0961V37.8027C43.0935 38.9242 43.9607 39.8961 45.0822 39.9559ZM36.9178 42.0494C35.7215 41.9896 34.7196 42.9466 34.7196 44.1428V46.1914C34.7196 46.4456 34.4206 46.5802 34.2262 46.4157L29.4561 42.5129C29.0673 42.1989 28.6037 42.0344 28.1252 42.0344C27.5121 42.0344 26.914 42.3036 26.5103 42.797C25.7776 43.6942 25.9121 45.0101 26.8093 45.7428L30.8467 49.0475C31.1458 49.2868 31.1458 49.7354 30.8467 49.9746L26.8093 53.2793C25.9121 54.012 25.7776 55.3279 26.5103 56.2251C27.243 57.1223 28.5589 57.2569 29.4561 56.5241L34.2262 52.6213C34.4206 52.4569 34.7196 52.6064 34.7196 52.8456V54.8344C34.7196 55.9559 35.5869 56.9279 36.7084 56.9877C37.9047 57.0475 38.9065 56.0905 38.9065 54.8942V44.1877C38.9065 43.0812 38.0393 42.1092 36.9178 42.0494ZM32.4766 25.0027C28.3495 25.0027 25 28.3522 25 32.4793C25 36.6064 28.3495 39.9559 32.4766 39.9559C36.6037 39.9559 39.9533 36.6064 39.9533 32.4793C39.9533 28.3522 36.6037 25.0027 32.4766 25.0027ZM32.4766 35.769C30.6673 35.769 29.1869 34.2886 29.1869 32.4793C29.1869 30.6699 30.6673 29.1896 32.4766 29.1896C34.286 29.1896 35.7664 30.6699 35.7664 32.4793C35.7664 34.2886 34.286 35.769 32.4766 35.769ZM54.9065 47.4326H44.1402C42.9888 47.4326 42.0467 48.3746 42.0467 49.526C42.0467 50.6774 42.9888 51.6195 44.1402 51.6195H54.9065C56.0579 51.6195 57 50.6774 57 49.526C57 48.3746 56.0579 47.4326 54.9065 47.4326Z" fill="black" />
              </svg>


            </div>
          </Link>
          <Link to="/">
            <svg className="modalCloseButton" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" fill="white" stroke="#EEEEEE" />
              <path d="M25.2422 16.7573L16.7569 25.2426" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M25.2431 25.2426L16.7578 16.7573" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Link>
        </div>
      </div>
      {/* <div className="navbarFixed">
    <Link to="/">
     <svg
      width="44"
      height="20"
      viewBox="0 0 44 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
     >
      <path
       d="M20.9942 13.632L24.6767 9.32C24.9856 8.928 24.7322 8.448 24.257 8.448H21.1368C20.8042 8.448 20.6062 8.616 20.4399 8.784L17.0662 12.728V0.536C17.0662 0.256 16.8128 0 16.5356 0H13.4392C13.162 0 12.9086 0.256 12.9086 0.536V19.184C12.9086 19.464 13.162 19.72 13.4392 19.72H16.5356C16.8128 19.72 17.0662 19.464 17.0662 19.184V14.592L20.8596 19.496C20.9467 19.608 21.1685 19.72 21.2793 19.72H25.041C25.5162 19.72 25.7696 19.24 25.4608 18.872L20.9942 13.632ZM5.79698 8.168C2.62131 8.168 0 10.872 0 14.056C0 17.296 2.62131 20 5.79698 20C8.97264 20 11.594 17.296 11.594 14.056C11.6019 10.872 8.98056 8.168 5.79698 8.168ZM5.79698 16.368C4.56947 16.368 3.59539 15.328 3.59539 14.056C3.59539 12.816 4.56947 11.8 5.79698 11.8C7.02448 11.8 7.99856 12.816 7.99856 14.056C8.00648 15.32 7.02448 16.368 5.79698 16.368ZM41.7747 5.04C43.0022 5.04 44.0079 4.056 44.0079 2.816C44.0079 1.576 43.0022 0.56 41.7747 0.56C40.5472 0.56 39.5731 1.576 39.5731 2.816C39.5652 4.056 40.5472 5.04 41.7747 5.04ZM43.3348 8.448H40.27C39.9928 8.448 39.7394 8.672 39.7394 8.984V19.184C39.7394 19.464 39.9928 19.72 40.27 19.72H43.3348C43.6119 19.72 43.8654 19.464 43.8654 19.184V8.984C43.8654 8.68 43.6119 8.448 43.3348 8.448ZM34.4096 13.632L38.0922 9.32C38.401 8.928 38.1476 8.448 37.6724 8.448H34.5522C34.2196 8.448 34.0216 8.616 33.8553 8.784L30.4816 12.728V0.536C30.4816 0.256 30.2282 0 29.951 0H26.8546C26.5774 0 26.324 0.256 26.324 0.536V19.184C26.324 19.464 26.5774 19.72 26.8546 19.72H29.951C30.2282 19.72 30.4816 19.464 30.4816 19.184V14.592L34.275 19.496C34.3621 19.608 34.5839 19.72 34.6947 19.72H38.4564C38.9316 19.72 39.185 19.24 38.8762 18.872L34.4096 13.632Z"
       fill="white"
      />
     </svg>
    </Link>
   </div> */}
      <div className="planContainer">
        <div className="planContainer-cover">
          <Fade delay={300}>
            <img src={vender1} />
          </Fade>
        </div>
        <div className="planContainer-form vender1-container">
          <Fade delay={300}>
            <div className="container">
              <div className="row">
                <div className="twelve columns">
                  <div className="planContainer-form__container">
                    <p className="planContainer-form__title">¿Qué es lo que vendes? </p>
                    <p className="planContainer-form__subtitleAlt">
                      Selecciona la opción donde se ubicará tu perfil.
                    </p>
                    <hr className="hrMin" />
                    <div className="fieldError">{tipoError}</div>
                    <div>
                      {Tipos.map((tipo, index) => (
                        <label class="radioContainer radioContainer-vender">
                          <input
                            type="radio"
                            name="category"
                            onChange={() => handleChange(tipo.id)}
                            value={tipo.id}
                          />
                          <span className="checkmark"></span>
                          {tipo.detalle == "Contratar"
                            ? (<>Servicio
                              <svg data-tip="Conjunto de actividades profesionales o<br/>técnicas hechas para alguien más." width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z" fill="#66666A" fill-opacity="0.25" />
                                <path d="M6.83154 8.8877C6.83154 8.65495 6.87093 8.4598 6.94971 8.30225C7.03206 8.14469 7.14665 8.01042 7.29346 7.89941C7.44385 7.78841 7.64258 7.67204 7.88965 7.55029C8.11165 7.43929 8.28174 7.28711 8.3999 7.09375C8.51807 6.89681 8.57715 6.64258 8.57715 6.33105C8.57715 6.0625 8.52344 5.82975 8.41602 5.63281C8.30859 5.43587 8.15999 5.28727 7.97021 5.18701C7.78044 5.08317 7.56559 5.03125 7.32568 5.03125C7.08219 5.03125 6.86556 5.08675 6.67578 5.19775C6.48958 5.30876 6.34277 5.46273 6.23535 5.65967C6.13151 5.85303 6.07959 6.07503 6.07959 6.32568C6.07959 6.46175 6.09749 6.61393 6.1333 6.78223L5.03223 6.65869C5.01074 6.5083 5 6.36328 5 6.22363C5 5.79395 5.09668 5.41081 5.29004 5.07422C5.48698 4.73405 5.75911 4.47087 6.10645 4.28467C6.45736 4.09489 6.8584 4 7.30957 4C7.76074 4 8.16536 4.09489 8.52344 4.28467C8.88151 4.47445 9.1626 4.74479 9.3667 5.0957C9.5708 5.44303 9.67285 5.84587 9.67285 6.3042C9.67285 6.84489 9.55111 7.27816 9.30762 7.604C9.06771 7.92985 8.76335 8.16618 8.39453 8.31299C8.20833 8.39176 8.07227 8.48307 7.98633 8.58691C7.90039 8.68717 7.85742 8.82682 7.85742 9.00586V9.51611H6.83154V8.8877ZM7.37402 11.8794C7.23796 11.8794 7.11084 11.8454 6.99268 11.7773C6.87809 11.7129 6.78678 11.6234 6.71875 11.5088C6.65072 11.3906 6.6167 11.2635 6.6167 11.1274C6.6167 10.9914 6.65072 10.866 6.71875 10.7515C6.79036 10.6369 6.88346 10.5456 6.99805 10.4775C7.11263 10.4095 7.23796 10.3755 7.37402 10.3755C7.50651 10.3755 7.62826 10.4095 7.73926 10.4775C7.85384 10.5456 7.94515 10.6369 8.01318 10.7515C8.08122 10.866 8.11523 10.9914 8.11523 11.1274C8.11523 11.2635 8.08122 11.3906 8.01318 11.5088C7.94515 11.6234 7.85384 11.7129 7.73926 11.7773C7.62826 11.8454 7.50651 11.8794 7.37402 11.8794Z" fill="white" />
                              </svg></>)
                            : tipo.detalle == "Comer"
                              ? (<>Comida
                                <svg data-tip="Cosas naturales o artificiales,<br/>resultado de un trabajo u operación." width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z" fill="#66666A" fill-opacity="0.25" />
                                  <path d="M6.83154 8.8877C6.83154 8.65495 6.87093 8.4598 6.94971 8.30225C7.03206 8.14469 7.14665 8.01042 7.29346 7.89941C7.44385 7.78841 7.64258 7.67204 7.88965 7.55029C8.11165 7.43929 8.28174 7.28711 8.3999 7.09375C8.51807 6.89681 8.57715 6.64258 8.57715 6.33105C8.57715 6.0625 8.52344 5.82975 8.41602 5.63281C8.30859 5.43587 8.15999 5.28727 7.97021 5.18701C7.78044 5.08317 7.56559 5.03125 7.32568 5.03125C7.08219 5.03125 6.86556 5.08675 6.67578 5.19775C6.48958 5.30876 6.34277 5.46273 6.23535 5.65967C6.13151 5.85303 6.07959 6.07503 6.07959 6.32568C6.07959 6.46175 6.09749 6.61393 6.1333 6.78223L5.03223 6.65869C5.01074 6.5083 5 6.36328 5 6.22363C5 5.79395 5.09668 5.41081 5.29004 5.07422C5.48698 4.73405 5.75911 4.47087 6.10645 4.28467C6.45736 4.09489 6.8584 4 7.30957 4C7.76074 4 8.16536 4.09489 8.52344 4.28467C8.88151 4.47445 9.1626 4.74479 9.3667 5.0957C9.5708 5.44303 9.67285 5.84587 9.67285 6.3042C9.67285 6.84489 9.55111 7.27816 9.30762 7.604C9.06771 7.92985 8.76335 8.16618 8.39453 8.31299C8.20833 8.39176 8.07227 8.48307 7.98633 8.58691C7.90039 8.68717 7.85742 8.82682 7.85742 9.00586V9.51611H6.83154V8.8877ZM7.37402 11.8794C7.23796 11.8794 7.11084 11.8454 6.99268 11.7773C6.87809 11.7129 6.78678 11.6234 6.71875 11.5088C6.65072 11.3906 6.6167 11.2635 6.6167 11.1274C6.6167 10.9914 6.65072 10.866 6.71875 10.7515C6.79036 10.6369 6.88346 10.5456 6.99805 10.4775C7.11263 10.4095 7.23796 10.3755 7.37402 10.3755C7.50651 10.3755 7.62826 10.4095 7.73926 10.4775C7.85384 10.5456 7.94515 10.6369 8.01318 10.7515C8.08122 10.866 8.11523 10.9914 8.11523 11.1274C8.11523 11.2635 8.08122 11.3906 8.01318 11.5088C7.94515 11.6234 7.85384 11.7129 7.73926 11.7773C7.62826 11.8454 7.50651 11.8794 7.37402 11.8794Z" fill="white" />
                                </svg></>)
                              : tipo.detalle == "Comprar"
                                ? (<>Producto
                                  <svg data-tip="Sustancias utilizadas por seres vivos<br/>para comerse y servir de alimento." width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z" fill="#66666A" fill-opacity="0.25" />
                                    <path d="M6.83154 8.8877C6.83154 8.65495 6.87093 8.4598 6.94971 8.30225C7.03206 8.14469 7.14665 8.01042 7.29346 7.89941C7.44385 7.78841 7.64258 7.67204 7.88965 7.55029C8.11165 7.43929 8.28174 7.28711 8.3999 7.09375C8.51807 6.89681 8.57715 6.64258 8.57715 6.33105C8.57715 6.0625 8.52344 5.82975 8.41602 5.63281C8.30859 5.43587 8.15999 5.28727 7.97021 5.18701C7.78044 5.08317 7.56559 5.03125 7.32568 5.03125C7.08219 5.03125 6.86556 5.08675 6.67578 5.19775C6.48958 5.30876 6.34277 5.46273 6.23535 5.65967C6.13151 5.85303 6.07959 6.07503 6.07959 6.32568C6.07959 6.46175 6.09749 6.61393 6.1333 6.78223L5.03223 6.65869C5.01074 6.5083 5 6.36328 5 6.22363C5 5.79395 5.09668 5.41081 5.29004 5.07422C5.48698 4.73405 5.75911 4.47087 6.10645 4.28467C6.45736 4.09489 6.8584 4 7.30957 4C7.76074 4 8.16536 4.09489 8.52344 4.28467C8.88151 4.47445 9.1626 4.74479 9.3667 5.0957C9.5708 5.44303 9.67285 5.84587 9.67285 6.3042C9.67285 6.84489 9.55111 7.27816 9.30762 7.604C9.06771 7.92985 8.76335 8.16618 8.39453 8.31299C8.20833 8.39176 8.07227 8.48307 7.98633 8.58691C7.90039 8.68717 7.85742 8.82682 7.85742 9.00586V9.51611H6.83154V8.8877ZM7.37402 11.8794C7.23796 11.8794 7.11084 11.8454 6.99268 11.7773C6.87809 11.7129 6.78678 11.6234 6.71875 11.5088C6.65072 11.3906 6.6167 11.2635 6.6167 11.1274C6.6167 10.9914 6.65072 10.866 6.71875 10.7515C6.79036 10.6369 6.88346 10.5456 6.99805 10.4775C7.11263 10.4095 7.23796 10.3755 7.37402 10.3755C7.50651 10.3755 7.62826 10.4095 7.73926 10.4775C7.85384 10.5456 7.94515 10.6369 8.01318 10.7515C8.08122 10.866 8.11523 10.9914 8.11523 11.1274C8.11523 11.2635 8.08122 11.3906 8.01318 11.5088C7.94515 11.6234 7.85384 11.7129 7.73926 11.7773C7.62826 11.8454 7.50651 11.8794 7.37402 11.8794Z" fill="white" />
                                  </svg></>)
                                : null}

                          <ReactTooltip className="tooltipStyle" multiline="true" place="top" type="dark" effect="solid" />

                        </label>
                      ))}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade delay={300}>
            <div className="planContainer-form__optionTotal">
              <button
                className="button u-pull-right aButton"
                onClick={() => {
                  handleContinue();
                }}
              >
                {Loading && <i className="" /*style={{ marginRight: "5px" }}*/ />}
                {Loading && "Cargando..."}
                {!Loading && "Continuar"}
              </button>
            </div>

          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Vender;
