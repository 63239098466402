import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import aboutCover from "../images/about.svg";
import Accordion from "./elements/accordion";
import Footer from "./Footer";

function About() {
    return (
        <div>
            <Navbar negocio={[]} />
            <div className="about-container">
                <div className="row">
                    <div className="six columns about-container__cover">
                        <img className="about-container__coverImg" src={aboutCover} />
                    </div>
                    <div className="six columns about-container__info">
                        <div className="about-container__infoBox">
                            <Accordion>
                                <div label="¿Cómo funciona Okki?">
                                    <p className="panel">
                                        <Fade>
                                            Es una plataforma donde las personas buscan y encuentran lo que tu vendes. Tú tienes el control de cada operación, decides como cobrar, decides como enviar. Sin intermediarios, sin comisiones.
                                        </Fade>
                                    </p>
                                </div>
                                <div label="¿Cómo me compran?">
                                    <p className="panel">
                                        <Fade>
                                            Crea tu tienda en línea, carga tus productos y comparte tu link personalizado, Okki crea y facilita la comunicación entre tus clientes y tu, poniéndolos en contacto directamente por medio de Whatsapp.
                                        </Fade>
                                    </p>
                                </div>
                                <div label="¿Cómo gana dinero Okki?">
                                    <p className="panel">
                                        <Fade>
                                            Con los negocios que contraten los servicios PRO, les brindamos herramientas para que sus productos tengan más oportunidad de ser vistos.
                                        </Fade>
                                    </p>
                                </div>
                                <div label="¿Quiénes somos?">
                                    <p className="panel">
                                        <Fade>
                                            Somos un equipo de personas que comparten una misma visión, ayudar a digitalizar a la mayor cantidad de negocios posible, brindando herramientas fáciles de usar y gratuitas.
                                        </Fade>
                                    </p>
                                </div>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default About;
