import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import aboutCover from "../images/about.svg";
import Accordion from "./elements/accordion";
import Footer from "./Footer";

function Privacidad() {
    return (
        <div>
            <Navbar negocio={[]} />
            <div className="about-container container">
                <div className="row">
                    <div className="termsContainer">
                        <h1 className="termsContainer-title titleMin">Políticas de Privacidad</h1>
                        <br />
                        <p className="termsContainer-text">
                            Conforme a lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la “Ley”), ponemos a su disposición el presente Aviso de Privacidad, a efecto de garantizar la privacidad y el derecho a la autodeterminación informativa de las personas. El presente Aviso de Privacidad es aplicable con respecto de cualquier información concerniente a una persona física identificada o identificable, sean datos personales o datos personales sensibles. En el tratamiento de datos personales, se deberá observar los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad, previstos en la Ley.
                            <br /><br />
                            Los datos personales que sean recabados por parte de la sociedad “LAGOM STARTUP STUDIO S.A.P.I DE C.V.” (referida como “OKKI”), no son recabados a través de medios engañosos o fraudulentos. OKKI entiende y respeta que existe la expectativa razonable de privacidad respecto de los datos personales proporcionados por los titulares de derechos de protección de datos personales (el “Titular” o los “Titulares”). El Responsable procurará que los datos personales recabados sean pertinentes, correctos y actualizados para los fines para los cuales hayan sido recabados. El tratamiento de datos personales se limitará al cumplimiento de las finalidades previstas en el presente Aviso de Privacidad.
                            <br /><br />
                            Se entiende que el Titular consiente tácitamente el tratamiento de sus datos, estando este Aviso de Privacidad a su disposición en forma física o electrónica, en caso que no manifieste su oposición. El consentimiento de un Titular podrá ser revocado en cualquier momento sin que se le atribuyan efectos retroactivos. Para revocar el consentimiento, el Titular deberá seguir los procedimientos dispuestos por el Responsable en el presente Aviso de Privacidad.
                            <br /><br />
                            En cumplimiento con lo dispuesto en los artículos 15 y 16 de la Ley, le informamos lo siguiente:
                            <br /><br />
                            <span>1. Identidad y domicilio del Responsable.</span>

                            <br /><br />
                            El presente Aviso de Privacidad es aplicable a la sociedad “LAGOM STARTUP STUDIO S.A.P.I DE C.V.”, siendo ésta el “Responsable” en términos de lo dispuesto en la Ley. El domicilio convencional de OKKI se encuentra ubicado en VASCONCELOS 204-B COL. BOSQUES DEL VALLE, SAN PEDRO GARZA GARCÍA, NUEVO LEÓN, MEXICO, CP 66250.
                            <br /><br />
                            <span>2. Finalidades del tratamiento de Datos.</span>
                            <br /><br />
                            El Tratamiento que el Responsable puede darle a los datos que llegue a recabar de un Titular, incluye la obtención, uso, divulgación o almacenamiento de datos personales, por cualquier medio. El uso abarca cualquier acción de acceso, manejo, aprovechamiento, transferencia o disposición de datos personales. Los datos que sean recabados por OKKI, son para la prestación de servicios a sus clientes, para informar sobre sus servicios o demás características como empresa frente al mercado, para realizar investigación de mercado con el propósito de desarrollar y mejorar los productos y servicios de OKKI, o para entablar alguna otra potencial relación con terceros.
                            <br /><br />
                            Los distintos tipos de información que OKKI puede recabar de un Titular incluyen de identificación, edad, contacto, financieros, profesionales, de nacionalidad y cualesquier otros similares para la consecución de los objetos de OKKI. Lo anterior, puede incluir información sobre cuentas bancarias o similares, información financiera, tanto propias como de terceros que estén vinculados a un Titular en una medida necesaria para la prestación de los servicios de OKKI. 
                            <br /><br />
                            En caso que sea requerido el consentimiento de un Titular, conforme a lo dispuesto en la Ley, el mismo será otorgado en forma electrónica a través del portal de OKKI, consintiendo al contenido del presente Aviso de Privacidad.
                            <br /><br />
                            Para cumplir con las finalidades descritas en el presente Aviso de Privacidad, OKKI podrá compartir con terceros, nacionales o extranjeros, algunos o todos los datos personales que recaba. Dichos terceros podrán ser compañías afiliadas o subsidiarias, o instituciones, organizaciones o entidades de sistema financiero mexicano, así como sus equivalentes en el extranjero, entidades de seguros y fianzas, entidades relacionadas al sector turismo, con la finalidad de cumplir con lo dispuesto en el presente Aviso de privacidad.
                            <br /><br />
                            El tratamiento que OKKI le pueda dar a los datos personales de un Titular, incluye lo siguiente:
                            <br /><br />
                            i. Dar cumplimiento a la Ley y sus disposiciones reglamentarias;
                            <br />
                            ii. Cumplir con lo establecido en la relación de servicios entre el Titular y OKKI;
                            <br />
                            iii. Gestionar las transacciones del Titular mediante el uso del Portal de OKKI;
                            <br />
                            iv. Gestionar o procurar los trámites y demás actos administrativos o privados que sean necesarios para cumplir con los servicios pactados con un Titular;
                            <br />
                            v. Desarrollar la oferta de productos y servicios de OKKI;
                            <br />
                            vi. Para propósitos estadísticos, de mercado, publicitarios y comerciales;
                            <br />
                            vii. Para la transmisión de los datos proporcionados por el Titular a OKKI, así como a terceros no pertenecientes al mismo grupo al cual pertenece OKKI, en cumplimiento de los incisos anteriores; y
                            <br />
                            viii. Para gestionar el acceso, control y seguridad de empresa OKKI, sus clientes y sus colaboradores.
                            <br /><br />
                            En cualquier caso, el Titular podrá revocar dicho consentimiento y solicitar su inscripción en el Registro Público Para Evitar Publicidad, previsto en la Ley Federal de Protección al Consumidor.
                            <br /><br />
                            <span>3. Opciones y medios que el Responsable ofrece para limitar el uso o divulgación de los Datos.</span>
                            <br /><br />
                            El Responsable mantendrá medidas de seguridad administrativas, técnicas y físicas que permitan proteger los datos personales contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado. Las vulneraciones de seguridad que pudieran suceder en cualquier fase del tratamiento, serán informadas a la brevedad posible, a fin de que el afectado pueda tomar las medidas correspondientes para la defensa de sus derechos.
                            <br /><br />
                            El Responsable o los terceros que intervengan en cualquier fase del tratamiento de datos personales deberán guardar confidencialidad respecto de éstos. Adicionalmente, el tratamiento de datos personales deberá ser no discriminatorio y objetivo, buscando el beneficio de los clientes de OKKI y de sus colaboradores.
                            <br /><br />
                            Los Titulares tienen derecho a acceder a sus datos personales que hayan sido recabados por OKKI, así como conocer el Aviso de Privacidad al que está sujeto su tratamiento.
                            <br /><br />
                            <span>4. Medios para ejercer los derechos de acceso, rectificación, cancelación u oposición (los “Derechos ARCO”).</span>
                            <br /><br />
                            Cualquier Titular, o en su caso su representante legal, podrá ejercer los Derechos ARCO, conforme a lo dispuesto en la Ley. El ejercicio de cualquiera de ellos no es requisito previo ni impedirá el ejercicio de otro.
                            <br /><br />
                            Cuando los datos personales hubiesen sido transmitidos con anterioridad a la fecha de rectificación o cancelación y sigan siendo tratados por terceros, el Responsable deberá hacer de su conocimiento dicha solicitud de rectificación o cancelación, para que proceda a efectuarla también.
                            <br /><br />
                            Cualquier solicitud de acceso, rectificación, cancelación u oposición por parte de un Titular, deberá contener y acompañar lo siguiente:
                            <br /><br />
                            i. El nombre del Titular y domicilio u otro medio para comunicarle la respuesta a su solicitud;
                            <br />
                            ii. Los documentos que acrediten la identidad o, en su caso, la representación legal del Titular;
                            <br />
                            iii. La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados;
                            <br />
                            iv. Cualquier otro elemento o documento que facilite la localización de los datos personales; y
                            <br />
                            v. En caso de solicitar una rectificación de sus datos personales, deberá indicar las modificaciones a realizarse y aportar la documentación que sirva de sustento.
                            <br />
                            La empresa OKKI cuenta un departamento encargado de atención a la protección de datos personales. Este departamento es el encargado de responder a las solicitudes de acceso, rectificación, cancelación u oposición por parte de los Titulares. Una vez que se recibe una solicitud, el departamento encargado dará respuesta en un plazo máximo de 20 días hábiles. En caso que la pretensión del Titular contenida en la solicitud haya sido finalmente considerada como procedente, los cambios solicitados se implementarán dentro de un plazo máximo de 15 días hábiles. OKKI podrá notificar al Titular respectivo sobre alguna prórroga a los plazos referidos, por una sola ocasión y por periodos de igual duración, cuando el caso lo amerite y sea justificado de acuerdo a las propias circunstancias del caso.
                            <br /><br />
                            El Titular podrá contactar al departamento de datos personales de OKKI a través de la cuenta de correo electrónico: hola@okki.co. Por tal medio los Titulares podrán promover solicitudes para el ejercicio de los Derechos ARCO.
                            <br /><br />
                            No obstante lo anterior, el Responsable podrá negar el acceso a los datos personales de que se trate, o a realizar la rectificación o cancelación o conceder la oposición al tratamiento de los mismos, en los siguientes supuestos:
                            <br /><br />
                            i. Cuando el solicitante no sea el Titular de los datos personales, o el representante legal no esté debidamente acreditado para ello;
                            <br />
                            ii. Cuando en su base de datos, no se encuentren los datos personales del solicitante;
                            <br />
                            iii. Cuando se lesionen los derechos de un tercero;
                            <br />
                            iv. Cuando exista un impedimento legal, o la resolución de una autoridad competente, que restrinja el acceso a los datos personales, o no permita la rectificación, cancelación u oposición de los mismos; y
                            <br />
                            v. Cuando la rectificación, cancelación u oposición haya sido previamente realizada.
                            <br />
                            En cualquier caso, el Responsable informará el motivo de su decisión y así la comunicará, en los plazos establecidos al efecto y por el mismo medio por el que se realice la solicitud.
                            <br /><br />
                            El ejercicio de los anteriores derechos por parte del Titular será gratuito, al igual que la entrega de datos personales por parte de OKKI. No obstante, el Titular deberá cubrir los gastos justificados de envío o con el costo de reproducción en copias u otros formatos.
                            <br /><br />
                            <span>5. Sobre las Transferencias de Datos.</span>
                            <br /><br />
                            En caso que el Responsable transfiera datos personales a terceros nacionales o extranjeros, deberá comunicarles sobre la existencia y alcance del presente Aviso de Privacidad y su alcance. En cualquier caso, el tercero receptor de que se trate asumirá las mismas obligaciones que correspondan al Responsable bajo el presente Aviso de Privacidad. Las transferencias nacionales o internacionales de datos podrán llevarse a cabo sin el consentimiento del Titular cuando en los supuestos en listados en el artículo 37 de la Ley.
                            <br /><br />
                            <span>6. Procedimiento y medio por el cual el Responsable comunicará a los Titulares sobre cambios al presente Aviso de Privacidad.</span>
                            <br /><br />
                            OKKI se reserva el derecho de hacer cambios discrecionalmente al presente Aviso de Privacidad. En tal caso, estarán disponibles en nuestro sitio web: www.okki.co, o mediante comunicados en nuestras oficinas y sucursales.
                        </p>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Privacidad;
