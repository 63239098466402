import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import profileThumb from "../images/profileThumb.png";
import welcomeCover from "../images/welcomeCover.svg";
import Navbar from "./NavbarProfile";
import Compressor from 'compressorjs';

import Select from "react-select";
import { getNegocios, getCategorias, putNegocios, getRangoPrecios, CheckOkkiDomain, route, bucket_route, getCurrentNegocio } from "./API Functions";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css"
import { func } from "prop-types";
import SidebarSettings from "./SidebarSettings";
import Settings2 from "./Settings-2";

import selectIcon from "../images/select_icon.svg";
import successUrl from "../images/successUrl.svg";
import failUrl from "../images/failUrl.svg";
import mexTel from "../images/mexTel.svg";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

import CharacterCounter from 'react-character-counter'
import Modal from "react-modal";
import SettingsNegocio from "./Settings-Negocio";

import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from './NewProduct/canvasUtils'
import { styles } from './NewProduct/styles'

import zoomIcon from "../images/zoom-icon.svg";
import rotateIcon from "../images/rotate-icon.svg";

const customStylesModal = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: 900,
        maxHeight: 700,
        height: "90%",
        padding: "20px 0",
        border: "none",
        borderRadius: 12,
    },
};

const options = [
    { value: 1, label: "$1 - $99" },
    { value: 2, label: "$100 - $199" },
    { value: 3, label: "$200 - $399" },
];

export const serverDelay = (func) => (duration) =>
    new Promise((resolve, reject) =>
        setTimeout(() => {
            resolve(func());
        }, duration || 1000)
    );

export const getWhitelistFromServer = serverDelay(() => [
    "aaa",
    "aaa1",
    "aaa2",
    "aaa3",
    "bbb1",
    "bbb2",
    "bbb3",
    "bbb4",
]);

export const getValue = serverDelay(() => ["foo", "bar", "baz"]);

const baseTagifySettings = {
    blacklist: ["xxx", "sex", "porno", "pene", "p3n3", "pito", "puto", "pendejo"],
    maxTags: 5,
    //backspace: "edit",
    placeholder: "Etiquetas (máx 5)",
    /* dropdown: {
         enabled: 0, // a;ways show suggestions dropdown
     },*/
};

// callbacks props (for this demo, the same callback reference is assigned to every event type)
const tagifyCallbacks = {
    // add: callback,
    // remove: callback,
    // input: callback,
    // edit: callback,
    // invalid: callback,
    // click: callback,
    // keydown: callback,
    // focus: callback,
    // blur: callback,
    // "edit:input": callback,
    // "edit:updated": callback,
    // "edit:start": callback,
    // "edit:keydown": callback,
    // "dropdown:show": callback,
    // "dropdown:hide": callback,
    // "dropdown:select": callback
};

const pixelRatio = 4;

function getResizedCanvas(canvas, newWidth, newHeight) {
    const tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx = tmpCanvas.getContext("2d");
    ctx.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        newWidth,
        newHeight
    );

    return tmpCanvas;
}

function generateDownload(previewCanvas, crop) {
    if (!crop || !previewCanvas) {
        return;
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

    canvas.toBlob(
        blob => {
            const previewUrl = window.URL.createObjectURL(blob);

            const anchor = document.createElement("a");
            anchor.download = "cropPreview.png";
            anchor.href = URL.createObjectURL(blob);
            anchor.click();

            window.URL.revokeObjectURL(previewUrl);
        },
        "image/png",
        1
    );
}

const customStyles = {
    dropdownIndicator: base => ({
        ...base,
        color: "#081027"
    })
};

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

const SettingsPrev = ({ classes }) => {
    const tagifyRef = useRef();
    // just a name I made up for allowing dynamic changes for tagify settings on this component
    const [tagifySettings, setTagifySettings] = useState([]);
    const [tagifyProps, setTagifyProps] = useState({});


    const [negocio, setNegocio] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [rangos, setRangos] = useState([]);


    const [Loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [url_negocio, setUrlNegocio] = useState("");
    const [whatsapp, setWhatsapp] = useState(0);
    const [descripcion, setDescripcion] = useState("");
    const [categoria_id, setCategoria] = useState("");
    const [tags, setTags] = useState([]);
    const [rango, setRango] = useState("");
    const [imagen, setImagen] = useState("");
    const [file_preview, setFilePreview] = useState("");
    const [file, setFile] = useState([]);

    const [settings2, setSettings2] = useState(false);


    // crop
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    //const [crop, setCrop] = useState({ unit: "px", width: 360, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(false);
    const [crop_completed, setCropCompleted] = useState(false);
    const [completedCropImg, setCompletedCroppedImage] = useState("");

    const [profileImg, setProfileImg] = useState("");

    const [imageSrc, setImageSrc] = React.useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedImage, setCroppedImage] = useState(null)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    //data 

    const [nombreError, setNombreError] = useState("");
    const [postEmailError, setPostEmailError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [nombre_negocioError, setNombre_negocioError] = useState("");
    const [url_negocioError, setUrl_negocioError] = useState("");
    const [whatsappError, setWhatsappError] = useState("");
    const [descripcionError, setDescripcionError] = useState("");
    const [tagsError, setTagsError] = useState("");
    const [categoriaError, setCategoriaError] = useState("");
    const [rangoError, setRangoError] = useState("");
    const [imagenError, setImagenError] = useState([]);
    const [is_editing, setEditing] = useState(false);


    const [dominio_okkiError, setDominio_okkiError] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    //EASY CROP FUNCTIONALITY
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation,
                "producto_image"
            )
            var fileData = new FileReader();
            fileData.readAsDataURL(croppedImage)
            //console.log(croppedImage)
            fileData.onloadend = () => {
                setFilePreview(fileData.result);
                setCropCompleted(true);
                setCroppedImage(compress_image(croppedImage))
                setCompletedCroppedImage(compress_image(croppedImage));
                closeModal();
            };


        } catch (e) {
            console.error(e)

        }
    }, [imageSrc, croppedAreaPixels, rotation])

    const onFileChange = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file_to_compress = e.target.files[0]
            const file = compress_image(file_to_compress);
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            setImagen(file);
            setImageSrc(imageDataUrl)
            setEditing(true);
            openModal();
        }

    }

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        getCurrentNegocio().then(res => {
            const negocio = res;
            console.log(negocio)
            setNombre(negocio.nombre);
            setEmail(negocio.user.correo.toLowerCase());
            setWhatsapp(negocio.whatsap ? negocio.whatsap : "");
            if (negocio.nuevo && !negocio.dominio_okki) {
                //setTags("");
                setDescripcion(negocio.descripcion ? negocio.descripcion : "");
                setUrlNegocio(negocio.dominio_okki ? negocio.dominio_okki : "");
            } else {
                //setTags(JSON.parse(negocio.tags_ids));
                setDescripcion(negocio.descripcion);
                setUrlNegocio(negocio.dominio_okki);
                if (negocio.imagen_url) {
                    setImagen(negocio.imagen_url);
                    setProfileImg(negocio.imagen_url);
                    setFilePreview(negocio.imagen_url);
                }
            }
            const categorias = [];
            negocio.categorias.map((categoria, index) => {
                if (categoria.tipo_id == negocio.tipos[0].id) {
                    categoria = {
                        ...categoria,
                        value: categoria.id,
                        label: categoria.detalle
                    }
                    categorias.push(categoria);
                    if (categoria.id == negocio.categoria_id) {
                        setCategoria(categoria);
                    }
                }
            })
            setCategorias(categorias);

            const rangos = [];
            negocio.rangos.map((rango, index) => {
                rango = {
                    ...rango,
                    value: rango.id,
                    label: rango.detalle
                }
                rangos.push(rango);
                if (rango.id == negocio.rango_id) {
                    setRango(rango);
                }
            });
            setRangos(rangos);

            setNegocio(negocio);
        })
        //setTagifyProps({ loading: true });

        getWhitelistFromServer(2000).then((response) => {
            setTagifySettings((lastState) => ({
                ...lastState,
                whitelist: response,
            }));

            //setTagifyProps({ showDropdown: "a", loading: false });
        });
    }, [])

    // merged tagify settings (static & dynamic)
    const settings = {
        ...baseTagifySettings,
        ...tagifySettings,
        callbacks: tagifyCallbacks,
    };

    const onChange = useCallback((e) => {
        e.persist();
        console.log("CHANGED:", e.target.value);
        const tags = tagifyRef.current.getTagElms()
        console.log(tags)
    }, []);

    // access Tagify internal methods example:
    const clearAll = () => {
        tagifyRef.current && tagifyRef.current.removeAllTags();
    };

    function completeCrop(crop) {
        setCompletedCrop(crop)
    }

    function validateFields() {
        let nombreError = ""
        let imagenError = ""
        let emailError = ""
        let nombre_negocioError = ""
        let url_negocioError = ""
        let envio_idError = ""
        let whatsappError = ""
        let descripcionError = ""
        let tagsError = ""
        let categoriaError = ""
        let rangoError = ""

        if (nombre == "") {
            nombreError = "Requerido"
        }
        if (imagen == "") {
            imagenError = "Agrega una imagen de logotipo"
        }
        if (email == "") {
            emailError = "Requerido"
        }
        if (url_negocio == "") {
            url_negocioError = "Requerido"
        } else {
            const letters = /^[A-Za-z0-9_-]+$/;
            if (!letters.test(url_negocio)) {
                url_negocioError = "Formato no valido"
            }
        }
        if (whatsapp == "") {
            whatsappError = "Requerido"
        } else {
            whatsappError = validateNumberLength(whatsapp);
        }
        if (descripcion == "") {
            descripcionError = "Requerido"
        }
        /*
        if (tags == "") {
            tagsError = "Requerido"
        }
        if (categoria_id == "") {
            categoriaError = "Selecciona una opción"
        }
        if (rango == "") {
            rangoError = "Selecciona una opción"
        }
        */

        if (nombreError || imagenError || emailError || nombre_negocioError || url_negocioError || envio_idError || whatsappError || descripcionError || rangoError) {
            setNombreError(nombreError)
            setImagenError(imagenError)
            setEmailError(emailError)
            setNombre_negocioError(nombre_negocioError)
            setUrl_negocioError(url_negocioError)
            setWhatsappError(whatsappError)
            setDescripcionError(descripcionError)
            setTagsError(tagsError)
            setCategoriaError(categoriaError)
            setRangoError(rangoError)
            return false
        } else {
            setNombreError(nombreError)
            setImagenError(imagenError)
            setEmailError(emailError)
            setNombre_negocioError(nombre_negocioError)
            setUrl_negocioError(url_negocioError)
            setWhatsappError(whatsappError)
            setDescripcionError(descripcionError)
            setTagsError(tagsError)
            setCategoriaError(categoriaError)
            setRangoError(rangoError)
            return true
        }
    }

    function handleCategoria(selectedOption) {
        setCategoria(selectedOption);
    }

    function handleRango(selectedOption) {
        setRango(selectedOption);
    }


    function handleChangeFile(files) {
        setCropCompleted(false);
        setImagen([]);
        setFile([]);
        openModal();
        setEditing(false);
        console.log(files);
        var fileData = new FileReader();
        const length = files.length;
        const files_l = files.length > 0 ? 1 : null;
        if (files_l !== null) {
            let i = 0;
            let valid_files = 0;
            while (i < files.length) {
                if ((files[i].type == "image/png" || files[i].type == "image/jpg" || files[i].type == "image/jpeg" || files[i].type == "image/gif") && files[i].size < 5000000) {
                    valid_files = valid_files + 1;
                }
                i++;
            }
            console.log(valid_files)
            if (valid_files == 1) {
                var _URL = window.URL || window.webkitURL;
                const img = new Image();
                var objectUrl = _URL.createObjectURL(files[0]);
                img.onload = function () {
                    //alert(this.width + " " + this.height);
                    _URL.revokeObjectURL(objectUrl);
                    if (this.width !== 1040 && this.width !== 1200) {
                        fileData.readAsDataURL(files[0])
                        fileData.onloadend = () => {
                            setFilePreview(fileData.result)
                        };
                        setImagen(files);
                        setEditing(true);
                        setFile(files)
                    } else {
                        setImagenError("Las dimensiones de la imagen no estan permitidas.");
                        setTimeout(() => {
                            setImagenError("");
                        }, 2000);
                    }
                };
                img.src = objectUrl;

                //console.log(img)

                //console.log(fileData)
            } else {
                setImagenError("Los archivos deben de pesar menos de 5 MB");
                setTimeout(() => {
                    setImagenError("");
                }, 2000);
            }
        }
    };

    function updateDomain(e) {
        const value = e.target.value
        setUrlNegocio(value);

        const data = {
            dominio_okki: value
        }

        CheckOkkiDomain(data).then(res => {
            if (res) {
                //setUrl_negocioError("El dominio esta disponible")
                setDominio_okkiError(false)
            } else {
                //setUrl_negocioError("El dominio ya existe")
                setDominio_okkiError(true)
            }
        })
    }

    async function handleSubmit() {
        setLoading(true)
        /*
        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }

        const data = {
            id: negocio.id,
            tags_ids: JSON.stringify(tags),
            nombre: nombre,
            codigo_postal: "0000",
            direccion: "provicional",
            website: negocio.website,
            whatsap: negocio.whatsap,
            facebook: "provicional",
            instagram: "provicional",
            telefono: negocio.whatsap,
            imagen_url: "provicional",
            categoria_id: categoria_id.id,
            descripcion: descripcion,
            dominio_okki: url_negocio,
            rango_id: rango.id,
            zona: negocio.zona,
            persona_contacto: negocio.persona_contacto,
            precio_envio: negocio.precio_envio,
            tipos: [negocio.tipos[0].id],
            envios: [negocio.envios[0].id],
            ciudad_id: 0
        }
        */

        const isValid = validateFields()
        if (isValid) {

            var formData = new FormData();

            formData.append("image[]", completedCropImg);

            formData.append("id", negocio.id);
            formData.append("nombre", nombre);
            formData.append("codigo_postal", negocio.codigo_postal ? negocio.codigo_postal : 0);
            formData.append("direccion", negocio.direccion);
            formData.append("website", negocio.website);
            formData.append("whatsap", whatsapp);
            formData.append("facebook", negocio.facebook);
            formData.append("instagram", negocio.instagram);
            formData.append("telefono", whatsapp);
            formData.append("categoria_id", negocio.categoria_id);
            formData.append("descripcion", descripcion);
            const dominio = url_negocio.replace(/ /g, '-');
            formData.append("dominio_okki", dominio);
            formData.append("rango_id", negocio.rango_id ? negocio.rango_id : 0);
            formData.append("zona", negocio.zona);
            formData.append("persona_contacto", negocio.persona_contacto);
            formData.append("precio_envio", negocio.precio_envio);
            formData.append("tipos", [negocio.tipos[0].id]);
            formData.append("envios", [negocio.envios[0].id]);
            formData.append("ciudad_id", negocio.ciudad_id);

            const data = {
                id: negocio.id,
                data: formData
            }
            putNegocios(data).then(res => {
                if (res) {
                    if (negocio.nuevo) {
                        localStorage.setItem('negocio', JSON.stringify(res))
                        setNegocio(res);
                        changeSettings(res);
                    } else {
                        localStorage.setItem('negocio', JSON.stringify(res))
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000);
                    }
                }
            })
        }
        setTimeout(() => {
            setLoading(false);
            setDominio_okkiError("");
        }, 2000);
    }
    function changeSettings(negocio) {
        setSettings2(true);
    }

    async function finished_crop() {
        const croppedImg = await getCroppedImg(imgRef.current, completedCrop, "producto_image");
        var fileData = new FileReader();
        fileData.readAsDataURL(croppedImg)
        fileData.onloadend = () => {
            setFilePreview(fileData.result);
        };
        setCropCompleted(true);
        setCroppedImage(croppedImg);
    }

    function clearPreview() {
        setFilePreview("")
        setCropCompleted(false)
        setImagen([])
    }

    function OngetCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        // As Base64 string
        // const base64Image = canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/png', 1);
        });
    }

    function validateNumberLength(value) {
        var msg = ""
        if (value.length > 10 || value.length < 10) {
            msg = "El número debe ser de 10 dígitos";
        }
        setWhatsappError(msg);
        return msg
    }

    function compress_image(file) {
        const compressed = new Compressor(file, {
            quality: 0.6,
            maxHeight: 800,
            maxWidth: 800,
            mimeType: 'image/jpeg'
        });

        //console.log(compressed,'compress')
        return compressed.file
    }



    return (
        <>{settings2 ?
            <SettingsNegocio negocio={negocio} />
            :
            negocio.id ?
                <>
                    {negocio.nuevo ?
                        <div className="four columns settings-form__sidebar onlyDesktop" style={{ marginLeft: 0 }}>

                            <div className="settings-header settings-headerModal">
                                <div className="settings-header__breadcrumb settings-header__breadcrumb-title">
                                    <h3 className="settings-header__title">Configuración</h3>

                                    <hr className="settingsDivider" />
                                </div>
                            </div>
                            <ul className="settings-form__menu settings-form__menuList">
                                <li>
                                    <Link className="active">
                                        Perfil
                                    </Link>
                                </li>
                                <li>
                                    <Link className="">
                                        Negocio
                                    </Link>
                                </li>
                                <li>
                                    <Link className="">
                                        Dirección y Envíos
                                    </Link>
                                </li>
                                <li>
                                    <Link className="">
                                        Redes Sociales
                                    </Link>
                                </li>
                                <li>
                                    <Link className="">
                                        Cuenta
                                    </Link>
                                </li>
                                <li>
                                    <Link className="">
                                        Plan
                                    </Link>
                                </li>
                                {/* <li>
                        <Link to="/pro" target="_blank" activeClassName="active">
                          PRO
                                    </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: `/${this.state.negocio.dominio_okki}` }} activeClassName="active">
                          Mi perfil
                                    </Link>
                      </li> */}
                            </ul>

                        </div>
                        : null}

                    <div className="eight columns settings-form__container">
                        <div className="settings-header settings-form__row settings-headerModal">
                            <div className="settings-form__column settings-form__columnLabel">
                                <label>
                                    Logotipo
                            </label>
                            </div>
                            <div className="settings-form__column columnRow settings-form__columnField">
                                {is_editing ?
                                    <img className="settings-form__thumb" src={file_preview} />
                                    :
                                    <img className="settings-form__thumb" src={negocio.imagen_url ? `${bucket_route}${negocio.imagen_url}` : profileThumb} />
                                }
                                <div className="inputFile-container">
                                    <input
                                        id="files"
                                        type="file"
                                        multiple
                                        name="files"
                                        accept=".png, .jpeg, .jpg, .gif"
                                        className="inputFile"
                                        // onChange={uploadImage}
                                        onChange={onFileChange}
                                    />
                                    <label for="files">Carga tu logo</label>
                                </div>

                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column settings-form__columnLabel">
                            </div>

                            <div className="settings-form__column settings-form__columnField">

                                <Modal
                                    isOpen={modalIsOpen}
                                    onRequestClose={closeModal}
                                    style={customStylesModal}
                                    contentLabel="Example Modal"
                                    id="productItemModalCrop"
                                >
                                    <div className="modalCrop">
                                        <svg className="modalCloseButton onlyMobile" onClick={closeModal} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" stroke="#EEEEEE" />
                                            <path d="M25.2422 16.7574L16.7569 25.2427" stroke="#66666A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M25.2431 25.2427L16.7578 16.7574" stroke="#66666A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <p className="modalCrop-title">
                                            Editar Imagen
                                    </p>
                                        <div className="modalCrop-container">
                                            {imageSrc ? (
                                                <React.Fragment>
                                                    <div className={classes.cropContainer}>
                                                        <Cropper
                                                            image={imageSrc}
                                                            crop={crop}
                                                            rotation={rotation}
                                                            zoom={zoom}
                                                            aspect={1}
                                                            onCropChange={setCrop}
                                                            onRotationChange={setRotation}
                                                            onCropComplete={onCropComplete}
                                                            onZoomChange={setZoom}
                                                        />
                                                    </div>
                                                    <div className={classes.controls}>
                                                        <button onClick={closeModal} className="button button-alphaAlt onlyDesktop">
                                                            Cancelar
                                                        </button>
                                                        <div className={classes.sliderContainer}>
                                                            <Typography
                                                                variant="overline"
                                                                classes={{ root: classes.sliderLabel }}
                                                            >
                                                                <img src={zoomIcon} />
                                                            </Typography>
                                                            <Slider
                                                                value={zoom}
                                                                min={1}
                                                                max={3}
                                                                step={0.1}
                                                                aria-labelledby="Zoom"
                                                                classes={{ container: classes.slider }}
                                                                onChange={(e, zoom) => setZoom(zoom)}
                                                            />
                                                        </div>
                                                        <div className={classes.sliderContainer}>
                                                            <Typography
                                                                variant="overline"
                                                                classes={{ root: classes.sliderLabel }}
                                                            >
                                                                <img src={rotateIcon} />
                                                            </Typography>
                                                            <Slider
                                                                value={rotation}
                                                                min={0}
                                                                max={360}
                                                                step={1}
                                                                aria-labelledby="Rotation"
                                                                classes={{ container: classes.slider }}
                                                                onChange={(e, rotation) => setRotation(rotation)}
                                                            />
                                                        </div>
                                                        <Button
                                                            onClick={showCroppedImage}
                                                            variant="contained"
                                                            color="primary"
                                                            classes={{ root: classes.cropButton }}
                                                        >
                                                            Recortar
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.375 14.125C15.375 14.65 15.15 15.1 14.85 15.4C14.475 15.775 14.025 16 13.5 16C12.45 16 11.625 15.175 11.625 14.125C11.625 13.525 11.925 12.925 12.375 12.625C12.675 12.4 13.05 12.25 13.5 12.25C14.55 12.25 15.375 13.075 15.375 14.125Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M4.875 4L10.125 10L12.375 12.625" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M8.625 14.125C8.625 15.175 7.8 16 6.75 16C6.225 16 5.7 15.775 5.4 15.4C5.1 15.1 4.875 14.65 4.875 14.125C4.875 13.075 5.7 12.25 6.75 12.25C7.125 12.25 7.5 12.4 7.875 12.625C8.325 12.925 8.625 13.525 8.625 14.125Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M15.375 4L10.125 10L7.875 12.625" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                    {/*
          <ImgDialog img={croppedImage} onClose={onClose} />
          */
                                                    }
                                                </React.Fragment>
                                            ) : (
                                                    <input type="file" onChange={onFileChange} accept="image/*" />
                                                )}
                                        </div>
                                    </div>

                                </Modal>


                                {/* <div>
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                width: completedCrop?.width ?? 0,
                                height: completedCrop?.height ?? 0
                            }}
                        />
                    </div> */}
                                {/* <button
                        type="button"
                        disabled={!completedCrop?.width || !completedCrop?.height}
                        onClick={() =>
                            generateDownload(previewCanvasRef.current, completedCrop)
                        }
                    >
                        Download cropped image
                    </button> */}
                                <div className="fieldError">
                                    {imagenError}
                                </div>
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column settings-form__columnLabel">
                                <label>Nombre del negocio</label>
                            </div>
                            <div className="settings-form__column settings-form__columnField">
                                <input
                                    className={nombreError ? "settings-form__input inputError" : "settings-form__input"}
                                    type="text"
                                    maxLength="64"
                                    placeholder="Negocio"
                                    value={nombre ? nombre : null}
                                    onChange={e => setNombre(e.target.value)}
                                />
                                <div className="fieldError">
                                    {nombreError}
                                </div>
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column settings-form__columnLabel">
                                <label>Okki URL</label>
                            </div>
                            <div className="settings-form__column settings-form__columnField">
                                <p className="okkiurlInput-placeholder">okki.co/</p>
                                <input
                                    className={url_negocioError ? "settings-form__input okkiurlInput inputError" : "settings-form__input okkiurlInput"}
                                    type="text"
                                    maxLength="64"
                                    placeholder="okkiurl"
                                    pattern="[A-Za-z]"
                                    value={url_negocio ? url_negocio : null}
                                    onChange={(e) => { updateDomain(e) }}
                                />
                                <div className="fieldError">
                                    {url_negocioError}
                                </div>
                                {url_negocio !== "" ?
                                    <>
                                        <div className={dominio_okkiError ? "fieldSuccess-hide" : "fieldSuccess"}>
                                            <img src={successUrl} />
                                        </div>
                                        <div className={dominio_okkiError ? "fieldErrorUrl" : "fieldErrorUrl-hide"}>
                                            <img src={failUrl} />
                                        </div>
                                    </>
                                    :
                                    null
                                }

                            </div>

                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column settings-form__columnLabel">
                                <label>Whatsapp</label>
                            </div>
                            <div className="settings-form__column settings-form__columnField">
                                <p className="phoneInput-placeholder">
                                    <img src={mexTel} />
                                +52
                            </p>
                                <input
                                    className={whatsappError ? "settings-form__input phoneInput inputError" : "settings-form__input phoneInput"}
                                    type="number"
                                    maxlength="10"
                                    value={whatsapp}
                                    placeholder="81 1234 5678"
                                    // disabled
                                    onChange={(e) => {
                                        setWhatsapp(e.target.value)
                                        validateNumberLength(e.target.value)
                                    }
                                    }
                                />
                                <div className="fieldError">
                                    {whatsappError}
                                </div>
                            </div>

                        </div>

                        <div className="settings-form__row">
                            <div className="settings-form__column settings-form__columnLabel">
                                <label>Correo electrónico</label>
                            </div>
                            <div className="settings-form__column settings-form__columnField">
                                <input
                                    className={emailError ? "settings-form__input inputError" : "settings-form__input"}
                                    type="email"
                                    placeholder="ejemplo@correo.com"
                                    value={email ? email : null}
                                />
                                <div className="fieldError">
                                    {emailError}
                                </div>
                            </div>
                        </div>

                        <div className="settings-form__row">
                            <div className="settings-form__column settings-form__columnLabel">
                                <label>Biografía</label>
                            </div>
                            <div className="settings-form__column settings-form__columnField settings-form__columnDescripcion settings-form__charCounter">
                                <CharacterCounter
                                    value={descripcion}
                                    maxLength={250}>
                                    <textarea
                                        maxLength={250}
                                        className={descripcionError ? "settings-form__input inputError" : "settings-form__input"}
                                        value={descripcion}
                                        onChange={e => setDescripcion(e.target.value)} />
                                </CharacterCounter>
                                <div className="fieldError">
                                    {descripcionError}
                                </div>
                                <br className="onlyDesktop" /><br className="onlyDesktop" />
                            </div>


                        </div>



                    </div>

                    <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                        <a>{" "}</a>
                        <a className="button" onClick={handleSubmit}>
                            {Loading && (
                                <i className="" style={{ marginRight: "5px" }} />
                            )}
                            {Loading && (
                                'Guardando...'
                            )}
                            {!Loading && (
                                negocio.nuevo ?
                                    'Continuar'
                                    :
                                    'Guardar'
                            )}
                        </a>
                    </div>
                </>
                :
                null

        }
        </>
    );
}

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const Settings = withStyles(styles)(SettingsPrev);

export default Settings;
