import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import adsCover from "../../images/adsCard.png";
import itemThumb from "../../images/itemThumb.svg";
import itemProfile from "../../images/itemProfile.svg";
import profileThumb2 from "../../images/profileThumb2.svg";
import Fade from "react-reveal/Fade";
import ReactTooltip from "react-tooltip";
import useModalProductos from '../useModalProductos'

import NumberFormat from 'react-number-format';

import "react-morphing-modal/dist/ReactMorphingModal.css";
import useCategoryProfiles from "../Categories/UseCategoryProfiles";
import CategoryProfiles from "../Categories/CategoryProfiles";
import { bucket_route } from "../API Functions";

function Negocios({negocios}) {
    const { isShowing, toggle } = useCategoryProfiles();
    const { flagsShowing, flags } = useCategoryProfiles();
    const { shareShowing, share } = useCategoryProfiles();
    const { popupShare, popup } = useCategoryProfiles();

    function showCategoryProfiles() {
        toggle();
    }

    return(
        <>
        <div className="container centerFlex">
                    <div className="home-cover__profilesResult  search-header__profiles">
                       {negocios.map((negocio,index)=>(
                            <div className="profile-card">
                            <Link to={`/${negocio.dominio_okki}`}>
                                <div className={negocio.pro ? "profile-cardHome__icon profile-cardHome__iconPro" : "profile-cardHome__icon"}>
                                    <img className="profile-cardHome__icon" src={negocio.imagen_url !== '' ? `${bucket_route}${negocio.imagen_url}` : profileThumb2} />
                                </div>
                            </Link>
                            <p className="profile-card__name">{negocio.nombre}</p>
                        </div>
                       ))
                       }
                        
                        <div className="profile-card profile-card__action">
                            <svg onClick={showCategoryProfiles} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30" cy="30" r="30" fill="white" />
                                <path d="M31 35L36 30L31 25" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M36 30H24" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>

                    </div>
                </div>

        <CategoryProfiles
        isShowing={isShowing}
        hide={toggle}
        negocios={negocios}
        />
</>
    );
}

export default Negocios;