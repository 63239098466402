import axios from "axios";
import { data } from "isotope-layout";
import NewSettings from "./NewSettings";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

//export const route = "https://back-okki-test.lagom.agency"
export const route = "https://back.okki.co"
//export const route = "http://127.0.0.1:8000"

//export const bucket_route = "https://okki.s3.amazonaws.com/"
//export const bucket_route = "http://127.0.0.1:8000/storage/"
//export const bucket_route = "https://back-okki-test.lagom.agency/storage/"
export const bucket_route = "https://back.okki.co/storage/"

export const user_token = cookies.get('user_token');

//USER REQUESTS
export const registerUser = (newUser) => {
    return axios
        .post(`${route}/api/register/`, newUser, {
            headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
            console.log(res);
            //localStorage.setItem("user_token", res.data.data.token);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const loggedIn = () => {
    return axios
        .get(`${route}/api/logged_in`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            //window.location.replace("/login")
        });
};

export const PreloadProductImage = (data) => {
    return axios
        .post(`${route}/api/preload_product_images/`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const logOut = (newUser) => {
    if (true) {
        sessionStorage.clear();
        localStorage.clear();
        cookies.remove('user_token');
        window.location.replace("/login");
    }
};

export const updateUser = (newUser) => {
    return axios
        .post(`${route}/api/update_user/`, newUser, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const loginUser = (newUser) => {
    return axios
        .post(`${route}/api/login/`, newUser, {
            headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
            if (res.rol_id !== 1) {
                localStorage.setItem('negocio', JSON.stringify(res.data.data.negocio))
                return res.data.data;
                //sessionStorage.setItem("user_token", res.data.data.token);
                //sessionStorage.setItem('subcategorias', JSON.stringify(res.data.data.subcategorias));
                //sessionStorage.setItem('productos', JSON.stringify(res.data.data.productos))
                //console.log(res.data.data.negocio.nuevo)
            }

        })
        .catch((err) => {
            console.log(err);
        });
};

export const postRecoverPassword = data => {
    return axios
        .post(`${route}/api/password/create`, data, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res;
        })
        .catch(err => {
            console.log(err)
        });
};

export const getMailFromToken = token => {
    return axios
        .get(`${route}/api/password/find/${token}`, {
            headers: { "Content-Type": "application/json" }
        })
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const resendEmailToken = () => {
    return axios
        .get(`${route}/api/resend_access_token/`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const newPassword = data => {
    return axios
        .post(`${route}/api/password/reset`, data, {
            headers: { "Content-Type": "application/json" }
        })
        .then(res => {
            console.log(res);
            return res
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteAccount = () => {
    return axios
        .get(`${route}/api/delete_account`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postMediaClickCounter = (data) => {
    return axios
        .post(`${route}/api/media_click_counter/`, data, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => {
            console.log(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};



//BACKOFFICE ROUTES

export const getBackofficeUsers = () => {
    return axios
        .get(`${route}/api/backoffice/list`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postChangeSuscriptionStatus = (data) => {
    return axios
        .post(`${route}/api/change_suscription_status/`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getTipos = () => {
    return axios
        .get(`${route}/api/tipos`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getRecommendedProducts = () => {
    return axios
        .get(`${route}/api/recommended_products`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPublicCiudades = () => {
    return axios
        .get(`${route}/api/ciudades_public`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPublicEstados = () => {
    return axios
        .get(`${route}/api/estados_public`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

//NEGOCIOS REQUESTS
export const putNegocios = (data) => {
    console.log(data)
    return axios
        .post(`${route}/api/negocios/${data.id}?_method=PUT`, data.data, {
            headers: { "Authorization": `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
}

export const getNegocios = id => {
    return axios
        .get(`${route}/api/negocios/${id}`, {
            headers: { 'Authorization': `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getCurrentNegocio = () => {
    return axios
        .get(`${route}/api/current_negocio/`, {
            headers: { 'Authorization': `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getStats = id => {
    return axios
        .get(`${route}/api/estadisticas/${id}`, {
            headers: { 'Authorization': `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getVisitStats = timeSpan => {
    return axios
        .get(`${route}/api/estadisticas_visitas/${timeSpan}`, {
            headers: { 'Authorization': `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const CheckOkkiDomain = data => {
    return axios
        .post(`${route}/api/check_okki_domain/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const getNegocioByDomain = data => {
    return axios
        .post(`${route}/api/negocio_domain/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const getConektaCustomer = () => {
    return axios
        .get(`${route}/api/conekta_data/`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const postConektaSuscription = data => {
    return axios
        .post(`${route}/api/conekta_suscription/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const postSuscripcionData = data => {
    return axios
        .put(`${route}/api/suscripciones/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};




//CATEGORIAS REQUESTS

export const getCategoriasTipo = data => {
    return axios
        .post(`${route}/api/categorias_tipo/`,data, {
            headers: { 'Authorization': `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getCategoriasCategorias = tipo_id => {
    return axios
        .get(`${route}/api/categorias_categorias/${tipo_id}`, {
            headers: { 'Authorization': `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getFilters = () => {
    return axios
        .get(`${route}/api/load_filters/`, {
            headers: { 'Authorization': `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getCategorias = (id) => {
    return axios
        .get(`${route}/api/categorias/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postCategoria = data => {
    return axios
        .post(`${route}/api/categorias/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putCategoria = (data) => {
    return axios
        .put(`${route}/api/categorias/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteCategoria = (data) => {
    return axios
        .delete(`${route}/api/categorias/${data}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

// CIUDAD REQUESTS

export const getCiudades = () => {
    return axios
        .get(`${route}/api/ciudades/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postCiudad = data => {
    return axios
        .post(`${route}/api/ciudades/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const getCiudadesByEstado = data => {
    return axios
        .post(`${route}/api/ciudades_by_estado_id/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putCiudad = (data) => {
    return axios
        .put(`${route}/api/ciudades/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteCiudad = (data) => {
    return axios
        .delete(`${route}/api/ciudades/${data}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

// ESTADO REQUESTS

export const getEstados = () => {
    return axios
        .get(`${route}/api/estados/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postEstado = data => {
    return axios
        .post(`${route}/api/estados/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putEstados = (data) => {
    return axios
        .put(`${route}/api/estados/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

// CUPONES REQUEST  

export const getCupones = () => {
    return axios
        .get(`${route}/api/cupones/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postCupon = data => {
    return axios
        .post(`${route}/api/cupones/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putCupon = (data) => {
    return axios
        .put(`${route}/api/cupones/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteCupon = (data) => {
    return axios
        .delete(`${route}/api/cupones/${data}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getCuponByCodigo = (code) => {
    return axios
        .get(`${route}/api/cupon/${code}`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};


// RANGO PRECIOS REQUESTS
export const getRangoPrecios = () => {
    return axios
        .get(`${route}/api/rango_precios/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postRangoPrecio = data => {
    return axios
        .post(`${route}/api/rango_precios/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putRangoPrecio = (data) => {
    return axios
        .put(`${route}/api/rango_precios/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteRangoPrecio = (data) => {
    return axios
        .delete(`${route}/api/rango_precios/${data}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

// BLACKLIST REQUESTS
export const getBlacklist = () => {
    return axios
        .get(`${route}/api/blacklist/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postBlacklist = data => {
    return axios
        .post(`${route}/api/blacklist/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putBlacklist = (data) => {
    return axios
        .put(`${route}/api/blacklist/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteBlacklist = (data) => {
    return axios
        .delete(`${route}/api/blacklist/${data}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

// PRODUCTOS REQUEST

export const putProductos = (data) => {
    console.log(data)
    return axios
        .post(`${route}/api/productos/${data.id}?_method=PUT`, data.data, {
            headers: { "Authorization": `Bearer ${user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
}

export const getProductos = (id) => {
    return axios
        .get(`${route}/api/productos/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getProductosPurchase = (id) => {
    return axios
        .get(`${route}/api/producto_purchase/${id}`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getProductosByID = (id) => {
    return axios
        .get(`${route}/api/productos/${id}`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};
export const getProductosByNombre = (data) => {
    return axios
        .post(`${route}/api/productos_by_name`, data,{
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postProductos = data => {
    return axios
        .post(`${route}/api/productos/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteProducto = (data) => {
    return axios
        .delete(`${route}/api/productos/${data}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const UpdateProductoStatus = (data) => {
    return axios
        .post(`${route}/api/producto_status/`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getProductosProfile = (negocio_id) => {
    return axios
        .get(`${route}/api/productos_profile/${negocio_id}`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

// SUBCATEGORIAS
export const getSubcategorias = (id) => {
    return axios
        .get(`${route}/api/subcategorias/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postSubcategoria = data => {
    return axios
        .post(`${route}/api/subcategorias/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putSubcategorias = (data) => {
    return axios
        .put(`${route}/api/subcategorias/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteSubcategoria = (data) => {
    return axios
        .delete(`${route}/api/subcategorias/${data}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getSubcategoriasProfile = (negocio_id) => {
    return axios
        .get(`${route}/api/subcategorias_profile/${negocio_id}`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postSubcategoriaArrange = data => {
    return axios
        .post(`${route}/api/change_arrange_index/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

// FLAGS
export const getFlags = (id) => {
    return axios
        .get(`${route}/api/flags/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postFlag = data => {
    return axios
        .post(`${route}/api/flags/`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user_token}`
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putFlag = (data) => {
    return axios
        .put(`${route}/api/flags/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteFlag = (data) => {
    return axios
        .delete(`${route}/api/flags/${data}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const UpdateFlagStatus = (data) => {
    return axios
        .post(`${route}/api/flag_status/`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user_token}`,
            },
        })
        .then((res) => {
            console.log(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
};

//SEARCH TEST
export const getSearchTest = () => {
    return axios
        .get(`${route}/api/search_test/`,{
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

// HOME ROUTES
export const getProductosHome = (data) => {
    return axios
        .post(`${route}/api/home/`, data,{
            headers: { 'Access-Control-Allow-Origin': `*` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getNegociosHome = (id) => {
    return axios
        .get(`${route}/api/negocios_home/`, {
            headers: { 'Access-Control-Allow-Origin': `*` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const loadNextProductPage = (url) => {
    return axios
        .get(`${url}`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const loadNextPage = (url) => {
    return axios
        .get(`${url}`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const loadNextPagePost = (url,data) => {
    return axios
        .post(`${url}`,data, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

//SEARCH RESULTS

export const SearchResults = data => {
    return axios
        .post(`${route}/api/search/`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const SearchTags = data => {
    return axios
        .post(`${route}/api/search_tags/`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const SearchTyping = data => {
    return axios
        .post(`${route}/api/search_typing/`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const loadNextProductPageSearch = data => {
    return axios
        .post(`${data.url}`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

//ORDER PAYMENTS
export const postConektaOrder = data => {
    return axios
        .post(`${route}/api/card_payment/`, data, {
            headers: {
                "Authorization": `Bearer ${localStorage.user_token}`
            }
        })
        .then(res => {
            console.log(res.data.token);
            return res
        })
        .catch(err => {
            console.log(err.response, 'test');
            return err.response
        });
};

export const pauseConektaSuscription = (id) => {
    return axios
        .get(`${route}/api/pause_suscription/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const resumeConektaSuscription = (id) => {
    return axios
        .get(`${route}/api/resume_suscription/`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

//REPORT ROUTES
export const getSuscriptions = () => {
    return axios
        .get(`${route}/api/backoffice/reports`, {
            headers: { Authorization: `Bearer ${user_token}` },
        })
        .then((res) => {
            console.log(res);
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};