import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import { getRecommendedProducts } from "./API Functions";
import ProductList from "./Products/ProductsList";


function ErrorSearch({keywords}) {
    const [products, setProducts] = useState([]);
    useEffect(()=>{
        getRecommendedProducts().then(res=>{
            if(res){
                setProducts(res)
            }
        })
    },[])
 return (
  <div>
   <div className="home-gallery container searchError-container">
    <p className="searchError-message">
     No existen resultados para tu búsqueda: <span>{keywords}</span>
    </p>
   </div>
   <hr style={{ marginBottom: 0 }} />
   <div className="home-gallery container">
    <div className="home-gallery__headerResults row">
     <div className="four columns settings-header__breadcrumb">
      <h3 className="titleMin">Recomendado</h3>
     </div>
    </div>

    <ProductList
    productos={products}
    />
   </div>

  </div>
 );
}

export default ErrorSearch;
