import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import venderThumb from "../images/vender-1.svg";
import selectArrow from "../images/selectArrow.svg";
import profileTable from "../images/profileTable.svg";
import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import MaterialTable from "material-table";

import Modal from "react-modal";
import Sidebar from "./Sidebar";
import { getCategorias, getTipos, postCategoria, deleteCategoria, putCategoria, loggedIn } from "./API Functions";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        padding: "50px 40px",
        border: "none",
        borderRadius: 12,
    },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Backoffice2() {
    var subtitle;


    const [modalIsOpenDelete, setIsOpenDelete] = React.useState(false);
    const [modalIsOpenEdit, setIsOpenEdit] = React.useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const [categorias, setCategorias] = useState([]);
    const [tipos, setTipos] = useState([]);

    const [current_tipo_id, setCurrentTipoID] = useState(1);
    const [tipo_id, setTipoID] = useState("");
    const [detalle, setDetalle] = useState("");

    const [tipo_id_edit, setTipoIDEdit] = useState("");
    const [detalle_edit, setDetalleEdit] = useState("");

    const [categoria_delete, setCategoriaDelete] = useState("");
    const [categoria_edit, setCategoriaEdit] = useState("");

    const [tipoError, setTipoError] = useState("");
    const [detalleError, setDetalleError] = useState("");

    const [loading, setLoading] = useState(false);

    const [condition, setCondition] = useState(true);
    const [condition2, setCondition2] = useState(false);
    const [condition3, setCondition3] = useState(false);

    useEffect(() => {
        loggedIn();
        setLoading(true);
        loadCategorias(1);
        getTipos().then(res => {
            setTipos(res);
            setLoading(false);
        })

    }, [])


    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function validateFields() {
        let tipoError = ""
        let detalleError = ""
        if (tipo_id == "") {
            tipoError = "Selecciona una opción"
        }
        if (detalle == "") {
            detalleError = "Requerido"
        }
        if (tipoError || detalleError) {
            setTipoError(tipoError);
            setDetalleError(detalleError);
            return false
        } else {
            setTipoError(tipoError);
            setDetalleError(detalleError);
            return true
        }
    }

    function handleCreate() {
        const data = {
            tipo_id: tipo_id,
            detalle: detalle
        }
        const isValid = validateFields()
        if (isValid) {
            postCategoria(data).then(res => {
                setLoading(true);
                closeModal();
                loadCategorias(current_tipo_id);
            })
        }
    }

    function openModalDelete(id_categoria) {
        setCategoriaDelete(id_categoria);
        setIsOpenDelete(true);
        console.log(id_categoria)
    }

    function closeModalDelete() {
        setCategoriaDelete("");
        setIsOpenDelete(false);
    }

    function openModalEdit(categoria) {
        setCategoriaEdit(categoria.id);
        setTipoIDEdit(categoria.tipo_id);
        setDetalleEdit(categoria.detalle);
        setIsOpenEdit(true);
    }

    function closeModalEdit() {
        setCategoriaEdit("");
        setIsOpenEdit(false);
        setTipoIDEdit("");
        setDetalleEdit("");
    }

    function handleDelete() {
        deleteCategoria(categoria_delete).then(res => {
            setLoading(true);
            closeModalDelete();
            loadCategorias(current_tipo_id);
        })
    }

    function handleEdit() {
        const data = {
            id: categoria_edit,
            tipo_id: tipo_id_edit,
            detalle: detalle_edit
        }
        putCategoria(data).then(res => {
            setLoading(true);
            closeModalEdit();
            loadCategorias(current_tipo_id);
        })
    }

    function loadCategorias(tipo_id) {
        setCurrentTipoID(tipo_id);
        setLoading(true);
        getCategorias().then(res => {
            if (!res) {
                window.location.replace("/login")
            } else {
                let categorias = [];
                res.map((categoria, index) => {
                    if (categoria.tipo_id == tipo_id) {
                        categorias.push(categoria);
                    }
                    setCategorias(categorias);
                })
            }
            setTimeout(() => {
                setLoading(false);
            }, 700);
        })
    }

    return (
        <div className="backoffice">
            <Sidebar />
            <div className="backofficeContainer">
                <div className="backofficeContainer-header">
                    <div className="backofficeContainer-header__about">
                        <h3 className="backofficeContainer-header__title">Categorías</h3>
                        <h3 className="backofficeContainer-header__titleAlt">{categorias ? categorias.length : 0}</h3>
                    </div>
                </div>
                <div className="backofficeContainer-headerMin">
                    <div className="backofficeContainer-headerMin__item">
                        <a className={condition ? "backofficeContainer-headerMin-tab active" : "backofficeContainer-headerMin-tab"} onClick={() => { loadCategorias(1); setCondition(true); setCondition2(false); setCondition3(false) }}>Contratar</a>
                        <a className={condition2 ? "backofficeContainer-headerMin-tab active" : "backofficeContainer-headerMin-tab"} onClick={() => { loadCategorias(2); setCondition(false); setCondition2(true); setCondition3(false) }}>Comer</a>
                        <a className={condition3 ? "backofficeContainer-headerMin-tab active" : "backofficeContainer-headerMin-tab"} onClick={() => { loadCategorias(3); setCondition(false); setCondition2(false); setCondition3(true) }}>Comprar</a>
                    </div>
                    <div className="backofficeContainer-headerMin__item">
                        <a className="button" onClick={openModal}>
                            Agregar
      </a>
                    </div>
                </div>
                <div className="backofficeTable">
                    <hr />
                    {/*<table>
      <thead>
       <tr>
        <th>Categoría</th>
        <th style={{ textAlign: "center" }}>Negocios</th>
        <th style={{ textAlign: "center" }}>Eliminar</th>
       </tr>
      </thead>
      <tbody>
       <tr>
        <td>Hamburguesa</td>
        <td style={{ textAlign: "center" }}>31</td>
        <td>
         <div className="approvedBtns">
          <a>
           <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M14.2422 5.75735L5.75691 14.2426"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
            <path
             d="M14.2431 14.2426L5.75781 5.75735"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
           </svg>
          </a>
         </div>
        </td>
       </tr>
      </tbody>
     </table>*/}
                    <MaterialTable
                        columns={[
                            { title: "Categoria", field: "categoria" },
                            { title: "Negocios", field: "negocios" },
                            { title: "Eliminar", field: "actions" }
                        ]}
                        title={"Solicitudes"}
                        data={categorias.map((categoria, index) => ({
                            categoria: categoria.detalle,
                            negocios: categoria.negocios,
                            actions: (
                                <div className="approvedBtns">
                                    <a onClick={() => { openModalDelete(categoria.id) }}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M14.2422 5.75735L5.75691 14.2426"
                                                stroke="#ACACB5"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M14.2431 14.2426L5.75781 5.75735"
                                                stroke="#ACACB5"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </a>
                                    <a onClick={() => { openModalEdit(categoria) }}>
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                </div>
                            )
                        }))}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [15, 20, 30, 50],
                            emptyRowsWhenPaging: false,
                            paging: categorias.length > 20 ? true : false,
                            showTitle: false,
                            search: true
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage:

                                    loading ?
                                        <>
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{
                                                    marginRight:
                                                        "5px"
                                                }}
                                            />

                                            <span>Cargando...</span>
                                        </>
                                        :
                                        'No hay datos para mostrar.'

                            }
                        }}
                    />
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Agregar Categoría
    </p>
                <br />
                <div className="modalInput">
                    <select
                        className="inputSelect"
                        style={{ backgroundImage: `url(${selectArrow})` }}
                        onChange={e => setTipoID(e.target.value)}
                    >
                        {tipos.map((tipo, index) => (
                            <option key={index} value={tipo.id}>{tipo.detalle}</option>
                        ))}
                    </select>
                </div>
                <div className="modalInput">
                    <label>Nombre</label>
                    <input
                        type="text"
                        class="input registerInput"
                        placeholder="Mexicana"
                        onChange={e => setDetalle(e.target.value)}
                    />
                </div>
                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModal}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleCreate}>
                        Agregar
     </button>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpenDelete}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalDelete}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Deseas eliminar esta categoría?
    </p>
                <br />

                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModalDelete}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleDelete}>
                        Eliminar
     </button>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpenEdit}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalEdit}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Editar Categoría
    </p>
                <br />
                {/*
    <div className="modalInput">
     <select
      className="inputSelect"
      value={tipo_id_edit}
      style={{ backgroundImage: `url(${selectArrow})` }}
      onChange={ e => setTipoIDEdit(e.target.value) }
     >
        {tipos.map((tipo,index)=>(
            <option key={index} value={tipo.id}>{tipo.detalle}</option>
        ))}
     </select>
    </div>
    */}
                <div className="modalInput">
                    <label>Nombre</label>
                    <input
                        type="text"
                        class="input registerInput"
                        placeholder="Mexicana"
                        value={detalle_edit}
                        onChange={e => setDetalleEdit(e.target.value)}
                    />
                </div>
                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModalEdit}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleEdit}>
                        Editar
     </button>
                </div>
            </Modal>
        </div>
    );
}

export default Backoffice2;
