import { useState } from 'react';

const useCategoryProfiles = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [flagsShowing, setFlags] = useState(false);
  const [shareShowing, setShare] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);
  }

  function flags() {
    setFlags(!flagsShowing);
  }

  function share() {
    setShare(!shareShowing);
  }

  return {
    isShowing,
    toggle,
    flags,
    flagsShowing,
    share,
    shareShowing
  }
};

export default useCategoryProfiles;
