import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Navbar from "./Navbar";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import profileThumb from "../images/profileLagom.png";

import Bounce from "react-reveal/Bounce";

import Fade from "react-reveal/Fade";
import selectIconAlt from "../images/select_icon_alt.svg";
import DraftEye from "../images/draftEye.svg";
import Select, { components } from "react-select";
import { putNegocios, logOut, route } from "./API Functions";

const options = [
    {
        value: 1, label: "Borrador", icon: "draftDot.png"
    },
    {
        value: 2, label: "Publicar", icon: "publishDot.png"
    },
];
const { Option, SingleValue } = components;
const IconOption = props => (
    <Option {...props}>
        <img
            src={require('../images/' + props.data.icon)}
            style={{ width: 10, marginRight: 10 }}
            alt={props.data.label}
        />
        {props.data.label}
    </Option>
);
const ValueOption = (props) => (
    <SingleValue {...props}>
        <img
            src={require('../images/' + props.data.icon)}
            style={{ width: 10, marginRight: 10 }}
            alt={props.data.label}
        />
        {props.data.label}
    </SingleValue>
);

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

function openNav() {
    const navigationWindow = document.getElementById("myNav");
    navigationWindow.style.width = "580px";
    const navbarOverlay = document.getElementById("navbarOverlay");
    navbarOverlay.style.opacity = 1;
    navbarOverlay.style.width = "100%";
    navbarOverlay.style.visibility = "visible";
    const menuContainer1 = document.getElementById("menuContainer1");
    if (menuContainer1) {
        menuContainer1.style.display = "block";
    }
    const menuContainer2 = document.getElementById("menuContainer2");
    if (menuContainer2) {
        menuContainer2.style.display = "block";
    }
}

function closeNav() {
    const navigationWindow = document.getElementById("myNav");
    navigationWindow.style.width = "0%";
    const navbarOverlay = document.getElementById("navbarOverlay");
    navbarOverlay.style.opacity = 0;
    navbarOverlay.style.visibility = "hidden";
    const menuContainer1 = document.getElementById("menuContainer1");
    if (menuContainer1) {
        menuContainer1.style.display = "none";
    }
    const menuContainer2 = document.getElementById("menuContainer2");
    if (menuContainer2) {
        menuContainer2.style.display = "none";
    }
}

function DraftView(props) {
    const [isToggled, setToggled] = useState(false);

    const [negocio, setNegocio] = useState(localStorage.getItem('negocio') ? JSON.parse(localStorage.getItem('negocio')) : []);
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);


    const toggleTrueFalse = () => setToggled(!isToggled);

    useEffect(() => {
        console.log(props)
        if (props.negocio) {
            const option = options.filter(option => option.value == props.negocio.status_id)
            setStatus(option);
        }
    }, [])

    function handleStatusOption(selectedOption) {
        setStatus(selectedOption);
    }

    function handleUpdate() {
        setLoading(true)
        const data_content = {
            id: negocio.id,
            tags_ids: negocio.tags_ids,
            nombre: negocio.nombre,
            website: negocio.website,
            whatsap: negocio.whatsap,
            facebook: negocio.facebook,
            telefono: negocio.whatsap,
            categoria_id: negocio.categoria_id,
            descripcion: negocio.descripcion,
            dominio_okki: negocio.dominio_okki,
            rango_id: negocio.rango_id,
            instagram: negocio.instagram,
            precio_envio: negocio.precio_envio,
            persona_contacto: negocio.persona_contacto,
            ciudad_id: negocio.ciudad_id,
            zona: negocio.zona,
            codigo_postal: negocio.codigo_postal,
            direccion: negocio.direccion,
            //EDITED FIELDS IN VIEW
            status_id: 2
        }
        const isValid = true;
        if (isValid) {
            const data = {
                id: negocio.id,
                data: data_content
            }
            putNegocios(data).then(res => {
                if (res) {
                    localStorage.setItem('negocio', JSON.stringify(res))
                    window.location.reload()
                }
            })
        }
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }



    return (
        <>
            <Navbar />
            <div className="draftHeader">
                <img src={DraftEye} className="draftHeader-icon" />
                <p>Modo borrador: Tu tienda aún no es pública</p>
                <a className="draftHeader-button" onClick={()=>{handleUpdate()}}>Publicar</a>
            </div>
            <div className="draftView-bottom">
                <Bounce bottom>
                    <p className="draftView-message">Modo borrador: tu tienda aún no es pública.</p>
                </Bounce>
            </div>
        </>
    );
}

export default DraftView;
