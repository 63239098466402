import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Navbar from "./Navbar";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import Fade from "react-reveal/Fade";
import Select, { components } from "react-select";
import selectIcon from "../images/select_icon.svg";
import { loadNextProductPage, loadNextProductPageSearch, route, getNegociosHome, getProductosHome, bucket_route, SearchResults, getSearchTest, getTipos, getFilters, SearchTags, loadNextPagePost } from "./API Functions";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTooltip from "react-tooltip";
import profileThumb from "../images/profileLagom.png";
import ScrollTop from "./ScrollTop";
import ProductoModal from "./ProductoModal";
import Footer from "./Footer";
import { Image } from 'use-image-color'
import NumberFormat from 'react-number-format';
import useModalProductos from "./useModalProductos";
import ModalProductos from "./ModalProductos";
import EmptyStateFree from "./EmptyStateFree";
import Blocked from "./Blocked";
import Negocios from "./Negocios/Negocios";
import ProductList from "./Products/ProductsList";
import Cookies from 'universal-cookie';
import ErrorSearch from "./ErrorSearch";

const cookies = new Cookies();

const options = [
    { value: "new", label: "Lo más nuevo" },
    { value: "old", label: "Lo más antiguo" },
    { value: "relevant", label: "Relevante" },
];

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};


function Search(props) {
    const [isToggled, setToggled] = useState(false);
    const [keywords, setKeywords] = useState( props.match.params ? props.match.params.keywords : "")
    const [page, setPage] = useState([])
    const [productos, setProductos] = useState([])
    const [last_page, setLastPage] = useState([])
    const [currentProduct, setCurrentProduct] = useState([]);
    const [tags, setTags] = useState([]);
    const [locked_content, setLockedContent] = useState(false);

    const [negocios, setNegocios] = useState([])
    const [inHover, setHover] = useState(false);

    const [isToggledMore, setToggledMore] = useState(false);
    const toggleMore = () => setToggledMore(!isToggledMore);

    const toggleTrueFalse = () => setToggled(!isToggled);

    const { isShowing, toggle } = useModalProductos();
    const { flagsShowing, flags } = useModalProductos();
    const { shareShowing, share } = useModalProductos();

    const [tipo_id, setTipoId] = useState(0);
    const [categorias, setCategorias] = useState([]);

    const [rangos, setRangos] = useState([])
    const [envios, setEnvios] = useState([])
    const [tipos, setTipos] = useState([]);

    const [order_id, setOrderId] = useState(null);
    const [envio_id, setEnvioId] = useState("");
    const [rango_id, setRangoId] = useState("");
    const [tag_filter, setTag] = useState("");
    const [state, setState] = useState("");

    const [loadEnd, setLoadEnd] = useState(false);

    const [tagsPage, setTagsPage] = useState([]);
    const [tagsNextPage, setTagsNextPage] = useState([]);

    const [productosPage, setProductosPage] = useState([]);
    const [productosNextPage, setProductosNextPage] = useState([]);

    const [negociosPage, setNegociosPage] = useState([]);
    const [negociosNextPage, setNegociosNextPage] = useState([]);

    const emptyOption = {
        value : 0,
        label : 'Ver todo'
      }

    useEffect(() => {
        //console.log(keywords)
        //console.log(props,'props')
        const state = cookies.get('state');
        console.log(state)
        if(state){
            loadData(tipo_id,order_id,envio_id,rango_id,tag_filter,state);
            setState(state);
        }else{
            loadData(tipo_id,order_id,envio_id,rango_id,tag_filter,"");
        }
        loadTags();
        loadFilters();
        loadTipos();
    }, [])

    function loadTipos() {
        getTipos().then(res=>{
            if(res){
              setTipos(res);
            }
          })
    }

    function loadData(tipo_id,order_id,envio_id,rango_id, tag,state){
        const data = {
            keywords: keywords,
            tipo_id : tipo_id,
            order_id : order_id ? order_id : "",
            envio_id : envio_id ? envio_id : "",
            rango_id : rango_id ? rango_id : "",
            tag : tag ? tag : "",
            state : state ? state : ""
        }
        console.log(data)
        SearchResults(data).then(res=>{
            if(res){
                console.log(res,'test');

                setNegocios(res.negocios.data);
                setNegociosPage(res.negocios);
                setNegociosNextPage(res.negocios.next_page_url);

                setProductos(res.productos.data);
                setProductosPage(res.productos);
                setProductosNextPage(res.productos.next_page_url);
                
                setLoadEnd(true);
            }
        })
    }
    

    function loadTags(tipo_id) {
        const data ={
            keywords : keywords,
            tipo_id : tipo_id ? tipo_id : ""
        }
        SearchTags(data).then(res=>{
            if(res){
                const tags = []
                res.data.map(tag=>{
                    const json_tag = JSON.parse(tag.tags);
                    const detail = capitalize(json_tag[0].value);
                    tags.push(detail)
                })
                const uniquetags = Array.from(new Set(tags));
                setTags(uniquetags);
                setTagsPage(res);
                setTagsNextPage(res.next_page_url);
            }
        })
    }

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      }
      

    function loadMoreTags() {
        const data = {
            keywords : keywords,
            tipo_id: tipo_id
        }
        loadNextPagePost(tagsNextPage, data).then(res => {
            if (res) {
                const tags_n = [];
                res.data.map(tag=>{
                    const json_tag = JSON.parse(tag.tags);
                    const detail = capitalize(json_tag[0].value);
                    tags_n.push(detail)
                })
                const tags_o = tags.concat(tags_n)
                const uniquetags = Array.from(new Set(tags_o));
                setTags(uniquetags);
                setTagsPage(res);
                setTagsNextPage(res.next_page_url);
                
            }
        })
    }
    function loadMoreProductos() {
        const data = {
            keywords: keywords,
            tipo_id : tipo_id,
            order_id : order_id ? order_id : "",
            envio_id : envio_id ? envio_id : "",
            rango_id : rango_id ? rango_id : "",
            tag : tag_filter ? tag_filter : "",
            state : state ? state : ""
        }
        console.log(data)
        loadNextPagePost(productosNextPage, data).then(res => {
            if (res) {
                setProductosPage(res.productos);
                setProductosNextPage(res.productos.next_page_url);
                const productos_o = productos.concat(res.productos.data)
                setProductos(productos_o);
            }
        })
    }

    function loadFilters(){
        getFilters().then(res=>{
            if(res){
                const rangos_option = [];
                rangos_option.push(emptyOption);
                res.rangos.map(rango =>{
                  rango = {
                    ...rango,
                    value : rango.id,
                    label : rango.detalle
                  }
                  rangos_option.push(rango);
                })
                setRangos(rangos_option);

                const envios_option = [];
                envios_option.push(emptyOption);
                res.envios.map(envio =>{
                  envio = {
                    ...envio,
                    value : envio.id,
                    label : envio.nombre
                  }
                  envios_option.push(envio);
                })
                setEnvios(envios_option);
              }
        })
    }

    function filterByType(value){
        setTipoId(value);
        loadData(value,order_id,envio_id,rango_id,tag_filter,state);
      }
    function filterByDate(value){
        setOrderId(value.value);
        loadData(tipo_id,value.value,envio_id,rango_id,tag_filter,state);
    }
    function filterByRango(value){
        setRangoId(value.value)
        loadData(tipo_id,order_id,envio_id,value.value,tag_filter,state);
    }
    function filterByEnvio(value){
        setEnvioId(value.value)
        loadData(tipo_id,order_id,value.value,rango_id,tag_filter,state);
    }
    function filterByTag(value){
        setTag(value)
        loadData(tipo_id,order_id,envio_id,rango_id,value);
    }

    

    return (
        <div>
            <Navbar />
            {locked_content ?
            <Blocked/>
            :
            <>
            <div className="home-gallery  search-header">
                <div className="home-gallery__headerResults container row">
                    <div className="four columns search-header__breadcrumbAlt2">
                        <div>
                            <h3 className="titleMin">{productos.length} {keywords}</h3>
                        </div>
                        <div className="onlyMobile moreOptionsToggle" onClick={toggleTrueFalse}>
                            {isToggled ? <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="42" height="42" rx="6" fill="#081027" />
                                <path d="M25.2422 16.7573L16.7569 25.2426" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M25.2431 25.2426L16.7578 16.7573" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                                : <svg onClick={toggleTrueFalse} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="41" height="41" rx="5.5" fill="white" stroke="#E8E8EE" />
                                    <path d="M14 17H28" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16 21H26" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18 25H24" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>}


                        </div>
                        {/* <h3 className="settings-header__title resultsNumber">9,999</h3> */}
                    </div>
                    <div className="u-center four columns">
                        <div className="home-gallery__header-div onlyDesktop">
                        {tipos.map((tipo,index)=>(
                            <a className={ tipo.id == tipo_id ? "itemTag itemTag-active" : "itemTag"} onClick={() => { filterByType(tipo.id) }}>{tipo.detalle}</a>
                        ))
                        }
                        </div>
                        <div className="onlyMobile">
                            <select style={{ backgroundImage: `url(${selectIcon})` }} className="inputSelect inputSelectSearch" value={tipo_id} onChange={(e) => { filterByType(e.target.value) }}>
                                <option>Categoría</option>
                                {tipos.map((tipo,index)=>(
                                    <option  value={tipo.id} >{tipo.detalle} </option>
                                ))
                                }
                            </select>

                        </div>
                    </div>
                    <div className="four columns home-gallery__headerSelects onlyDesktop">
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect"
                            options={envios}
                            onChange={filterByEnvio}
                            placeholder="Pick n Go"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                            styles={{
                                dropdownIndicator: (provided, state) => ({
                                    ...provided,
                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                })
                            }}
                        />
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect"
                            options={rangos}
                            onChange={filterByRango}
                            placeholder="$$"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                            styles={{
                                dropdownIndicator: (provided, state) => ({
                                    ...provided,
                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                })
                            }}
                        />
                    </div>
                    <div className="four columns onlyMobile">
                        {isToggled ?
                            <div className="home-gallery__headerSelects home-gallery__headerSelectsMini">
                                <select style={{ backgroundImage: `url(${selectIcon})` }} className="inputSelect">
                                    <option>Pick n Go</option>
                                </select>

                                <select style={{ backgroundImage: `url(${selectIcon})` }} className="inputSelect">
                                    <option>$$</option>
                                </select>
                            </div> : <div></div>}
                    </div>
                </div>
                <hr className="fullDivider" style={{ margin: 0 }} />
                {loadEnd && (productos.length > 0 || negocios.length > 0 || tags.length > 0 ) ?
                <div>
                <div className="search-header__tags row">
                    <a className={tag_filter ? "itemTag" : "itemTag itemTag-active"} onClick={() => { filterByTag("") }}>Todo</a>
                    {tags.map(tag => (
                        <a className={tag_filter == tag ? "itemTag itemTag-active" : "itemTag"}  onClick={() => { filterByTag(tag) }}>
                            {tag}
                        </a>
                    )) }
                    
                    <a className={tagsNextPage ?
                        "itemTagButton onlyDesktop"
                        :
                        "itemTagButton onlyDesktop noDisplay"
                    }
                         onClick={loadMoreTags}>Ver más
                    </a>
                    
                </div>
                <hr className="fullDivider" style={{ margin: 0 }} />
                <div className="container">
                    <div className="home-cover__profilesResult search-header__profiles">
                    <Negocios
                    negocios={negocios}
                    />
                        

                    </div>
                </div>
                <hr className="fullDivider" />
                <div className="container" >
                    <InfiniteScroll
                        dataLength={productos.length}
                        next={() => { loadMoreProductos() }}
                        hasMore={productosPage.next_page_url == productosNextPage}
                    >
                        <Fade cascade>
                        <ProductList
                        productos = {productos}
                        />
                        </Fade>

                    </InfiniteScroll>
                </div>

                <div className="homeGrid-button centerFlex">
                    {productosNextPage ?
                        <button className="button-loadMore" onClick={() => { loadMoreProductos() }}>Cargar más productos</button>
                        :
                        null
                    }
                </div>
            </div>
                :
                <div>
                {loadEnd && ( 
                <ErrorSearch 
                keywords={keywords}
                />)
                }
                </div>
                }           
            </div>
            <ScrollTop />
            <ModalProductos
                isShowing={isShowing}
                hide={toggle}
                flags={flags}
                flagsShowing={flagsShowing}
                share={share}
                shareShowing={shareShowing}
                producto={currentProduct}
                tags={tags}
            />
            <Footer />
            </>
        }
        </div>
       
    );
}

export default Search;
