import React, { useState, useEffect } from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
import visaTag from "../images/visaTag.png";
import Navbar from "./Navbar";
import { getNegocios, putNegocios, deleteAccount, pauseConektaSuscription, resumeConektaSuscription, getCurrentNegocio, getConektaCustomer } from "./API Functions";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import Settings4 from "./Settings-4";
import SettingsReady from "./SettingsReady";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    padding: "50px 40px",
    border: "none",
    borderRadius: 12,
  },
};

function Settings5(props) {
  var subtitle;

  const [Loading, setLoading] = useState(false);
  const [window_loaded, setWindowLoaded] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [negocio, setNegocio] = useState([]);
  const [nuevoNegocio, setNewNegocio] = useState(false);
  const [create_product, setCreateProduct] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [last4, setLast4] = useState("");
  const [next_charge, setNextCharge] = useState("");
  const [expire_date, setExpireDate] = useState("");
  const [trial_end, setTrialEnd] = useState("");

  const [subscription, setSubscription] = useState("");
  const [subscription_price, setSubscriptionPrice] = useState("");

  const [settings4, setSettings4] = useState(false);

  const [presona_contacto, setPersonaContacto] = useState("");
  const [email, setEmail] = useState("");

  const [presona_contactoError, setPersonaContactoError] = useState("");

  const [isToggledNotifications, setToggledNotifications] = useState(true);
  const toggleTrueFalseNotifications = () => setToggledNotifications(!isToggledNotifications);

  const [isToggledNews, setToggledNews] = useState(true);
  const toggleTrueFalseNews = () => setToggledNews(!isToggledNews);

  const [isToggled, setToggled] = useState(false);
  const toggleTrueFalse = () => setToggled(!isToggled);

  useEffect(() => {
    if (props.negocio) {
      const negocio = props.negocio;
      if (negocio.nuevo) {
        setPersonaContacto("");
        setEmail(negocio.user.correo);
      } else {

        setEmail(negocio.user.correo);
        setPersonaContacto(negocio.persona_contacto);
      }
      setNegocio(negocio);
    } else {
      getCurrentNegocio().then(res => {
        if (res) {
          const negocio = res;
          if (negocio.nuevo) {
            setPersonaContacto("");
            setEmail(negocio.user.correo);
          } else {
            setEmail(negocio.user.correo);
            setPersonaContacto(negocio.persona_contacto);
          }
          setNegocio(negocio);
        }
      })

    }
    getConektaCustomer().then(res => {
      if (res) {
        const customer = res;
        setCustomer(customer);
        var date = new Date(customer.subscription.billing_cycle_end * 1000);
        const expire_date = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
        setExpireDate(expire_date);
        setNextCharge(expire_date);
        var date = new Date(customer.subscription.trial_end * 1000);
        const trial_end = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
        setTrialEnd(trial_end);
        setLast4(customer.payment_sources.data[0].last4)
        setSubscriptionPrice(customer.subscription.plan_id == '2' ? 2500 : 250)
        setSubscription(customer.subscription.status)
        setWindowLoaded(true);
      } else {
        setCustomer([]);
        setWindowLoaded(true);
      }
    })

  }, [])

  function openReadyModal() {
    setIsOpen(true);
  }

  function closeReadyModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function validateFields() {
    let presona_contactoError = "";
    /*let instagramError = "";
    let facebookError = "";
  
    if (web == "") {
     webError = "Requerido";
    }
    if (instagram == "" || instagram == null) {
     instagramError = "Requerido";
    } */

    if ([presona_contacto] == "") {
      presona_contactoError = "Requerido";
    }


    if (
      presona_contactoError
    ) {
      setPersonaContactoError(presona_contactoError);
      //setInstagramError(instagramError);
      //setFacebookError(facebookError);
      return false;
    } else {
      setPersonaContactoError(presona_contactoError);
      //setInstagramError(instagramError);
      //setFacebookError(facebookError);
      return true;
    }
  }

  function handleSubmit() {
    setLoading(true)
    const data_content = {
      id: negocio.id,
      tags_ids: negocio.tags_ids,
      nombre: negocio.nombre,
      codigo_postal: negocio.codigo_postal,
      direccion: negocio.direccion,
      whatsap: negocio.whatsap,
      telefono: negocio.whatsap,
      categoria_id: negocio.categoria_id,
      descripcion: negocio.descripcion,
      dominio_okki: negocio.dominio_okki,
      rango_id: negocio.rango_id,
      ciudad_id: negocio.ciudad_id,
      zona: negocio.zona,
      precio_envio: negocio.precio_envio,
      envios: [negocio.envios[0].id],
      tipos: [negocio.tipos[0].id],
      instagram: negocio.instagram,
      facebook: negocio.facebook,
      website: negocio.website,
      //EDITED FIELDS ON VIEW
      persona_contacto: presona_contacto
    }
    const isValid = validateFields()
    if (isValid) {
      const data = {
        id: negocio.id,
        data: data_content
      }
      putNegocios(data).then(res => {
        if (res) {
          if (negocio.nuevo) {
            localStorage.setItem('negocio', JSON.stringify(res));
            window.location.reload();
          } else {
            localStorage.setItem('negocio', JSON.stringify(res));
            window.location.reload();
          }

        }
      })
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  function handleDelete() {
    deleteAccount().then(res => {
      if (res) {
        window.location.replace("/login")
      }
    })
  }

  function create1stProduct() {
    setLoading(true);
    openReadyModal();
    setTimeout(() => {
      setLoading(false);
      if (negocio.nuevo) {
        setCreateProduct(true);
      } else {
        window.location.reload();
      }
    }, 3000);
  }
  return (
    <>
      {settings4 ?
        <Settings4 negocio={negocio} />
        :
        window_loaded ?
          create_product ?
            <Redirect
              to={{
                pathname: '/nuevo-producto',
                state: { negocio: negocio }
              }}
            />
            :
            <>
              {
                negocio.nuevo ?
                  <div className="four columns settings-form__sidebar onlyDesktop" style={{ marginLeft: 0 }}>

                    <div className="settings-header settings-headerModal">
                      <div className="settings-header__breadcrumb settings-header__breadcrumb-title">
                        <h3 className="settings-header__title">Configuración</h3>

                        <hr className="settingsDivider" />
                      </div>
                    </div>
                    <ul className="settings-form__menu settings-form__menuList">
                      <li>
                        <Link className="">
                          Perfil
                                    </Link>
                      </li>
                      <li>
                        <Link className="">
                          Negocio
                                    </Link>
                      </li>
                      <li>
                        <Link className="">
                          Dirección y Envíos
                                    </Link>
                      </li>
                      <li>
                        <Link className="">
                          Redes Sociales
                                    </Link>
                      </li>
                      <li>
                        <Link className="">
                          Cuenta
                                    </Link>
                      </li>
                      <li>
                        <Link className="active">
                          Plan
                                    </Link>
                      </li>
                      {/* <li>
                        <Link to="/pro" target="_blank" activeClassName="active">
                          PRO
                                    </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: `/${this.state.negocio.dominio_okki}` }} activeClassName="active">
                          Mi perfil
                                    </Link>
                      </li> */}
                    </ul>

                  </div>
                  : null
              }
              <div className="eight columns settings-form__container settings-form__containerPlans">

                {customer.id && subscription !== "in_trial" ?
                  <div>
                    <div className="settings-form__row">
                      <div className="settings-form__column settings-form__columnLabel">
                        <label>Plan actual</label>
                      </div>
                      <div className="settings-form__column settings-form__columnField">
                        <a className="itemTagPro">PRO</a>
                        {/* <a className="itemTagFree">Grátis</a> */}
                      </div>
                    </div>
                    <div className="settings-form__row">
                      <div className="settings-form__column settings-form__columnLabel">
                        <label>Expira</label>
                      </div>
                      <div className="settings-form__column settings-form__columnField">
                        <input
                          className="settings-form__input"
                          type="text"
                          placeholder="01/01/2020"
                          value={expire_date}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="settings-form__row">
                      <div className="settings-form__column settings-form__columnLabel">
                        <label>Precio</label>
                      </div>
                      <div className="settings-form__column settings-form__columnField">
                        <p className="">{`$${subscription_price}/`}{subscription_price == 2500 ? 'Anual' : 'Mensual'}</p>
                      </div>
                    </div>
                    <div className="settings-form__row">
                      <div className="settings-form__column settings-form__columnLabel">
                        <label>Tarjeta</label>
                      </div>
                      <div className="settings-form__column settings-form__columnField">
                        <div className="settings-form__cardRow">
                          <img height="22" src={visaTag} />
                          <p className="">{`**** ${last4}`}</p>
                        </div>
                      </div>
                    </div>
                    <div className="settings-form__row">
                      <div className="settings-form__column settings-form__columnLabel">
                        <label>Próximo cargo</label>
                      </div>
                      <div className="settings-form__column settings-form__columnField">
                        <input
                          className="settings-form__input"
                          type="text"
                          placeholder="01/01/2020"
                          value={next_charge}
                          disabled
                        />
                      </div>
                    </div>
                    {customer.subscription.status == 'active' ?
                      <div className="settings-form__row">
                        <div className="settings-form__column settings-form__columnLabel">
                          <label>Cancelar</label>
                        </div>
                        <div className="settings-form__column settings-form__columnField">
                          <button
                            className="button btnDelete"
                            onClick={() => { pauseConektaSuscription().then(res => { window.location.reload() }) }}
                          >
                            Cancelar suscripción
              </button>
                        </div>
                      </div>
                      :

                      <div className="settings-form__row">
                        <div className="settings-form__column settings-form__columnLabel">
                          <label>Renaudar Suscripcion</label>
                        </div>
                        <div className="settings-form__column settings-form__columnField">
                          <button
                            className="button"
                            onClick={() => { resumeConektaSuscription().then(res => { window.location.reload() }) }}
                          >
                            Activar
              </button>
                        </div>
                      </div>

                    }
                  </div>
                  :
                  negocio.id && subscription == "in_trial" ?
                    <>
                      <div className="settings-form__row">
                        <div className="settings-form__column settings-form__columnLabel">
                          <label>Plan actual</label>
                        </div>
                        <div className="settings-form__column settings-form__columnField">
                          <a className="itemTagPro">PRO</a>
                          <span className="itemTagTrial">Prueba gratuita de 30 días</span>
                        </div>

                      </div>
                      <div className="settings-form__row">
                        <div className="settings-form__column settings-form__columnLabel">
                          <label>Expira</label>
                        </div>
                        <div className="settings-form__column settings-form__columnField">
                          <input
                            className="settings-form__input"
                            type="text"
                            placeholder="01/01/2020"
                            value={trial_end}
                            disabled
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="settings-form__row">
                        <div className="settings-form__column settings-form__columnLabel"></div>
                        <div className="settings-form__column settings-form__columnField">
                          <div className="settings-form__proChecklist">
                            <ul className="settings-form__proChecklistList">
                              <li>Productos ilimitados</li>
                              <li>5 imagenes por producto</li>
                              <li>Mensaje personalizado por producto</li>
                              <li>5 etiquetas por producto</li>
                              <li>Precios privados</li>
                              <li>Placa PRO</li>
                              <li>Estadísticas</li>
                            </ul>
                          </div>
                          {customer.subscription.status == 'in_trial' ?
                            <div className="settings-form__row">
                              <div className="settings-form__column settings-form__columnField">
                                <a className="button btnDelete" onClick={() => { pauseConektaSuscription().then(res => { window.location.reload() }) }}> 
                                  Cancelar suscripción
                                </a>
                              </div>
                            </div>
                            :
                            <div className="settings-form__row">
                              <div className="settings-form__column settings-form__columnLabel">
                                <label>Renaudar Suscripcion</label>
                              </div>
                              <div className="settings-form__column settings-form__columnField">
                                <button className="button" onClick={() => { resumeConektaSuscription().then(res => { window.location.reload() }) }} >
                                  Activar
                                </button>
                              </div>
                            </div>
                          }
                        </div>
                        {negocio.nuevo ?
                          <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                            <a className="button button-alphaAlt" onClick={() => { setSettings4(true) }}>Regresar</a>
                            <a className="button" onClick={create1stProduct}>
                              {Loading && (
                                <i className="" style={{ marginRight: "5px" }} />
                              )}
                              {Loading && (
                                negocio.nuevo ?
                                  'Creando...'
                                  :
                                  'Guardando...'
                              )}
                              {!Loading && (
                                negocio.nuevo ?
                                  'Finalizar configuración'
                                  :
                                  'Guardar'
                              )}
                            </a>
                          </div>
                          :
                          <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                            <a>{" "}</a>
                            <a className="button" onClick={handleSubmit}>
                              {Loading && (
                                <i className="" style={{ marginRight: "5px" }} />
                              )}
                              {Loading && (
                                'Guardando...'
                              )}
                              {!Loading && (
                                negocio.nuevo ?
                                  'Continuar'
                                  :
                                  'Guardar'
                              )}
                            </a>
                          </div>
                        }
                      </div>
                    </>
                    :
                    negocio.id ?
                      <>
                        <div className="settings-form__row">
                          <div className="settings-form__column settings-form__columnLabel">
                            <label>Plan actual</label>
                          </div>
                          <div className="settings-form__column settings-form__columnField">
                            <p className="settings-from__proLabel">Okki básico</p>
                          </div>

                        </div>
                        <hr />
                        <div className="settings-form__row">
                          <div className="settings-form__column settings-form__columnLabel"></div>
                          <div className="settings-form__column settings-form__columnField">
                            <Link style={{ textAlign: "center" }} className="button buttonPro"
                              to={{
                                pathname: "/pro-checkout",
                                state: { plan_id: isToggled ? 2 : 1, plan_price: isToggled ? 2500 : 250 }
                              }}
                            >
                              Prueba PRO gratis por 30 días
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6424 8.22228L9.99163 4.28577L8.21385 8.22228L4.27734 10.0001L8.21385 11.6508L9.99163 15.7143L11.6424 11.6508L15.7059 10.0001L11.6424 8.22228Z" fill="white" />
                              </svg>
                            </Link>
                            <div className="settings-form__proChecklist">
                              <ul className="settings-form__proChecklistList">
                                <li>Productos ilimitados</li>
                                <li>5 imagenes por producto</li>
                                <li>Mensaje personalizado por producto</li>
                                <li>5 etiquetas por producto</li>
                                <li>Precios privados</li>
                                <li>Placa PRO</li>
                                <li>Estadísticas</li>
                              </ul>

                            </div>
                          </div>
                          {negocio.nuevo ?
                            <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                              <a className="button button-alphaAlt" onClick={() => { setSettings4(true) }}>Regresar</a>
                              <a className="button" onClick={create1stProduct}>
                                {Loading && (
                                  <i className="" style={{ marginRight: "5px" }} />
                                )}
                                {Loading && (
                                  negocio.nuevo ?
                                    'Creando...'
                                    :
                                    'Guardando...'
                                )}
                                {!Loading && (
                                  negocio.nuevo ?
                                    'Finalizar configuración'
                                    :
                                    'Guardar'
                                )}
                              </a>
                            </div>
                            :
                            <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                              <a>{" "}</a>
                              <a className="button" onClick={handleSubmit}>
                                {Loading && (
                                  <i className="" style={{ marginRight: "5px" }} />
                                )}
                                {Loading && (
                                  'Guardando...'
                                )}
                                {!Loading && (
                                  negocio.nuevo ?
                                    'Continuar'
                                    :
                                    'Guardar'
                                )}
                              </a>
                            </div>
                          }
                        </div>
                      </>
                      :
                      null
                }

              </div>
            </>

          :
          null
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeReadyModal}
        className="settingsModal"
        contentLabel="Example Modal"
      >

        <SettingsReady />


      </Modal>

    </>
  );
}

export default Settings5;
