import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import vender3 from "../images/registro3.svg";
import Fade from "react-reveal/Fade";
import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import NavbarMin from "./NavbarMin";
import { loggedIn } from "./API Functions";
import { Helmet } from "react-helmet";

function Vender3() {
  useEffect(() => {
  }, [])
  return (
    <div>
      <Helmet>
        <title>Registro | Okki</title>
      </Helmet>
      <div className="onlyDesktop vender3-top">
        <NavbarMin />
      </div>
      <div className="onlyMobile">
        <div className="modalItem-respNav">


          <Link to="/" className="okkiLogoMobile">
            <div>
              <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M45.0822 39.9559C46.2785 40.0157 47.2804 39.0587 47.2804 37.8625V35.8139C47.2804 35.5597 47.5794 35.4251 47.7738 35.5896L52.5439 39.4924C52.9327 39.8064 53.3963 39.9709 53.8748 39.9709C54.4879 39.9709 55.086 39.7017 55.4897 39.2083C56.2224 38.3111 56.0878 36.9952 55.1907 36.2625L51.1533 32.9578C50.8542 32.7185 50.8542 32.2699 51.1533 32.0307L55.1907 28.726C56.0878 27.9933 56.2224 26.6774 55.4897 25.7802C54.757 24.883 53.4411 24.7485 52.5439 25.4812L47.7738 29.384C47.5794 29.5485 47.2804 29.3989 47.2804 29.1597V27.1559C47.2804 26.0344 46.4131 25.0625 45.2916 25.0027C44.0953 24.9428 43.0935 25.8999 43.0935 27.0961V37.8027C43.0935 38.9242 43.9607 39.8961 45.0822 39.9559ZM36.9178 42.0494C35.7215 41.9896 34.7196 42.9466 34.7196 44.1428V46.1914C34.7196 46.4456 34.4206 46.5802 34.2262 46.4157L29.4561 42.5129C29.0673 42.1989 28.6037 42.0344 28.1252 42.0344C27.5121 42.0344 26.914 42.3036 26.5103 42.797C25.7776 43.6942 25.9121 45.0101 26.8093 45.7428L30.8467 49.0475C31.1458 49.2868 31.1458 49.7354 30.8467 49.9746L26.8093 53.2793C25.9121 54.012 25.7776 55.3279 26.5103 56.2251C27.243 57.1223 28.5589 57.2569 29.4561 56.5241L34.2262 52.6213C34.4206 52.4569 34.7196 52.6064 34.7196 52.8456V54.8344C34.7196 55.9559 35.5869 56.9279 36.7084 56.9877C37.9047 57.0475 38.9065 56.0905 38.9065 54.8942V44.1877C38.9065 43.0812 38.0393 42.1092 36.9178 42.0494ZM32.4766 25.0027C28.3495 25.0027 25 28.3522 25 32.4793C25 36.6064 28.3495 39.9559 32.4766 39.9559C36.6037 39.9559 39.9533 36.6064 39.9533 32.4793C39.9533 28.3522 36.6037 25.0027 32.4766 25.0027ZM32.4766 35.769C30.6673 35.769 29.1869 34.2886 29.1869 32.4793C29.1869 30.6699 30.6673 29.1896 32.4766 29.1896C34.286 29.1896 35.7664 30.6699 35.7664 32.4793C35.7664 34.2886 34.286 35.769 32.4766 35.769ZM54.9065 47.4326H44.1402C42.9888 47.4326 42.0467 48.3746 42.0467 49.526C42.0467 50.6774 42.9888 51.6195 44.1402 51.6195H54.9065C56.0579 51.6195 57 50.6774 57 49.526C57 48.3746 56.0579 47.4326 54.9065 47.4326Z" fill="white" />
              </svg>

            </div>
          </Link>

        </div>
      </div>
      <div className="planContainer">
        <div className="planContainer-cover">
          <Fade delay={300}>
            <img src={vender3} />
          </Fade>
        </div>
        <div className="planContainer-form planContainer-formAlt">
          <Fade delay={300}>
            <div className="container">
              <div className="row">
                <div className="twelve columns">
                  <div className="planContainer-form__container planContainer-form__thanks">
                    <Fade delay={300}>
                      <img className="onlyMobile planContainer-form__mobileCover" src={vender3} />
                    </Fade>
                    <h1 className="subtitle">
                      Estamos revisando tu información
                    </h1>
                    <br />
                    <p>
                      Nuestro equipo está revisando tu cuenta y se comunicará contigo en breve. Mientras tanto, siéntete libre de explorar cómo funciona Okki.
                    </p>
                    <Link className="button button-alphaInverted onlyDesktop" to="/">
                      Explorar Okki
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Fade>

        </div>
        <Link className="button button-alphaInverted button-vender3 onlyMobile" to="/">
          Explorar Okki
        </Link>
      </div>
    </div>
  );
}

export default Vender3;
