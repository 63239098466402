import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import venderThumb from "../../images/vender-1.svg";
import selectArrow from "../../images/selectArrow.svg";
import profileTable from "../../images/profileTable.svg";
import profileThumb from "../../images/profileThumb.png";
import itemThumb from "../../images/itemThumb.svg";
import itemProfile from "../../images/itemProfile.svg";
import MaterialTable from "material-table";

import Modal from "react-modal";
import Sidebar from "../Sidebar";
import { getRangoPrecios, postRangoPrecio, deleteRangoPrecio, putRangoPrecio, loggedIn, postBlacklist, deleteBlacklist, getBlacklist, putBlacklist } from "../API Functions";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        padding: "50px 40px",
        border: "none",
        borderRadius: 12,
    },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Blacklist() {
    var subtitle;

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpenEdit, setIsOpenEdit] = React.useState(false);
    const [modalIsOpenDelete, setIsOpenDelete] = React.useState(false);

    const [blacklist, setBlacklist] = useState([]);

    const [dominio, setDominio] = useState("");
    const [dominio_edit, setDominioEdit] = useState("");

    const [blacklist_delete, setBlacklistDelete] = useState("");
    const [blacklist_edit, setBlacklistEdit] = useState("");

    const [dominioError, setDominioError] = useState("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loggedIn();
        setLoading(true);
        loadBlacklist();
    }, [])


    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function validateFields() {
        let dominioError = ""

        if (dominio == "") {
            dominioError = "Requerido"
        }
        if (dominioError) {
            setDominioError(dominioError);
            return false
        } else {
            setDominioError(dominioError);
            return true
        }
    }

    function handleCreate() {
        const data = {
            dominio: dominio
        }
        const isValid = validateFields()
        if (isValid) {
            postBlacklist(data).then(res => {
                setLoading(true);
                closeModal();
                loadBlacklist();
            })
        }
    }

    function openModalDelete(id) {
        setBlacklistDelete(id);
        setIsOpenDelete(true);
    }

    function closeModalDelete() {
        setBlacklistDelete("");
        setIsOpenDelete(false);
    }

    function handleDelete() {
        deleteBlacklist(blacklist_delete).then(res => {
            setLoading(true);
            closeModalDelete();
            loadBlacklist();
        })
    }


    function openModalEdit(blacklist) {
        setBlacklistEdit(blacklist.id);
        setDominioEdit(blacklist.detalle);
        setIsOpenEdit(true);
    }

    function closeModalEdit() {
        setBlacklistEdit("");
        setDominioEdit("");
        setIsOpenEdit(false);
    }

    function handleEdit() {
        const data = {
            id: blacklist_edit,
            dominio: dominio_edit
        }
        putBlacklist(data).then(res => {
            setLoading(true);
            loadBlacklist();
            closeModalEdit();
        })
    }

    function loadBlacklist() {
        setLoading(true);
        getBlacklist().then(res => {
            if (!res) {
                //window.location.replace("/login")
            } else {
                setBlacklist(res);
            }
            setTimeout(() => {
                setLoading(false);
            }, 700);
        })
    }

    return (
        <div className="backoffice">
            <Sidebar />
            <div className="backofficeContainer">
                <div className="backofficeContainer-header">
                    <div className="backofficeContainer-header__about">
                        <h3 className="backofficeContainer-header__title">Blacklist</h3>
                        <h3 className="backofficeContainer-header__titleAlt">{blacklist ? blacklist.length : 0}</h3>
                    </div>
                </div>
                <div className="backofficeContainer-headerMin">
                    <div>
                    </div>
                    <div className="backofficeContainer-headerMin__item">
                        <a className="button" onClick={openModal}>
                            Agregar
      </a>
                    </div>
                </div>
                <div className="backofficeTable">
                    <hr />
                    {/*<table>
      <thead>
       <tr>
        <th>Categoría</th>
        <th style={{ textAlign: "center" }}>Negocios</th>
        <th style={{ textAlign: "center" }}>Eliminar</th>
       </tr>
      </thead>
      <tbody>
       <tr>
        <td>Hamburguesa</td>
        <td style={{ textAlign: "center" }}>31</td>
        <td>
         <div className="approvedBtns">
          <a>
           <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M14.2422 5.75735L5.75691 14.2426"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
            <path
             d="M14.2431 14.2426L5.75781 5.75735"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
           </svg>
          </a>
         </div>
        </td>
       </tr>
      </tbody>
     </table>*/}
                    <MaterialTable
                        columns={[
                            { title: "Dominio", field: "dominio" },
                            { title: "Eliminar", field: "actions" }
                        ]}
                        title={"Solicitudes"}
                        data={blacklist.map((dominio, index) => ({
                            dominio: dominio.dominio,
                            actions: (
                                <div className="approvedBtns">
                                    <a onClick={() => { openModalDelete(dominio.id) }}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M14.2422 5.75735L5.75691 14.2426"
                                                stroke="#ACACB5"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M14.2431 14.2426L5.75781 5.75735"
                                                stroke="#ACACB5"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </a>
                                    <a onClick={() => { openModalEdit(dominio) }}>
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                </div>
                            )
                        }))}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50],
                            emptyRowsWhenPaging: false,
                            paging: blacklist.length > 10 ? true : false,
                            showTitle: false,
                            search: true
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage:

                                    loading ?
                                        <>
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{
                                                    marginRight:
                                                        "5px"
                                                }}
                                            />

                                            <span>Cargando...</span>
                                        </>
                                        :
                                        'No hay datos para mostrar.'

                            }
                        }}
                    />
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Agregar Blacklist
    </p>
                <br />
                <div className="modalInput">
                    <label>Dominio</label>
                    <input
                        type="text"
                        class="input registerInput"
                        placeholder="Mexicana"
                        onChange={e => setDominio(e.target.value)}
                    />
                </div>
                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModal}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleCreate}>
                        Agregar
     </button>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpenDelete}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalDelete}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Deseas eliminar este dominio?
    </p>
                <br />

                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModalDelete}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleDelete}>
                        Eliminar
     </button>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpenEdit}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalEdit}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Editar Dominio
    </p>
                <br />
                <div className="modalInput">
                    <label>dominio</label>
                    <input
                        type="text"
                        class="input registerInput"
                        placeholder="Mexicana"
                        value={dominio_edit}
                        onChange={e => setDominioEdit(e.target.value)}
                    />
                </div>
                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModalEdit}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleEdit}>
                        Editar
     </button>
                </div>
            </Modal>
        </div>
    );
}

export default Blacklist;
