import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import venderThumb from "../images/vender-1.svg";
import selectArrow from "../images/selectArrow.svg";
import profileTable from "../images/profileTable.svg";
import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import Sidebar from "./Sidebar";
import Select, { components } from "react-select";
import { getBackofficeUsers, postChangeSuscriptionStatus, loggedIn } from "./API Functions";
import MaterialTable from "material-table";

const options = [
    { value: "new", label: "Lo más nuevo" },
    { value: "old", label: "Lo más antiguo" },
    { value: "relevant", label: "Relevante" },
];

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

function Backoffice2() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const [condition, setCondition] = useState(true);

    useEffect(() => {
        loggedIn();
        setLoading(true);
        loadUsers(true);
    }, [])

    function changeSuscriptioStatus(user_id, suscription_id, value) {
        const data = {
            suscription_id: suscription_id,
            suscription_status: value,
            user_id: user_id,
            new_register: false
        };
        postChangeSuscriptionStatus(data).then(res => {
            if (res) {
                window.location.reload();
            } else {
            }
        });
    };

    function loadUsers(suscription_status) {
        setLoading(true);
        getBackofficeUsers().then(res => {
            if (!res) {
                window.location.replace("/login")
            } else {
                let users = [];
                res.map((user, index) => {
                    if (user.suscription) {
                        if ((user.suscription.plan_id == 2 || user.suscription.plan_id == 4 || user.suscription.plan_id == 5) && user.suscription.status == suscription_status) {
                            users.push(user);
                        }
                    }
                    setUsers(users);
                })
            }
            setTimeout(() => {
                setLoading(false);
            }, 700);
        })
    }

    return (
        <div className="backoffice">
            <Sidebar />
            <div className="backofficeContainer">
                <div className="backofficeContainer-header">
                    <div className="backofficeContainer-header__about">
                        <h3 className="backofficeContainer-header__title">Vendedores</h3>
                        <h3 className="backofficeContainer-header__titleAlt">{users ? users.length : 0}</h3>
                    </div>
                </div>
                <div className="backofficeContainer-headerMin">
                    <div className="backofficeContainer-headerMin__item">
                        <a className={condition ? "backofficeContainer-headerMin-tab active" : "backofficeContainer-headerMin-tab"} onClick={() => { loadUsers(true); setCondition(!condition) }}>Activas</a>
                        <a className={condition ? "backofficeContainer-headerMin-tab" : "backofficeContainer-headerMin-tab active"} onClick={() => { loadUsers(false); setCondition(!condition) }}>Desactivadas</a>
                    </div>
                    <div style={{ display: "flex" }} className="backofficeContainer-headerMin__item">
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect "
                            options={options}
                            placeholder="Ciudad"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 12,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                        />
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect inputSelect-backoffice"
                            options={options}
                            placeholder="Ciudad"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 12,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                        />
                    </div>
                </div>
                <div className="backofficeTable">
                    <hr />
                    {/*<table>
      <thead>
       <tr>
        <th>Negocio</th>
        <th>Vende</th>
        <th>Whatsapp</th>
        <th>Correo Electrónico</th>
        <th>Nombre Completo</th>
        <th style={{ textAlign: "center" }}>PRO</th>
        <th style={{ textAlign: "center" }}>Perfil</th>
        <th style={{ textAlign: "center" }}>Desactivar</th>
       </tr>
      </thead>
      <tbody>
       <tr>
        <td>Louis Vuitton</td>
        <td>Productos</td>
        <td>811 123 4567</td>
        <td>hawkings@example.com</td>
        <td>Guy Hawkins</td>
        <td>
         <span className="dotYes">•</span>
        </td>
        <td>
         <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={profileTable} />
         </div>
        </td>
        <td>
         <div className="approvedBtns">
          <a>
           <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M14.2422 5.75735L5.75691 14.2426"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
            <path
             d="M14.2431 14.2426L5.75781 5.75735"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
           </svg>
          </a>
         </div>
        </td>
       </tr>
      </tbody>
     </table>*/}
                    <MaterialTable
                        columns={[
                            {
                                title: "Negocio",
                                field: "negocio",
                            },
                            { title: "Vende", field: "tipo" },
                            { title: "Whatsapp", field: "whatsapp" },
                            { title: "Correo", field: "email" },
                            { title: "Nombre Completo", field: "nombre" },
                            {
                                title: "Pro", field: "pro", headerStyle: {
                                    textAlign: 'center',
                                }
                            },
                            {
                                title: "Perfil", field: "perfil", headerStyle: {
                                    textAlign: 'center',
                                }
                            },
                            {
                                title: "Acciones", field: "actions", headerStyle: {
                                    textAlign: 'center',
                                }
                            }
                        ]}
                        title={"Solicitudes"}
                        data={users.map((user, index) => ({
                            tipo: user.tipo ? user.tipo.detalle == 'Contratar' ? 'Servicios' : user.tipo.detalle == 'Comer' ? 'Comida' : user.tipo.detalle == "Comprar" ? "Productos" : null : '---',
                            negocio: user.negocio ? user.negocio.nombre : '---',
                            whatsapp: user.negocio.whatsap ? user.negocio.whatsap : '-',
                            nombre: user.nombre,
                            email: user.correo,
                            pro: user.negocio.pro ? <span className="dotYes">•</span> : <span className="dotNo">◦</span> ,
                            perfil: <div style={{ display: "flex", justifyContent: "center" }}>
                                <Link>
                                    <img src={profileTable} onClick={() => { window.location.replace(`/${user.negocio.dominio_okki}`) }} />
                                </Link>
                            </div>,
                            actions: (
                                user.suscription ?
                                    <div className="approvedBtns">
                                        <>
                                            {user.suscription.status ? <a onClick={() => { changeSuscriptioStatus(user.id, user.suscription.id, false) }}>
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.2422 5.75735L5.75691 14.2426"
                                                        stroke="#ACACB5"
                                                        stroke-width="1.5"
                                                        stroke-miterlimit="10"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M14.2431 14.2426L5.75781 5.75735"
                                                        stroke="#ACACB5"
                                                        stroke-width="1.5"
                                                        stroke-miterlimit="10"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </a> : null}
                                        </>
                                        <>
                                            {!user.suscription.status ?
                                                <a onClick={() => { changeSuscriptioStatus(user.id, user.suscription.id, true) }}>
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M14.2422 5.75735L5.75691 14.2426"
                                                            stroke="#ACACB5"
                                                            stroke-width="1.5"
                                                            stroke-miterlimit="10"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M14.2431 14.2426L5.75781 5.75735"
                                                            stroke="#ACACB5"
                                                            stroke-width="1.5"
                                                            stroke-miterlimit="10"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </a> : null}
                                        </>
                                    </div>
                                    :
                                    '---'
                            )
                        }))}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [15, 20, 30, 50],
                            emptyRowsWhenPaging: false,
                            // paging: users.length > 20 ? true : false,
                            paging: true,
                            showTitle: false,
                            search: true
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage:

                                    loading ?
                                        <>
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{
                                                    marginRight:
                                                        "5px"
                                                }}
                                            />

                                            <span>Cargando...</span>
                                        </>
                                        :
                                        'No hay datos para mostrar.'

                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Backoffice2;
