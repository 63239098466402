import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cover from "../images/home-cover.svg";
import adsCover from "../images/adsCard.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import selectIcon from "../images/select_icon.svg";
import Navbar from "./Navbar";
import ScrollTop from "./ScrollTop";
import Select, { components } from "react-select";
import Fade from "react-reveal/Fade";
import Footer from "./Footer";
import ReactTooltip from "react-tooltip";
import profileThumb from "../images/profileLagom.png";
import useModalProductos from './useModalProductos'

import NumberFormat from 'react-number-format';

import { useModal, Modal } from "react-morphing-modal";

import "react-morphing-modal/dist/ReactMorphingModal.css";
import { loggedIn, getNegocios, getProductosHome, route, loadNextProductPage, getNegociosHome, bucket_route, getCurrentNegocio, user_token, getCiudades, getRangoPrecios, getTipos, loadNextPagePost } from "./API Functions";

import InfiniteScroll from "react-infinite-scroll-component";

import { Image } from 'use-image-color'
import ModalProductos from "./ModalProductos";
import EmptyStateFree from "./EmptyStateFree";
import EmptyStateProfile from "./EmptyStateProfile";
import Blocked from "./Blocked";
import CookiesPop from "./CookiesPop";
import ProductList from "./Products/ProductsList";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 8L10 12L14 8"
          stroke="#081027"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

function Home(props) {
  const [negocio, setNegocio] = useState()
  const [productos, setProductos] = useState([]);
  const [locked_content, setLockedContent] = useState(false);
  const [productos_filtered, setProductosFiltered] = useState([]);
  const [page, setPage] = useState([])
  const [ciudades, setCiudades] = useState([])
  const [rangos, setRangos] = useState([])
  const [lastPage, setLastPage] = useState(0)
  const [negocios, setNegocios] = useState([]);
  const [tipos, setTipos] = useState([]);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const google_api_key = "AIzaSyDI55JWeJBFwp8URFhI3AeB_Jz8lP3pCs4";

  const [state, setState] = useState("");

  const sort_options = [
    {
      value: 0,
      label: 'Lo más antiguo'
    },
    {
      value: 1,
      label: 'Lo más reciente'
    }
  ]

  const [tipo_id, setTipoId] = useState(null);
  const [order_id, setOrderId] = useState(null);

  const [isToggled, setToggled] = useState(false);
  const toggleTrueFalse = () => setToggled(!isToggled);

  const [showCookieModal, setShowCookieModal] = useState(cookies.get('showCookieModal') ? cookies.get('showCookieModal') : false)

  useEffect(() => {
    getNegociosHome().then(res => {
      setNegocios(res);
    })
    getTipos().then(res => {
      if (res) {
        setTipos(res);
      }
    })
    const state = cookies.get('state');
    console.log(state)
    if(state){
      loadData(tipo_id,order_id,state)
      setState(state);
    }else{
      loadData(tipo_id,order_id,state)
      geoLocation();
    }
    
  }, [])

  function geoLocation() {
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(getCoordinates)
    }
  }

  function getCoordinates(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
      setLatitude(latitude);
      setLongitude(longitude);
      reverseGeocodeCoordinates(latitude, longitude);
  }

  function reverseGeocodeCoordinates(latitude, longitude) {
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=false&key=${google_api_key}`)
    .then(res => res.json())
    .then(data => setCityonCookies(data))
    .catch(error => console.log(error))
  }

  function setCityonCookies(data) {
    console.log(data)
    if(data.results[0]){
      var fifteen_days = new Date();
      var today = new Date();
      fifteen_days.setDate(today.getDate() + 15);
      cookies.set('state', data.results[0].address_components[4].long_name, {
        path: '/',
        expires: fifteen_days
      });
      window.location.reload();
    }
  }



  function closeCookieModal() {
    var modal = document.getElementById("myModal");

    modal.style.display = "none";
  }

  const { modalProps, getTriggerProps, activeModal, close } = useModal({
    onOpen() {
      var navbarMain = document.getElementById("navbarMain");
      navbarMain.style.zIndex = 0;
    },
    onClose() {
      var navbarMain = document.getElementById("navbarMain");
      navbarMain.style.zIndex = 9999;
    },
  });

  function loadData(tipo_id, order_id, state) {
    const data = {
      tipo_id: tipo_id ? tipo_id : "",
      order_id: order_id ? order_id : "",
      state: state ? state : ""
    }
    console.log(data, 'lel')
    getProductosHome(data).then(res => {
      if (res) {
        setProductos(res.data);
        setProductosFiltered(res.data);
        setPage(res);
        setLastPage(res.last_page);
      }
    })
  }

  function loadNextPage() {
    const data = {
      tipo_id: tipo_id,
      order_id: order_id ? order_id : "",
      state : state ? state : ""
    }
    console.log(data, 'next load')
    loadNextPagePost(page.next_page_url, data).then(res => {
      if (res) {
        const productos_o = productos.concat(res.data)
        setProductos(productos_o);
        setProductosFiltered(productos_o);
        setPage(res);
        setLastPage(res.last_page);
      }
    })
  }

  function filterByDate(value) {
    setOrderId(value.value);
    loadData(tipo_id, value.value);
  }
  function filterByType(value) {
    setTipoId(value);
    loadData(value, order_id);
  }
  return (
    <div>
      <Navbar />
      {locked_content ?
        <Blocked />
        :
        <>
          <div className="home-cover container">
            <div className="row rowCenter">
              <div className="six">
                <Fade delay={200}>
                  <div>
                    <h5 className="home-cover__title"> Encuentra todo lo que necesitas </h5>
                    <p className="subtitle home-cover__subtitle">
                      Compra sin intermediarios ni comisiones extras. Contacta directamente al negocio.
                    </p>
                    <div className="home-cover__profiles">
                      {negocios.map(negocio => (
                        <div className="profile-cardHome">
                          <Link to={`/${negocio.dominio_okki}`}>
                            <div
                              //
                              data-tip={`${negocio.nombre}`}
                              className={negocio.pro ? "profile-cardHome__icon profile-cardHome__iconPro" : "profile-cardHome__icon"}
                            >
                              <img className="profile-cardHome__icon" src={negocio.imagen_url ? `${bucket_route}${negocio.imagen_url}` : profileThumb} onClick={() => { window.location.replace(`/${negocio.dominio_okki}`) }} />
                            </div>
                            <ReactTooltip className="tooltipStyle" place="top" effect="solid" />
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="six">
                <Fade delay={500}>
                  <img className="home-cover__img" src={cover} />
                </Fade>
              </div>
            </div>
          </div>
          <Fade delay={200}>
            <hr className="fullDivider" />
          </Fade>
          <Fade>
            <div className="home-gallery container">
              <div className="home-gallery__header row">
                <div className="four columns home-gallery__header-div">
                  <h3 className="titleMin">Explorar</h3>
                </div>
                <div className="u-center four columns home-gallery__header-div">
                  {tipos.map((tipo, index) => (
                    <a className={tipo.id == tipo_id ? "itemTag itemTag-active" : "itemTag"} onClick={() => { filterByType(tipo.id) }}>{tipo.detalle}</a>
                  ))
                  }
                </div>
                <div className="four columns home-gallery__header-div">
                  <Select
                    classNamePrefix="inputSelect"
                    className=" inputSelect u-pull-right"
                    options={sort_options}
                    onChange={filterByDate}
                    placeholder="Recientes"
                    components={{ DropdownIndicator }}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    styles={{
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                      })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 6,
                      colors: {
                        ...theme.colors,
                        primary50: "#ccc",
                        primary25: "#F6F6F6",
                        primary: "black",
                      },
                    })}
                  />
                </div>
              </div>
              <InfiniteScroll
                dataLength={productos_filtered.length}
                next={() => { page.current_page !== lastPage ? loadNextPage() : setLockedContent(false) }}
                hasMore={page.currentPage !== lastPage}
              >
                <Fade cascade>
              <ProductList productos={productos_filtered} />
              </Fade>
              </InfiniteScroll>

              <div className="homeGrid-button centerFlex">
                {page.next_page_url ?
                  <button className="button-loadMore" onClick={() => { loadNextPage() }}>Cargar más productos</button>
                  :
                  null
                }
                
              </div>
            </div>
          </Fade>
          <ScrollTop />
          <Footer />


         
          {showCookieModal ? null :  <CookiesPop />}
        </>
      }
    </div >
  );
}

export default Home;
