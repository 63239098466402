import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import profileThumb2 from "../images/emptyThumb.svg";
import itemThumb from "../images/emptyItem.svg";

function EmptyStateFree() {
    return (
        <div>
            <Navbar negocio={[]} />
            <div className=" container settings-headerAlt">
                <div className="row rowProfile rowProfile-header">
                    <div className="rowProfile-thumb">
                        <img className="rowProfile-thumb" src={profileThumb2} />
                    </div>
                    <div className="rowProfile-headerInfo">
                        <div className="rowProfile-top rowProfile-placeholder">
                            <div className="rowProfile-logoEmpty"></div>
                            <div className="rowProfile-actions">

                            </div>
                        </div>

                        <div className="rowProfile-responsive__title">
                            <div className="rowProfile-logoEmpty"></div>

                        </div>
                        <p className="rowProfile-about rowProfile-aboutEmpty">
                            <div className="rowProfile-logoEmpty"></div>
                            <div className="rowProfile-logoEmpty"></div>
                            <div className="rowProfile-logoEmpty"></div>
                        </p>
                        <div className="rowProfile-top rowProfile-bottom">
                            <div className="rowProfile-logoEmpty"></div>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{ maxWidth: "none" }} />
            <div className="home-gallery container home-galleryProfile">
                <div className="">
                    <hr />

                    <div className="homeGrid">
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
    );
}

export default EmptyStateFree;
