import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import venderThumb from "../images/vender-1.svg";
import selectArrow from "../images/selectArrow.svg";
import profileTable from "../images/profileTable.svg";
import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import Sidebar from "./Sidebar";
import Select, { components } from "react-select";
import { getBackofficeUsers, loggedIn, getSuscriptions, getEstados, getCiudades } from "./API Functions";
import MaterialTable from "material-table";
import { TextField } from '@material-ui/core';

const options = [
    { value: "new", label: "Lo más nuevo" },
    { value: "old", label: "Lo más antiguo" },
    { value: "relevant", label: "Relevante" },
];

function Reportes() {
    //const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [condition, setCondition] = useState(true);
    const [report, setReport] = useState({
        sus: null, 
        sus_approved: null,
        sus_rejected: null,
        sus_inactive: null,
        sus_canceled: null,
        tipos: [],
        num_categorias: null,
        categorias: [],
        num_pro: null,
        num_pro_canceled: null,
        num_free: null,
        num_free_4: null,
        states: [],
        cities: [],
        flagged_products: null,
        dates: [],
        daily_sus: null,
        monthly_sus: null,
        yearly_sus: null,
    });

    useEffect(() => {
        loggedIn();
        setLoading(true);
        //loadUsers(true);
        loadSuscriptions();
    }, [])


/*
    function loadUsers(suscription_status) {
        setLoading(true);
        getBackofficeUsers().then(res => {
            if (!res) {
                window.location.replace("/login")
            } else {
                let users = [];
                res.map((user, index) => {
                    if (user.suscription) {
                        if (user.suscription.plan_id == 2 && user.suscription.status == suscription_status) {
                            users.push(user);
                        }
                    }
                    setUsers(users);
                })
            }
            setTimeout(() => {
                setLoading(false);
            }, 700);
        })
    }
*/
    function loadSuscriptions() {
        let reportClone = {...report};
        let newStates = [];
        let newCities = [];
        getSuscriptions().then(res => {
            if (!res) {
                console.log("error suscriptions");
            } else {
                console.log("suscriptions", res);
                let newReport = {
                    sus: 0,
                    sus_approved: 0,
                    sus_rejected: 0,
                    sus_inactive: 0,
                    sus_canceled: 0,
                    tipos: [],
                    num_categorias: 0,
                    categorias: [],
                    num_pro: 0,
                    num_pro_canceled: 0,
                    num_free: 0,
                    num_free_4: 0,
                    flagged_products: 0,
                    dates: [],
                    daily_sus: 0,
                    monthly_sus: 0,
                    yearly_sus: 0,
                }
                res.map((row) => {
                    if (row.suscription) {
                        newReport.sus += 1;
                        newReport.dates.push(row.suscription.created_at);
                        if (row.suscription.status == true) {
                            newReport.sus_approved += 1;
                        } else {
                            newReport.sus_rejected += 1;
                            if (row.suscription.plan_id == 1) {
                                newReport.sus_canceled += 1;
                                if (row.negocio && row.negocio.pro) {
                                    newReport.num_pro_canceled += 1;
                                }
                            } else if (row.suscription.plan_id == 2) {
                                newReport.sus_inactive += 1;
                            }
                        }
                    }
                    if (row.tipo) {
                        let searchTipo = newReport.tipos.find(el => el.id == row.tipo.id);
                        if (searchTipo) {
                            searchTipo.count += 1;
                        } else {
                            newReport.tipos.push({id: row.tipo.id, detalle: row.tipo.detalle, count: 1});
                        }
                    }
                    if (row.negocio) {
                        if (row.negocio.pro) {
                            newReport.num_pro += 1;
                        } else {
                            newReport.num_free += 1;
                            if (row.negocio.productos.length > 4) {
                                newReport.num_free_4 += 1;
                            }
                        }
                        if (row.negocio.productos.length > 0) {
                            newReport.flagged_products += row.negocio.productos.filter((obj) => obj.flagged == true).length;
                        }
                        let searchCategoria = newReport.categorias.find(obj => obj.detalle == row.negocio.categoria_detalle);
                        if (searchCategoria) {
                            searchCategoria.count += 1;
                        } else {
                            newReport.categorias.push({detalle: row.negocio.categoria_detalle, count: 1});
                            newReport.num_categorias += 1;
                        }
                        let searchEstado = newStates.find(obj => obj.name == row.negocio.estado_nombre);
                        if (searchEstado) {
                            searchEstado.count += 1;
                        } else {
                            newStates.push({name: row.negocio.estado_nombre, count: 1});
                        }
                        let searchCiudad = newCities.find(obj => obj.name == row.negocio.ciudad_nombre);
                        if (searchCiudad) {
                            searchCiudad.count += 1;
                        } else {
                            newCities.push({name: row.negocio.ciudad_nombre, count: 1});
                        }
                    }
                })
                newReport.daily_sus = dailySuscriptions(newReport.dates, new Date());
                newReport.monthly_sus = monthlySuscriptions(newReport.dates, new Date());
                newReport.yearly_sus = yearlySuscriptions(newReport.dates, new Date());
                Object.assign(reportClone, newReport);
            }
            setTimeout(() => {
                setLoading(false);
            }, 700);
        });
        /*
        getEstados().then(res => {
            if (!res) {
                console.log("error states");
            } else {
                res.map((row) => {
                    if (row.negocios > 0) {
                        newStates.push({name: row.name, count: row.negocios});
                    }
                })
            }
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        });
        getCiudades().then(res => {
            if (!res) {
                console.log("error ciudades");
            } else {
                res.map((row) => {
                    if (row.negocios > 0) {
                        newCities.push({name: row.nombre, count: row.negocios});
                    }
                })
            }
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        });
        */
        reportClone.states = newStates;
        reportClone.cities = newCities;
        console.log("reportClone", reportClone);
        setReport(reportClone);
    }

    function dailySuscriptions(datesArray, dateInit) {
        const first = dateInit.setHours(0,0,0,0);
        const dayAfter = dateInit.setDate(dateInit.getDate() + 1);
        const second = new Date(dayAfter).setHours(0,0,0,0);
        //console.log("first", new Date(first));
        //console.log("second", new Date(second));
        return filterDates(datesArray, first, second);
    }

    function monthlySuscriptions(datesArray, dateInit) {
        const first = new Date(dateInit.getFullYear(), dateInit.getMonth(), 1).setHours(0,0,0,0);
        const second = new Date(dateInit.getFullYear(), dateInit.getMonth() + 1, 0).setHours(0,0,0,0);
        //console.log("first", first);
        //console.log("second", second);
        return filterDates(datesArray, first, second);
    }

    function yearlySuscriptions(datesArray, dateInit) {
        const first = new Date(dateInit.getFullYear(), 0, 1).setHours(0,0,0,0);
        const second = new Date(dateInit.getFullYear() + 1, 0, 0).setHours(0,0,0,0);
        //console.log("first", first);
        //console.log("second", second);
        return filterDates(datesArray, first, second);
    }

    function filterDates(datesArray, first, second) {
        let count = 0;
        datesArray.map((d) => {
            let dTime = new Date(d).getTime();
            if (dTime >= first && dTime < second) {
                count += 1;
            }
        })
        return count;
    }

    function mostActiveDay() {
        let perDay = [];
        let result = "Sin datos";
        report.dates.map((d) => {
            let dTime = new Date(d).setHours(0,0,0,0);
            let i = perDay.findIndex(element => element.date == dTime);
            if (i >= 0) {
                perDay[i].count += 1;
            } else {
                perDay.push({date: dTime, count: 1});
            }
        })
        if (perDay.length > 0) {
            let maximum = Math.max.apply(Math, perDay.map(function(o) { return o.count; }));
            let maxDate = new Date( perDay.find(function(o){ return o.count == maximum; }).date );
            result = maxDate.getDate() + "/" + (maxDate.getMonth() + 1) + "/" + maxDate.getFullYear();
        }
        return result;
    }

    function updatePeriodicSuscriptions(event, timeClass) {
        const dateString = event.target.value.replace(/-/g, '\/'); //replace - by / so date is correctly parced
        const newDateInit = new Date(dateString);
        let reportClone = {...report};
        if (timeClass == "daily") {
            reportClone.daily_sus = dailySuscriptions(report.dates, newDateInit);
        } else if (timeClass == "monthly") {
            reportClone.monthly_sus = monthlySuscriptions(report.dates, newDateInit);
        } else if (timeClass == "yearly") {
            reportClone.yearly_sus = yearlySuscriptions(report.dates, newDateInit);
        }
        setReport(reportClone);
    }

    function datePickerFormater(date) {
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    } 

    return (
        <div className="backoffice">
            <Sidebar />
            <div className="backofficeContainer">
                <div className="backofficeContainer-header">
                    <div className="backofficeContainer-header__about">
                        <h3 className="backofficeContainer-header__title">Vendedores</h3>
                        <h3 className="backofficeContainer-header__titleAlt">{report ? report.sus : 0}</h3>
                    </div>
                </div>
                <div className="backofficeContainer-headerMin">
                </div>
                <div className="backofficeTable">
                    <hr />
                    <MaterialTable
                        columns={[
                            { title: "Clave",field: "clave" },
                            { title: "Valor", field: "valor" }
                        ]}
                        title={"Solicitudes"}
                        data={[
                            {clave: "Solicitudes totales", valor: report.sus},
                            {clave: "Solicitudes aprobadas", valor: report.sus_approved},
                            {clave: "Solicitudes rechazadas", valor: report.sus_rejected},
                            {clave: "Solicitudes desactivadas", valor: report.sus_inactive},
                            {clave: "Solicitudes canceladas", valor: report.sus_canceled},
                            {clave: "Tipos de negocio", valor: report.tipos.map((t) => {return t.detalle + ": " + t.count.toString()}).join("  |  ")},
                            {clave: "Categorías totales", valor: report.num_categorias},
                            {clave: "Cantidades por categoría", valor: report.categorias.map((c) => {return c.detalle.toString() + "-> " + c.count.toString()}).join("  |  ")},
                            {clave: "Cuentas pro", valor: report.num_pro},
                            {clave: "Cuentas pro canceladas", valor: report.num_pro_canceled},
                            {clave: "Cuentas gratis", valor: report.num_free},
                            {clave: "Cuentas gratis con +4 prods.", valor: report.num_free_4},
                            {clave: "Cuentas por Estado", valor: report.states.map((s) => {return s.name + ": " + s.count.toString()}).join("  |  ")},
                            {clave: "Cuentas por Ciudad", valor: report.cities.map((c) => {return c.name + ": " + c.count.toString()}).join("  |  ")},
                            {clave: "Productos flagueados", valor: report.flagged_products},
                            {clave: "Fechas de registro", valor: report.dates.map((c) => {return new Date(c)}).join("  |  ")},
                            {clave: "Día con más registros", valor: mostActiveDay()},
                            {clave:
                                <div>
                                    <div>Solicitudes diarias</div>
                                    <TextField
                                        id="date_daily_sus"
                                        type="date"
                                        defaultValue={datePickerFormater(new Date())}
                                        onChange={(event) => updatePeriodicSuscriptions(event, "daily")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>, 
                                valor: report.daily_sus },
                            {clave:
                                <div>
                                    <div>Solicitudes mensuales</div>
                                    <TextField
                                        id="date_monthly_sus"
                                        type="date"
                                        defaultValue={datePickerFormater(new Date())}
                                        onChange={(event) => updatePeriodicSuscriptions(event, "monthly")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>, 
                                valor: report.monthly_sus },
                            {clave:
                                <div>
                                    <div>Solicitudes anuales</div>
                                    <TextField
                                        id="date_yearly_sus"
                                        type="date"
                                        defaultValue={datePickerFormater(new Date())}
                                        onChange={(event) => updatePeriodicSuscriptions(event, "yearly")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        views={["year"]}
                                    />
                                </div>, 
                                valor: report.yearly_sus },
                        ]}
                        options={{
                            pageSize: 50,
                            pageSizeOptions: [15, 20, 30, 50],
                            emptyRowsWhenPaging: false,
                            paging: true,
                            showTitle: false,
                            search: true
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage:

                                    loading ?
                                        <>
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{
                                                    marginRight:
                                                        "5px"
                                                }}
                                            />

                                            <span>Cargando...</span>
                                        </>
                                        :
                                        'No hay datos para mostrar.'

                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Reportes;
