import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Navbar from "./Navbar";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import Fade from "react-reveal/Fade";

import Select, { components } from "react-select";

import selectIcon from "../images/select_icon.svg";
import Help from "./Help";

import ScrollTop from "./ScrollTop";
import Footer from "./Footer";


const options = [
    { value: "new", label: "Lo más nuevo" },
    { value: "old", label: "Lo más antiguo" },
    { value: "relevant", label: "Relevante" },
];

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

function Category() {
    const [isToggled, setToggled] = useState(false);

    const toggleTrueFalse = () => setToggled(!isToggled);


    const [isToggledMore, setToggledMore] = useState(false);
    const toggleMore = () => setToggledMore(!isToggledMore);


    return (
        <div>
            <Navbar negocio={[]} />
            <div className="home-gallery  search-header">
                <div className="home-gallery__headerResults container row">
                    <div className="four columns search-header__breadcrumbAlt2">
                        <h3 className="titleMin">Categoría</h3>
                        <div className="onlyMobile moreOptionsToggle" onClick={toggleTrueFalse}>
                            {isToggled ? <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="42" height="42" rx="6" fill="#081027" />
                                <path d="M25.2422 16.7573L16.7569 25.2426" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M25.2431 25.2426L16.7578 16.7573" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                                : <svg onClick={toggleTrueFalse} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="41" height="41" rx="5.5" fill="white" stroke="#E8E8EE" />
                                    <path d="M14 17H28" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16 21H26" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18 25H24" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>}


                        </div>
                        {/* <h3 className="settings-header__title resultsNumber">9,999</h3> */}
                    </div>

                    <div className="eight columns home-gallery__headerSelects onlyDesktop">
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect"
                            options={options}
                            placeholder="Reciente"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                            styles={{
                                dropdownIndicator: (provided, state) => ({
                                    ...provided,
                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                })
                            }}
                        />
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect"
                            options={options}
                            placeholder="Pick n Go"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius:6,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                            styles={{
                                dropdownIndicator: (provided, state) => ({
                                    ...provided,
                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                })
                            }}
                        />
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect"
                            options={options}
                            placeholder="$$"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                            styles={{
                                dropdownIndicator: (provided, state) => ({
                                    ...provided,
                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                })
                            }}
                        />
                    </div>
                    <div className="u-center four columns onlyMobile">

                        <div className="">
                            <select style={{ backgroundImage: `url(${selectIcon})` }} className="inputSelect inputSelectSearch">
                                <option>Categoría</option>
                            </select>

                        </div>
                    </div>
                    <div className="four columns onlyMobile">
                        {isToggled ?

                            <Fade top>
                                <div className="home-gallery__headerSelects home-gallery__headerSelectsMini">
                                    <select style={{ backgroundImage: `url(${selectIcon})` }} className="inputSelect">
                                        <option>Pick n Go</option>
                                    </select>

                                    <select style={{ backgroundImage: `url(${selectIcon})` }} className="inputSelect">
                                        <option>$$</option>
                                    </select>
                                </div>
                            </Fade> : <div></div>}
                    </div>
                </div>
                <hr className="fullDivider" style={{ margin: 0 }} />
                <div className="search-header__tags row">
                    <a className="itemTag itemTag-active">Todo</a>
                    <a className="itemTag">Comprar</a>
                    <a className="itemTag">Comer</a>
                    <a className="itemTag">Contratar</a>
                    <a className="itemTag">Comprar</a>
                    <a className="itemTag">Comer</a>
                    <a className="itemTag">Contratar</a>
                    <a className="itemTag">Comprar</a>
                    <a className="itemTag">Comer</a>
                    <a className="itemTag">Contratar</a>
                    <a className="itemTag">Contratar</a>
                    <a className="itemTag">Contratar</a>
                    <span className={isToggledMore ?
                        "itemTagFade noDisplay"
                        :
                        "itemTagFade"}></span>
                    <a className={isToggledMore ?
                        "itemTagButton onlyDesktop noDisplay"
                        :
                        "itemTagButton onlyDesktop"} onClick={toggleMore}>Ver más</a>
                    <div className={isToggledMore ?
                        "search-header__tags-extra search-header__tags-extraShow"
                        :
                        "search-header__tags-extra"}>
                        <a className="itemTag">Comprar</a>
                        <a className="itemTag">Comer</a>
                        <a className="itemTag">Contratar</a>
                        <a className="itemTag">Comprar</a>
                        <a className="itemTag">Comer</a>
                        <a className="itemTag">Contratar</a>
                        <a className="itemTag">Comprar</a>
                        <a className="itemTag">Comer</a>
                        <a className="itemTag">Contratar</a>
                        <a className="itemTag">Contratar</a>
                        <a className="itemTag">Contratar</a>
                    </div>
                </div>
                <hr className="fullDivider" style={{ margin: 0 }} />
                <div className="container">
                    <div className="home-cover__profilesResult  search-header__profiles">
                        <div className="profile-card">
                            <Link to="/perfil">
                                <div
                                    className="profile-card__icon"
                                    style={{ background: "#c4c4c4" }}
                                ></div>
                            </Link>
                            <p className="profile-card__name">Ebay</p>
                        </div>
                        <div className="profile-card">
                            <div
                                className="profile-card__icon"
                                style={{ background: "#12145F" }}
                            ></div>
                            <p className="profile-card__name">Gillete</p>
                        </div>
                        <div className="profile-card">
                            <div
                                className="profile-card__icon"
                                style={{ background: "#E70012" }}
                            ></div>
                            <p className="profile-card__name">Nintendo</p>
                        </div>
                        <div className="profile-card">
                            <Link to="/perfil">
                                <div
                                    className="profile-card__icon"
                                    style={{ background: "#c4c4c4" }}
                                ></div>
                            </Link>
                            <p className="profile-card__name">Ebay</p>
                        </div>
                        <div className="profile-card">
                            <div
                                className="profile-card__icon"
                                style={{ background: "#12145F" }}
                            ></div>
                            <p className="profile-card__name">Gillete</p>
                        </div>
                        <div className="profile-card">
                            <div
                                className="profile-card__icon"
                                style={{ background: "#E70012" }}
                            ></div>
                            <p className="profile-card__name">Nintendo</p>
                        </div>
                        <div className="profile-card">
                            <Link to="/perfil">
                                <div
                                    className="profile-card__icon"
                                    style={{ background: "#c4c4c4" }}
                                ></div>
                            </Link>
                            <p className="profile-card__name">Ebay</p>
                        </div>
                        <div className="profile-card">
                            <div
                                className="profile-card__icon"
                                style={{ background: "#12145F" }}
                            ></div>
                            <p className="profile-card__name">Gillete</p>
                        </div>
                        <div className="profile-card">
                            <div
                                className="profile-card__icon"
                                style={{ background: "#E70012" }}
                            ></div>
                            <p className="profile-card__name">Nintendo</p>
                        </div>
                        <div className="profile-card profile-card__action">
                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30" cy="30" r="30" fill="white" />
                                <path d="M31 35L36 30L31 25" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M36 30H24" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>

                    </div>
                </div>
                <hr className="fullDivider" />
                <div className="homeGrid container">
                    <div className="homeGrid-item">
                        <img src={itemThumb} />
                        <div className="homeGrid-item__info">
                            <img className="homeGrid-item__icon" src={itemProfile} />
                            <div>
                                <p>Antigua and Barbuda</p>
                                <p>$9,999</p>
                            </div>
                        </div>
                    </div>
                    <div className="homeGrid-item">
                        <img src={itemThumb} />
                        <div className="homeGrid-item__info">
                            <img className="homeGrid-item__icon" src={itemProfile} />
                            <div>
                                <p>Antigua and Barbuda</p>
                                <p>$9,999</p>
                            </div>
                        </div>
                    </div>
                    <div className="homeGrid-item">
                        <img src={itemThumb} />
                        <div className="homeGrid-item__info">
                            <img className="homeGrid-item__icon" src={itemProfile} />
                            <div>
                                <p>Antigua and Barbuda</p>
                                <p>$9,999</p>
                            </div>
                        </div>
                    </div>
                    <div className="homeGrid-item">
                        <img src={itemThumb} />
                        <div className="homeGrid-item__info">
                            <img className="homeGrid-item__icon" src={itemProfile} />
                            <div>
                                <p>Antigua and Barbuda</p>
                                <p>$9,999</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollTop />
            <Footer />
        </div>
    );
}

export default Category;
