import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import adsCover from "../../images/adsCard.png";
import itemThumb from "../../images/itemThumb.svg";
import itemProfile from "../../images/itemProfile.svg";
import Fade from "react-reveal/Fade";
import ReactTooltip from "react-tooltip";
import useModalProductos from '../useModalProductos'

import NumberFormat from 'react-number-format';

import "react-morphing-modal/dist/ReactMorphingModal.css";
import { bucket_route } from "../API Functions";

import ModalProductos from "../ModalProductos";
import ProductPrice from "./ProductPrice";
import ProductCard from "./ProductCard";


const ProductList = ({productos,disabled_logo}) => {
   


    const [inHover, setHover] = useState(false);


    useEffect(()=>{
        //alert('test')

    },[])

    return (    
    <>
        <div className="homeGrid">
            {productos.map((producto, index) => (
            <div>
           <ProductCard
           producto={producto}
           disabled_logo={disabled_logo}
           />
           </div>

    ))
    }   
        </div>
          
    </>
)};

export default ProductList;