import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import classnames from "classnames";
import { logOut, route } from "./API Functions";
import Fade from "react-reveal/Fade";
import selectIconAlt from "../images/select_icon_alt.svg";

import profileThumb from "../images/profileThumb.png";

function openNav() {
  const navigationWindow = document.getElementById("myNav");
  navigationWindow.style.width = "580px";
  const navbarOverlay = document.getElementById("navbarOverlay");
  navbarOverlay.style.opacity = 1;
  navbarOverlay.style.width = "100%";
  navbarOverlay.style.visibility = "visible";
  const menuContainer1 = document.getElementById("menuContainer1");
  if (menuContainer1) {
    menuContainer1.style.display = "block";
  }
  const menuContainer2 = document.getElementById("menuContainer2");
  if (menuContainer2) {
    menuContainer2.style.display = "block";
  }
}

function closeNav() {
  const navigationWindow = document.getElementById("myNav");
  navigationWindow.style.width = "0%";
  const navbarOverlay = document.getElementById("navbarOverlay");
  navbarOverlay.style.opacity = 0;
  navbarOverlay.style.visibility = "hidden";
  const menuContainer1 = document.getElementById("menuContainer1");
  if (menuContainer1) {
    menuContainer1.style.display = "none";
  }
  const menuContainer2 = document.getElementById("menuContainer2");
  if (menuContainer2) {
    menuContainer2.style.display = "none";
  }
}

const NavbarBurger = (props) => (
  <a
    role="button"
    onClick={props.toggleMenu}
    className={`navbar-burger ${props.active ? "is-active" : ""}`}
    aria-label="menu"
    aria-expanded="false"
    data-target="navbarBasic"
  ></a>
);

export default class NavbarMin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: typeof window !== "undefined" && window.pageYOffset,
      visible: true,
      hoveredBuy: false,
      hoveredEat: false,
      hoveredHire: false,
    };
  }
  state = {
    activeMenu: true,
    isFocused: false,
  };

  onMouseEnterBuy = (e) => {
    this.setState({ hoveredBuy: true });
  };
  onMouseEnterEat = (e) => {
    this.setState({ hoveredEat: true });
  };
  onMouseEnterHire = (e) => {
    this.setState({ hoveredHire: true });
  };

  onMouseLeaveBuy = (e) => {
    this.setState({ hoveredBuy: false });
  };
  onMouseLeaveEat = (e) => {
    this.setState({ hoveredEat: false });
  };
  onMouseLeaveHire = (e) => {
    this.setState({ hoveredHire: false });
  };

  onFocusChange = () => {
    this.setState({ isFocused: !this.state.isFocused });
  };

  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    });
  };
  escFunction(event) {
    if (event.keyCode === 27) {
      const navigationWindow = document.getElementById("myNav");
      navigationWindow.style.width = "0%";
      const navbarOverlay = document.getElementById("navbarOverlay");
      navbarOverlay.style.opacity = 0;
      navbarOverlay.style.visibility = "hidden";
      const menuContainer1 = document.getElementById("menuContainer1");
      if (menuContainer1) {
        menuContainer1.style.display = "none";
      }
      const menuContainer2 = document.getElementById("menuContainer2");
      if (menuContainer2) {
        menuContainer2.style.display = "none";
      }
    }
  }
  componentDidMount() {
    if (typeof window === "undefined") {
      global.window = {};
    }
    window.addEventListener("scroll", this.handleScroll);

    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos || "100" > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      const navigationWindow = document.getElementById("myNav");
      navigationWindow.style.width = "0%";
      const navbarOverlay = document.getElementById("navbarOverlay");
      navbarOverlay.style.opacity = 0;
      navbarOverlay.style.visibility = "hidden";
      const menuContainer1 = document.getElementById("menuContainer1");
      if (menuContainer1) {
        menuContainer1.style.display = "none";
      }
      const menuContainer2 = document.getElementById("menuContainer2");
      if (menuContainer2) {
        menuContainer2.style.display = "none";
      }
    }
  }

  render() {
    const { hoveredEat } = this.state;
    const { hoveredBuy } = this.state;
    const { hoveredHire } = this.state;
    const styleBuy = hoveredBuy ? { backgroundColor: "#95983A" } : {};
    const styleEat = hoveredEat ? { backgroundColor: "#ED3325" } : {};
    const styleHire = hoveredHire ? { backgroundColor: "#FF9C38" } : {};
    return (
      <>
        <Link to="/" className="okkiLogo okkiLogoVender">
          <div>
            <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M45.5925 39.8231C46.9383 39.8904 48.0654 38.8137 48.0654 37.468V35.1633C48.0654 34.8773 48.4019 34.7259 48.6206 34.9109L53.9869 39.3016C54.4243 39.6549 54.9458 39.8399 55.4841 39.8399C56.1738 39.8399 56.8467 39.5371 57.3009 38.982C58.1252 37.9726 57.9738 36.4923 56.9645 35.668L52.4224 31.9502C52.086 31.681 52.086 31.1764 52.4224 30.9072L56.9645 27.1894C57.9738 26.3651 58.1252 24.8848 57.3009 23.8754C56.4766 22.8661 54.9963 22.7147 53.9869 23.539L48.6206 27.9296C48.4019 28.1147 48.0654 27.9465 48.0654 27.6773V25.4231C48.0654 24.1614 47.0897 23.068 45.828 23.0007C44.4822 22.9334 43.3551 24.01 43.3551 25.3558V37.4007C43.3551 38.6623 44.3308 39.7558 45.5925 39.8231ZM36.4075 42.1782C35.0617 42.1109 33.9346 43.1876 33.9346 44.5334V46.838C33.9346 47.124 33.5981 47.2754 33.3794 47.0904L28.0131 42.6997C27.5757 42.3465 27.0542 42.1614 26.5159 42.1614C25.8262 42.1614 25.1533 42.4642 24.6991 43.0194C23.8748 44.0287 24.0262 45.5091 25.0355 46.3334L29.5776 50.0511C29.914 50.3203 29.914 50.825 29.5776 51.0941L25.0355 54.8119C24.0262 55.6362 23.8748 57.1165 24.6991 58.1259C25.5234 59.1352 27.0037 59.2866 28.0131 58.4623L33.3794 54.0717C33.5981 53.8866 33.9346 54.0549 33.9346 54.324V56.5614C33.9346 57.8231 34.9103 58.9165 36.172 58.9838C37.5178 59.0511 38.6449 57.9745 38.6449 56.6287V44.5838C38.6449 43.339 37.6692 42.2455 36.4075 42.1782ZM31.4112 23.0007C26.7682 23.0007 23 26.7689 23 31.4119C23 36.0549 26.7682 39.8231 31.4112 39.8231C36.0542 39.8231 39.8224 36.0549 39.8224 31.4119C39.8224 26.7689 36.0542 23.0007 31.4112 23.0007ZM31.4112 35.1128C29.3757 35.1128 27.7103 33.4474 27.7103 31.4119C27.7103 29.3764 29.3757 27.7109 31.4112 27.7109C33.4467 27.7109 35.1121 29.3764 35.1121 31.4119C35.1121 33.4474 33.4467 35.1128 31.4112 35.1128ZM56.6449 48.2343H44.5327C43.2374 48.2343 42.1776 49.2941 42.1776 50.5894C42.1776 51.8848 43.2374 52.9446 44.5327 52.9446H56.6449C57.9402 52.9446 59 51.8848 59 50.5894C59 49.2941 57.9402 48.2343 56.6449 48.2343Z" fill="white" />
            </svg>

            {/* {!this.state.activeLogo ? (
              <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M35.0937 39.9952L39.1444 35.252C39.4842 34.8208 39.2054 34.2928 38.6827 34.2928H35.2505C34.8846 34.2928 34.6668 34.4776 34.4839 34.6624L30.7729 39.0008V25.5896C30.7729 25.2816 30.4941 25 30.1892 25H26.7831C26.4782 25 26.1994 25.2816 26.1994 25.5896V46.1024C26.1994 46.4104 26.4782 46.692 26.7831 46.692H30.1892C30.4941 46.692 30.7729 46.4104 30.7729 46.1024V41.0512L34.9456 46.4456C35.0414 46.5688 35.2853 46.692 35.4073 46.692H39.5451C40.0678 46.692 40.3466 46.164 40.0068 45.7592L35.0937 39.9952ZM18.3767 33.9848C14.8834 33.9848 12 36.9592 12 40.4616C12 44.0256 14.8834 47 18.3767 47C21.8699 47 24.7533 44.0256 24.7533 40.4616C24.7621 36.9592 21.8786 33.9848 18.3767 33.9848ZM18.3767 43.0048C17.0264 43.0048 15.9549 41.8608 15.9549 40.4616C15.9549 39.0976 17.0264 37.98 18.3767 37.98C19.7269 37.98 20.7984 39.0976 20.7984 40.4616C20.8071 41.852 19.7269 43.0048 18.3767 43.0048ZM57.9521 30.544C59.3024 30.544 60.4087 29.4616 60.4087 28.0976C60.4087 26.7336 59.3024 25.616 57.9521 25.616C56.6019 25.616 55.5304 26.7336 55.5304 28.0976C55.5217 29.4616 56.6019 30.544 57.9521 30.544ZM59.6683 34.2928H56.297C55.9921 34.2928 55.7133 34.5392 55.7133 34.8824V46.1024C55.7133 46.4104 55.9921 46.692 56.297 46.692H59.6683C59.9731 46.692 60.2519 46.4104 60.2519 46.1024V34.8824C60.2519 34.548 59.9731 34.2928 59.6683 34.2928ZM49.8506 39.9952L53.9014 35.252C54.2411 34.8208 53.9623 34.2928 53.4397 34.2928H50.0074C49.6415 34.2928 49.4238 34.4776 49.2408 34.6624L45.5298 39.0008V25.5896C45.5298 25.2816 45.251 25 44.9461 25H41.54C41.2351 25 40.9564 25.2816 40.9564 25.5896V46.1024C40.9564 46.4104 41.2351 46.692 41.54 46.692H44.9461C45.251 46.692 45.5298 46.4104 45.5298 46.1024V41.0512L49.7025 46.4456C49.7983 46.5688 50.0423 46.692 50.1642 46.692H54.3021C54.8248 46.692 55.1035 46.164 54.7638 45.7592L49.8506 39.9952Z" fill="#081027" />
              </svg>

            ) : (
                <img src={okkiFaces[this.state.currentImageIndex]} />
              )} */}
          </div>
        </Link>
        <div className="navbarHideShow2">
          <div
            id="navbarOverlay"
            className="navbarOverlay navbarOverlay-active"
          ></div>
          <header className="navbar-home">
            <div
              className={
                this.state.isFocused
                  ? "navbar-home__section navbar-home__sectionReduced"
                  : "navbar-home__section"
              }
            >

              {/* <Link to="/">
                <svg
                  width="44"
                  height="20"
                  viewBox="0 0 44 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M20.9942 13.632L24.6767 9.32C24.9856 8.928 24.7322 8.448 24.257 8.448H21.1368C20.8042 8.448 20.6062 8.616 20.4399 8.784L17.0662 12.728V0.536C17.0662 0.256 16.8128 0 16.5356 0H13.4392C13.162 0 12.9086 0.256 12.9086 0.536V19.184C12.9086 19.464 13.162 19.72 13.4392 19.72H16.5356C16.8128 19.72 17.0662 19.464 17.0662 19.184V14.592L20.8596 19.496C20.9467 19.608 21.1685 19.72 21.2793 19.72H25.041C25.5162 19.72 25.7696 19.24 25.4608 18.872L20.9942 13.632ZM5.79698 8.168C2.62131 8.168 0 10.872 0 14.056C0 17.296 2.62131 20 5.79698 20C8.97264 20 11.594 17.296 11.594 14.056C11.6019 10.872 8.98056 8.168 5.79698 8.168ZM5.79698 16.368C4.56947 16.368 3.59539 15.328 3.59539 14.056C3.59539 12.816 4.56947 11.8 5.79698 11.8C7.02448 11.8 7.99856 12.816 7.99856 14.056C8.00648 15.32 7.02448 16.368 5.79698 16.368ZM41.7747 5.04C43.0022 5.04 44.0079 4.056 44.0079 2.816C44.0079 1.576 43.0022 0.56 41.7747 0.56C40.5472 0.56 39.5731 1.576 39.5731 2.816C39.5652 4.056 40.5472 5.04 41.7747 5.04ZM43.3348 8.448H40.27C39.9928 8.448 39.7394 8.672 39.7394 8.984V19.184C39.7394 19.464 39.9928 19.72 40.27 19.72H43.3348C43.6119 19.72 43.8654 19.464 43.8654 19.184V8.984C43.8654 8.68 43.6119 8.448 43.3348 8.448ZM34.4096 13.632L38.0922 9.32C38.401 8.928 38.1476 8.448 37.6724 8.448H34.5522C34.2196 8.448 34.0216 8.616 33.8553 8.784L30.4816 12.728V0.536C30.4816 0.256 30.2282 0 29.951 0H26.8546C26.5774 0 26.324 0.256 26.324 0.536V19.184C26.324 19.464 26.5774 19.72 26.8546 19.72H29.951C30.2282 19.72 30.4816 19.464 30.4816 19.184V14.592L34.275 19.496C34.3621 19.608 34.5839 19.72 34.6947 19.72H38.4564C38.9316 19.72 39.185 19.24 38.8762 18.872L34.4096 13.632Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="44" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Link> */}
            </div>
            <div
              className="navbar-home__section navbar-home__section2"
              className={
                this.state.isFocused
                  ? "navbar-home__section navbar-home__sectionExp navbar-home__section2"
                  : "navbar-home__section navbar-home__section2"
              }
            ></div>
            <div className="navbar-iconWrapper">
              <Link className="closebtn" to="/">
                <svg className="modalCloseButton" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" stroke="#EEEEEE" />
                  <path d="M25.2422 16.7574L16.7569 25.2427" stroke="#66666A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M25.2431 25.2427L16.7578 16.7574" stroke="#66666A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </Link>
            </div>
          </header>

        </div>
      </>

    );
  }
}
