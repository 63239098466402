import { createGlobalStyle } from "styled-components";

import HeyNowRegularTtf from "./HeyWow-Regular.ttf";
import HeyNowRegularWoff from "./heywow-regular-webfont.woff";
import HeyNowRegularWoff2 from "./heywow-regular-webfont.woff2";
import HeyNowMediumTtf from "./HeyWow-SemiBold.ttf";
import HeyNowMediumWoff from "./heywow-semibold-webfont.woff";
import HeyNowMediumWoff2 from "./heywow-semibold-webfont.woff2";
import HeyNowBoldTtf from "./HeyWow-Bold.ttf";
import HeyNowBoldWoff from "./heywow-bold-webfont.woff";
import HeyNowBoldWoff2 from "./heywow-bold-webfont.woff2";

export default createGlobalStyle`
    @font-face {
        font-family: 'heywowregular';
        src: 
        url(${HeyNowRegularWoff2}) format('woff2'),
        url(${HeyNowRegularWoff}) format('woff'),
        url(${HeyNowRegularTtf}) format('ttf');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'heywowmedium';
        src: 
        url(${HeyNowMediumWoff2}) format('woff2'),
        url(${HeyNowMediumWoff}) format('woff'),
        url(${HeyNowMediumTtf}) format('ttf');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'heywowbold';
        src: 
        url(${HeyNowBoldWoff2}) format('woff2'),
        url(${HeyNowBoldWoff}) format('woff'),
        url(${HeyNowBoldTtf}) format('ttf');
        font-weight: bold;
        font-style: normal;
    }
    body {
      font-family: 'heywowregular', sans-serif;
    }
`;
