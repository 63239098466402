import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import Modal from "react-modal";
import profileThumb2 from "../../images/profileThumb2.svg";

import cover from "../../images/home-cover.svg";
import itemThumb from "../../images/itemThumb.svg";
import itemProfile from "../../images/itemProfile.svg";
import Navbar from "../Navbar";
import ScrollTop from "../ScrollTop";
import Select, { components } from "react-select";
import Footer from "../Footer";
import { bucket_route } from "../API Functions";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        padding: 0,
        border: "none",
        borderRadius: 0,
    },
};

const options = [
    { value: "new", label: "Lo más nuevo" },
    { value: "old", label: "Lo más antiguo" },
    { value: "relevant", label: "Relevante" },
];

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

const CategoryProfiles = ({ isShowing, hide, negocios }) => isShowing ? ReactDOM.createPortal(
    <React.Fragment>
        <Modal
            isOpen={isShowing}
            onAfterOpen={false}
            onRequestClose={hide}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div>
                <div className="home-gallery">
                    <div className="home-gallery__headerResults container row">
                        <div className="four columns search-header__breadcrumbAlt">
                            <a>
                                <svg onClick={hide} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 5L4 10L9 15" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4 10L16 10" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </a>
                            <h3 className="titleMin">Regresar</h3>
                        </div>

                        <div className="eight columns home-gallery__headerSelects">
                        </div>
                    </div>
                    <hr style={{ margin: 0 }} />
                    <hr style={{ margin: 0 }} />

                    <div className="container">
                        <div className="home-cover__profilesResult ">
                            <div className="row rowFull profileResultRow">
                                {negocios.map((negocio, index) => (
                                    <div className="six columns">
                                        <Link className="profile-card__large">
                                            <div className="profile-card__large-block">
                                            <Link to={`/${negocio.dominio_okki}`}>
                                                <div className={negocio.pro ? "rowProfile-thumbPro" : "rowProfile-thumb"}>
                                                    <img className="rowProfile-thumb" src={negocio.imagen_url !== '' ? `${bucket_route}${negocio.imagen_url}` : profileThumb2} />
                                                </div>
                                            </Link>
                                            <p className="profile-card__name">{negocio.nombre}</p>
                                            </div>
                                            <div>
                                                <div className="profile-card__large-blockAlt">
                                                    <a className="itemTag">{negocio.nombre}</a>
                                                    <a className="itemTag">{'$'}</a>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </Modal>
    </React.Fragment>, document.body
) : null;

export default CategoryProfiles;
