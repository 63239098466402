import React, {
    Component,
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";
import { Link, NavLink } from "react-router-dom";
import Select, { components } from "react-select";
import Switch from "react-switch";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css"
import { loggedIn, getNegocios, postProductos, getSubcategorias, getProductosByID, route, putProductos, user_token, PreloadProductImage, bucket_route } from "../API Functions";
import { FileDrop } from "react-file-drop";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ReactTooltip from "react-tooltip";
import { Helmet } from 'react-helmet';
import NumberFormat from 'react-number-format';
import CharacterCounter from 'react-character-counter'
import Modal from "react-modal";
import { array, func } from "prop-types";
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getOrientation } from 'get-orientation/browser'
import ImgDialog from './ImgDialog'
import { getCroppedImg, getRotatedImage } from './canvasUtils'
import { styles } from './styles'
import zoomIcon from "../../images/zoom-icon.svg";
import rotateIcon from "../../images/rotate-icon.svg";
import plusIcon from "../../images/add_plus.svg";
import Compressor from 'compressorjs';
import axios from "axios";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: 900,
        maxHeight: 700,
        height: "90%",
        padding: "20px 0",
        border: "none",
        borderRadius: 12,
    },
};

// Setting a high pixel ratio avoids blurriness in the canvas crop preview.
const pixelRatio = 4;

function getResizedCanvas(canvas, newWidth, newHeight) {
    const tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx = tmpCanvas.getContext("2d");
    ctx.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        newWidth,
        newHeight
    );

    return tmpCanvas;
}


const options = [
    { value: "new", label: "Mexicana" },
    { value: "old", label: "Oriental" },
    { value: "relevant", label: "Hamburguesa" },
];

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

const NuevoProducto = ({ classes }) => {

    const tagifyRef = useRef();
    // just a name I made up for allowing dynamic changes for tagify settings on this component
    const [tagifySettings, setTagifySettings] = useState([]);
    const [tagifyProps, setTagifyProps] = useState({});

    const [negocio, setNegocio] = useState(JSON.parse(localStorage.getItem('negocio')));
    const [tipo_id, setTipo] = useState("");

    const [files, setFile] = useState([]);
    const [file_preview, setFilePreview] = useState("");

    const [subcategorias, setSubcategorias] = useState([]);

    const [Loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState("");
    const [negocio_id, setNegocioId] = useState(JSON.parse(localStorage.getItem('negocio')));
    const [precio, setPrecio] = useState("");
    const [precio_descuento, setPrecioDescuento] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [tags, setTags] = useState("");
    const [producto_id, setProductoID] = useState("");
    const [imagenEdit, setImagenEdit] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [subcategoria_id, setSubcategoriaId] = useState("");
    const [lightbox, setLightbox] = useState(false);
    const [show_price, setShowPrice] = useState(false);
    const [images_preloaded, setImagesPreloaded] = useState([]);
    const [principal_preloaded, setPrincipalPreloadedId] = useState("");

    const [precioError, setPrecioError] = useState("");
    const [precio_descuentoError, setPrecioDescuentoError] = useState("");
    const [descripcionError, setDescripcionError] = useState("");
    const [tagsError, setTagsError] = useState("");
    const [nombreError, setNombreError] = useState("");
    const [createError, setCreateError] = useState("");
    const [fileError, setFileError] = useState("");
    const [imgError, setImgError] = useState("");
    const fileUpload = useRef(null);
    const fileUploadExtra = useRef(null);

    const [mensajeError, setMensajeError] = useState("");
    const [subcategoria_idError, setSubcategoriaIdError] = useState("");

    const [edit, setEdit] = useState(false);
    const [principal_image, setPrincipalImage] = useState({
        original: "",
        cropped: ""
    })
    const [file_preview_array, setFilesToPreview] = useState([]);

    // Crop
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    //const [crop, setCrop] = useState({ unit: "%", aspect: 4 / 5 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [crop_completed, setCropCompleted] = useState(false);
    const [completedCropImg, setCompletedCroppedImage] = useState("");
    const [index_to_crop, setIndextoCrop] = useState(0);
    const [index_to_edit, setIndextoEdit] = useState(0);
    const [addImageButton, setImageButton] = useState(false);
    //const [current_editing_crop, setCurrentEditingCrop] = useState({ unit: "%", aspect: 4 / 5 });
    const [current_editing_img, setCurrentEditinImg] = useState("");
    const [current_crop_Completed, setCurrentCropCompleted] = useState(false);
    const [current_crop, setCurrentCrop] = useState(false);

    const [imageSrc, setImageSrc] = React.useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedImage, setCroppedImage] = useState(null)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const [imageExtraSrc, setImageExtraSrc] = React.useState(null)
    const [current_editing_crop, setCurrentEditingCrop] = useState({ x: 0, y: 0 })
    const [current_editing_rotation, setCurrentEditingRotation] = useState(0)
    const [current_editing_zoom, setCurrentEditingZoom] = useState(1)


    const [cover_image, setCoverImage] = useState([]);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpenExtras, setIsOpenExtras] = React.useState(false);

    const [isEditting, setIsEditing] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function openModalExtras() {
        setIsOpenExtras(true);
    }

    function closeModalExtras() {
        setIsOpenExtras(false);
    }

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    function loadSubcategorias(negocio_id, n_subcategoria_id) {
        getSubcategorias().then(res => {
            if (res) {
                const subcategorias = [];
                res.map((subcategoria, index) => {
                    console.log(subcategoria.negocio_id);
                    console.log(negocio_id)
                    if (subcategoria.negocio_id == negocio_id) {
                        subcategoria = {
                            ...subcategoria,
                            value: subcategoria.id,
                            label: subcategoria.detalle
                        }

                        if (subcategoria.id == n_subcategoria_id) {
                            setSubcategoriaId(subcategoria);
                        }
                        subcategorias.push(subcategoria);
                    }
                })
                if (n_subcategoria_id == "") {
                    setSubcategoriaId(subcategorias[0])
                }
                setSubcategorias(subcategorias);
            }
            console.log(subcategorias, 'lelo');
        })
    }

    // set "showDropdown" to some value, which will filter the dropdown by that value
    const serverDelay = (func) => (duration) =>
        new Promise((resolve, reject) =>
            setTimeout(() => {
                resolve(func());
            }, duration || 1000)
        );

    const getWhitelistFromServer = serverDelay(() => [
        "aaa",
        "aaa1",
        "aaa2",
        "aaa3",
        "bbb1",
        "bbb2",
        "bbb3",
        "bbb4",
    ]);

    const getValue = serverDelay(() => ["foo", "bar", "baz"]);

    const baseTagifySettings = {
        blacklist: ["xxx", "yyy", "zzz"],
        maxTags: negocio.pro ? 5 : 2,
        pattern: /^.{0,16}$/,
        //backspace: "edit",
        placeholder: negocio.pro ? "Etiquetas (máx 5)" : "Etiquetas (máx 2)",
        dropdown: {
            enabled: 0, // a;ways show suggestions dropdown
        },
    };

    // callbacks props (for this demo, the same callback reference is assigned to every event type)
    const tagifyCallbacks = {
        // add: callback,
        // remove: callback,
        // input: callback,
        // edit: callback,
        // invalid: callback,
        // click: callback,
        // keydown: callback,
        // focus: callback,
        // blur: callback,
        // "edit:input": callback,
        // "edit:updated": callback,
        // "edit:start": callback,
        // "edit:keydown": callback,
        // "dropdown:show": callback,
        // "dropdown:hide": callback,
        // "dropdown:select": callback
    };

    // on component mount
    useEffect(() => {
        if (JSON.parse(localStorage.getItem('negocio')).id) {
            setNegocioId(negocio.id)
            setTipo(negocio.tipos[0].id)
            loadSubcategorias(negocio.id, 0)
            console.log(negocio, 'lel')
        } else {
            if (user_token) {
                window.location.replace("/catalogo")
            } else {
                window.location.replace("/404")
            }
        }

        //setTagifyProps({ loading: true });

        getWhitelistFromServer(2000).then((response) => {
            setTagifySettings((lastState) => ({
                ...lastState,
                whitelist: response,
            }));

            //setTagifyProps({ showDropdown: "a", loading: false });
        });
        const pathname = window.location.pathname
        const producto = JSON.parse(sessionStorage.getItem('producto_edit'));
        if (pathname.includes('edit')) {
            if (producto) {
                /* getProductosByID(props.match.params.producto_id).then(res => {
                     if (res) {
                         setNombre(res.nombre)
                         /*if(res.image_url){
                             setFilePreview(`${route}/storage/${res.image_url}`)
                             setCropCompleted(true);
                         }
                         setPrecio(res.precio)
                         setPrecioDescuento(res.precio_descuento)
                         setDescripcion(res.descripcion)
                         setTags(res.tags);
                         setEdit(true);
                         setProductoID(res.id);
                     }
                 })*/
                setNombre(producto.nombre);
                setPrecio(producto.precio)
                setPrecioDescuento(producto.precio_descuento)
                setDescripcion(producto.descripcion)
                setTags(producto.tags);
                setEdit(true);
                setImagenEdit(producto.image_url);
                setProductoID(producto.id);
                setLightbox(producto.lightbox);
                setShowPrice(producto.precio_privado);
                setSubcategoriaId(producto.subcategoria_id);
                setMensaje(producto.mensaje_privado ? producto.mensaje_privado : "");
                loadSubcategorias(producto.negocio.id, producto.subcategoria_id)
                if (producto.principal_image) {
                    const principal_image = {
                        cropped: `${bucket_route}${producto.principal_image.path}`,
                        original: `${bucket_route}${producto.principal_image.path}`,
                        crop: { x: 0, y: 0 }
                    };
                    setPrincipalImage(principal_image);
                } else {
                    const principal_image = {
                        cropped: `${bucket_route}${producto.images[0].path}`,
                        original: `${bucket_route}${producto.images[0].path}`,
                        crop: { x: 0, y: 0 }
                    };
                    setPrincipalImage(principal_image);
                }
                setFilePreview(true);
                setCropCompleted(true);
                const files = [];
                producto.images.map((image, index) => {
                    const file = {
                        original: `${bucket_route}${image.path}`,
                        cropped: `${bucket_route}${image.path}`,
                        crop: { x: 0, y: 0 },
                        deleted: false,
                        image_id: image.id,
                        completed_crop : ""
                    }
                    files.push(file)
                })
                setFilesToPreview(files);
                setIndextoEdit(files.length)
                if(files.length < 4){
                    files.push(
                        {
                            original: "",
                            cropped: "",
                            crop: { x: 0, y: 0 },
                            completed_crop: "",
                            crop_completed: false,
                            hidden: false,
                            deleted: false
                        }
                    )
                }
                setImageButton(true);
                //addImageToArray(index_to_edit)
                console.log(producto)

            }
        }

    }, []);

    // merged tagify settings (static & dynamic)
    const settings = {
        ...baseTagifySettings,
        ...tagifySettings,
        callbacks: tagifyCallbacks,
    };

    const onChange = useCallback((e) => {
        e.persist();
        console.log("CHANGED:", e.target.value);
        const tags = tagifyRef.current.getTagElms();
        console.log(tags);
    }, []);

    // access Tagify internal methods example:
    const clearAll = () => {
        tagifyRef.current && tagifyRef.current.removeAllTags();
    };

    //EASY CROP FUNCTIONALITY
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    //EASY CROP FUNCTIONALITY
    const onCurrentEditingCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation,
                "producto_image"
            )
            var fileData = new FileReader();
            fileData.readAsDataURL(croppedImage)
            fileData.onloadend = () => {
                //setFilePreview(fileData.result);
                const file = {
                    ...principal_image,
                    cropped: fileData.result,
                    new: true
                };
                setPrincipalImage(file);
                setFilePreview(fileData.result);
                
            };
            setImageButton(true);
            setCropCompleted(true);
            const compressedImg = compress_image(croppedImage,true);
            //console.log('donee', { croppedImage })
            setCroppedImage(compressedImg)
            setCompletedCroppedImage(compressedImg);
            addImageToArray(index_to_edit);
            closeModal();

        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels, rotation])

    const showCurrentCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageExtraSrc,
                croppedAreaPixels,
                current_editing_rotation,
                "producto_image"
            )
            var fileData = new FileReader();
            fileData.readAsDataURL(croppedImage)
            fileData.onloadend = () => {
                //setFilePreview(fileData.result);
                const files = file_preview_array;
                files[index_to_edit] = {
                    ...files[index_to_edit],
                    cropped: fileData.result,
                    croppedImg: compress_image(croppedImage,false),
                    crop_completed: true
                }
                setFilesToPreview(files);
                setImageButton(true);
                setCurrentEditingCrop(false);
                setCurrentCropCompleted(false);
                setCurrentEditinImg("");
                setCurrentEditingCrop({ x: 0, y: 0 });
                setIndextoEdit(index_to_edit + 1);
                addImageToArray(index_to_edit + 1);
                closeModalExtras();

                if (isEditting) {
                    setIsEditing(false);
                    setIndextoEdit(file_preview_array.length - 1);
                }
            };

        } catch (e) {
            console.error(e)
        }
    }, [imageExtraSrc, croppedAreaPixels, current_editing_rotation])


    const onClose = useCallback(() => {
        setCroppedImage(null)
    }, [])

    const onFileChange = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            setImageSrc(imageDataUrl)
        }
        openModal();
    }

    async function onFileDropChange(files) {
        if (files && files.length > 0) {
            const file = files[0];
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            setImageSrc(imageDataUrl)
        }
        openModal();
    }

    const onFileChangeArray = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            setImageExtraSrc(imageDataUrl)
        }
        console.log(file_preview_array)
        openModalExtras();
    }

    function compress_image(file, isPrincipal) {
        const compressed =  new Compressor(file, {
            quality: 0.6,
            mimeType: 'image/jpeg',
            success(result) {
              const formData = new FormData();
        
              // The third parameter is required for server
              formData.append('file', result, result.name);
        
              // Send the compressed image file to server with XMLHttpRequest.
              PreloadProductImage(formData).then(res=>{
                  if(res){
                    if(isPrincipal){
                        setPrincipalPreloadedId(res.id);
                        console.log(res.id)
                    }else{
                        images_preloaded.push(res.id);
                    }
                  }
              })
                
                },
          });
      
         //console.log(compressed,'compress')
         return compressed.file
      }

    function validateFields() {
        let precioError = "";
        let precio_descuentoError = "";
        let descripcionError = "";
        let tagsError = "";
        let nombreError = "";
        let mensajeError = "";
        let subcategoria_idError = "";
        let imgError = "";

        if (principal_preloaded == "" && !edit) {
            imgError = "Imagen requerida"
        }

        if (precio == "") {
            precioError = "Requerido";
        }
        if (descripcion == "") {
            descripcionError = "Requerido";
        }
        if (tags == "") {
            tagsError = "Requerido";
        }
        if (nombre == "") {
            nombreError = "Requerido";
        }

        if (parseInt(precio) < parseInt(precio_descuento)) {
            precio_descuentoError = "El precio con descuento no puede ser mayor"
        }
        /*
        if (mensaje == "") {
            mensajeError = "Requerido"
        }
        */

        if (subcategoria_id == "") {
            subcategoria_idError = "Selecciona una opción"
        }

        if (precioError || precio_descuentoError || descripcionError || tagsError || nombreError || mensajeError || subcategoria_idError || imgError) {
            setPrecioError(precioError);
            setPrecioDescuentoError(precio_descuentoError);
            setDescripcionError(descripcionError);
            setTagsError(tagsError);
            setNombreError(nombreError);
            setMensajeError(mensajeError);
            setSubcategoriaIdError(subcategoria_idError);
            setImgError(imgError);
            return false
        } else {
            setPrecioError(precioError)
            setPrecioDescuentoError(precio_descuentoError);
            setDescripcionError(descripcionError);
            setTagsError(tagsError);
            setNombreError(nombreError);
            setMensajeError(mensajeError);
            setSubcategoriaIdError(subcategoria_idError);
            setImgError(imgError);
            return true
        }
    }

    function handleSubCategoria(selectedOption) {
        setSubcategoriaId(selectedOption);
    }

    function handleChangeFile(files) {
        console.log(files);
        var fileData = new FileReader();
        const length = files.length;
        const files_l = files.length > 0 ? 1 : null;
        if (files_l !== null) {
            let i = 0;
            let valid_files = 0;
            while (i < files.length) {
                if ((files[i].type == "image/png" || files[i].type == "image/jpg" || files[i].type == "image/jpeg" || files[i].type == "image/gif") && files[i].size < 5000000) {
                    valid_files = valid_files + 1;
                }
                i++;
            }
            console.log(valid_files)
            if (valid_files == 1) {
                var _URL = window.URL || window.webkitURL;
                const img = new Image();
                var objectUrl = _URL.createObjectURL(files[0]);
                img.onload = function () {
                    //alert(this.width + " " + this.height);
                    _URL.revokeObjectURL(objectUrl);
                    if (this.width !== 1040 && this.width !== 1200) {
                        fileData.readAsDataURL(files[0])
                        fileData.onloadend = () => {
                            //setFilePreview(fileData.result)
                            const file = {
                                original: fileData.result,
                                cropped: ""
                            }
                            setPrincipalImage(file)
                            openModal(file);
                        };
                        setFile(files)

                    } else {
                        setFileError("Las dimensiones de la imagen no estan permitidas.");
                        setTimeout(() => {
                            setFileError("");
                        }, 2000);
                    }
                };
                img.src = objectUrl;

                //console.log(img)

                //console.log(fileData)
            } else if (valid_files > 1) {
                let i = 0;
                while (i < files.length) {
                    if (addToPreviewFiles(files[i], i)) {
                        i++;
                    }
                }
                console.log(file_preview_array, 'test');
            }
            else {
                setFileError("Los archivos deben de pesar menos de 5 MB");
                setTimeout(() => {
                    setFileError("");
                }, 2000);
            }
        }
    };

    function addToPreviewFiles(file, index) {
        var fileData = new FileReader();
        //setIndextoEdit(index);
        fileData.readAsDataURL(file)
        fileData.onloadend = () => {
            file_preview_array[index_to_edit] = {
                ...file_preview_array[index_to_edit],
                original: fileData.result,
                cropped: ""
            }
            setCurrentEditinImg(fileData.result);
            openModalExtras();
        };

        return true
    }

    function OnegetCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = Math.ceil(crop.width * scaleX);
        canvas.height = Math.ceil(crop.height * scaleY);
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );

        // As Base64 string
        // const base64Image = canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/png', 1);
        });
    }

    function completeCrop(crop) {
        setCompletedCrop(crop)
    }

    function uploadClickFile() {
        fileUpload.current.click();
    }

    function uploadClickFileExtra() {
        fileUploadExtra.current.click();
    }

    async function finished_crop() {
        const croppedImg = await getCroppedImg(imgRef.current, completedCrop, "producto_image");
        var fileData = new FileReader();
        fileData.readAsDataURL(croppedImg)
        fileData.onloadend = () => {
            //setFilePreview(fileData.result);
            const file = {
                ...principal_image,
                cropped: fileData.result,
                new: true
            };
            setPrincipalImage(file);
            setFilePreview(fileData.result);
            setImageButton(true);
            setCropCompleted(true);
            setCroppedImage(croppedImg);
        };

    }

    function clearPreview() {
        setPrincipalImage({
            original: "",
            cropped: ""
        })
        setFilePreview("");
        setCropCompleted(false)
    }

    function editPrincipalImage() {
        setCropCompleted("");
        setCompletedCrop(false);
        openModal(principal_image);
    }

    function setCropFileArray(c, index) {
        const files = file_preview_array;
        files[index_to_edit] = {
            ...files[index_to_edit],
            crop: c
        }
        setCurrentEditingCrop(c);
        //setFilesToPreview(files);
    }

    function completeCropFileArray(c, index) {
        const files = file_preview_array;
        files[index_to_edit] = {
            ...files[index_to_edit],
            completed_crop: c
        }
        setCurrentCrop(c);
        //setCurrentCropCompleted(true);
        //setFilesToPreview(files);
    }

    function addImageToArray(index_to_edit) {
        const files = file_preview_array;
        var valid_files = 0;
        files.map(file=>{
            if(!file.deleted){
                valid_files = valid_files + 1;
            }
        })
        if (valid_files < 4) {
            const files = file_preview_array;
            files[index_to_edit] = {
                ...files[index_to_edit],
                original: "",
                cropped: "",
                crop: { x: 0, y: 0 },
                completed_crop: "",
                crop_completed: false,
                hidden: false,
                deleted: false
            }
            setFilesToPreview(files);
        } else if (valid_files == 4) {
            setFileError("Solo puedes subir 4 imagenes.")
            setTimeout(() => {
                setFileError("");
            }, 2000);
        }
    }

    function handleChangeArrayFile(files, index) {
        console.log(files)
        const files_l = files.length > 0 ? 1 : null;
        if (files_l !== null) {
            let i = 0;
            let valid_files = 0;
            while (i < files.length) {
                if ((files[i].type == "image/png" || files[i].type == "image/jpg" || files[i].type == "image/jpeg" || files[i].type == "image/gif") && files[i].size < 5000000) {
                    valid_files = valid_files + 1;
                }
                i++;
                //console.log('yyy')
            }
            console.log(valid_files)
            if (valid_files == 1) {
                let i = 0;
                if (addToPreviewFiles(files[i], i)) {
                    i++;
                }
                //console.log('xxx')
                //console.log(file_preview_array, 'test');
            }
            else {
                setFileError("Los archivos deben de pesar menos de 5 MB");
                setTimeout(() => {
                    setFileError("");
                }, 2000);
            }
        }
    }



    function clearArrayCropped(index_edit) {
        
        let files = file_preview_array;
        const new_files = [];
        files.map((image, index) => {
            if (index == index_edit) {
                image = {
                    ...image,
                    cropped: "",
                    crop: { x: 0, y: 0 },
                    completed_crop: "",
                    deleted: true
                }
                new_files.push(image);
            } else {
                new_files.push(image);
            }
            setImageButton(true)
        })
        //console.log(new_files);
        var valid_files = 0;
        new_files.map(file=>{
            if(!file.deleted){
                valid_files = valid_files + 1;
            }
        })
        if(valid_files < 4 && index_to_edit == 4){
            new_files.push(
                    {
                        original: "",
                        cropped: "",
                        crop: { x: 0, y: 0 },
                        completed_crop: "",
                        crop_completed: false,
                        hidden: false,
                        deleted: false
                    }
            )
            
        }
        setFilesToPreview(new_files);
        setIndextoEdit(new_files.length)
        
    }

    function editArrayCrop(index) {
        setIndextoEdit(index);
        setCurrentCrop(file_preview_array[index].completed_crop);
        setCurrentEditinImg(file_preview_array[index].original);
        setCurrentEditingCrop(file_preview_array[index].crop);
        setIsEditing(true);
        openModalExtras();
    }

    async function getCroppedArrayImage(file) {
        const img = await OnegetCroppedImg(file.original, file.completed_crop, "producto_image");
        var fileData = new FileReader();
        fileData.readAsDataURL(img)
        fileData.onloadend = () => {
            return img
        };
    }

    async function handleCreate() {
        setLoading(true)
        const isValid = validateFields();
        if (isValid) {
            let formData = new FormData();
            const imgs = []
            console.log(file_preview_array, 'send')
            if (principal_image.new) {
                formData.append("principal_image", principal_preloaded);
            }

            for (var i = 0; i < file_preview_array.length; i++) {
                if (file_preview_array[i].deleted && file_preview_array[i].image_id) {
                    formData.append("images_to_delete[]", parseInt(file_preview_array[i].image_id));
                    console.log(file_preview_array[i], 'deleted')
                } 
                /*
                else if (file_preview_array[i].croppedImg) {
                    formData.append("image[]", file_preview_array[i].croppedImg);
                }
                */
            }

            for (var i = 0; i < images_preloaded.length; i++) {
                formData.append("preloaded_images[]", images_preloaded[i]);
                /*
                else if (file_preview_array[i].croppedImg) {
                    formData.append("image[]", file_preview_array[i].croppedImg);
                }
                */
            }


            formData.append("nombre", nombre);
            formData.append("descripcion", descripcion);
            formData.append("tags", tags);
            formData.append("negocio_id", negocio_id);
            formData.append("tipo_id", tipo_id);
            formData.append("precio", precio);
            formData.append("precio_descuento", precio_descuento > 0 ? precio_descuento : 0)
            formData.append("subcategoria_id", subcategoria_id.value);
            formData.append("mensaje_privado", mensaje);
            formData.append("lightbox", lightbox);
            formData.append("precio_privado", show_price);

            //console.log(formData)

            if (edit) {
                //console.log(edit)
                const data = {
                    id: producto_id,
                    data: formData
                }
                putProductos(data).then(res => {
                    if (res) {
                        sessionStorage.removeItem('producto_edit');
                        window.location.replace("/catalogo");
                        
                    } else {
                        setCreateError("Error al actualizar producto");
                        setTimeout(() => {
                            setCreateError("");
                            setLoading(false);
                        }, 2000);
                    }
                })
            } else {
                postProductos(formData).then(res => {
                    if (res) {
                        sessionStorage.removeItem('producto_edit');
                        window.location.replace("/catalogo");
                    } else {
                        setCreateError("Error al crear producto");
                        setTimeout(() => {
                            setCreateError("");
                            setLoading(false);
                        }, 2000);
                    }
                })
            }

        }
    }

    function cancelEdition() {
        sessionStorage.removeItem('producto_edit');
        window.location.replace("/catalogo");
    }

    return (
        <div>
            { negocio_id ?
                <div>
                    <Helmet>
                        <title>Nuevo Producto | Okki</title>
                    </Helmet>
                    <div className="newProduct-header">
                        <p>Nuevo producto</p>
                    </div>
                    <div className="settings-form container">
                        <div className="row newProduct-contentRow">
                            <div className="six columns">
                                <input
                                    type="file"
                                    name="files"
                                    id="image"
                                    multiple
                                    ref={fileUpload}
                                    accept=".png, .jpeg, .jpg,.gif"
                                    // onChange={uploadImage}
                                    //onChange={onSelectFile}
                                    //onChange={e => handleChangeFile(e.target.files)}
                                    onChange={onFileChange}
                                    style={{ display: "none" }}
                                />
                                {
                                    !file_preview ?

                                        <>
                                            <div className="settings-form__productPicError fieldError">
                                                {fileError}
                                            </div>
                                            <div className="fileDrop-container" onClick={() => { uploadClickFile() }}>
                                                <FileDrop
                                                    onDrop={onFileDropChange}
                                                >
                                                    <div className="settings-form__productPic">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 10L4 10" stroke="#111111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M10 16L10 4" stroke="#111111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>


                                                        <br />
                                                        <p><span>Arrastra y suelta tu imagen</span></p>
                                                        <p className="settings-form__productPicNote">
                                                            Tamaño recomendado:<br />
                                                            720px x 960px o superior<br />
                                                            JPG o PNG

                                                    </p>
                                                    </div>
                                                </FileDrop>

                                            </div>

                                        </>
                                        :

                                        crop_completed ?
                                            <div className="settings-form__productPic">
                                                <img className="settings-form__productPicPreview" src={principal_image.cropped} />
                                                <div className="settings-form__productPicDelete" onClick={() => { clearPreview() }}>
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="32" height="32" rx="16" fill="#111" fill-opacity="0.6" />
                                                        <path d="M19.8184 12.1816L12.1816 19.8184" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M19.8184 19.8184L12.1816 12.1816" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                                {producto_id ?
                                                    null
                                                    :
                                                    <div className="settings-form__productPicEdit" onClick={() => { editPrincipalImage() }}>
                                                        Editar
                                                </div>
                                                }
                                            </div>
                                            :
                                            <div>
                                              <div onClick={openModal} className="fileDrop-container">
                                                  <div className="file-drop-target">
                                                      <div className="settings-form__productPic">
                                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path d="M16 10L4 10" stroke="#111111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                              <path d="M10 16L10 4" stroke="#111111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                          </svg>
                                                          <p><span>Haz click para subir una imagen</span></p>
                                                          <p className="settings-form__productPicNote">
                                                              Tamaño recomendado:<br />
                                                              720px x 960px o superior<br />
                                                              JPG o PNG
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                            </div>     
                                }
                                <div className="settings-form__productPicError fieldError">
                                  {imgError}
                                </div>
                                <div className={negocio.pro ? "settings-form__productPicRow" : ""}>

                                    {file_preview_array.length > 0 && negocio.pro ?
                                        file_preview_array.map((file, index) => (
                                            file.deleted ?
                                                null
                                                :
                                                file.crop_completed == "" && addImageButton ?
                                                    <>
                                                        <input
                                                            type="file"
                                                            name="files"
                                                            id="image"
                                                            multiple
                                                            ref={fileUploadExtra}
                                                            accept=".png, .jpeg, .jpg,.gif"
                                                            // onChange={uploadImage}
                                                            //onChange={onSelectFile}
                                                            onChange={onFileChangeArray}
                                                            style={{ display: "none" }}
                                                        />
                                                        <div className="fileDrop-container" onClick={() => { uploadClickFileExtra(); setIndextoEdit(index) }}>
                                                            <FileDrop onDrop={onFileChangeArray}>
                                                                <div className="settings-form__productPic">
                                                                    <img src={plusIcon} />
                                                                </div>
                                                            </FileDrop>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="settings-form__productPic">
                                                        <img className="settings-form__productPicPreview" src={`${file.cropped}`} />
                                                        <div className="settings-form__productPicDelete" onClick={() => { clearArrayCropped(index) }}>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="10" cy="10" r="10" fill="#111111" fill-opacity="0.6" />
                                                                <path d="M12.6504 7.34778L7.34709 12.6511" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M12.6529 12.6511L7.34961 7.34778" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                        {producto_id ?
                                                            null
                                                            :
                                                            <div className="settings-form__productPicEdit" onClick={() => { editArrayCrop(index) }}>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="20" height="20" rx="10" fill="black" fill-opacity="0.6" />
                                                                    <path d="M11.8452 6.33593L6.39628 11.8103L6 14L8.17957 13.5521L13.6285 8.07776C14.1238 7.58009 14.1238 6.78383 13.6285 6.33593C13.1331 5.88802 12.3406 5.88802 11.8452 6.33593Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>

                                                            </div>
                                                        }
                                                    </div>
                                        ))
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="six columns">
                                <div className="newProduct__row">
                                    <div className="newProduct__column">
                                        <label>Título</label>
                                    </div>
                                    <div className="newProduct__column">
                                        <input
                                            className="newProduct__input"
                                            type="text"
                                            maxLength="32"
                                            placeholder="Producto"
                                            value={nombre}
                                            onChange={e => setNombre(e.target.value)}
                                        />
                                        <div className="fieldError">
                                            {nombreError}
                                        </div>
                                    </div>
                                </div>
                                <div className="newProduct__rowDouble">
                                    <div>
                                        <div className="newProduct__column">
                                            <label>Precio</label>
                                        </div>
                                        <div className="newProduct__column">
                                            <NumberFormat
                                                isAllowed={(values) => {
                                                    const { formattedValue, floatValue } = values;
                                                    return formattedValue === "" || floatValue <= 999999999;
                                                }}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    // formattedValue = $2,223
                                                    // value ie, 2223
                                                    setPrecio(value)
                                                    console.log(value, "test")
                                                }}
                                                // onChange={e => setPrecio((e.target.value.replace("$", "")))}
                                                placeholder="$9,999"
                                                value={precio}
                                                isNumericString={true}
                                                removeFormatting={true}
                                                className="settings-form__input priceSmall"
                                                decimalScale={2}
                                                displayType={'input'}
                                                thousandSeparator={true}
                                                prefix={'$'} />
                                            {/* 
                                    <input
                                        className="newProduct__inputHalf"
                                        type="number"
                                        max="999999999"
                                        placeholder="$9,999"
                                        value={precio}
                                        onChange={e => setPrecio(e.target.value)}
                                    /> */}
                                            <div className="fieldError">
                                                {precioError}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="newProduct__column">
                                            <label>
                                                Precio con descuento
                                        <div>
                                                    <svg data-tip="Precio de oferta." width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z" fill="#66666A" fill-opacity="0.25" />
                                                        <path d="M5.46523 7.11011C5.46523 6.92391 5.49674 6.76779 5.55977 6.64175C5.62565 6.51571 5.71732 6.40828 5.83477 6.31948C5.95508 6.23068 6.11406 6.13758 6.31172 6.04019C6.48932 5.95138 6.62539 5.82964 6.71992 5.67495C6.81445 5.5174 6.86172 5.31401 6.86172 5.06479C6.86172 4.84995 6.81875 4.66375 6.73281 4.5062C6.64688 4.34865 6.52799 4.22977 6.37617 4.14956C6.22435 4.06649 6.05247 4.02495 5.86055 4.02495C5.66576 4.02495 5.49245 4.06935 5.34063 4.15815C5.19167 4.24696 5.07422 4.37013 4.98828 4.52769C4.90521 4.68237 4.86367 4.85998 4.86367 5.0605C4.86367 5.16935 4.87799 5.2911 4.90664 5.42573L4.02578 5.3269C4.00859 5.20659 4 5.09058 4 4.97886C4 4.63511 4.07734 4.3286 4.23203 4.05933C4.38958 3.78719 4.60729 3.57664 4.88516 3.42769C5.16589 3.27586 5.48672 3.19995 5.84766 3.19995C6.20859 3.19995 6.53229 3.27586 6.81875 3.42769C7.10521 3.57951 7.33008 3.79578 7.49336 4.07651C7.65664 4.35438 7.73828 4.67664 7.73828 5.04331C7.73828 5.47586 7.64089 5.82248 7.44609 6.08315C7.25417 6.34383 7.01068 6.53289 6.71563 6.65034C6.56667 6.71336 6.45781 6.78641 6.38906 6.86948C6.32031 6.94969 6.28594 7.06141 6.28594 7.20464V7.61284H5.46523V7.11011ZM5.89922 9.50347C5.79036 9.50347 5.68867 9.47625 5.59414 9.42183C5.50247 9.37026 5.42943 9.29865 5.375 9.20698C5.32057 9.11245 5.29336 9.01076 5.29336 8.9019C5.29336 8.79305 5.32057 8.69279 5.375 8.60112C5.43229 8.50946 5.50677 8.43641 5.59844 8.38198C5.6901 8.32756 5.79036 8.30034 5.89922 8.30034C6.00521 8.30034 6.1026 8.32756 6.19141 8.38198C6.28307 8.43641 6.35612 8.50946 6.41055 8.60112C6.46497 8.69279 6.49219 8.79305 6.49219 8.9019C6.49219 9.01076 6.46497 9.11245 6.41055 9.20698C6.35612 9.29865 6.28307 9.37026 6.19141 9.42183C6.1026 9.47625 6.00521 9.50347 5.89922 9.50347Z" fill="white" />
                                                    </svg>
                                                    <ReactTooltip className="tooltipStyle" multiline="true" place="top" type="dark" effect="solid" />
                                                </div>
                                            </label>
                                        </div>
                                        <div className="newProduct__column">
                                            <NumberFormat
                                                isAllowed={(values) => {
                                                    const { formattedValue, floatValue } = values;
                                                    return formattedValue === "" || floatValue <= 999999999;
                                                }}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    // formattedValue = $2,223
                                                    // value ie, 2223
                                                    setPrecioDescuento(value)
                                                    console.log(value, "test")
                                                }}
                                                // onChange={e => setPrecio((e.target.value.replace("$", "")))}
                                                placeholder="$9,999"
                                                value={precio_descuento}
                                                isNumericString={true}
                                                removeFormatting={true}
                                                className="settings-form__input"
                                                decimalScale={2}
                                                displayType={'input'}
                                                thousandSeparator={true}
                                                prefix={'$'} />
                                            {/* <input
                                        className="newProduct__inputHalf"
                                        type="number"
                                        max="999999999"
                                        placeholder="$9,999"
                                        value={precio_descuento}
                                        onChange={e => setPrecioDescuento(e.target.value)}
                                    /> */}
                                            <div className="fieldError">
                                                {precio_descuentoError}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="newProduct__row">
                                    <div className="newProduct__column">
                                        <label>
                                            Descripción
                                            <div>
                                                <svg data-tip="Explica como es tu producto, tu servicio,<br/>tu platillo para que tus clientes tengan<br/>una idea completa de lo que ofreces." width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z" fill="#66666A" fill-opacity="0.25" />
                                                    <path d="M5.46523 7.11011C5.46523 6.92391 5.49674 6.76779 5.55977 6.64175C5.62565 6.51571 5.71732 6.40828 5.83477 6.31948C5.95508 6.23068 6.11406 6.13758 6.31172 6.04019C6.48932 5.95138 6.62539 5.82964 6.71992 5.67495C6.81445 5.5174 6.86172 5.31401 6.86172 5.06479C6.86172 4.84995 6.81875 4.66375 6.73281 4.5062C6.64688 4.34865 6.52799 4.22977 6.37617 4.14956C6.22435 4.06649 6.05247 4.02495 5.86055 4.02495C5.66576 4.02495 5.49245 4.06935 5.34063 4.15815C5.19167 4.24696 5.07422 4.37013 4.98828 4.52769C4.90521 4.68237 4.86367 4.85998 4.86367 5.0605C4.86367 5.16935 4.87799 5.2911 4.90664 5.42573L4.02578 5.3269C4.00859 5.20659 4 5.09058 4 4.97886C4 4.63511 4.07734 4.3286 4.23203 4.05933C4.38958 3.78719 4.60729 3.57664 4.88516 3.42769C5.16589 3.27586 5.48672 3.19995 5.84766 3.19995C6.20859 3.19995 6.53229 3.27586 6.81875 3.42769C7.10521 3.57951 7.33008 3.79578 7.49336 4.07651C7.65664 4.35438 7.73828 4.67664 7.73828 5.04331C7.73828 5.47586 7.64089 5.82248 7.44609 6.08315C7.25417 6.34383 7.01068 6.53289 6.71563 6.65034C6.56667 6.71336 6.45781 6.78641 6.38906 6.86948C6.32031 6.94969 6.28594 7.06141 6.28594 7.20464V7.61284H5.46523V7.11011ZM5.89922 9.50347C5.79036 9.50347 5.68867 9.47625 5.59414 9.42183C5.50247 9.37026 5.42943 9.29865 5.375 9.20698C5.32057 9.11245 5.29336 9.01076 5.29336 8.9019C5.29336 8.79305 5.32057 8.69279 5.375 8.60112C5.43229 8.50946 5.50677 8.43641 5.59844 8.38198C5.6901 8.32756 5.79036 8.30034 5.89922 8.30034C6.00521 8.30034 6.1026 8.32756 6.19141 8.38198C6.28307 8.43641 6.35612 8.50946 6.41055 8.60112C6.46497 8.69279 6.49219 8.79305 6.49219 8.9019C6.49219 9.01076 6.46497 9.11245 6.41055 9.20698C6.35612 9.29865 6.28307 9.37026 6.19141 9.42183C6.1026 9.47625 6.00521 9.50347 5.89922 9.50347Z" fill="white" />
                                                </svg>
                                                <ReactTooltip className="tooltipStyle" multiline="true" place="top" type="dark" effect="solid" />
                                            </div>
                                        </label>
                                    </div>
                                    <div className="newProduct__column newProduct__charCounter">
                                        <CharacterCounter
                                            value={descripcion}
                                            maxLength={250}>
                                            <textarea maxLength={250} className="settings-form__input" value={descripcion} onChange={e => setDescripcion(e.target.value)} />
                                        </CharacterCounter>
                                        {/* <textarea className="newProduct__input"
                                    value={descripcion}
                                    onChange={e => setDescripcion(e.target.value)}
                                /> */}
                                        <div className="fieldError">
                                            {descripcionError}
                                        </div>
                                    </div>
                                </div>
                                <div className="newProduct__row">
                                    <div className="newProduct__column">
                                        <label>
                                            Etiquetas
                                            <div>
                                                <svg data-tip="Escribe las palabras clave que mejor describan tu producto,<br/>entre más asignes más oportunidad a que te encuentren." width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z" fill="#66666A" fill-opacity="0.25" />
                                                    <path d="M5.46523 7.11011C5.46523 6.92391 5.49674 6.76779 5.55977 6.64175C5.62565 6.51571 5.71732 6.40828 5.83477 6.31948C5.95508 6.23068 6.11406 6.13758 6.31172 6.04019C6.48932 5.95138 6.62539 5.82964 6.71992 5.67495C6.81445 5.5174 6.86172 5.31401 6.86172 5.06479C6.86172 4.84995 6.81875 4.66375 6.73281 4.5062C6.64688 4.34865 6.52799 4.22977 6.37617 4.14956C6.22435 4.06649 6.05247 4.02495 5.86055 4.02495C5.66576 4.02495 5.49245 4.06935 5.34063 4.15815C5.19167 4.24696 5.07422 4.37013 4.98828 4.52769C4.90521 4.68237 4.86367 4.85998 4.86367 5.0605C4.86367 5.16935 4.87799 5.2911 4.90664 5.42573L4.02578 5.3269C4.00859 5.20659 4 5.09058 4 4.97886C4 4.63511 4.07734 4.3286 4.23203 4.05933C4.38958 3.78719 4.60729 3.57664 4.88516 3.42769C5.16589 3.27586 5.48672 3.19995 5.84766 3.19995C6.20859 3.19995 6.53229 3.27586 6.81875 3.42769C7.10521 3.57951 7.33008 3.79578 7.49336 4.07651C7.65664 4.35438 7.73828 4.67664 7.73828 5.04331C7.73828 5.47586 7.64089 5.82248 7.44609 6.08315C7.25417 6.34383 7.01068 6.53289 6.71563 6.65034C6.56667 6.71336 6.45781 6.78641 6.38906 6.86948C6.32031 6.94969 6.28594 7.06141 6.28594 7.20464V7.61284H5.46523V7.11011ZM5.89922 9.50347C5.79036 9.50347 5.68867 9.47625 5.59414 9.42183C5.50247 9.37026 5.42943 9.29865 5.375 9.20698C5.32057 9.11245 5.29336 9.01076 5.29336 8.9019C5.29336 8.79305 5.32057 8.69279 5.375 8.60112C5.43229 8.50946 5.50677 8.43641 5.59844 8.38198C5.6901 8.32756 5.79036 8.30034 5.89922 8.30034C6.00521 8.30034 6.1026 8.32756 6.19141 8.38198C6.28307 8.43641 6.35612 8.50946 6.41055 8.60112C6.46497 8.69279 6.49219 8.79305 6.49219 8.9019C6.49219 9.01076 6.46497 9.11245 6.41055 9.20698C6.35612 9.29865 6.28307 9.37026 6.19141 9.42183C6.1026 9.47625 6.00521 9.50347 5.89922 9.50347Z" fill="white" />
                                                </svg>
                                                <ReactTooltip className="tooltipStyle" multiline="true" place="top" type="dark" effect="solid" />        
                                            </div>
                                        </label>
                                    </div>
                                    <div className="newProduct__column">
                                        <Tags
                                            tagifyRef={tagifyRef}
                                            settings={settings}
                                            value={tags}
                                            {...tagifyProps}
                                            onChange={e => setTags(e.target.value)}
                                        />
                                        <div className="fieldError">
                                            {tagsError}
                                        </div>
                                    </div>
                                </div>
                                
                                <div

                                >
                                    <div className="newProduct__proFeature">
                                        <div className="newProduct__row">
                                            <p className="newProduct__pro-title">Opciones PRO</p>
                                            <hr />
                                        </div>
                                        <div className={negocio.pro ? "" : "disabledDiv disabledDiv-noPro"}>

                                            <div className="newProduct__row2">
                                                <div className="newProduct__column2">
                                                    <p>Ocultar precio</p>
                                                </div>
                                                <div className="newProduct__column3">
                                                    <Switch
                                                        checked={show_price}
                                                        onChange={e => { setShowPrice(e) }}
                                                        onColor="#111"
                                                        handleDiameter={12}
                                                        uncheckedIcon={false}
                                                        checkedIcon={true}
                                                        height={16}
                                                        width={28}
                                                        className="react-switch"
                                                        id="material-switch"
                                                    />
                                                </div>
                                            </div>
                                            <div className="newProduct__row">
                                                <div className="newProduct__column">
                                                    <label>Sub-categoría</label>
                                                </div>
                                                <div className="newProduct__column">
                                                    <Select
                                                        classNamePrefix="inputSelect"
                                                        className="inputSelect settings-form__input"
                                                        options={subcategorias}
                                                        value={subcategoria_id}
                                                        onChange={handleSubCategoria}
                                                        placeholder="Sin subcategoría"
                                                        components={{ DropdownIndicator }}
                                                        maxMenuHeight={150}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 6,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary50: "#ccc",
                                                                primary25: "#F6F6F6",
                                                                primary: "black",
                                                            },
                                                        })}
                                                    />
                                                    <div className="fieldError">
                                                        {subcategoria_idError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="newProduct__row">
                                                <div className="newProduct__column">
                                                    <label>Mensaje personalizado</label>
                                                </div>
                                                <div className="newProduct__column newProduct__charCounter">
                                                    <CharacterCounter
                                                        value={mensaje}
                                                        maxLength={50}>
                                                        <textarea maxLength={50} className="newProduct__input" value={mensaje} onChange={e => { setMensaje(e.target.value) }} />
                                                    </CharacterCounter>
                                                    {/* <textarea className="newProduct__input" value={mensaje} onChange={e => { setMensaje(e.target.value) }} /> */}
                                                    <div className="fieldError">
                                                        {mensajeError}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {negocio.pro ?
                                            null
                                            :
                                            <Link style={{ textAlign: "center" }} className="button buttonPro"
                                                to={{
                                                    pathname: "/pro",
                                                }}
                                                target="_blank"
                                            >
                                                Contratar PRO
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.6424 8.22228L9.99163 4.28577L8.21385 8.22228L4.27734 10.0001L8.21385 11.6508L9.99163 15.7143L11.6424 11.6508L15.7059 10.0001L11.6424 8.22228Z" fill="white" />
                                                </svg>

                                            </Link>
                                        }
                                        {/* <div className="newProduct__row2">
                                    <div className="newProduct__column2">
                                        <label>Lightbox con detalles del producto</label>
                                    </div>
                                    <div className="newProduct__column3">
                                        <Switch
                                            checked={lightbox}
                                            onChange={e => { setLightbox(e) }}
                                            onColor="#3059C8"
                                            handleDiameter={12}
                                            uncheckedIcon={false}
                                            checkedIcon={true}
                                            height={16}
                                            width={28}
                                            className="react-switch"
                                            id="material-switch"
                                        />
                                    </div>
                                </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="fieldError">
                            {createError}
                        </div>
                    </div>
                    <div className="newProduct-rowLast">
                        <div className="newProduct-rowLast-container">
                            <Link onClick={cancelEdition} className="button button-alphaAlt">
                                Cancelar
     </Link>
                            {/*
     <Link onClick={handleCreate} className="button">
      Publicar{" "}
      <svg
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        d="M11 14L15 10L11 6"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
       />
       <path
        d="M15 10L5 10"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
       />
      </svg>
     </Link>*/}
                            {edit ?
                                <a className="button buttonPublish" onClick={handleCreate}>
                                    {Loading && (
                                        <i className="" style={{ marginRight: "5px" }} />
                                    )}
                                    {Loading && (
                                        'Guardando...'
                                    )}
                                    {!Loading && (
                                        <>
                                            <span>Guardar</span>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M11 14L15 10L11 6"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M15 10L5 10"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </>
                                    )}
                                </a>
                                :
                                <a className="button buttonPublish" onClick={handleCreate}>
                                    {Loading && (
                                        <span style={{ marginLeft: "10px" }} >Creando...</span>
                                    )}
                                    {!Loading && (
                                        <>
                                            <span>Publicar</span>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M11 14L15 10L11 6"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M15 10L5 10"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </>
                                    )}
                                </a>

                            }
                        </div>
                    </div>
                </div>
                :
                null
            }

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                id="productItemModalCrop"
            >
                <div className="modalCrop">
                    <svg className="modalCloseButton onlyMobile" onClick={closeModal} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" stroke="#EEEEEE" />
                        <path d="M25.2422 16.7574L16.7569 25.2427" stroke="#66666A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M25.2431 25.2427L16.7578 16.7574" stroke="#66666A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p className="modalCrop-title">
                        Editar Imagen
                    </p>
                    <>
                        <div className="modalCrop-container">
                            {imageSrc ? (
                                <React.Fragment>
                                    <div className={classes.cropContainer}>
                                        <Cropper
                                            image={imageSrc}
                                            crop={crop}
                                            rotation={rotation}
                                            zoom={zoom}
                                            aspect={3 / 4}
                                            onCropChange={setCrop}
                                            onRotationChange={setRotation}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                        />
                                    </div>
                                    <div className={classes.controls}>
                                        <button onClick={closeModal} className="button button-alphaAlt onlyDesktop">
                                            Cancelar
                                        </button>
                                        <div className={classes.sliderContainer}>
                                            <Typography
                                                variant="overline"
                                                classes={{ root: classes.sliderLabel }}
                                            >
                                                <img src={zoomIcon} />
                                            </Typography>
                                            <Slider
                                                value={zoom}
                                                min={1}
                                                max={3}
                                                step={0.1}
                                                aria-labelledby="Zoom"
                                                classes={{ container: classes.slider }}
                                                onChange={(e, zoom) => setZoom(zoom)}
                                            />
                                        </div>
                                        <div className={classes.sliderContainer}>
                                            <Typography
                                                variant="overline"
                                                classes={{ root: classes.sliderLabel }}
                                            >
                                                <img src={rotateIcon} />
                                            </Typography>
                                            <Slider
                                                value={rotation}
                                                min={0}
                                                max={360}
                                                step={1}
                                                aria-labelledby="Rotation"
                                                classes={{ container: classes.slider }}
                                                onChange={(e, rotation) => setRotation(rotation)}
                                            />
                                        </div>
                                        <Button
                                            onClick={()=>{showCroppedImage()}}
                                            variant="contained"
                                            color="#000"
                                            classes={{ root: classes.cropButton }}
                                        >
                                            Recortar
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.375 14.125C15.375 14.65 15.15 15.1 14.85 15.4C14.475 15.775 14.025 16 13.5 16C12.45 16 11.625 15.175 11.625 14.125C11.625 13.525 11.925 12.925 12.375 12.625C12.675 12.4 13.05 12.25 13.5 12.25C14.55 12.25 15.375 13.075 15.375 14.125Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.875 4L10.125 10L12.375 12.625" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.625 14.125C8.625 15.175 7.8 16 6.75 16C6.225 16 5.7 15.775 5.4 15.4C5.1 15.1 4.875 14.65 4.875 14.125C4.875 13.075 5.7 12.25 6.75 12.25C7.125 12.25 7.5 12.4 7.875 12.625C8.325 12.925 8.625 13.525 8.625 14.125Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.375 4L10.125 10L7.875 12.625" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </Button>
                                    </div>
                                    {/*
          <ImgDialog img={croppedImage} onClose={onClose} />
          */
                                    }
                                </React.Fragment>
                            ) : (
                                    <input type="file" onChange={onFileChange} accept="image/*" />
                                )}
                        </div>

                    </>
                </div>

            </Modal>

            <Modal
                isOpen={modalIsOpenExtras}
                onRequestClose={closeModalExtras}
                style={customStyles}
                contentLabel="Extra images Modal"
                id="productItemModalCrop"
            >
                <div className="modalCrop">
                    <svg className="modalCloseButton onlyMobile" onClick={closeModalExtras} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" stroke="#EEEEEE" />
                        <path d="M25.2422 16.7574L16.7569 25.2427" stroke="#66666A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M25.2431 25.2427L16.7578 16.7574" stroke="#66666A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p className="modalCrop-title">
                        Editar Imagen
                    </p>

                    <div className="modalCrop-container">
                        {imageExtraSrc ? (
                            <React.Fragment>
                                <div className={classes.cropContainer}>
                                    <Cropper
                                        image={imageExtraSrc}
                                        crop={current_editing_crop}
                                        rotation={current_editing_rotation}
                                        zoom={current_editing_zoom}
                                        aspect={3 / 4}
                                        onCropChange={setCurrentEditingCrop}
                                        onRotationChange={setCurrentEditingRotation}
                                        onCropComplete={onCurrentEditingCropComplete}
                                        onZoomChange={setCurrentEditingZoom}
                                    />
                                </div>
                                <div className={classes.controls}>
                                    <button onClick={closeModalExtras} className="button button-alphaAlt onlyDesktop">
                                        Cancelar
                                        </button>
                                    <div className={classes.sliderContainer}>
                                        <Typography
                                            variant="overline"
                                            classes={{ root: classes.sliderLabel }}
                                        >
                                            <img src={zoomIcon} />
                                        </Typography>
                                        <Slider
                                            value={current_editing_zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            classes={{ container: classes.slider }}
                                            onChange={(e, zoom) => setCurrentEditingZoom(zoom)}
                                        />
                                    </div>
                                    <div className={classes.sliderContainer}>
                                        <Typography
                                            variant="overline"
                                            classes={{ root: classes.sliderLabel }}
                                        >
                                            <img src={rotateIcon} />
                                        </Typography>
                                        <Slider
                                            value={current_editing_rotation}
                                            min={0}
                                            max={360}
                                            step={1}
                                            aria-labelledby="Rotation"
                                            classes={{ container: classes.slider }}
                                            onChange={(e, rotation) => setCurrentEditingRotation(rotation)}
                                        />
                                    </div>
                                    <Button
                                        onClick={showCurrentCroppedImage}
                                        variant="contained"
                                        color="#000"
                                        classes={{ root: classes.cropButton }}
                                    >
                                        Recortar
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.375 14.125C15.375 14.65 15.15 15.1 14.85 15.4C14.475 15.775 14.025 16 13.5 16C12.45 16 11.625 15.175 11.625 14.125C11.625 13.525 11.925 12.925 12.375 12.625C12.675 12.4 13.05 12.25 13.5 12.25C14.55 12.25 15.375 13.075 15.375 14.125Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M4.875 4L10.125 10L12.375 12.625" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.625 14.125C8.625 15.175 7.8 16 6.75 16C6.225 16 5.7 15.775 5.4 15.4C5.1 15.1 4.875 14.65 4.875 14.125C4.875 13.075 5.7 12.25 6.75 12.25C7.125 12.25 7.5 12.4 7.875 12.625C8.325 12.925 8.625 13.525 8.625 14.125Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M15.375 4L10.125 10L7.875 12.625" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </Button>
                                </div>
                                {/*
                        <ImgDialog img={croppedImage} onClose={onClose} />
                        */
                                }
                            </React.Fragment>
                        ) : (
                                <input type="file" onChange={onFileChange} accept="image/*" />
                            )}
                    </div>
                </div>

            </Modal>




        </div>
    );
}

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const NuevoProductoStyled = withStyles(styles)(NuevoProducto);

export default NuevoProductoStyled;
