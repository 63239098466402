import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Link, Redirect } from "react-router-dom";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import aboutCover from "../images/about.svg";
import Accordion from "./elements/accordion";
import Footer from "./Footer";
import ReactTooltip from "react-tooltip";
import { loggedIn } from "./API Functions";
import proCover from "../images/proCover.svg"

function Pro() {
    const [isToggled, setToggled] = useState(false);
    const [logged, setLogged] = useState("");
    const [window_load, setWindowLoad] = useState(false);
    const toggleTrueFalse = () => setToggled(!isToggled);
    const [redirect_checkout, setRedirectCheckout] = useState(false);

    useEffect(() => {
        loggedIn().then(res=>{
            if(res){
                setLogged(true);
                setWindowLoad(true);
            }else{
                setLogged(false);
                setWindowLoad(true);
            }
        });
    }, [])

    return (
        <div>
            {window_load ?
            <div>
            <Navbar negocio={[]} />
            <div className="about-container pro-container">
                <div className="row">
                    <div className="twelve columns">
                        <div className="pro-container__hero">
                            <p className="pro-container__hero-subtitle">
                                Conviertete en Okki PRO
                            </p>
                            <h1 className="pro-container__hero-title">Utiliza Okki de forma gratuita o utiliza las herramientas PRO que ofrecemos para exponenciar tu negocio.</h1>
                            <img className="pro-container__hero-cover" src={proCover} />
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div>

                        <div className="twelve columns">
                            <div className="pro-container__cards">
                                <div className="pro-container__cardsItem">
                                    <p className="pro-container__cardsItem-badgeFree">Grátis</p>
                                    <p className="pro-container__cardsItem-price">MX$0</p>
                                    <ul className="settings-form__proChecklistList">
                                        <li>Siempre GRATIS</li>
                                        <li>Hasta 8 productos</li>
                                        <li>2 etiquetas</li>
                                        <li>Integración con Whatsapp</li>
                                    </ul>
                                </div>
                                <div className="pro-container__cardsItem pro-container__cardsItemPro">
                                    <p className="pro-container__cardsItem-badgeFree itemBadge">PRO</p>
                                    <div className="pro-container__cardsItem-priceRow">
                                        {isToggled ?
                                            <Flip bottom >
                                                <div className="pro-container__cardsItem-priceRow">
                                                    <p className="pro-container__cardsItem-price">MX$2,500</p>
                                                    <span className="pro-container__cardsItem-priceMonth">/anual</span>
                                                </div>

                                            </Flip>
                                            :
                                            <Flip top >
                                                <section className="pro-container__cardsItem-priceRow">
                                                    <p className="pro-container__cardsItem-price">MX$250</p>
                                                    <span className="pro-container__cardsItem-priceMonth">/mensual</span>
                                                </section>
                                            </Flip>
                                        }

                                    </div>
                                    {isToggled ?
                                        <ul className="settings-form__proChecklistList">
                                            <li>2 meses GRATIS</li>
                                            <li>Productos Ilimitados</li>
                                            <li>5 etiquetas por producto</li>
                                            <li>Integración con Whatsapp</li>
                                            <li>Mensaje personalizado</li>
                                            <li>Precios privados</li>
                                            <li>Placa PRO</li>
                                        </ul>
                                        :
                                        <ul className="settings-form__proChecklistList">
                                            <li>30 días GRATIS</li>
                                            <li>Productos Ilimitados</li>
                                            <li>5 etiquetas por producto</li>
                                            <li>Integración con Whatsapp</li>
                                            <li>Mensaje personalizado</li>
                                            <li>Precios privados</li>
                                            <li>Placa PRO</li>
                                            <li>Cancela en cualquier momento</li>
                                        </ul>
                                    }

                                    {logged ?
                                        <a style={{ textAlign: "center" }} className="button buttonPro" onClick={() => { setRedirectCheckout(true) }}>
                                        Contratar PRO
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.6424 8.22228L9.99163 4.28577L8.21385 8.22228L4.27734 10.0001L8.21385 11.6508L9.99163 15.7143L11.6424 11.6508L15.7059 10.0001L11.6424 8.22228Z" fill="white" />
                                        </svg>

                                    </a>
                                    :
                                    <a style={{ textAlign: "center" }} className="button buttonPro" href="/vender">
                                    Vende en Okki
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.6424 8.22228L9.99163 4.28577L8.21385 8.22228L4.27734 10.0001L8.21385 11.6508L9.99163 15.7143L11.6424 11.6508L15.7059 10.0001L11.6424 8.22228Z" fill="white" />
                                    </svg>

                                    </a>
                                    }
                                    <div className="pro-container__table-toggle">
                                        <p>
                                            Mensual
                                                </p>
                                        <label class="switch">
                                            <input onClick={toggleTrueFalse} type="checkbox" />
                                            <span class="slider round"></span>
                                        </label>
                                        <p>
                                            Anual
                                                </p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="pro-container__faq">
                                <h3 className="pro-container__hero-title">Funciones</h3>
                                <div className="pro-container__faq-item">
                                    <p className="pro-container__faq-title">Whatsapp</p>
                                    <p className="pro-container__faq-question">Utilizamos Whatsapp para conectar a las personas interesadas en tus productos o negocio directamente contigo, la comunicación es directa, sin intermediarios, facilitamos la transacción, tú y tus clientes deciden cómo cobras y la forma en que les envías.</p>
                                </div>
                                <div className="pro-container__faq-item">
                                    <p className="pro-container__faq-title">Etiquetas</p>
                                    <p className="pro-container__faq-question">Las etiquetas son palabras clave que describen tu producto o negocio, los usuarios PRO pueden agregar más que los usuarios con cuenta gratuita, esto les ayuda y les da más oportunidades a sus productos para que su clientes los encuentren.</p>
                                </div>
                                <div className="pro-container__faq-item">
                                    <p className="pro-container__faq-title">Mensaje personalizado</p>
                                    <p className="pro-container__faq-question">Okki facilita a los consumidores el proceso de compra dándoles a ustedes la opción de personalizar el mensaje que ellos les enviarán a ustedes cuando lo intenten, por esto cada producto tiene la opción de personalizar el texto para que sus clientes inviertan tiempo escribiendo.</p>
                                    {/* <div className="pro-container__faq-columns">
                                        <ul className="settings-form__proChecklistList">
                                            <li>Productos Ilimitados</li>
                                            <li>10 etiquetas</li>
                                            <li>Crea subcategorías</li>
                                            <li>Placa PRO</li>
                                        </ul>
                                        <ul className="settings-form__proChecklistList">
                                            <li>Mensaje personalizado</li>
                                            <li>Precios privados</li>
                                            <li>Estadísticas</li>
                                            <li>Cancela en cualquier momento</li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className="pro-container__faq-item">
                                    <p className="pro-container__faq-title">Perfil de negocio</p>
                                    <p className="pro-container__faq-question">Es la manera más fácil de tener una tienda en línea. Al crear y configurar tu negocio con la información correspondiente podrás compartir el link que hayas especificado en la configuración por ej. <u>https://www.okki.co/minegocio. </u>
                                        <br /><br />
                                    Al compartirlo en tus redes sociales se podrá visualizar la descripción de tu negocio como el logotipo y un link directo a tu perfil.</p>
                                </div>
                                <div className="pro-container__faq-item">
                                    <p className="pro-container__faq-title">Categorias</p>
                                    <p className="pro-container__faq-question">La clasificación en la que configuras tu tienda es uno de los factores más importantes que toma en cuenta el buscador de Okki, Google, Bing, Yahoo y otros más.<br /><br /> Los Okkers PRO tienen la opción de crear sus propias categorías y asignar los productos a cada una de ellas logrando una estructura correcta de la información para facilitar a éstos buscadores encontrarte.</p>
                                </div>
                            </div>
                            <hr />
                            <div className="pro-container__outro">
                                <h1 className="pro-container__outro-title">¡Da un paso adelante de los demás, conviértete en PRO!</h1>
                                
                                {logged ?
                                        <a style={{ textAlign: "center" }} className="button buttonPro" onClick={() => { setRedirectCheckout(true) }}>
                                        Contratar PRO
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.6424 8.22228L9.99163 4.28577L8.21385 8.22228L4.27734 10.0001L8.21385 11.6508L9.99163 15.7143L11.6424 11.6508L15.7059 10.0001L11.6424 8.22228Z" fill="white" />
                                        </svg>
    
                                    </a>
                                    :
                                    <a style={{ textAlign: "center" }} className="button buttonPro" href="/vender" >
                                        Vende en Okki
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.6424 8.22228L9.99163 4.28577L8.21385 8.22228L4.27734 10.0001L8.21385 11.6508L9.99163 15.7143L11.6424 11.6508L15.7059 10.0001L11.6424 8.22228Z" fill="white" />
                                    </svg>

                                </a>
                                    }
                            </div>
                        </div>
                    </div>
                </div>
                {redirect_checkout ?
                    <Redirect
                        to={{
                            pathname: "/pro-checkout",
                            state: { plan_id: isToggled ? 2 : 1, plan_price: isToggled ? 2500 : 250 }
                        }}

                    >

                    </Redirect>
                    :
                    null

                }
            </div>
            <Footer />
            </div>
            :
            null
            }

        </div>
    );
}

export default Pro;
