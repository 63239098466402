import React from 'react';
import { NavLink } from 'react-router-dom';

export default function SidebarSettings(props) {

    return (
        <div
            className={props.nuevoNegocio ? "disabledDiv" : ""}
        >
            <div className="four columns settings-form__sidebar">
                <div className="settings-header settings-headerModal">
                    <div className="settings-header__breadcrumb">
                        <h3 className="settings-header__title">Configuración</h3>
                        <hr className="settingsDivider" />
                    </div>
                </div>
                <ul className="settings-form__menu">
                    <li>
                        <NavLink to="/newsettings" activeClassName="active">
                            Perfil
        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/settings2" activeClassName="active">
                            Dirección y Envíos
        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/settings3" activeClassName="active">
                            Redes Sociales
        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/settings4" activeClassName="active">
                            Cuenta
        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/settings5" activeClassName="active">
                            PRO
        </NavLink>
                    </li>
                    <li>
                        <NavLink to={{ pathname: `/okker/${props.negocio.dominio_okki}` }} activeClassName="active">
                            Mi perfil
        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}