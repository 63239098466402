import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import ReactTooltip from "react-tooltip";

function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = event => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}

function Help() {
    const ref = useRef();
    const [isToggled, setToggled] = useState(false);

    const toggleTrueFalse = () => setToggled(!isToggled);

    useOnClickOutside(ref, () => setToggled(false));

    return (
        <>
            <Zoom duration={300}>
                <div className="helpModal" className={isToggled ? "helpModal" : "helpModal-hidden"}>
                    <h5>Tu Asistente Okki</h5>
                    <hr />
                    <ul ref={ref}>
                        <li>Cómo funciona Okki</li>
                        <li>Cómo convertirte en Okki Pro</li>
                        <li>Cómo crear tu tienda</li>
                        <li>Cómo subir tus productos</li>
                        <li>Cómo vender más</li>
                        <li>Cómo eliminar tu cuenta</li>
                        <li>Cómo ser feliz</li>
                        <li>¿Tienes otra duda? Chatea con nosotros.</li>
                    </ul>
                </div>
            </Zoom>
            <div data-tip="Asistente Okki" className="helpButton" onClick={() => setToggled(true)}>
                {isToggled ?
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="21" cy="21" r="21" fill="#FF3C14" />
                        <path d="M25.2422 16.7573L16.7569 25.2426" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M25.2431 25.2426L16.7578 16.7573" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    :
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="helpButtonPath" d="M42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21Z" fill="#111" />
                        <path d="M20.7266 21.8047C20.7266 21.4661 20.7839 21.1823 20.8984 20.9531C21.0182 20.724 21.1849 20.5286 21.3984 20.3672C21.6172 20.2057 21.9062 20.0365 22.2656 19.8594C22.5885 19.6979 22.8359 19.4766 23.0078 19.1953C23.1797 18.9089 23.2656 18.5391 23.2656 18.0859C23.2656 17.6953 23.1875 17.3568 23.0312 17.0703C22.875 16.7839 22.6589 16.5677 22.3828 16.4219C22.1068 16.2708 21.7943 16.1953 21.4453 16.1953C21.0911 16.1953 20.776 16.276 20.5 16.4375C20.2292 16.599 20.0156 16.8229 19.8594 17.1094C19.7083 17.3906 19.6328 17.7135 19.6328 18.0781C19.6328 18.276 19.6589 18.4974 19.7109 18.7422L18.1094 18.5625C18.0781 18.3438 18.0625 18.1328 18.0625 17.9297C18.0625 17.3047 18.2031 16.7474 18.4844 16.2578C18.7708 15.763 19.1667 15.3802 19.6719 15.1094C20.1823 14.8333 20.7656 14.6953 21.4219 14.6953C22.0781 14.6953 22.6667 14.8333 23.1875 15.1094C23.7083 15.3854 24.1172 15.7786 24.4141 16.2891C24.7109 16.7943 24.8594 17.3802 24.8594 18.0469C24.8594 18.8333 24.6823 19.4635 24.3281 19.9375C23.9792 20.4115 23.5365 20.7552 23 20.9688C22.7292 21.0833 22.5312 21.2161 22.4062 21.3672C22.2812 21.513 22.2188 21.7161 22.2188 21.9766V22.7188H20.7266V21.8047ZM21.5156 26.1562C21.3177 26.1562 21.1328 26.1068 20.9609 26.0078C20.7943 25.9141 20.6615 25.7839 20.5625 25.6172C20.4635 25.4453 20.4141 25.2604 20.4141 25.0625C20.4141 24.8646 20.4635 24.6823 20.5625 24.5156C20.6667 24.349 20.8021 24.2161 20.9688 24.1172C21.1354 24.0182 21.3177 23.9688 21.5156 23.9688C21.7083 23.9688 21.8854 24.0182 22.0469 24.1172C22.2135 24.2161 22.3464 24.349 22.4453 24.5156C22.5443 24.6823 22.5938 24.8646 22.5938 25.0625C22.5938 25.2604 22.5443 25.4453 22.4453 25.6172C22.3464 25.7839 22.2135 25.9141 22.0469 26.0078C21.8854 26.1068 21.7083 26.1562 21.5156 26.1562Z" fill="white" />
                    </svg>
                }

            </div>
            <ReactTooltip className="tooltipStyle" multiline="true" place="top" type="dark" effect="solid" />

        </>
    );
}


export default Help;