import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import aboutCover from "../images/about.svg";
import Accordion from "./elements/accordion";
import Footer from "./Footer";

function Cookies() {
    return (
        <div>
            <Navbar negocio={[]} />
            <div className="about-container container">
                <div className="row">
                    <div className="termsContainer">
                        <h1 className="termsContainer-title titleMin">Aviso de cookies</h1>
                        <br />
                        <p className="termsContainer-text">
                            Okki usa cookies (galletas) para guardar ciertos datos cuando inicias sesión. Nosotros usamos uno, localStorage.
                            <br /><br />
                            Cookies (galletas) son unos pequeños archivos de texto enviados por nosotros hacia tu computadora y de tu computadora o celular hacia nosotros cada vez que visitas nuestro sitio. Éstos son únicos en tu cuenta o en tu browser. Las cookies de inicio de sesión son válidas unicamente mientras tienes tu navegador abierto y son borradas automáticamente cuando cierras tu navegador. Las cookies persistentes duran hasta que tu o tu navegador las borran o hasta que expiran.
                            <br /><br />
                            Varias cookies están asociadas con tu cuenta y tu información personal con la intención de recordar que ya estuviste en este sitio. Otros cookies no estan asociados a tu cuenta pero son unicos y nos permite generar estadísticas y personalizaciones entre otras cosas. Si tu accesa a okki a travez de tu navegador, tu puedes administrar tus cookies, si las desactivas existe la posibilidad que Okki no te pueda ofrecer el mejor perfomance.
                            <br /><br />
                            Usamos plataformas terceras como Google Analytics y Hotjar para obetenr resultados de utilización de nuestra plataforma.
                        </p>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Cookies;
