import React, { useEffect, useState, Component } from "react";
import { Link, Redirect, useRouteMatch } from "react-router-dom";
import itemThumb from "../images/itemThumb.svg";
import Navbar from "./Navbar";
import { getSubcategorias, postSubcategoriaArrange, postSubcategoria, getProductos, deleteSubcategoria, route, deleteProducto, UpdateProductoStatus, bucket_route, getCurrentNegocio, user_token } from "./API Functions";
import { ToggleLayer } from "react-laag";
import Modal from "react-modal";
import DraftView from "./DraftView";
import Footer from "./Footer";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import NumberFormat from 'react-number-format';
import NoPro from "./NoPro.js"
import { Image } from 'use-image-color'
import EmptyStateCatalogo from "./EmptyStateCatalogo";
import ModalProductos from "./ModalProductos";
import useModalProductos from "./useModalProductos";

import Cookies from 'universal-cookie';
import EmptyStateCatalogoPro from "./EmptyStateCatalogoPro";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import ProductPrice from "./Products/ProductPrice";

const cookies = new Cookies();

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    padding: "40px",
    border: "none",
    borderRadius: 12,
    textAlign: "center"
  },
};

const DragHandle = sortableHandle(() => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5 7H15" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M5 10H15" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M5 13H15" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
</svg>
);

const SortableItem = sortableElement(({ value, id, default_id }) => {

  function handleDeleteSubcategoria(id, default_id) {
    const data = {
      subcategoria_id: id,
      subcategoria_default_id: default_id
    }
    deleteSubcategoria(id).then(res => {
      if (res) {
        window.location.reload(false)
      }
    })
  }
  return (
    <li>
      <DragHandle />
      {value}
      <a className="sortList-close" onClick={() => { handleDeleteSubcategoria(id, default_id) }}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.8184 5.18176L5.18161 12.8185" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12.8184 12.8185L5.18164 5.18176" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </a>
    </li>
  )
});

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

class Sortable extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    console.log(this.props)
    this.setState({
      items: this.props.subcategorias
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const new_items = arrayMove(this.state.items, oldIndex, newIndex);
    const items_update = [];
    this.setState(({ items }) => ({
      items: new_items,
    }));
    new_items.map((item, index) => {
      item = {
        ...item,
        arrange_index: index
      }
      items_update.push(item);
    })
    const data = {
      items: items_update
    }
    console.log(data)
    postSubcategoriaArrange(data).then(res => {
      if (res) {
        window.location.reload(false)
      }
    })
  };

  render() {
    const { items } = this.state;

    return (
      <div className="sortList">
        <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
          {items.map((value, index) => (
            <SortableItem key={`item-${value.id}`} index={index} value={value.detalle} id={value.id} default_id={value.id} />
          ))}
        </SortableContainer>
      </div>

    );
  }
}

function Catalogo(props) {
  let { url } = useRouteMatch();
  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [subcategorias, setSubcategorias] = useState([]);
  const [subcategorias_productos, setSubcategoriasProductos] = useState([]);
  const [subcategorias_arrange, setSubcategoriasArrange] = useState([]);
  const [productos, setProductos] = useState([]);
  const [productos_loaded, setProductosLoaded] = useState(false);
  const [currentProduct, setCurrentProduct] = useState([]);
  const [tags, setTags] = useState([]);
  const [product_creation, setProductCreation] = useState(false);

  const { isShowing, toggle } = useModalProductos();
  const { flagsShowing, flags } = useModalProductos();
  const { shareShowing, share } = useModalProductos();
  const { image_loaded, loadImage } = useModalProductos();

  const [negocio, setNegocio] = useState([])
  const [negocio_id, setNegocioId] = useState(negocio.id ? negocio.id : "");
  const [filter_subcategoria_id, setFilterSubcategoriaId] = useState("");
  const [detalle, setDetalle] = useState("");

  const [detalleError, setDetalleError] = useState("");

  const [modalIsOpenDelete, setIsOpenDelete] = React.useState(false);
  const [producto_delete, setProductoDelete] = useState("");

  const [modalIsOpenLimit, setIsOpenLimit] = React.useState(false);

  const [product_array, setProductArray] = useState([{}, {}, {}, {}, {}, {}, {}, {}]);

  const [loading, setLoading] = useState(false);

  const [inHover, setHover] = useState(false);

  const [showGetProModal, setShowGetProModal] = useState(cookies.get('showGetModalPro') ? cookies.get('showGetModalPro') : false)

  useEffect(() => {
    const storage_negocio = JSON.parse(localStorage.getItem('negocio'))
    if (user_token && !storage_negocio) {
      getCurrentNegocio().then(res => {
        if (res) {
          const negocio = res;
          loadSubcategorias();
          setNegocio(res);
          setNegocioId(negocio.id)
        } else {
          window.location.replace('/login')
        }
      })
    } else if (storage_negocio) {
      loadSubcategorias();
      setNegocio(storage_negocio);
      setNegocioId(storage_negocio.id)
    }
    else {
      window.location.replace('/404');
    }
  }, [])

  function loadSubcategorias() {
    getSubcategorias().then(res => {
      if (res) {
        loadProductos(res);
      }
    })
  }

  function loadProductos(subcategorias) {
    getProductos().then(res => {
      if (res) {
        const productos = res
        const subcategorias_productos = []
        var no_detail = ""
        const subcategorias_arrange = []
        const length = subcategorias.length
        //console.log(subcategorias)
        subcategorias.map((subcategoria, index_p) => {
          const subcategoria_productos = []
          productos.map((producto, index) => {
            if (producto.subcategoria_id == subcategoria.id) {
              subcategoria_productos.push(producto)
            }
          })
          if (subcategoria.detalle == "Sin subcategoria") {
            subcategoria = {
              ...subcategoria,
              detalle: "",
              productos: subcategoria_productos
            }
            no_detail = subcategoria;
          } else {
            subcategoria = {
              ...subcategoria,
              productos: subcategoria_productos
            }
            subcategorias_productos[subcategoria.arrange_index + 1] = subcategoria;
            subcategorias_arrange[subcategoria.arrange_index] = subcategoria;
          }
        })
        product_array.map((product, index) => {
          product_array[index] = res[index];
        })
        subcategorias_productos.push(no_detail);
        setProductos(res);
        setProductosLoaded(true);
        sessionStorage.setItem('productos', JSON.stringify(res));
        setSubcategoriasProductos(subcategorias_productos);
        setSubcategorias(subcategorias_productos);
        setSubcategoriasArrange(subcategorias_arrange);
        console.log(subcategorias_arrange);
      } else {
        setProductos([])
      }
    })
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModalDelete(id_producto) {
    setProductoDelete(id_producto);
    setIsOpenDelete(true);
  }

  function closeModalDelete() {
    setProductoDelete("");
    setIsOpenDelete(false);
  }

  function openModalLimit() {
    setIsOpenLimit(true);
  }

  function closeModalLimit() {
    setIsOpenLimit(false);
  }

  function handleDeleteSubcategoria(id) {
    const data = {
      subcategoria_id: id,
      subcategoria_default_id: subcategorias[0].id
    }
    deleteSubcategoria(id).then(res => {
      if (res) {
        loadSubcategorias();
      }
    })
  }

  function validateFields() {
    let detalleError = "";

    if (detalle = "") {
      detalleError = "Requerido"
    }

    if (detalleError) {
      setDetalleError(detalleError)
      return false
    } else {
      setDetalleError(detalleError)
      return true
    }
  }

  function handleCreate() {
    const data = {
      negocio_id: negocio_id,
      detalle: detalle
    }
    postSubcategoria(data).then(res => {
      if (res) {
        loadSubcategorias();
        closeModal();
        setDetalle("");
      }
    })
  }

  function handleDelete() {
    deleteProducto(producto_delete).then(res => {
      setLoading(true);
      closeModalDelete();
      loadSubcategorias();
    })
  }

  function handleStatus(producto) {
    var status_id = "";
    if (producto.status_id == 1) {
      status_id = 2;
    } else {
      status_id = 1;
    }
    const data = {
      id: producto.id,
      status_id: status_id
    }
    UpdateProductoStatus(data).then(res => {
      if (res) {
        loadSubcategorias();
      }
    })
  }

  const refs = subcategorias.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const ScrollToSubcategoria = id =>
    refs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  function validateProductCreation() {
    if (!negocio.pro && productos.length > 7) {
      setIsOpenLimit(true);
    } else {
      setProductCreation(true);
    }
  }

  function showProduct(producto) {
    console.log(producto);
    const product_path = producto.nombre.replace(/ /g, '-');
    producto = {
      ...producto,
      pathname: `/${producto.negocio.dominio_okki}/productos/${producto.id}/${product_path}`,
      previous_path: `${window.location.pathname}`
    }
    var images = [];
    images.push(producto.principal_image)
    producto.images.map((image, index) => {
      images.push(image);
    })
    producto.images = images;
    sessionStorage.setItem('previous_path', producto.previous_path);
    sessionStorage.setItem('product_path', producto.pathname)
    setCurrentProduct(producto);
    const tags = [];
    JSON.parse(producto.tags).map(tag => {
      tags.push(tag.value)
    })
    setTags(tags);
    toggle();
  }

  function navigateToCreateProduct(producto) {
    window.location.reload()
    sessionStorage.setItem('producto_edit', JSON.stringify(producto));
    window.location.replace('/editar-producto');
  }

  return (
    <div>
      <Helmet>
        <script src="https://embed.small.chat/T01BJC6LV8DG01BTLL2TNY.js" async></script>
      </Helmet>
      {product_creation ?
        <Redirect push to={{
          pathname: "/nuevo-producto",
          state: { negocio: negocio }
        }} />
        :
        null
      }
      {productos_loaded ?
        <div>
          {negocio.status_id == 1 ?
            <DraftView negocio={negocio} />
            :
            <Navbar negocio={negocio} />
          }
          <div className="settings-headerAlt settings-headerAlt-catalogo container">
            <div className="settings-header__breadcrumbCatalog">
              <div className="settings-header__breadcrumbCatalogTitle">
                <h3 className="settings-header__title">Catálogo</h3>
              </div>
              <Link onClick={validateProductCreation} className="button"> Nuevo producto </Link>
            </div>
          </div>

          {negocio.pro ?
            <div className="home-gallery__filters">
              <div className="home-gallery__filtersContainer container">
                <div className="home-gallery__filters-corner"></div>
                <ul className="home-gallery__filters-list">
                  {subcategorias.map((subcategoria, index) => (
                    <li onClick={() => { ScrollToSubcategoria(subcategoria.id) }}>
                      <a>
                        {subcategoria.detalle == "Sin subcategoria" ? null : subcategoria.detalle}
                      </a>
                    </li>
                  ))
                  }
                </ul>
                <div className="home-gallery__filters-corner">
                  <button data-tip="Nueva subcategoría" className="button-secondary button-square" onClick={openModal}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" > 
                    <path d="M10 5V15" stroke="#111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" /> 
                    <path d="M15 10H5" stroke="#111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" /> 
                  </svg>
                  </button>
                  <ReactTooltip className="tooltipStyle" multiline="true" place="top" type="dark" effect="solid" />
                </div>
              </div>
            </div>
            :
            <hr style={{ maxWidth: "none" }} />
          }
          <div className="home-gallery home-galleryEmpty container">
            {!negocio.pro ?
              <div className="homeGrid">
                {product_array.map((producto, index) => (
                  producto ?
                    <div className={producto.status_id == 2 ? "homeGrid-item" : "homeGrid-item deactivatedProduct"} key={index} >
                      <div className="homeGrid-item__top" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
                        <Image className="homeGrid-item__cover" src={producto.principal_image ? `${bucket_route}${producto.principal_image.path}` : itemThumb} thumbnail={producto.principal_image ? `${bucket_route}${producto.principal_image.path}` : itemThumb} />
                        <div className="homeGrid-item__coverOverlay">
                          <a className="homeGrid-item__coverOverlay-frame" onClick={() => { showProduct(producto) }} />
                          {inHover &&
                            <a className="homeGrid-item__coverOverlay-link" target="_blank">
                              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="21" cy="21" r="21" fill="black" fill-opacity="0.6" />
                                <path id="bagBg" d="M29.6739 27.7172C29.846 28.922 28.9111 30 27.694 30H14.306C13.0889 30 12.154 28.922 12.3261 27.7172L13.7547 17.7172C13.8954 16.7319 14.7393 16 15.7346 16H26.2654C27.2607 16 28.1046 16.7319 28.2453 17.7172L29.6739 27.7172Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path id="zipper" d="M25 20C25 22.2 23.2 24 21 24C18.8 24 17 22.2 17 20" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path id="handle" d="M17 16C17 13.8 18.8 12 21 12C23.2 12 25 13.8 25 16" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </a>
                          }
                        </div>
                      </div>
                      <div className="homeGrid-item__info homeGrid-item__dropdown">
                        <div>
                          <p className="homeGrid-item__name" >
                            {producto.nombre}
                          </p>
                          <NumberFormat decimalScale={2} value={producto.precio} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p>{value}</p>} />
                        </div>
                        <div>
                          <ToggleLayer
                            renderLayer={({ isOpen, layerProps, close }) =>
                              isOpen && (
                                <div 
                                  ref={layerProps.ref} 
                                  className="layer dropdownMenu"
                                  style={{
                                    ...layerProps.style,
                                    width: 120,
                                    backgroundColor: "#ffffff",
                                    borderWidth: 1,
                                    borderColor: "#ddd",
                                    borderRadius: 6,
                                    borderStyle: "solid",
                                    boxShadow: "0px 5px 10px rgba(8, 16, 39, 0.08)",
                                    padding: 5,
                                    zIndex: 9999
                                  }}
                                >
                                  <a className="dropdownItem" onClick={() => { navigateToCreateProduct(producto) }} >Editar</a>
                                  <a className="dropdownItem" onClick={() => { handleStatus(producto) }} >{producto.status_id == 2 ? 'Desactivar' : 'Publicar'}</a>
                                  <a className="dropdownItem" onClick={() => { openModalDelete(producto.id) }}>Eliminar</a>
                                </div>
                              )
                            }
                            closeOnOutsideClick
                            placement={{
                              anchor: "BOTTOM_RIGHT",
                              autoAdjust: true,
                              triggerOffset: 10,
                            }}
                          >
                            {({ triggerRef, toggle }) => (
                              <div ref={triggerRef} className="toggle-btn" onClick={toggle}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" > 
                                  <circle cx="16" cy="10" r="2" transform="rotate(90 16 10)" fill="#081027" /> 
                                  <circle cx="10" cy="10" r="2" transform="rotate(90 10 10)" fill="#081027" /> 
                                  <circle cx="4" cy="10" r="2" transform="rotate(90 4 10)" fill="#081027" /> 
                                </svg>
                              </div>
                            )}
                          </ToggleLayer>
                        </div>
                      </div>
                    </div>
                    :
                    <div onClick={validateProductCreation} className="homeGrid-item-empty">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 10L4 10" stroke="#111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10 16L10 4" stroke="#111" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                ))
                }
              </div>
              :
              subcategorias_productos.map((subcategoria, index) => (
                <div key={index} ref={refs[subcategoria.id]} className={subcategoria.productos.length > 0 ? '' : 'hiddenGrid'}>
                  <h3 className="titleMinSub">{subcategoria.detalle}</h3>
                  <div className="homeGrid">
                    {subcategoria.productos.map((producto, index) => (
                      <div className={producto.status_id == 2 ? "homeGrid-item" : "homeGrid-item deactivatedProduct"} key={index} >
                        <div className="homeGrid-item__top" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
                          {
                            <Image className="homeGrid-item__cover" src={producto.principal_image ? `${bucket_route}${producto.principal_image.path}` : itemThumb} thumbnail={producto.principal_image ? `${bucket_route}${producto.principal_image.path}` : itemThumb} />
                          }                        
                          <div className="homeGrid-item__coverOverlay">
                            {producto.status_id == 2 ?
                              <a className="homeGrid-item__coverOverlay-frame" onClick={() => { showProduct(producto) }} />
                              :
                              null
                            }
                            {inHover &&
                              <a className="homeGrid-item__coverOverlay-link" target="_blank">
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="21" cy="21" r="21" fill="black" fill-opacity="0.6" />
                                  <path id="bagBg" d="M29.6739 27.7172C29.846 28.922 28.9111 30 27.694 30H14.306C13.0889 30 12.154 28.922 12.3261 27.7172L13.7547 17.7172C13.8954 16.7319 14.7393 16 15.7346 16H26.2654C27.2607 16 28.1046 16.7319 28.2453 17.7172L29.6739 27.7172Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                  <path id="zipper" d="M25 20C25 22.2 23.2 24 21 24C18.8 24 17 22.2 17 20" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                  <path id="handle" d="M17 16C17 13.8 18.8 12 21 12C23.2 12 25 13.8 25 16" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </a>
                            }
                          </div>
                        </div>
                        <div className="homeGrid-item__info homeGrid-item__dropdown">
                          <div className="homeGrid-item__info-main">
                            <p className="homeGrid-item__name" >
                              {producto.nombre}
                            </p>
                            <ProductPrice
                            producto={producto}
                            />
                          </div>
                          <div>
                            <ToggleLayer
                              renderLayer={({ isOpen, layerProps }) =>
                                isOpen && (
                                  <div
                                    ref={layerProps.ref}
                                    className="layer dropdownMenu"
                                    style={{
                                      ...layerProps.style,
                                      width: 120,
                                      backgroundColor: "#ffffff",
                                      borderWidth: 1,
                                      borderColor: "#ddd",
                                      borderRadius: 6,
                                      borderStyle: "solid",
                                      boxShadow: "0px 5px 10px rgba(8, 16, 39, 0.08)",
                                      padding: 5,
                                      zIndex: 9999
                                    }}
                                  >
                                    <a className="dropdownItem"
                                      onClick={() => { navigateToCreateProduct(producto) }}
                                    >Editar</a>
                                    <a className="dropdownItem" onClick={() => { handleStatus(producto) }} >{producto.status_id == 2 ? 'Desactivar' : 'Publicar'}</a>
                                    <a className="dropdownItem" onClick={() => { openModalDelete(producto.id)}} >Eliminar</a> 
                                  </div>
                                )
                              }
                              closeOnOutsideClick
                              placement={{
                                anchor: "BOTTOM_RIGHT",
                                autoAdjust: true,
                                triggerOffset: 10,
                              }}
                            >
                              {({ triggerRef, toggle }) => (
                                <div ref={triggerRef} className="toggle-btn" onClick={toggle}>
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" > 
                                    <circle cx="16" cy="10" r="2" transform="rotate(90 16 10)" fill="#081027" /> 
                                    <circle cx="10" cy="10" r="2" transform="rotate(90 10 10)" fill="#081027" /> 
                                    <circle cx="4" cy="10" r="2" transform="rotate(90 4 10)" fill="#081027" /> 
                                  </svg>
                                </div>
                              )}
                            </ToggleLayer>
                          </div>
                        </div>
                      </div>
                    ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
          <Footer />
          {/* <Help /> */}
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Agregar Subcategoria Modal"
          >
            <p ref={(_subtitle) => (subtitle = _subtitle)} className="titleMin modalTitle" style={{ textAlign: "center" }} > Agregar Sub-categoría </p>
            <br />
            <div className="modalInput">
              <div className="sortList-button">
                <input type="text" className="input sortList-input" value={detalle} maxLength="32" placeholder="Sub-categoría" onChange={e => setDetalle(e.target.value)} />
                <a className="button" onClick={handleCreate}> Crear </a>
              </div>
              <div className="fieldError">
                {detalleError}
              </div>
            </div>
            <Sortable subcategorias={subcategorias_arrange} />
          </Modal>

          <Modal
            isOpen={modalIsOpenDelete}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModalDelete}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <p ref={(_subtitle) => (subtitle = _subtitle)} className="titleMin modalTitle" > Eliminar producto </p>
            <p>¿Estás segurx de que deseas eliminar este producto de tu tienda?</p>
            <br />
            <div className="backofficeContainer__optionTotal modalRow deleteItem-actions">
              <p className="button button-alpha" onClick={closeModalDelete}> Cancelar </p>
              <button className="button u-pull-right aButton" onClick={handleDelete}> Eliminar </button>
            </div>
          </Modal>
          <Modal
            isOpen={modalIsOpenLimit}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModalLimit}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <p ref={(_subtitle) => (subtitle = _subtitle)} className="titleMin modalTitle" > Limite alcanzado </p>
            <p>Haz alcanzado la cantidad límite de productos disponibles en tu plan gratuito</p>
            <br />
            <div className="backofficeContainer__optionTotal modalRow deleteItem-actions">
              <Link style={{ textAlign: "center" }} className="button buttonPro" to={{ pathname: "/pro", }} > Contratar PRO
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6424 8.22228L9.99163 4.28577L8.21385 8.22228L4.27734 10.0001L8.21385 11.6508L9.99163 15.7143L11.6424 11.6508L15.7059 10.0001L11.6424 8.22228Z" fill="white" />
              </svg>
              </Link>
            </div>
          </Modal>
          <ModalProductos
            isShowing={isShowing}
            hide={toggle}
            flags={flags}
            flagsShowing={flagsShowing}
            share={share}
            shareShowing={shareShowing}
            image_loaded={image_loaded}
            loadImage={loadImage}
            producto={currentProduct}
            tags={tags}
          />
          {showGetProModal || negocio.pro ? null : <NoPro />}
        </div>
        :
        negocio.pro ?
          <EmptyStateCatalogoPro />
          :
          <EmptyStateCatalogo />
      }
    </div>
  );
}

export default Catalogo;
