import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Controller, Scene } from 'react-scrollmagic';

import docs from "../images/docs.svg";
import Accordion from "./elements/accordion";
import Footer from "./Footer";

function Documentacion() {
    return (
        <div>
            <Navbar negocio={[]} />
            <div className="about-container container">
                <div className="row">
                    <div className="twelve columns">
                        <div className="docs-header">
                            <h3 className="title">Asistente Okki</h3>
                            <p className="docs-header__about">¿Tienes una duda sobre Okki o solo quieres saludar?</p>
                            <img src={docs} />

                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="three columns onlyDesktop">
                        <Controller>
                            <Scene triggerHook={0.1} duration={1600} pin>
                                <div className="docs-sidebar">
                                    <div className="docs-sidebar__list">
                                        <li><a href="#id1" className="active">Cómo funciona Okki</a></li>
                                        <li><a href="#id1" className="">Cómo convertirte en Okki Pro</a></li>
                                        <li><a href="#id2" className="">Cómo crear tu tienda</a></li>
                                        <li><a href="#id3" className="">Cómo subir tus productos</a></li>
                                        <li><a href="#id4" className="">Cómo vender más</a></li>
                                        <li><a href="#id5" className="">Whatsapp para Negocios</a></li>
                                        <li><a href="#id6" className="">Cómo eliminar tu cuenta</a></li>
                                        <li><a href="#id7" className="">Cómo ser feliz</a></li>
                                        <li><a href="#id8" className="">¿Tienes otra duda?</a></li>
                                    </div>
                                </div>
                            </Scene>
                        </Controller>

                    </div>
                    <div className="nine columns">
                        <div id="id1" className="docs-info">
                            <div className="docs-info__title titleMin">
                                Cómo funciona Okki
                            </div>
                            <div className="docs-info__text">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non rutrum leo. Donec vestibulum leo in odio imperdiet, eu aliquet mauris consectetur. Vestibulum congue luctus pellentesque. Aenean augue nisl, sagittis sed erat vel, venenatis aliquet odio. Proin mattis arcu a lorem bibendum maximus. Sed et ante vel magna scelerisque fringilla. Aenean in semper turpis. Morbi condimentum libero nec risus rutrum, sit amet suscipit nulla ultrices.</p>
                            </div>
                        </div>
                        <div id="id2" className="docs-info">
                            <div className="docs-info__title titleMin">
                                Cómo convertirte en<br />
                                Okki Pro
                            </div>
                            <div className="docs-info__text">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non rutrum leo. Donec vestibulum leo in odio imperdiet, eu aliquet mauris consectetur. Vestibulum congue luctus pellentesque. Aenean augue nisl, sagittis sed erat vel, venenatis aliquet odio. Proin mattis arcu a lorem bibendum maximus. Sed et ante vel magna scelerisque fringilla. Aenean in semper turpis. Morbi condimentum libero nec risus rutrum, sit amet suscipit nulla ultrices.</p>
                                <br /><br />
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non rutrum leo. Donec vestibulum leo in odio imperdiet, eu aliquet mauris consectetur. Vestibulum congue luctus pellentesque. Aenean augue nisl, sagittis sed erat vel, venenatis aliquet odio. Proin mattis arcu a lorem bibendum maximus. Sed et ante vel magna scelerisque fringilla. Aenean in semper turpis. Morbi condimentum libero nec risus rutrum, sit amet suscipit nulla ultrices.</p>
                            </div>
                        </div>
                        <div id="id3" className="docs-info">
                            <div className="docs-info__title titleMin">
                                Cómo crear tu tienda
                            </div>
                            <div className="docs-info__text">
                                <p>Nunc sed orci luctus, pharetra mauris quis, vestibulum ligula. Integer feugiat tellus non egestas varius.
                                <br /><br />
                                Sed vitae nibh massa. Duis eget quam pharetra, venenatis dolor et, efficitur massa. Nunc vestibulum tempor enim, id convallis turpis tincidunt ac.
                                Nam volutpat massa sed elit pharetra, nec eleifend elit venenatis.
                                Phasellus tincidunt dictum tellus.
                                Phasellus quis nisi nec quam ornare consectetur.
                                Ut tempor non neque in egestas.
Cras cursus mauris nibh, sit amet finibus erat maximus in.</p>
                            </div>
                        </div>
                        <div className="docs-info">
                            <div className="docs-info__title titleMin">
                                Cómo subir tus productos
                            </div>
                            <div className="docs-info__text">
                                <p>Vestibulum nec elit et ex facilisis maximus quis quis eros. Morbi vel augue et nisi facilisis posuere. Nam eget finibus ipsum, nec venenatis elit. Ut ut ligula laoreet, suscipit urna ac, congue purus. Maecenas sit amet velit vel eros tempor sagittis. Sed a ultricies sapien. Cras mollis eros iaculis bibendum finibus. Aenean at dolor a massa mollis tincidunt. Nulla eu est ut enim sollicitudin pretium mattis et ex.</p>
                            </div>
                        </div>
                        <div className="docs-info">
                            <div className="docs-info__title titleMin">
                                Cómo vender más
                            </div>
                            <div className="docs-info__text">
                                <p>Aliquam posuere fermentum sapien, sed viverra neque bibendum at. Phasellus a purus ligula. Vestibulum a nisl sem. Ut ac risus aliquam, sodales tortor sit amet, tempor libero. Morbi faucibus nulla ante, id finibus ante lobortis ut. Donec at sapien orci. Duis vitae semper diam, id pulvinar massa. Interdum et malesuada fames ac ante ipsum primis in faucibus.

                                Cras pretium tortor sed ultricies viverra. Curabitur euismod bibendum leo et tempus. Proin suscipit urna ut faucibus scelerisque. Nunc finibus, lectus sed hendrerit pulvinar, velit mi tincidunt dui, non porttitor sem dolor a orci. Nam condimentum dui non ex ultricies blandit et nec lorem.</p>
                            </div>
                        </div>
                        <div className="docs-info">
                            <div className="docs-info__title titleMin">
                                Cómo vender más
                            </div>
                            <div className="docs-info__text">
                                <p>Aliquam posuere fermentum sapien, sed viverra neque bibendum at. Phasellus a purus ligula. Vestibulum a nisl sem. Ut ac risus aliquam, sodales tortor sit amet, tempor libero. Morbi faucibus nulla ante, id finibus ante lobortis ut. Donec at sapien orci. Duis vitae semper diam, id pulvinar massa. Interdum et malesuada fames ac ante ipsum primis in faucibus.

                                Cras pretium tortor sed ultricies viverra. Curabitur euismod bibendum leo et tempus. Proin suscipit urna ut faucibus scelerisque. Nunc finibus, lectus sed hendrerit pulvinar, velit mi tincidunt dui, non porttitor sem dolor a orci. Nam condimentum dui non ex ultricies blandit et nec lorem.</p>
                            </div>
                        </div>
                        <div className="docs-info">
                            <div className="docs-info__title titleMin">
                                Cómo vender más
                            </div>
                            <div className="docs-info__text">
                                <p>Aliquam posuere fermentum sapien, sed viverra neque bibendum at. Phasellus a purus ligula. Vestibulum a nisl sem. Ut ac risus aliquam, sodales tortor sit amet, tempor libero. Morbi faucibus nulla ante, id finibus ante lobortis ut. Donec at sapien orci. Duis vitae semper diam, id pulvinar massa. Interdum et malesuada fames ac ante ipsum primis in faucibus.

                                Cras pretium tortor sed ultricies viverra. Curabitur euismod bibendum leo et tempus. Proin suscipit urna ut faucibus scelerisque. Nunc finibus, lectus sed hendrerit pulvinar, velit mi tincidunt dui, non porttitor sem dolor a orci. Nam condimentum dui non ex ultricies blandit et nec lorem.</p>
                            </div>
                        </div>
                        <div className="docs-info">
                            <div className="docs-info__title titleMin">
                                Cómo vender más
                            </div>
                            <div className="docs-info__text">
                                <p>Aliquam posuere fermentum sapien, sed viverra neque bibendum at. Phasellus a purus ligula. Vestibulum a nisl sem. Ut ac risus aliquam, sodales tortor sit amet, tempor libero. Morbi faucibus nulla ante, id finibus ante lobortis ut. Donec at sapien orci. Duis vitae semper diam, id pulvinar massa. Interdum et malesuada fames ac ante ipsum primis in faucibus.

                                Cras pretium tortor sed ultricies viverra. Curabitur euismod bibendum leo et tempus. Proin suscipit urna ut faucibus scelerisque. Nunc finibus, lectus sed hendrerit pulvinar, velit mi tincidunt dui, non porttitor sem dolor a orci. Nam condimentum dui non ex ultricies blandit et nec lorem.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Documentacion;
