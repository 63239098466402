import React, { useEffect, useState } from "react";
import itemThumb from "../images/itemThumb.svg";
import { Link, Redirect } from "react-router-dom";
import profileThumb from "../images/profileThumb.png";
import { Helmet } from 'react-helmet-async';
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Bounce from "react-reveal/Bounce";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getProductosByNombre, getProductosPurchase, postFlag, route, SearchResults, getNegociosHome, bucket_route } from "./API Functions";
import NumberFormat from 'react-number-format';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import ProductPrice from "./Products/ProductPrice";
import useModalProductos from "./useModalProductos";
import ModalProductos from "./ModalProductos";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        maxWidth: 400,
        padding: "40px",
        border: "none",
        borderRadius: 12,
    },
};
const customStylesFlag = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        padding: "40px",
        border: "none",
        borderRadius: 12,
    },
};


function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <svg className={className} onClick={onClick} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" stroke="white" stroke-opacity="0.1" />
            <path d="M22 25L26 21L22 17" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M26 21L16 21" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <svg className={className} onClick={onClick} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="41.5" y="41.5" width="41" height="41" rx="20.5" transform="rotate(-180 41.5 41.5)" stroke="white" stroke-opacity="0.1" />
            <path d="M20 17L16 21L20 25" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 21L26 21" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
};

function ProductoModal(props) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpenFlag, setIsOpenFlag] = React.useState(false);
    const [producto, setProducto] = React.useState(true);
    const [tags, setTags] = React.useState([]);
    const [category, setCategory] = React.useState("");
    const [position, setPosition] = React.useState("");
    const [image_loaded_end, setImageLoaded] = useState(false);

    const { isShowing, toggle } = useModalProductos();
    const { flagsShowing, flags } = useModalProductos();
    const { shareShowing, share } = useModalProductos();
    const { popupShare, popup } = useModalProductos();
    const { image_loaded, loadImage } = useModalProductos();

    const [currentProduct, setCurrentProduct] = useState([]);

    const [inHover, setHover] = useState(false);

    // const [producto, setProducto] = useState(props.location.state.producto)
    useEffect(() => {
        console.log(props, 'test')
        if (props.location.state) {
            const producto = props.location.state.producto;
            const position = props.location.state.position;
            var images = [];
            images.push(producto.principal_image)
            producto.images.map((image, index) => {
                images.push(image);
            })
            producto.images = images;
            setProducto(producto);

            const tags = [];
            JSON.parse(producto.tags).map(tag => {
                tags.push(tag.value)
            })
            console.log(tags)
            setTags(tags);
            setPosition(position);
            console.log(position)
        } else {
            const product_name = props.match.params.nombreProducto.replace(/-/g, ' ');
            const data = {
                product_id : props.match.params.productoId,
                okki_domain : props.match.params.okkiDomain
            }
            getProductosByNombre(data).then(res => {
                if (res) {
                    showProduct(res)
                } else {
                    setProducto(false)
                }
            })
        }
    }, [])

    function showProduct(producto) {
        var product_path = producto.nombre.replace(/ /g, '-');
        product_path = product_path.replace(/[^\w\s]/gi, '-');
        producto = {
        ...producto,
        pathname: `/${producto.negocio.dominio_okki}/productos/${producto.id}/${product_path}`,
        previous_path: `${window.location.pathname}`
        }
        var images = [];
        images.push(producto.principal_image)
        producto.images.map((image, index) => {
        images.push(image);
        })
        producto.images = images;
        sessionStorage.setItem('previous_path', producto.previous_path);
        sessionStorage.setItem('product_path', producto.pathname)
        setCurrentProduct(producto);
        const tags = [];
        JSON.parse(producto.tags).map(tag => {
        tags.push(tag.value)
        })
        setTags(tags);
        toggle();
    }

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }
    function openModalFlag() {
        setIsOpenFlag(true);
    }

    function closeModalFlag() {
        setIsOpenFlag(false);
    }
    const url = window.location.href
    function copiedPopUp() {
        const copiedPop = document.getElementById("copiedPop");
        copiedPop.classList.add("copiedPop-show");
        copiedPop.classList.add("copiedPop-exit");
    }
    function reportedPopUp() {
        const reportedPop = document.getElementById("reportedPop");
        reportedPop.classList.add("copiedPop-show");
        reportedPop.classList.add("copiedPop-exit");
    }

    function Search(keywords) {
        const data = {
            keywords: keywords
        }

        SearchResults(data).then(res => {
            if (res) {
                //console.log(res)
                sessionStorage.setItem('keywords', keywords);
                sessionStorage.setItem('negocios_result', JSON.stringify(res.negocios));
                sessionStorage.setItem('productos_result', JSON.stringify(res.productos));
                window.location.replace(`/search/${data.keywords}`)
            }
        })
    }

    function createFlag() {
        const data = {
            motivo: category,
            producto_id: producto.id,
            negocio_id: producto.negocio_id
        }

        postFlag(data).then(res => {
            if (res) {
                closeModalFlag();
            }
        })
    }

    function handlePurchase() {
        getProductosPurchase(producto.id).then(res => {
            if (res) {
                window.open(`https://wa.me/52${producto.negocio.whatsap}?text=${producto.mensaje_privado ? producto.mensaje_privado.replace(/ /g, '%20') : 'Estoy%20interesad@%20en%20el%20producto'}%20${producto.nombre}`) 
            }
        })
    }

    return (
        <div>
           <ModalProductos
            isShowing={isShowing}
            hide={toggle}
            flags={flags}
            flagsShowing={flagsShowing}
            share={share}
            shareShowing={shareShowing}
            image_loaded={image_loaded}
            loadImage={loadImage}
            popupShare={popupShare}
            popup={popup}
            producto={currentProduct}
            tags={tags}
        />
        </div>
    );

}

export default ProductoModal;