import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function NoPro() {
    const ref = useRef();
    const [isToggled, setToggled] = useState(true);

    const toggleTrueFalse = () => setToggled(!isToggled);

    function hideGetModalPro() {
        var two_days = new Date();
        var today = new Date();
        two_days.setDate(today.getDate() + 15);

        cookies.set('showGetModalPro', true, {
            path: '/',
            expires: two_days
        });

        setToggled(false)
    }


    return (
        <>
            <Zoom duration={300}>
                <div ref={ref} className={isToggled ? "noProModal" : "noProModal-hidden"}>
                    <svg onClick={() => hideGetModalPro()} className="noProModal-close" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.2422 5.75732L5.75691 14.2426" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.2431 14.2426L5.75781 5.75732" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>


                    <p>
                        ¡Prueba lo mejor de Okki durante <span>30 días</span>. Crece tu negocio desde el día uno!
                    </p>
                    <Link to="/pro" target="_blank" className="button buttonPro">
                        Prueba PRO gratis
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6424 8.22228L9.99163 4.28577L8.21385 8.22228L4.27734 10.0001L8.21385 11.6508L9.99163 15.7143L11.6424 11.6508L15.7059 10.0001L11.6424 8.22228Z" fill="white" />
                        </svg>

                    </Link>
                </div>
            </Zoom>

        </>
    );
}


export default NoPro;