import React, { useEffect, useState, Component } from "react";
import NumberFormat from "react-number-format";

function ProductPrice({producto}){

    return(
        <>
        { 
            producto.precio_descuento > 0 ?
            <div className="priceRow" id="cancel-payment-amount">
            <NumberFormat decimalScale={2} value={producto.precio} displayType={'text'} thousandSeparator={true} prefix={'$'} 
            renderText={value => 
                <span className="productPrice-discount">
                <span class="dollars"> {`${value.toString().split(".")[0]}.`}</span>
                <sup class="cents">{`${value.toString().split(".")[1]}.`}</sup>
            </span>
          }
            />
            <NumberFormat decimalScale={2} value={producto.precio_descuento} displayType={'text'} thousandSeparator={true} prefix={'$'} 
            renderText={value => 
              <p>
                  <span className="dollars">{`${value.toString().split(".")[0]}.`}</span>
                  <sup className="cents">{`${value.toString().split(".")[1]}`}</sup>
              </p>
          }
            />
            </div>
            :
            <NumberFormat decimalScale={2} value={producto.precio} displayType={'text'} thousandSeparator={true} prefix={'$'} 
            renderText={value => 
              <div className="priceRow" id="cancel-payment-amount">
              <p>
                  <span className="dollars">{`${value.toString().split(".")[0]}.`}</span>
                  <sup className="cents">{`${value.toString().split(".")[1]}`}</sup>
              </p>
              </div>
          }
            />
        
            }
            </>
    )
}

export default ProductPrice;