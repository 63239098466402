import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import profileThumb from "../images/profileThumb.png";
import Navbar from "./Navbar";
import { getCurrentNegocio, getNegocios, putNegocios } from "./API Functions";
import Settings4 from "./Settings-4";
import Settings2 from "./Settings-2";

function Settings3(props) {
    const [Loading, setLoading] = useState(false);

    const [negocio, setNegocio] = useState([]);

    const [web, setWeb] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");

    const [webError, setWebError] = useState("");
    const [facebookError, setFacebookError] = useState("");
    const [instagramError, setInstagramError] = useState("");

    const [settings4, setSettings4] = useState(false);

    const [settings2, setSettings2] = useState(false);

    useEffect(() => {
        if (props.negocio) {
            const negocio = props.negocio;
            setWeb(negocio.website !== "null" ? negocio.website : "");
            if (negocio.nuevo && negocio.facebook == "null" && negocio.instagram == "null") {
                setFacebook("");
                setInstagram("");
            } else {
                setFacebook(negocio.facebook);
                setInstagram(negocio.instagram);
            }
            setNegocio(negocio);
        } else {
            getCurrentNegocio().then(res => {
                const negocio = res;
                setWeb(negocio.website !== "null" ? negocio.website : "");
                if (negocio.nuevo && negocio.facebook == "null" && negocio.instagram == "null") {
                    setFacebook("");
                    setInstagram("");
                } else {
                    setFacebook(negocio.facebook !== "null" ? negocio.facebook : "");
                    setInstagram(negocio.instagram !== "null" ? negocio.instagram : "");
                }
                setNegocio(negocio);
            })
        }

    }, [])

    function validateFields() {
        let webError = "";
        let instagramError = "";
        let facebookError = "";

        /*
        if (web == "") {
            webError = "Requerido";
        }
        if (instagram == "" || instagram == null) {
            instagramError = "Requerido";
        }
        if (facebook == "") {
            facebookError = "Requerido";
        }
        */


        if (
            webError ||
            instagramError ||
            facebookError
        ) {
            setWebError(webError);
            setInstagramError(instagramError);
            setFacebookError(facebookError);
            return false;
        } else {
            setWebError(webError);
            setInstagramError(instagramError);
            setFacebookError(facebookError);
            return true;
        }
    }

    function handleSubmit() {
        setLoading(true)
        const data_content = {
            id: negocio.id,
            tags_ids: negocio.tags_ids,
            nombre: negocio.nombre,
            codigo_postal: negocio.codigo_postal,
            direccion: negocio.direccion,
            whatsap: negocio.whatsap,
            telefono: negocio.whatsap,
            categoria_id: negocio.categoria_id,
            descripcion: negocio.descripcion,
            dominio_okki: negocio.dominio_okki,
            rango_id: negocio.rango_id,
            ciudad_id: negocio.ciudad_id,
            zona: negocio.zona,
            precio_envio: negocio.precio_envio,
            persona_contacto: negocio.persona_contacto,
            envios: [negocio.envios[0].id],
            tipos: [negocio.tipos[0].id],
            //EDITED FIELDS ON VIEW
            instagram: instagram,
            facebook: facebook,
            website: web
        }
        const isValid = validateFields()
        if (isValid) {
            const data = {
                id: negocio.id,
                data: data_content
            }
            putNegocios(data).then(res => {
                if (res) {
                    if (res.nuevo) {
                        localStorage.setItem('negocio', JSON.stringify(res))
                        setNegocio(res);
                        changeSettings(res);
                    } else {
                        localStorage.setItem('negocio', JSON.stringify(res))
                        window.location.reload();
                    }
                }
            })
        }
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    function changeSettings(negocio) {
        setSettings4(true);
    }

    return (
        <>
            {settings4 ?
                <Settings4 />
                :
                settings2 ?
                    <Settings2 negocio={negocio} />
                    :
                    negocio.id ?
                        <>
                            {negocio.nuevo ?
                                <div className="four columns settings-form__sidebar onlyDesktop" style={{ marginLeft: 0 }}>

                                    <div className="settings-header settings-headerModal">
                                        <div className="settings-header__breadcrumb settings-header__breadcrumb-title">
                                            <h3 className="settings-header__title">Configuración</h3>

                                            <hr className="settingsDivider" />
                                        </div>
                                    </div>
                                    <ul className="settings-form__menu settings-form__menuList">
                                        <li>
                                            <Link className="">
                                                Perfil
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Negocio
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Dirección y Envíos
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="active">
                                                Redes Sociales
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Cuenta
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Plan
                                    </Link>
                                        </li>
                                        {/* <li>
                        <Link to="/pro" target="_blank" activeClassName="active">
                          PRO
                                    </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: `/${this.state.negocio.dominio_okki}` }} activeClassName="active">
                          Mi perfil
                                    </Link>
                      </li> */}
                                    </ul>

                                </div>
                                : null}
                            <div className="eight columns settings-form__container">
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>Website</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <input
                                            className={webError ? "settings-form__input inputError" : "settings-form__input"}
                                            type="text"
                                            placeholder="https://website.com/"
                                            value={web}
                                            onChange={e => setWeb(e.target.value)}
                                        />
                                        <div className="fieldError">{webError}</div>
                                    </div>
                                </div>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>www.facebook.com/</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <input
                                            className={facebookError ? "settings-form__input inputError" : "settings-form__input"}
                                            type="text"
                                            placeholder="okkiurl"
                                            value={facebook}
                                            onChange={e => setFacebook(e.target.value)}
                                        />
                                        <div className="fieldError">{facebookError}</div>
                                    </div>
                                </div>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>www.instagram.com/</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <input
                                            className={instagramError ? "settings-form__input inputError" : "settings-form__input"}
                                            type="text"
                                            placeholder="okkiurl"
                                            value={instagram}
                                            onChange={e => setInstagram(e.target.value)}
                                        />
                                        <div className="fieldError">{instagramError}</div>
                                    </div>
                                </div>
                                {negocio.nuevo ?
                                    <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                                        <a className="button button-alphaAlt" onClick={() => { setSettings2(true) }}>Regresar</a>
                                        <a className="button" onClick={handleSubmit}>
                                            {Loading && (
                                                <i className="" style={{ marginRight: "5px" }} />
                                            )}
                                            {Loading && (
                                                'Guardando...'
                                            )}
                                            {!Loading && (
                                                negocio.nuevo ?
                                                    'Continuar'
                                                    :
                                                    'Guardar'
                                            )}
                                        </a>
                                    </div>
                                    :
                                    <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                                        <a>{" "}</a>
                                        <a className="button" onClick={handleSubmit}>
                                            {Loading && (
                                                <i className="" style={{ marginRight: "5px" }} />
                                            )}
                                            {Loading && (
                                                'Guardando...'
                                            )}
                                            {!Loading && (
                                                negocio.nuevo ?
                                                    'Continuar'
                                                    :
                                                    'Guardar'
                                            )}
                                        </a>
                                    </div>
                                }
                            </div>
                        </>

                        :
                        null
            }
        </>
    );
}

export default Settings3;
