import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import blocked1 from "../images/blocked1.svg";
import blocked2 from "../images/blocked2.svg";
import Accordion from "./elements/accordion";
import Footer from "./Footer";

function Blocked() {
    return (
        <div>
            <div className="block-container">
                <div className="container">
                    <div className="row block-container__row">
                        <div className="four columns about-container__cover">
                            <img className="about-container__coverImg" src={blocked1} />
                        </div>
                        <div className="four columns about-container__cover onlyDesktop">
                            <div className="block-container__copy">
                                <h3 className="titleMin">
                                    Podrás accesar a ésta sección y otras más a partir del 23 de Noviembre.
                                </h3>
                                <br />
                                <p>Todos los negocios están cargando su información. Si tienes alguna duda, contáctanos en nuestro chat.</p>
                            </div>
                        </div>
                        <div className="four columns about-container__cover">
                            <img className="about-container__coverImg" src={blocked2} />
                        </div>
                        <div className="four columns about-container__cover onlyMobile">
                            <div className="block-container__copy">
                                <h3 className="titleMin">
                                    Podrás accesar a ésta sección y otras más a partir del 20 de Noviembre.
                                </h3>
                                <br />
                                <p>Todos los negocios están cargando su información. Si tienes alguna duda, contáctanos en nuestro chat.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Blocked;
