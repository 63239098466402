import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import thankyoupro from "../images/thankyoupro.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "./Navbar";
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import Footer from "./Footer";


function ThankYouPro() {
    const { width, height } = useWindowSize()
    return (
        <>
            <Confetti
                width={width}
                height={height}
                colors={[
                    '#A1D053', '#FFA114', '#3FC2FF', '#FF3C14'
                ]}
            />
            <Navbar />

            <div>
                <div className="modal-content modal-contentThanks">
                    <div className="modal-text">
                        <div className="modal-cover">
                            <img src={thankyoupro} />
                        </div>

                        <h3 className="modal-title">
                            Ahora estás un paso
                            adelante de los demás
                        </h3>
                        <p className="modal-txt">
                            Utiliza tu creatividad para agregar más etiquetas y fotografías en cada producto,  las oportunidades a que te encuentren son infinitas.
                        </p>
                        <br />
                        <Link to="/catalogo" className="button buttonIconRight buttonCenter">
                            <>
                                <span>Continuar</span>

                            </>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ThankYouPro;