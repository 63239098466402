import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import settingsReady from "../images/settingsReady.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SettingsReady() {

    return (
        <div className="settings-form settings-formReady">
            <div className="modal-content modal-contentWelcome modal-content-settingsReady">
                <div className="modal-text">
                    <div className="modal-cover">
                        <img className="modal-cover-settingsReady" src={settingsReady} />
                    </div>

                    <h3 className="modal-title">Estamos creando tu tienda...</h3>
                    <div class="containerSpinner">
                        <div class="circle"></div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default SettingsReady;