import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import aboutCover from "../images/about.svg";
import Accordion from "./elements/accordion";
import Footer from "./Footer";

function Terminos() {
    return (
        <div>
            <Navbar negocio={[]} />
            <div className="about-container container">
                <div className="row">
                    <div className="termsContainer">
                        <h1 className="termsContainer-title titleMin">Reglas de Uso del Portal y la Plataforma de OKKI</h1>
                        <br />
                        <p className="termsContainer-text">
                            Bienvenido al sitio web de “OKKI” en www.okki.co. Lea estos términos detenidamente antes de contratar un servicio a través de nuestro sitio web y/o antes de contratar alguno de nuestros servicios directamente y hacer uso de nuestros servicios en general. Al iniciar el uso de este sitio o ante la contratación de nuestros servicios, se presume que usted ha leído y consentido estos términos y condiciones. Al utilizar este sitio electrónicamente o cualquiera de nuestros servicios, el Usuario reconoce que ha leído y aceptado sujetarse por estos términos y condiciones en toda su extensión y con todo su alcance legal.
                            <br /><br />
                            Los siguientes términos y condiciones para el uso del portal (las “Reglas de Uso”) son aplicables a todos los sitios web de www.okki.co (el “Portal”), propiedad de “OKKI” LAGOM STARTUP STUDIO S.A.P.I. DE C.V.
                            <br /><br />
                            Para efectos de las presentes Reglas de Uso del Portal, se considerará usuario al: (i) público en general y/o (ii) cliente de OKKI que haya general un perfil de usuario a través de nuestro Portal y/o antes de contratar alguno de nuestros servicios a través del Portal o (iii) quien sea un funcionario de un cliente de OKKI y haga uso del Portal para recibir los servicios que presta OKKI al mercado general (el “Usuario”). El Usuario acepta plena e incondicionalmente tanto las Reglas de Uso del Portal, como el Aviso de Privacidad (conjuntamente, el “Aviso Legal”), publicados en el Portal. OKKI se reserva el derecho de actualizar o revisar estos términos y condiciones sin previo aviso y sin necesidad de autorización previa por parte de algún tercero; revise estos términos y condiciones periódicamente para enterarse de cualesquier cambios, sin responsabilidad alguna para OKKI. El uso continuo de este sitio después de la publicación de algún cambio en estos términos y condiciones indica que el Usuario acepta dichos cambios sin reserva alguna.
                            <br /><br />
                            OKKI es una plataforma que es una plataforma online que ayuda a conectar por medio de whatsapp a negocios con prospectos y clientes, generalmente a través del Portal y Plataforma, conforme a lo descrito en el Portal (los “Servicios”). OKKI no se encuentra comprometido con ninguna organización política o gubernamental.
                            <br /><br />
                            El material contenido en el Portal es para fines informativos de los servicios ofrecidos. Estas Reglas de Uso rigen la utilización del Portal, así como cualquier tecnología que en su caso sea utilizada por OKKI para la prestación de sus servicios al mercado en general (la “Plataforma”) y su acceso a éste constituye un contrato vinculante (un “Acuerdo”) por y entre OKKI y el Usuario. El Aviso Legal tiene carácter de obligatorio y vinculante para todos los Usuarios. En caso de que el Usuario no esté de acuerdo con el Aviso Legal, en todo o en parte, deberá abstenerse de utilizar, operar, visitar el Portal, la Plataforma y/o de contratar los servicios prestados por OKKI.
                            <br /><br />
                            Al usar el Portal, la Plataforma y/o al inducir su uso electrónicamente o a través de otros medios, el Usuario indica que acepta el Acuerdo y reconoce y acepta que: (i) OKKI se exime de toda responsabilidad, pérdida, daño o riesgo que resulte como consecuencia directa o indirecta del uso del Portal, la Plataforma o de los Servicios; (ii) el uso del Portal y la Plataforma está sujeto a los términos del Aviso Legal, según pueda ser modificado de tiempo en tiempo; y (iii) sólo utilizará los materiales contenidos en el Portal y la Plataforma para fines personales, no comerciales e informativos, y que no modificará ninguno de los materiales ahí contenidos.
                            <br /><br />
                            <span>Descripción General de los Servicios</span>

                            <br /><br />
                            En términos generales, y a reserva de cualquier disposición más detallada ya sea de fondo o de procesos aplicables que sean implementados a través del Portal o la Plataforma, para la prestación de los Servicios: (i) puede que el Usuario se dé de alta con un usuario y contraseña, o puede que los Servicios sean prestados sin la necesidad de generar algún usuario y/o contraseña y sean prestados en forma directa; (ii) el Usuario deberá proporcionar toda la información que sea necesaria para la adecuada y oportuna prestación de los Servicios; y (iii) la prestación de los Servicios se regirá por las instrucciones que OKKI haga disponibles en el Portal y/o la Plataforma de tiempo en tiempo, las cuales el Usuario deberá cumplir para la adecuada prestación de los Servicios.
                            <br /><br />
                            <span>Canales y Filtros de Seguridad</span>

                            <br /><br />
                            OKKI a su sola discreción podrá implementar los filtros de seguridad que considere apropiados para evaluar a los Usuarios, y determinar si la información brindanda por un Usuario sea confiable y segura, y si decide prestar o interrumplir los Servicios para un Usuario, a su sola discreción. Desde que un Usuario se da de alta en el Portal o la Plataforma, el equipo de OKKI podrá filtrarlo con el objetivo de salvaguardar la integridad de sus colaboradores, agentes, socios o accionistas, consejeros y empleados involucrados directa o indirectamente en la prestación de los Servicios.
                            <br /><br />
                            Para utilizar los Servicios que se ofrecen en el Portal, es posible que deba crear una cuenta y establecer una contraseña. En tal caso, Usted es completamente responsable de mantener la confidencialidad de la información de su cuenta, incluida la contraseña, y de cualquier y toda actividad que ocurra en su cuenta como resultado de que usted no haya podido mantener la confidencialidad y seguridad de dicha información. Usted acepta informar a OKKI de forma inmediata en caso de que se haga un uso ilegítimo de su cuenta o contraseña, o si ocurre cualquier problema relacionado con la seguridad. Es posible que sea legalmente responsable de las pérdidas ocasionadas a OKKI o a cualquier otro usuario o visitante del Portal por el uso de su cuenta o contraseña por parte de otra persona, como resultado de que usted no haya podido mantener la confidencialidad y seguridad de la información de la cuenta.
                            <br /><br />
                            No puede utilizar su cuenta o contraseña, ni la cuenta de otra persona, sin el permiso y el consentimiento expreso del titular de esa cuenta. OKKI no será responsable de ninguna pérdida o daño que surja por su incumplimiento de estas obligaciones.
                            <br /><br />
                            <span>Conducta del Usuario</span>

                            <br /><br />
                            El Usuario acepta cumplir todas las leyes locales, estatales, nacionales e internacionales pertinentes, reglamentos, normas y reglas y es el único responsable por todas las acciones u omisiones que ocurran como consecuencia del uso o acceso al Portal o la Plataforma incluyendo y sin limitaciones, el contenido de sus transmisiones a través de o hacia el Portal o la Plataforma. Sin limitar la general de lo anterior, el Usuario se compromete a no hacer lo siguiente:
                            <br />
                            - Usar el Portal o la Plataforma en relación con la comisión de delitos patrimoniales o de cualquier otro tipo, en particular en materia de antilavado de dinero y anticorrupción.
                            <br />
                            - Usar el Portal o la Plataforma en relación con cartas en cadena, correo electrónico publicitario, correo electrónico de publicidad indeseada, ni ningún duplicado ni mensajes no solicitados, comerciales o de otra í­ndole.
                            <br />
                            - Recopilar, compilar ni diseminar información acerca de terceros, incluyendo las direcciones de correo electrónico o datos personales, sin consentimiento del titular de la información o los datos.
                            <br />
                            - Crear una identidad falsa o una dirección de correo electrónico o encabezamiento falsificado, ni intentar de alguna manera engañar a otras personas sobre la identidad del remitente o el origen de un mensaje.
                            <br />
                            - Transmitir a través o hacia el Portal o la Plataforma materiales ilegales, hostigantes, difamatorios, abusivos, amenazantes, perjudiciales, vulgares, obscenos, ofensivos o de índole censurable.
                            <br />
                            - Transmitir algún material que pudiera infringir los derechos de propiedad intelectual u otros derechos de terceros, incluyendo y sin limitaciones, marcas comerciales, secretos comerciales o derechos de autor.
                            <br />
                            - Transmitir algún material que contenga virus, caballos de troya, gusanos, bombas de tiempo, programas de cancelación, ni ningún otro programa perjudicial o nocivo.
                            <br />
                            - Usar el Portal o la Plataforma para infringir alguna ley pertinente que restrinja la exportación o importación de datos, software o algún otro tipo de contenido.
                            <br />
                            - Interferir en, o perturbar, el funcionamiento de las redes conectadas con los sitios web de OKKI o infringir las normas, polí­ticas, reglas o procedimientos de tales redes.
                            <br />
                            - Obtener o intentar obtener acceso no autorizado al Portal o la Plataforma y los sitios web de OKKI u otras cuentas, sistemas computacionales o redes conectadas con los sitios web de OKKI o la Plataforma, por medio de la búsqueda u obtención ilegal de contraseñas ni por ningún otro medio.
                            <br />
                            - Interferir en el uso del Portal o la Plataforma por parte de algún tercero.
                            <br />
                            - Usar el Portal o la Plataforma de alguna manera no ética.
                            <br />
                            - Usar el Portal o la Plataforma para promocionar negocios en beneficio de alguna empresa o servicio de la competencia.
                            <br />
                            - Utilizar ningún tipo de “enlace profundo” (deep-link), “extracción de datos” (page-scrap), “robot”, “araña” (spider) u otros dispositivos automáticos, programas, algoritmos o metodologías, o procesos manuales similares o equivalentes para adquirir, copiar o supervisar cualquier parte del Portal, o bien para acceder a estos, ni tampoco podrá reproducir o evadir la estructura de navegación o presentación del Portal, con el objetivo de obtener o intentar obtener materiales, documentos o información por cualquier medio que no esté voluntariamente a disposición a través del mismo.
                            <br />
                            - Explorar, analizar o evaluar la vulnerabilidad del Portal ni de ninguna red conectada a este, así como tampoco infringir las medidas de seguridad o autenticación del mismo ni de ninguna red conectada a este.
                            <br /><br />
                            Queda expresamente prohibido: (i) utilizar el Portal y/o la Plataforma y/o los Servicios para propósitos ilegales, inmorales, obscenos o prohibidos por la costumbre y la legislación aplicable y/o por el Aviso Legal; (ii) provocar modificaciones, alteraciones y/o supresiones, realizados por medios electrónicos o de cualquier otra forma, empleando recursos lí­citos o ilícitos, que puedan interferir en la administración u operación del Portal y/o la Plataforma y/o los Servicios prestados por OKKI.
                            <br /><br />
                            El Usuario seré responsable de los daños y perjuicios que se causen a OKKI derivado de sus actos o de aquellos que provengan de sus funcionarios, empleados, agentes, dependientes, colaboradores y/o persona fí­sica o moral asociada, subsidiaria y/o filial.
                            <br /><br />
                            <span>Usuarios y Privacidad</span>

                            <br /><br />
                            Para que el Usuario pueda contactar a OKKI para consumo de los Servicios, consultas y dudas y demás opciones dentro del Portal o la Plataforma, podrá hacerlo a través de los contactos disponibles en el Portal o al contar con una cuenta de Usuario en la Plataforma. Para la prestación de los Servicios o para resolver alguna duda o consulta, es posible que se le pida al Usuario que proporcione información personal. Las polí­ticas de recolección y uso de datos de OKKI con respecto a la privacidad de dicha información están contenidas en el Aviso Legal, lo cual se considerará aquí incorporado por referencia para todos los propósitos y puede consultarlas a través del Portal. Es la responsabilidad del Usuario proporcionar información precisa, completa, verdadera y actualizada. El Usuario, mediante el uso del Portal y/o la Plataforma, está de acuerdo en lo indicado en el Aviso Legal, incluyendo el contenido del Aviso de Privacidad, y se obliga a mantener actualizado a la brevedad cualquier cambio en la información proporcionada.
                            <br /><br />
                            <span>Pago, Cancelaciones y Devoluciones, Re-programaciones</span>

                            <br /><br />
                            Los Servicios que sean prestados son personales e intransferibles, y sólo podrán ser utilizados por el Usuario contratante. El Usuario se obliga a cumplir con los términos de pago dispuestos en su contrato de servicios respectivo.
                            <br /><br />
                            Todos los titulares de tarjeta de crédito o débito están sujetos a comprobaciones y autorizaciones por parte del emisor de la tarjeta. Al proporcionar voluntariamente el Usuario información sobre su tarjeta de crédito o débito para la contratación de los Servicios, el Usuario consiente su uso para el cargo correspondiente, y confirma que la tarjeta es suya y que cuenta con fondos suficientes o crédito disponible para cubrir los cargos.
                            <br /><br />
                            Las plataformas de pago son independientes de OKKI y su finalidad es facilitar una transacción segura y ágil a los Usuarios. Cualquier consulta o incidencia sobre dicho sistema de pago deberá dirigirse directamente con las plataformas de pagos aplicables al Portal o la Plataforma, y en ningún caso OKKI asume alguna responsabilidad derivada del funcionamiento o uso de las plataformas de pago.
                            <br /><br />
                            Los Servicios de OKKI están diseñados para Usuarios mayores de edad, o quienes tengan capacidad legal suficiente para adquirir obligaciones, o en representación de menores de edad por parte de quien tenga la tutela legal, conforme a la legislación aplicable, y ello sea debidamente comprobado por medios legales fehacientes. Los Usuarios deberán suspender el uso del Portal o la Plataforma inmediatamente si no están de acuerdo o no aceptan todos estas Reglas de Uso o el contenido del Aviso Legal. OKKI se reserva el derecho de eliminar o prohibir a cualquier Usuario la utilización del Portal o la Plataforma a su sola discreción cuando tenga causas que considere justificadas, en base a lo contenido en estas Reglas de Uso, el Aviso Legal y el contrato de prestación de servicios relativo a la contratación de los Servicios.
                            <br /><br />
                            Al contratar los Servicios directamente el Usuario está de acuerdo en pagar a OKKI las tarifas indicadas en el Portal o la Plataforma por los Servicios contratados, o aquellas que se le indiquen a través del Portal o la Plataforma. OKKI se reserva el derecho de actualizar dichas tarifas en cualquier tiempo, a su sola discreción. El pago será cargado al momento de contratar y/o consumir los Servicios. Al aceptar estos términos y Reglas de Uso, el Usuario autoriza a OKKI a realizar el cargo automático a su tarjeta de crédito, débito, PayPal, u otra forma de pago.
                            <br /><br />
                            OKKI no será responsable por cancelaciones de Usuarios, incluyendo por algún caso fortuito o fuerza mayor que pueda afectar el desempeño de OKKI con relación a la prestación de los Servicios. Los costos relacionados a las cancelaciones y modificaciones correrán a cargo de OKKI solamente cuando no sea responsabilidad directa del Usuario o la empresa para quien éste desempeñe funciones, siempre y cuando lo anterior sea fehacientemente comprobado. Si no es por alguna conducta dolosa o negligencia bruta por parte de OKKI en la prestación de los Servicios, y exclusivamente por temas que dependan o estén bajo control de OKKI, OKKI no tendrá responsabilidad alguna y el Usuario deberá cubrir los gastos relevantes.
                            <br /><br />
                            <span>Derechos de Propiedad sobre Contenido del Portal y la Plataforma</span>

                            <br /><br />
                            OKKI cuenta con la titularidad y propiedad exclusiva de los derechos otorgados por las leyes vigentes y/o tratados internacionales inherentes a la propiedad intelectual, para todas las marcas, avisos comerciales, software y programas de computación en general, y demás figuras susceptibles de protección legal conforme a la Ley de la Propiedad Industrial y la Ley Federal del Derecho de Autor, con relación al Portal y la Plataforma, mediante los cuales promueva, comercialice y presten sus Servicios, incluyendo pero sin limitar: (i) la propiedad industrial sobre los tí­tulos de registros de marcas y avisos comerciales otorgados por el Instituto Mexicano de la Propiedad Industrial; (ii) todos los textos, gráficos, interfaces de usuario, interfaces visuales, fotografías, logotipos, sonidos, música, diseños y códigos informáticos, incluyendo el diseño, la estructura, la selección, la coordinación, la expresión, la apariencia y la disposición de dichos elementos, (iii) los derechos de autor respecto del programa(s) de computación, desarrollos de software, sistema(s) y/o sus derivados, otorgados por el Instituto Nacional de Derechos de Autor; y (iv) todos los desarrollos, know how, signos distintivos y/o bienes o derechos que le correspondan en el ámbito de la propiedad intelectual por derecho propio (la “Propiedad Intelectual”).
                            <br /><br />
                            OKKI no concede ninguna licencia o autorización de uso de ninguna clase sobre la Propiedad Intelectual, por lo que el Usuario reconoce que: (i) no tiene derecho alguno sobre la Propiedad Intelectual, por lo que se obliga a respetar en todo momento los derechos que detenta OKKI sobre ésta; (ii) no podrá modificar, alterar, suprimir, copiar o reproducir ya sea total o parcialmente, incluyendo pero sin limitar, el contenido informativo generado por OKKI, la Propiedad Intelectual y/o cualquier indicación contenida en el Portal o la Plataforma.
                            <br /><br />
                            <span>Terceros</span>

                            <br /><br />
                            Los prestadores de servicio de telefonía inalámbrica de los Usuarios, los fabricantes y vendedores de los dispositivos en los que el Usuario utilice o acceda al Portal o la Plataforma, el creador del sistema operativo de los Usuarios y el operador de cualquier tienda de aplicaciones o servicios similares mediante los cuales los Usuarios ingresen al Portal o la Plataforma y realicen transacciones (los “Terceros”) no son parte de estas Reglas de Uso ni de OKKI, y no son propietarios ni responsables del Portal o la Plataforma. Por consiguiente, OKKI no es y no será responsable por cualquier detalle operativo que dependa de los Terceros y que pudiera afectar la prestación de los Servicios a través del Portal o la Plataforma. Los Terceros no brindan ninguna garantía en relación con el Portal o la Plataforma, y OKKI no otorga ninguna garantía en relación con los servicios que presten los Terceros.
                            <br /><br />
                            El Portal y/o la Plataforma, y los medios transaccionales usados en los mismos, han sido creados para la versión más reciente disponible en el mercado de los sistemas operativos de internet de los Usuarios y pueden surgir inconvenientes de compatibilidad cuando se utilicen versiones anteriores. La cobertura de la red inalámbrica y la velocidad de la red de Wi-Fi varían según el proveedor y la ubicación geográfica. OKKI no se responsabiliza por las limitaciones y/o fallas en el funcionamiento de ningún servicio inalámbrico o Wi-Fi que se use para acceder al Portal o la Plataforma ni por la seguridad de los servicios inalámbricos o Wi-Fi. Asimismo, OKKI no se responsabiliza de los cargos o tarifas por uso de redes de datos, que son exclusiva responsabilidad del Usuario.
                            <br /><br />
                            <span>Contenido Provisto por el Usuario</span>

                            <br /><br />
                            Al publicar o enviar contenido al Portal o la Plataforma, el Usuario le otorga automáticamente a OKKI el derecho a usar, reproducir, presentar, exhibir, adaptar, modificar, distribuir o promover el contenido de cualquier forma y para cualquier fin, conforme a lo dispuesto en las Reglas de Uso y el Aviso Legal. Asimismo, el Usuario acepta y garantiza que tiene los derechos de propiedad necesarios para dicho contenido o control sobre dicho contenido y que la publicación y uso del contenido de parte de OKKI no infringe ni viola los derechos de ningún tercero, y no constituye un delito. El Usuario acepta y confirma que es responsable por todo el contenido que publique o envíe a través del Portal o la Plataforma. En estos casos, el Usuario se obliga a: (i) otorgar a OKKI una autorización de uso, gratuita y no exclusiva, sobre los datos compartidos; y (ii) sacar en paz y salvo a OKKI y/o sus filiales, de cualquier acción, demanda o reclamación por cualquier violación de propiedad intelectual derivada del uso de datos compartidos que el propio Usuario envíe a OKKI.
                            <br /><br />
                            <span>Suspensión o Denegación de Acceso</span>

                            <br /><br />
                            OKKI podrá, sin previo aviso, suspender, desconectar, denegar o restringir su acceso: (i) durante un fallo técnico del Portal o la Plataforma, o durante la modificación o mantenimiento del mismo; (ii) si el Usuario usa los sitios e instalaciones de OKKI de manera que viole estas Reglas de Uso o el contrato de prestación de servicios respectivo; o (iii) si el Usuario hace algo o deja de hacer algo que en la opinión de OKKI pudiera tener como consecuencia el poner en peligro el funcionamiento o la integridad de las instalaciones de cualquiera de las sucursales, empleados, clientes, el Portal o la Plataforma de OKKI.
                            <br /><br />
                            Con excepción de los casos anteriores, un Usuario podrá cancelar el Servicio de OKKI en cualquiera de sus modalidades en cualquier momento. Si existen cobros pendientes, al momento de la cancelación, se saldan automáticamente a favor de OKKI mediante cargo a los medios de pago provistos por el Usuario.
                            <br /><br />
                            <span>Exclusión de Garantías y Limitación de Responsabilidad Civil</span>

                            <br /><br />
                            Es posible que el Portal o la Plataforma de OKKI contengan enlaces a sitios, cookies, recursos o patrocinadores de terceros. La existencia de enlaces a y desde el Portal o la Plataforma a sitios de terceros no constituye un patrocinio de parte de OKKI de ningún sitio, recursos, patrocinadores o contenido de terceros y OKKI no acepta ninguna responsabilidad, directa o indirectamente, por ninguno de esos sitios, recursos, patrocinadores o contenido.
                            <br /><br />
                            OKKI no hace declaración alguna ni aprueba la precisión o confiabilidad de ningún consejo, opinión, declaración ni alguna otra información presentada o distribuida a través del Portal o la Plataforma. El Usuario acepta que, al confiar en alguna de dichas opiniones, consejos, declaraciones o información, lo hace a riesgo propio.
                            <br /><br />
                            OKKI y sus socios no se responsabilizan ni ofrecen garantías de ningún tipo con respecto a: (i) el contenido del Portal y la Plataforma; (ii) los materiales, software, funciones e información a las cuales se obtenga acceso por medio del software utilizado en el Portal y la Plataforma o a lo cual se obtenga acceso a través de los mismos; (iii) todo producto o servicio de terceros o enlaces a terceros en el Portal y la Plataforma; (iv) cualquier violación a la seguridad asociada con la transmisión de información sensible a través del Portal y la Plataforma o algún sitio enlazado; o (v) cualquier Servicio prestado, ya sea como consumidor o como prestador de sericios, por un tercero que haya sido contratado mediante el Portal o la Plataforma.
                            <br /><br />
                            En caso de violaciones a la seguridad asociada con el Portal y la Plataforma, OKKI hará sus mejores esfuerzos por apoyar a los Usuarios con respaldos de su información y del funcionamiento del Portal y la Plataforma, sin que ello cause alguna responsabilidad para OKKI o alguna garantía.
                            <br /><br />
                            OKKI y sus socios, accionistas, consejeros, empleados, colaboradores, asesores y agentes no otorgan ninguna garantía explícita o implícita, incluidas y sin limitaciones, las de no contravención o violación de leyes o reglamentos, comerciabilidad o idoneidad para un determinado propósito. OKKI no garantiza que las funciones contenidas en el Portal y la Plataforma o algún material o contenido de los mismos estarán libres de interrupciones o errores, que todos los defectos se corregirán, o que el Portal y la Plataforma o el servidor en el cual funcionan están libres de virus u otros componentes perjudiciales. OKKI no acepta ninguna responsabilidad por pérdidas o daños (incluidos y sin limitaciones, pérdidas o daños indirectos, especiales, consecuenciales o directos) que resulten del uso del Portal y la Plataforma, independientemente de si éstos ocurren a consecuencia de algún acto negligente u omisión. Si alguna ley, norma o decisión implica que algún término o garantía no puede excluirse, la única responsabilidad de OKKI será, en el caso de los Servicios, la de volver a suministrar los servicios previamente contratados.
                            <br /><br />
                            OKKI podría ofrecerles a sus Usuarios herramientas para efectuar transacciones o interactuar de alguna otra manera con terceros, incluyendo individuos. Los miembros deben tomar las medidas apropiadas al suministrar información a dichos terceros o en interacciones de otra índole con tales terceros. Todos los contactos o las interacciones entre el Usuario y terceros se hacen a riesgo suyo, y OKKI no tienen ninguna responsabilidad en tales contactos o interacciones.
                            <br /><br />
                            <span>Indemnización</span>

                            <br /><br />
                            Por medio de este documento, el Usuario acepta indemnizar, defender y mantener en paz y a salvo a OKKI y todas sus empresas filiales o subsidiarias, afiliadas, representantes, agentes, asesores, directores, consejeros, propietarios, empleados, colaboradores, proveedores de información, socios, concesionarios y licenciatarios (colectivamente las “Partes Indemnizadas”) frente a toda responsabilidad y costo incurrido por las Partes Indemnizadas en relación con cualquier reclamo relacionado con alguna infracción de este acuerdo cometida por el Usuario, todo uso no autorizado del contenido del Portal o la Plataforma o toda infracción cometida por el Usuario de las declaraciones, garantías o cláusulas precedentes, incluidos y sin limitaciones, los gastos y honorarios razonables de abogados. El Usuario debe cooperar plenamente según se requiera de manera razonable en defensa de todo reclamo. OKKI se reserva el derecho, a cuenta propia, de asumir la defensa y el control exclusivos de cualquier asunto que de otra manera esté sujeto a indemnización de parte suya y el Usuario no debe en ningún caso resolver ningún asunto sin el consentimiento por escrito de OKKI.
                            <br /><br />
                            <span>Modificaciones al Portal o a la Plataforma</span>
                            <br /><br />
                            OKKI podrá en cualquier momento y cuando lo considere conveniente, sin necesidad de avisar al Usuario, realizar correcciones, adiciones, mejoras o modificaciones, eliminar, suprimir el contenido, presentación, información, servicios, áreas, bases de datos y demás elementos del Portal o la Plataforma, de manera parcial o completa, sin que ello de lugar ni derecho a ninguna reclamación o indemnización, ni que esto implique reconocimiento de responsabilidad alguna a favor del Usuario.
                            <br /><br />
                            Estos cambios o modificaciones tendrán vigencia desde el momento de su actualización en la aplicación, sin necesidad de notificar al Usuario previamente. OKKI le recomienda al Usuario la constante revisión de estos Términos y Condiciones de uso.
                            <br /><br />
                            OKKI se reserva el derecho a terminar la aplicación o eliminar cualquiera de sus secciones o contenidos, en cualquier momento, bajo su discreción y sin necesidad de contar con autorización o notificación alguna por parte del Usuario.
                            <br /><br />
                            <span>Legislación Aplicable y Tribunales Competentes</span>
                            <br /><br />
                            Este acuerdo, las Reglas de Uso, y todos los asuntos que se originen a partir de este acuerdo o que estén relacionados con lo anterior, deberán regirse por las leyes de México, ante los tribunales federales competentes de la Ciudad de Monterrey, Nuevo León.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Terminos;
