import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import adsCover from "../../images/adsCard.png";
import itemThumb from "../../images/itemThumb.svg";
import itemProfile from "../../images/itemProfile.svg";
import Fade from "react-reveal/Fade";
import ReactTooltip from "react-tooltip";
import useModalProductos from '../useModalProductos'

import NumberFormat from 'react-number-format';

import "react-morphing-modal/dist/ReactMorphingModal.css";
import { bucket_route } from "../API Functions";

import ModalProductos from "../ModalProductos";
import ProductPrice from "./ProductPrice";

function ProductCard({producto, disabled_logo}){
    const { isShowing, toggle } = useModalProductos();
    const { flagsShowing, flags } = useModalProductos();
    const { shareShowing, share } = useModalProductos();
    const { popupShare, popup } = useModalProductos();
    const { image_loaded, loadImage } = useModalProductos();
    const [currentProduct, setCurrentProduct] = useState([]);
    const [tags, setTags] = useState([]);

    function showProduct(producto) {
        var product_path = producto.nombre.replace(/ /g, '-');
        product_path = product_path.replace(/[^\w\s]/gi, '-');
        producto = {
        ...producto,
        pathname: `/${producto.negocio.dominio_okki}/productos/${producto.id}/${product_path}`,
        previous_path: `${window.location.pathname}`
        }
        var images = [];
        images.push(producto.principal_image)
        producto.images.map((image, index) => {
        images.push(image);
        })
        producto.images = images;
        sessionStorage.setItem('previous_path', producto.previous_path);
        sessionStorage.setItem('product_path', producto.pathname)
        setCurrentProduct(producto);
        const tags = [];
        JSON.parse(producto.tags).map(tag => {
        tags.push(tag.value)
        })
        setTags(tags);
        toggle();
    }

    return(
        <>
        <div className={producto.status_id == 2 ? "homeGrid-item" : "homeGrid-item deactivatedProduct"} key={producto.id} >
            <div className="homeGrid-item__top">
                <img className="homeGrid-item__cover"  src={producto.principal_image ? `${bucket_route}${producto.principal_image.path}` : itemThumb} thumbnail={producto.principal_image ? `${bucket_route}${producto.principal_image.path}` : itemThumb} />
                <div className="homeGrid-item__coverOverlay">
                <a className="homeGrid-item__coverOverlay-frame"  onClick={() => { showProduct(producto) }} />
                    <a href={`https://wa.me/52${producto.negocio.whatsap}?text=${producto.mensaje_privado ? producto.mensaje_privado.replace(/ /g, '%20') : 'Estoy%20interesad@%20en%20el%20producto'}%20${producto.nombre}`} className="homeGrid-item__coverOverlay-link" target="_blank">
                        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="21" cy="21" r="21" fill="black" fill-opacity="0.6" />
                            <path id="bagBg" d="M29.6739 27.7172C29.846 28.922 28.9111 30 27.694 30H14.306C13.0889 30 12.154 28.922 12.3261 27.7172L13.7547 17.7172C13.8954 16.7319 14.7393 16 15.7346 16H26.2654C27.2607 16 28.1046 16.7319 28.2453 17.7172L29.6739 27.7172Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path id="zipper" d="M25 20C25 22.2 23.2 24 21 24C18.8 24 17 22.2 17 20" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path id="handle" d="M17 16C17 13.8 18.8 12 21 12C23.2 12 25 13.8 25 16" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </a>
                </div>
            </div>
            <div className="homeGrid-item__info">
                {disabled_logo ?
                null
                :
                    <div className={producto.negocio.pro ? "homeGrid-item__iconPro" : null}>
                    <img data-tip={`${producto.negocio.nombre}`} className="homeGrid-item__icon" src={producto.negocio.imagen_url !== '' ? `${bucket_route}${producto.negocio.imagen_url}` : itemProfile} onClick={() => { window.location.replace(`/${producto.negocio.dominio_okki}`) }} />

                    {/* <img className="settings-form__thumbNav" src={producto.negocio ? `${route}/storage/${this.state.negocio.imagen_url}` : profileThumb} alt="Perfil" onClick={() => { window.location.replace(`/${this.state.negocio.dominio_okki}`) }} /> */}
                    </div>
                }
            <div>

            <ReactTooltip className="tooltipStyle" place="top" effect="solid" />
                {producto.precio_privado ?  
                        <a className="homeGrid-item__name homeGrid-item__nameOnly" > {producto.nombre} </a> 
                        :
                        <a className="homeGrid-item__name" > {producto.nombre} </a>
                    }
                    <ProductPrice producto={producto} />
            </div>
            </div>
        </div>
          <ModalProductos
          isShowing={isShowing}
          hide={toggle}
          flags={flags}
          flagsShowing={flagsShowing}
          share={share}
          shareShowing={shareShowing}
          image_loaded={image_loaded}
          loadImage={loadImage}
          popupShare={popupShare}
          popup={popup}
          producto={currentProduct}
          tags={tags}
      />
      </>
    );  
}

export default ProductCard

