export const styles = theme => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: "90%",
    background: '#fff',
    [theme.breakpoints.up('sm')]: {
      height: 540,
    },
  },
  cropButton: {
    flexShrink: 0,
    background: "#000",
    textTransform: "none",
    boxShadow: "none",
    fontSize: 14,
    letterSpacing: 0,
    borderRadius: 6
  },
  controls: {
    padding: "20px 40px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    justifyContent: "space-between",
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.up('xs')]: {
      padding: 20
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    maxWidth: 230,
    [theme.breakpoints.down('xs')]: {
      maxWidth: "none"
    },
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
})
