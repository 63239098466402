import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import venderThumb from "../../images/vender-1.svg";
import selectArrow from "../../images/selectArrow.svg";
import profileTable from "../../images/profileTable.svg";
import profileThumb from "../../images/profileThumb.png";
import itemThumb from "../../images/itemThumb.svg";
import itemProfile from "../../images/itemProfile.svg";
import MaterialTable from "material-table";

import Modal from "react-modal";
import Sidebar from "../Sidebar";
import { getCupones, postCupon, deleteCupon, putCupon, loggedIn } from "../API Functions";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        padding: "50px 40px",
        border: "none",
        borderRadius: 12,
    },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Cupones() {
    var subtitle;


    const [modalIsOpenDelete, setIsOpenDelete] = React.useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpenEdit, setIsOpenEdit] = React.useState(false);

    const [cupones, setCupones] = useState([]);

    const [detalle, setDetalle] = useState("");
    const [detalle_edit, setDetalleEdit] = useState("");
    const [detalleError, setDetalleError] = useState("");

    const [porcentaje, setPorcentaje] = useState("");
    const [porcentaje_edit, setPorcentajeEdit] = useState("");
    const [porcentajeError, setPorcentajeError] = useState("");


    const [caducidad, setCaducidad] = useState("");
    const [caducidad_edit, setCaducidadEdit] = useState("");
    const [caducidadError, setCaducidadError] = useState("");


    const [cupon_delete, setCuponDelete] = useState("");
    const [cupon_edit, setCuponEdit] = useState("");


    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loggedIn();
        setLoading(true);
        loadCupones();
    }, [])


    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function openModalEdit(ciudad) {
        setCuponEdit(ciudad.id);
        setDetalleEdit(ciudad.nombre);
        setIsOpenEdit(true);
    }

    function closeModalEdit() {
        setIsOpenEdit(false);
        setDetalleEdit("");
        setCuponEdit("");
    }

    function handleEdit() {
        const data = {
            id: cupon_edit,
            nombre: detalle_edit,
            porcentaje: porcentaje_edit,
            caducidad: caducidad_edit
        }
        putCupon(data).then(res => {
            setLoading(true);
            closeModalEdit();
            loadCupones();
        })
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function validateFields() {
        let detalleError = ""
        let porcentajeError = ""
        let caducidadError = ""


        if (detalle == "") {
            detalleError = "Requerido"
        }
        if (porcentaje == "") {
            porcentajeError = "Requerido"
        }
        if (caducidad == "") {
            caducidadError = "Requerido"
        }

        if (detalleError || porcentajeError || caducidadError) {
            setDetalleError(detalleError);
            setPorcentajeError(porcentajeError);
            setCaducidadError(caducidadError);
            return false
        } else {
            setDetalleError(detalleError);
            setPorcentajeError(porcentajeError);
            setCaducidadError(caducidadError);
            return true
        }
    }

    function handleCreate() {
        const data = {
            codigo: detalle,
            porcentaje: porcentaje,
            caducidad: caducidad,
        }
        const isValid = validateFields()
        if (isValid) {
            postCupon(data).then(res => {
                setLoading(true);
                closeModal();
                loadCupones();
            })
        }
    }

    function openModalDelete(id_ciudad) {
        setCuponDelete(id_ciudad);
        setIsOpenDelete(true);
    }

    function closeModalDelete() {
        setCuponDelete("");
        setIsOpenDelete(false);
    }

    function handleDelete() {
        deleteCupon(cupon_delete).then(res => {
            setLoading(true);
            closeModalDelete();
            loadCupones();
        })
    }

    function loadCupones() {
        setLoading(true);
        getCupones().then(res => {
            if (!res) {
                //window.location.replace("/login")
            } else {
                setCupones(res);
            }
            setTimeout(() => {
                setLoading(false);
            }, 700);
        })
    }

    return (
        <div className="backoffice">
            <Sidebar />
            <div className="backofficeContainer">
                <div className="backofficeContainer-header">
                    <div className="backofficeContainer-header__about">
                        <h3 className="backofficeContainer-header__title">Cupones</h3>
                        <h3 className="backofficeContainer-header__titleAlt">{cupones ? cupones.length : 0}</h3>
                    </div>
                </div>
                <div className="backofficeContainer-headerMin">
                    <div>
                    </div>
                    <div className="backofficeContainer-headerMin__item">
                        <a className="button" onClick={openModal}>
                            Agregar
      </a>
                    </div>
                </div>
                <div className="backofficeTable">
                    <hr />
                    {/*<table>
      <thead>
       <tr>
        <th>Categoría</th>
        <th style={{ textAlign: "center" }}>Negocios</th>
        <th style={{ textAlign: "center" }}>Eliminar</th>
       </tr>
      </thead>
      <tbody>
       <tr>
        <td>Hamburguesa</td>
        <td style={{ textAlign: "center" }}>31</td>
        <td>
         <div className="approvedBtns">
          <a>
           <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M14.2422 5.75735L5.75691 14.2426"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
            <path
             d="M14.2431 14.2426L5.75781 5.75735"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
           </svg>
          </a>
         </div>
        </td>
       </tr>
      </tbody>
     </table>*/}
                    <MaterialTable
                        columns={[
                            { title: "Codigo", field: "cupon" },
                            { title: "Porcentaje", field: "procentaje" },
                            { title: "Caducidad", field: "caducidad" },
                            { title: "Usos", field: "usos" },
                            { title: "Status", field: "status" },
                            { title: "", field: "actions" }
                        ]}
                        title={"Solicitudes"}
                        data={cupones.map((cupon, index) => ({
                            cupon: cupon.codigo,
                            porcentaje: cupon.caducidad,
                            caducidad: cupon.caducidad,
                            usos: cupon.caducidad,
                            status: cupon.status,
                            actions: (
                                <div className="approvedBtns">
                                    <a onClick={() => { openModalDelete(cupon.id) }}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M14.2422 5.75735L5.75691 14.2426"
                                                stroke="#ACACB5"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M14.2431 14.2426L5.75781 5.75735"
                                                stroke="#ACACB5"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </a>
                                    <a onClick={() => { openModalEdit(cupon) }}>
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                </div>
                            )
                        }))}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30, 50],
                            emptyRowsWhenPaging: false,
                            paging: cupones.length > 10 ? true : false,
                            showTitle: false,
                            search: true
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage:

                                    loading ?
                                        <>
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{
                                                    marginRight:
                                                        "5px"
                                                }}
                                            />

                                            <span>Cargando...</span>
                                        </>
                                        :
                                        'No hay datos para mostrar.'

                            }
                        }}
                    />
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Agregar Cupon
    </p>
                <br />
                <div className="modalInput">
                    <label>Codigo</label>
                    <input
                        type="text"
                        class="input registerInput"
                        placeholder="PROMOKKI"
                        onChange={e => setDetalle(e.target.value)}
                    />

                    <label>Porcentaje</label>
                    <select
                        className="input registerInput"
                        name="descuento"
                        onChange={e => setPorcentaje(e.target.value)}
                        required
                    >
                        <option value='' selected>Selecciona</option>
                        <option value="5">5%</option>
                        <option value="10">10%</option>
                        <option value="20">20%</option>
                        <option value="30">30%</option>
                        <option value="40">40%</option>
                        <option value="50">50%</option>
                        <option value="60">60%</option>
                        <option value="70">70%</option>
                        <option value="80">80%</option>
                        <option value="90">90%</option>
                        <option value="100">100%</option>
                    </select>

                    <label>Caducidad</label>
                    <input
                        type="text"
                        class="input registerInput"
                        placeholder="0"
                        onChange={e => setCaducidad(e.target.value)}
                    />

                </div>

                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModal}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleCreate}>
                        Agregar
     </button>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpenDelete}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalDelete}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Deseas eliminar esta Ciudad?
    </p>
                <br />

                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModalDelete}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleDelete}>
                        Eliminar
     </button>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpenEdit}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalEdit}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Editar Cupon
    </p>
                <br />
                <div className="modalInput">
                    <label>Nombre</label>
                    <input
                        type="text"
                        class="input registerInput"
                        placeholder="Mexicana"
                        value={detalle_edit}
                        onChange={e => setDetalleEdit(e.target.value)}
                    />
                </div>
                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModalEdit}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleEdit}>
                        Editar
     </button>
                </div>
            </Modal>
        </div>
    );
}

export default Cupones;
