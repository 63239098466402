import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import profileThumb from "../images/profileThumb.png";
import Navbar from "./Navbar";

import Select, { components } from "react-select";
import { getCiudadesByEstado, getCurrentNegocio, getEstados, getNegocios, putNegocios } from "./API Functions";
import Settings3 from "./Settings-3";
import { func } from "prop-types";

import NumberFormat from 'react-number-format';
import { data } from "isotope-layout";
import SettingsNegocio from "./Settings-Negocio";


const options = [
    { value: null, label: "-" },
    { value: true, label: "Sí" },
    { value: false, label: "No" },
];

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

function Settings2(props) {
    const [Loading, setLoading] = useState(false);

    const [ciudades, setCiudades] = useState([]);
    const [estados, setEstados] = useState([]);
    const [negocio, setNegocio] = useState([]);

    const [direccion, setDireccion] = useState("");
    const [zona, setZona] = useState("");
    const [precio_envio, setPrecioEnvio] = useState(0);
    const [ciudad, setCiudad] = useState([]);
    const [estado, setEstado] = useState([]);
    const [envio, setEnvio] = useState([]);
    const [codigo_postal, setCodigoPostal] = useState("");

    const [data_loaded, setDataLoaded] = useState(false);

    const [direccionError, setDireccionError] = useState("");
    const [zonaError, setZonaError] = useState("");
    const [precio_envioError, setPrecioEnvioError] = useState("");
    const [ciudadError, setCiudadError] = useState("");
    const [envioError, setEnvioError] = useState("");
    const [estadoError, setEstadoError] = useState("");
    const [codigo_postalError, setCodigoPostalError] = useState("");

    const [settings3, setSettings3] = useState(false);

    const [settingsEnvio, setSettingsEnvio] = useState(false);

    useEffect(() => {
        // loadCiudades();
        if (props.negocio) {
            const negocio = props.negocio;
            console.log(negocio)
            negocio.envios ? negocio.envios[0].id == 1 ? setEnvio(options[1]) : setEnvio(options[2]) : setEnvio(options[0]);
            if (negocio.nuevo && negocio.direccion == "null" && negocio.zona == "null") {
                setDireccion("");
                setZona("");
                setPrecioEnvio("");
                setCodigoPostal("");
                const data = {
                    estado_id: 1
                }
                loadCiudades(data, negocio.ciudad_id);
                loadEstados(negocio.estado_id);
            } else {
                setDireccion(negocio.direccion !== "null" ? negocio.direccion : "");
                setZona(negocio.zona);
                setPrecioEnvio(negocio.precio_envio);
                setCodigoPostal(negocio.codigo_postal == 0 ? "" : negocio.codigo_postal);
                const data = {
                    estado_id: negocio.estado_id
                }
                loadEstados(negocio.estado_id);
                loadCiudades(data, negocio.ciudad_id);
            }
            setNegocio(negocio);
        } else {
            getCurrentNegocio().then(res => {
                if (res) {
                    const negocio = res;

                    negocio.envios ? negocio.envios[0].id == 1 ? setEnvio(options[1]) : setEnvio(options[2]) : setEnvio(options[0]);
                    if (negocio.nuevo && negocio.direccion == "null" && negocio.zona == "null") {
                        setDireccion("");
                        setZona("");
                        setPrecioEnvio("");
                        setCodigoPostal("");
                        const data = {
                            estado_id: negocio.estado_id
                        }
                        loadEstados(negocio.estado_id);
                        loadCiudades(data, negocio.ciudad_id);
                    } else {
                        setDireccion(negocio.direccion !== "null" ? negocio.direccion : "");
                        setZona(negocio.zona);
                        setPrecioEnvio(negocio.precio_envio);
                        setCodigoPostal(negocio.codigo_postal);
                        const data = {
                            estado_id: negocio.estado_id
                        }
                        loadEstados(negocio.estado_id);
                        loadCiudades(data, negocio.ciudad_id);
                    }
                    setNegocio(negocio);
                }
            })

        }


    }, [])

    function loadCiudades(data, ciudad_id) {
        getCiudadesByEstado(data).then(res => {
            if (res) {
                const ciudades = []
                res.map((ciudad, index) => {
                    ciudad = {
                        ...ciudad,
                        value: ciudad.id,
                        label: ciudad.nombre
                    }
                    ciudades.push(ciudad)
                    if (ciudad.id == ciudad_id) {
                        setCiudad(ciudad);
                        console.log(ciudad)
                    }
                })
                setCiudades(ciudades);

            } else {
                // window.location.replace("/login")
            }
        })
    }

    function loadEstados(estado_id) {
        getEstados().then(res => {
            if (res) {
                const estados = []
                res.map((estado, index) => {
                    estado = {
                        ...estado,
                        value: estado.id,
                        label: estado.name
                    }
                    estados.push(estado)
                    if (estado.id == estado_id) {
                        setEstado(estado);
                        console.log(estado)

                    }
                })
                setEstados(estados);
            } else {
                window.location.replace("/login")
            }
        })
    }

    function validateCPLength(value) {
        var msg = ""
        if (value.length > 5) {
            msg = "El número debe ser maximo 5 dígitos";
        }
        setCodigoPostalError(msg);
        return msg
    }

    function validateFields() {
        let direccionError = "";
        let zonaError = "";
        let precio_envioError = "";
        let ciudadError = "";
        let envioError = "";
        let estadoError = "";
        let codigo_postalError = "";
        /*
        if (direccion == "") {
            direccionError = "Requerido";
        }
        */
        if (zona == "") {
            zonaError = "Requerido";
        }

        if (ciudad == "") {
            ciudadError = "Selecciona una opción";
        }
        if (estado == "") {
            estadoError = "Selecciona una opción";
        }

        if (envio.value == null) {
            envioError = "Selecciona una opción";
        } else if (envio.value) {
            if (precio_envio == "") {
                precio_envioError = "Requerido";
            }
        }
        if (codigo_postal == "") {
            codigo_postalError = "Requerido";
        } else {
            codigo_postalError = validateCPLength(codigo_postal);
        }

        if (
            direccionError ||
            zonaError ||
            precio_envioError ||
            ciudadError ||
            envioError ||
            estadoError ||
            codigo_postalError
        ) {
            setDireccionError(direccionError);
            setZonaError(zonaError);
            setPrecioEnvioError(precio_envioError);
            setCiudadError(ciudadError);
            setEnvioError(envioError);
            setCodigoPostalError(codigo_postalError);
            setEstadoError(estadoError);
            return false;
        } else {
            setDireccionError(direccionError);
            setZonaError(zonaError);
            setPrecioEnvioError(precio_envioError);
            setCiudadError(ciudadError);
            setEnvioError(envioError);
            setCodigoPostalError(codigo_postalError);
            setEstadoError(estadoError);
            return true;
        }
    }

    function handleEnvioOption(selectedOption) {
        setEnvio(selectedOption);
    }

    function handleCiudadOption(selectedOption) {
        setCiudad(selectedOption);
    }
    function handleEstadoOption(selectedOption) {
        setEstado(selectedOption);
        const data = {
            estado_id: selectedOption.id
        }
        loadCiudades(data);
    }

    function handleSubmit() {
        setLoading(true)
        const data_content = {
            id: negocio.id,
            tags_ids: negocio.tags_ids,
            nombre: negocio.nombre,
            website: negocio.website,
            whatsap: negocio.whatsap,
            facebook: negocio.facebook,
            telefono: negocio.whatsap,
            categoria_id: negocio.categoria_id,
            descripcion: negocio.descripcion,
            dominio_okki: negocio.dominio_okki,
            rango_id: negocio.rango_id,
            instagram: negocio.instagram,
            precio_envio: precio_envio > 0 ? precio_envio : 0,
            persona_contacto: negocio.persona_contacto,
            tipos: [negocio.tipos[0].id],
            //EDITED FIELDS IN VIEW
            envios: envio.value ? [1] : [2],
            ciudad_id: ciudad.id,
            zona: zona,
            codigo_postal: codigo_postal,
            direccion: direccion,
            estado_id: estado.id
        }
        const isValid = validateFields()
        if (isValid) {
            const data = {
                id: negocio.id,
                data: data_content
            }
            putNegocios(data).then(res => {
                if (res) {
                    if (res.nuevo) {
                        localStorage.setItem('negocio', JSON.stringify(res))
                        setNegocio(res);
                        changeSettings(res);
                    } else {
                        localStorage.setItem('negocio', JSON.stringify(res))
                        window.location.reload()
                    }
                }
            })
        }
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    function changeSettings(negocio) {
        setSettings3(true);
    }

    function validateNumberLength(value) {
        var price = parseFloat(value.replace('$', ''));
        console.log(value, price);
        if (value.length < 6) {
            setPrecioEnvio(price)
        } else {
            setPrecioEnvioError("No puede ser mayor de 6 digitos.")
            setTimeout(() => {
                setPrecioEnvioError("");
            }, 2000);
        }
    }


    return (
        <>
            {settings3 ?
                <Settings3 negocio={negocio} />
                :
                settingsEnvio ?
                    <SettingsNegocio negocio={negocio} />
                    :
                    ciudades.length > 0 ?
                        <>
                            {negocio.nuevo ?
                                <div className="four columns settings-form__sidebar onlyDesktop" style={{ marginLeft: 0 }}>

                                    <div className="settings-header settings-headerModal">
                                        <div className="settings-header__breadcrumb settings-header__breadcrumb-title">
                                            <h3 className="settings-header__title">Configuración</h3>

                                            <hr className="settingsDivider" />
                                        </div>
                                    </div>
                                    <ul className="settings-form__menu settings-form__menuList">
                                        <li>
                                            <Link className="">
                                                Perfil
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Negocio
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="active">
                                                Dirección y Envíos
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Redes Sociales
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Cuenta
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Plan
                                    </Link>
                                        </li>
                                        {/* <li>
                        <Link to="/pro" target="_blank" activeClassName="active">
                          PRO
                                    </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: `/${this.state.negocio.dominio_okki}` }} activeClassName="active">
                          Mi perfil
                                    </Link>
                      </li> */}
                                    </ul>

                                </div>
                                : null}
                            <div className="eight columns settings-form__container">
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>
                                            Google Maps{" "}
                                            {/* <span style={{ color: "#999" }}>
                                    — Tincidunt bibendum mauris non laoreet aliquam pharetra, urna quis
                                    consequat.
                                </span> */}
                                        </label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <input
                                            className={direccionError ? "settings-form__input inputError" : "settings-form__input"}
                                            type="text"
                                            placeholder="Google Maps URL"
                                            value={direccion}
                                            onChange={e => setDireccion(e.target.value)}
                                        />
                                        <div className="fieldError">{direccionError}</div>
                                    </div>
                                </div>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>Estado</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">

                                        <Select
                                            classNamePrefix="inputSelect"
                                            className="inputSelect settings-form__input"
                                            options={estados}
                                            value={estado}
                                            onChange={handleEstadoOption}
                                            components={{ DropdownIndicator }}
                                            placeholder="Estado"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 6,
                                                colors: {
                                                    ...theme.colors,
                                                    primary50: "#ccc",
                                                    primary25: "#F6F6F6",
                                                    primary: "black",
                                                },
                                            })}
                                            styles={{
                                                dropdownIndicator: (provided, state) => ({
                                                    ...provided,
                                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                                })
                                            }}
                                        />
                                        <div className="fieldError">{estadoError}</div>
                                    </div>
                                </div>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>Localidad</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">

                                        <Select
                                            classNamePrefix="inputSelect"
                                            className="inputSelect settings-form__input"
                                            options={ciudades}
                                            value={ciudad}
                                            onChange={handleCiudadOption}
                                            components={{ DropdownIndicator }}
                                            placeholder="Ciudad"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 6,
                                                colors: {
                                                    ...theme.colors,
                                                    primary50: "#ccc",
                                                    primary25: "#F6F6F6",
                                                    primary: "black",
                                                },
                                            })}
                                            styles={{
                                                dropdownIndicator: (provided, state) => ({
                                                    ...provided,
                                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                                })
                                            }}
                                        />
                                        <div className="fieldError">{ciudadError}</div>
                                    </div>
                                </div>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>Zona</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <input
                                            className={zonaError ? "settings-form__input inputError" : "settings-form__input"}
                                            type="text"
                                            placeholder="Zona"
                                            value={zona}
                                            onChange={e => setZona(e.target.value)}
                                        />
                                        <div className="fieldError">{zonaError}</div>
                                    </div>
                                </div>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>Código Postal</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <input
                                            className={codigo_postalError ? "settings-form__input inputError" : "settings-form__input"}
                                            type="number"
                                            placeholder="00000"
                                            value={codigo_postal}
                                            onChange={(e) => {
                                                setCodigoPostal(e.target.value)
                                                validateCPLength(e.target.value)
                                            }}
                                        />
                                        <div className="fieldError">{codigo_postalError}</div>
                                    </div>
                                </div>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>¿Entregas a domicilio?</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <Select
                                            classNamePrefix="inputSelect"
                                            className="inputSelect settings-form__input"
                                            options={options}
                                            value={envio}
                                            onChange={handleEnvioOption}
                                            components={{ DropdownIndicator }}
                                            placeholder="Si"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 6,
                                                colors: {
                                                    ...theme.colors,
                                                    primary50: "#ccc",
                                                    primary25: "#F6F6F6",
                                                    primary: "black",
                                                },
                                            })}
                                            styles={{
                                                dropdownIndicator: (provided, state) => ({
                                                    ...provided,
                                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                                })
                                            }}
                                        />
                                        <div className="fieldError">{envioError}</div>
                                    </div>
                                </div>
                                {envio.value ?
                                    <div className="settings-form__row">
                                        <div className="settings-form__column settings-form__columnLabel">
                                            <label>Costo de Envío</label>
                                        </div>
                                        <div className="settings-form__column settings-form__columnField">

                                            <NumberFormat
                                                value={precio_envio}
                                                isNumericString={false}
                                                isAllowed={(values) => {
                                                    const { formattedValue, floatValue } = values;
                                                    return formattedValue === "" || floatValue <= 99999;
                                                }}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    // formattedValue = $2,223
                                                    // value ie, 2223
                                                    setPrecioEnvio(value)
                                                    console.log(value, "test")
                                                }}
                                                removeFormatting={true} className="settings-form__input"
                                                decimalScale={2} displayType={'input'}
                                                thousandSeparator={true} prefix={'$'} />
                                            {/* 
                            <input
                                className="settings-form__input"
                                type="number"
                                placeholder="$100"
                                max="99999"
                                pattern="[0-9]"
                                maxlength="5"
                                prefix="$"
                                value={
                                    precio_envio
                                }
                                onChange={e => validateNumberLength(e.target.value)}
                            /> 
                                                        */}

                                            <div className="fieldError">{precio_envioError}</div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                {negocio.nuevo ?
                                    <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                                        <a className="button button-alphaAlt" onClick={() => { setSettingsEnvio(true) }}>Regresar</a>
                                        <a className="button" onClick={handleSubmit}>
                                            {Loading && (
                                                <i className="" style={{ marginRight: "5px" }} />
                                            )}
                                            {Loading && (
                                                'Guardando...'
                                            )}
                                            {!Loading && (
                                                negocio.nuevo ?
                                                    'Continuar'
                                                    :
                                                    'Guardar'
                                            )}
                                        </a>
                                    </div>
                                    :
                                    <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                                        <a>{" "}</a>
                                        <a className="button" onClick={handleSubmit}>
                                            {Loading && (
                                                <i className="" style={{ marginRight: "5px" }} />
                                            )}
                                            {Loading && (
                                                'Guardando...'
                                            )}
                                            {!Loading && (
                                                negocio.nuevo ?
                                                    'Continuar'
                                                    :
                                                    'Guardar'
                                            )}
                                        </a>
                                    </div>
                                }
                            </div>
                        </>

                        :
                        null
            }
        </>
    );
}

export default Settings2;
