import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import useGTM from '@elgorditosalsero/react-gtm-hook';

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import useMightyMouse from "react-hook-mighty-mouse";

function openNav() {
  const navigationWindow = document.getElementById("myNav");
  const navigationWindowBox = document.getElementById("myBox");
  navigationWindow.style.width = "100%";
  navigationWindowBox.style.opacity = "1";
}

function closeNav() {
  const navigationWindow = document.getElementById("myNav");
  const navigationWindowBox = document.getElementById("myBox");
  navigationWindow.style.width = "0%";
  navigationWindowBox.style.opacity = "0";
}

function ThankYou() {
  const [headerStyle, setHeaderStyle] = useState({
    transition: "all 300ms ease-in",
  });

  const { init } = useGTM()

  const [tiredness, setTiredness] = useState(0);

  const {
    selectedElement: {
      position: { angle: angleLeftEye },
    },
  } = useMightyMouse(true, 'left-eye', { x: 20, y: 20 });
  const {
    selectedElement: {
      position: { angle: angleRightEye },
    },
  } = useMightyMouse(true, 'right-eye', { x: 20, y: 20 });

  const styleLeftEye = {
    transform: `rotate(${angleLeftEye}deg)`,
    transition: `all ${tiredness}s ease`
  };
  const styleRightEye = {
    transform: `rotate(${angleRightEye}deg)`,
    transition: `all ${tiredness}s ease`
  };

  useEffect(() => {
    init({ id: 'GTM-ID' })
  }, []);


  return (
    <div>
      <div className="customLand" id="customLand">
        <Fade duration={1500} delay={1000}>
          <a onClick={openNav} className="button button-alpha customLand-button">
            Beneficios
    </a>
        </Fade>
        <Fade cascade delay={100}>
          <div className="customLand-formThanks">
            <h5>
              Recibimos tus datos y muy pronto nos leeremos por correo. Mientras tanto, cuéntales la buena noticia a más negocios en
              {" "}
              <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fokki.co" target="_blank">
                Facebook
              </a>
              {" o "}
              <a href="https://twitter.com/intent/tweet?text=Vende%20directamente%20a%20tus%20clientes%20sin%20pagar%20comisiones%20https%3A%2F%2Fokki.co" target="_blank">
                Twitter
              </a>
            </h5>
          </div>
        </Fade>
        <div className="customLand-cover">
          <svg className="customLand-guyMobile" width="281" height="391" viewBox="0 0 281 391" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M182.176 66.2617C182.467 69.3781 181.691 75.3187 176.262 78.143C168.506 82.2332 160.266 77.8508 150.959 93.4328C141.652 109.015 132.733 137.452 132.733 137.452" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M201.371 63.2427C201.371 63.2427 198.463 74.1501 204.958 76.5847C211.453 79.0194 226.189 84.2783 236.369 92.0693C245.288 98.9838 271.269 122.941 278.443 133.264C285.327 143.198 248.39 166.084 230.94 172.316C228.71 173.096 227.74 175.725 228.904 177.77L229.001 177.868" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.558 75.4162C179.558 75.4162 191.676 79.3117 203.019 75.2214" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M251.589 134.53C251.589 134.53 229.485 119.435 222.699 111.45C222.408 111.06 221.729 111.352 221.729 111.839L222.796 154.884" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M226.674 181.763C227.74 184.295 230.552 186.437 233.46 190.723C236.369 195.008 233.169 194.618 233.169 194.618C230.649 193.449 222.796 188.19 218.434 179.426C214.168 170.661 216.01 163.454 219.791 158.195C223.572 152.936 254.304 132.777 254.013 132.972" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M233.363 190.723C233.363 190.723 238.114 193.742 237.726 190.138C237.338 187.217 233.751 182.737 231.23 180.594" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M235.302 185.172C235.302 185.172 238.404 187.022 239.471 186.048C240.44 185.074 240.149 183.126 233.46 177.381" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M157.648 178.354C157.648 178.354 175.68 181.374 191.967 181.276C208.254 181.179 218.337 179.426 218.337 179.426" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M233.751 171.245C233.751 171.245 234.042 173.29 235.69 175.043C237.241 176.796 239.762 178.744 239.859 180.399C239.956 182.055 237.532 181.276 237.532 181.276" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M247.323 101.321C247.323 101.321 247.323 109.209 233.751 120.506" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M138.744 120.409C138.744 120.409 143.979 131.024 158.812 136.186" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M229.389 192.378C229.389 192.378 233.267 249.739 233.363 281.39C233.46 313.041 232.976 363.39 234.43 371.376C234.43 371.376 245.482 373.324 264.192 383.647C265.356 384.329 265.162 386.082 263.805 386.374C259.539 387.25 249.069 387.64 223.184 383.355C223.184 383.355 215.525 382.381 215.525 377.901L214.847 366.604C214.168 359.495 207.091 278.469 205.637 264.737C204.377 252.856 197.106 208.35 195.167 196.566C195.07 196.176 194.488 196.176 194.488 196.566C193.809 206.305 191.677 237.274 191.386 248.766C191.095 261.426 186.248 360.274 185.472 375.953C185.278 379.167 183.533 382.186 180.625 383.744C177.522 385.303 174.129 385.595 174.129 385.595C148.632 389.49 137.968 389.588 133.509 389.101C132.055 388.906 131.667 387.056 132.927 386.276C150.377 375.564 165.404 375.174 165.404 375.174C165.404 375.174 161.332 313.041 159.006 276.716C157.358 251.785 157.164 214.388 157.164 193.45C157.164 184.393 157.455 175.336 157.939 166.279C158.715 154.008 159.587 135.504 158.909 128.687C158.424 124.207 158.424 117.195 158.618 110.67" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M133.703 139.692C133.703 139.692 121.972 115.053 116.834 103.269C115.574 100.445 114.895 97.4256 114.701 94.3092C114.604 91.6798 114.216 88.466 113.15 86.6156C111.502 83.5966 105.297 76.6821 101.516 74.7344C100.159 74.0527 98.5111 75.0265 98.6081 76.5847C98.6081 76.8769 98.705 77.2665 98.802 77.7534" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M96.7661 100.25C96.7661 100.25 101.613 110.183 105.879 126.642C110.339 143.685 119.936 165.694 132.733 167.934C145.53 170.174 158.715 136.088 158.715 136.088" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M79.1219 87.7842C79.1219 87.7842 75.0502 92.5562 76.8922 95.1856C78.7341 97.8151 83.2906 90.6084 83.2906 90.6084" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M82.7088 91.4849C82.7088 91.4849 79.4126 95.283 80.8668 97.3281C82.321 99.3733 85.908 93.7248 85.908 93.7248" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M85.617 94.2119C85.617 94.2119 83.4842 98.497 85.3262 99.6656C87.1681 100.834 89.9796 96.9388 89.9796 96.9388" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M89.495 97.5229C89.495 97.5229 87.3622 100.639 90.2706 102.49C92.5973 103.95 94.8271 99.9576 94.8271 99.9576" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M76.9889 80.7724L86.1018 71.9102C87.2652 70.8389 89.0102 70.7415 90.2705 71.618L110.241 86.4209C111.793 87.5895 111.986 89.9268 110.532 91.2902L101.419 100.25C100.256 101.321 98.511 101.516 97.2507 100.542L77.2797 85.7392C75.7286 84.4731 75.5347 82.1358 76.9889 80.7724Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M194.003 41.1358C198.126 41.1358 201.468 37.7785 201.468 33.637C201.468 29.4955 198.126 26.1382 194.003 26.1382C189.88 26.1382 186.538 29.4955 186.538 33.637C186.538 37.7785 189.88 41.1358 194.003 41.1358Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M175.777 41.1358C179.9 41.1358 183.242 37.7785 183.242 33.637C183.242 29.4955 179.9 26.1382 175.777 26.1382C171.654 26.1382 168.312 29.4955 168.312 33.637C168.312 37.7785 171.654 41.1358 175.777 41.1358Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M184.212 26.1382C184.212 26.1382 180.722 38.1168 182.37 44.6418C182.66 45.8104 183.921 46.4921 185.084 46.2C186.538 45.8104 188.38 45.2261 188.671 45.1287" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M205.152 44.6418C205.152 44.6418 203.31 61.7819 200.595 64.5088C197.59 67.5278 194.197 69.5729 185.278 67.8199C176.359 66.1644 172.578 43.9601 172.287 33.637C172.19 30.0337 172.19 27.0147 172.19 24.1904" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M186.538 34.3171C186.538 34.3171 185.084 33.4443 183.242 34.3171" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M207.188 34.3188C207.188 34.3188 209.03 28.7677 213.78 32.1763C218.53 35.5849 214.847 48.6348 205.055 45.7131" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M212.229 34.8057C212.229 34.8057 208.836 35.0978 207.866 40.4541" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M201.371 32.663L209.321 31.4944" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M194.391 22.4374C194.391 22.4374 192.549 25.4564 188.283 24.3852C184.018 23.3139 184.115 20.3923 184.89 19.808C185.666 19.2236 186.054 23.2165 182.757 23.7034C179.461 24.1904 175.777 22.34 176.165 18.9315C176.165 18.9315 174.42 25.1643 170.639 23.9956C168.797 23.4113 168.894 21.6583 169.476 20.0027C169.476 20.0027 168.119 22.0479 166.858 22.0479C165.598 22.0479 168.409 14.3543 174.517 13.4778" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M176.262 11.6268C176.262 11.6268 173.353 10.166 175.389 6.66003C175.389 6.66003 178.201 12.6007 184.696 8.12084C184.696 8.12084 184.018 3.25147 188.768 2.47238C192.937 1.79066 195.748 3.25147 198.366 5.58877" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M201.177 6.6606C201.177 6.6606 202.34 2.76511 205.249 1.69385C205.249 1.69385 200.401 7.63447 208.06 10.2639C208.06 10.2639 211.938 6.75799 216.494 9.29006C216.494 9.29006 210.581 10.7509 211.162 12.3091C211.162 12.3091 216.301 12.0169 217.367 16.1072C218.143 18.9314 214.362 19.2236 212.035 19.0288C212.035 19.0288 221.051 20.7818 216.01 29.2545" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M183.242 50.6799C183.242 50.6799 187.508 51.3616 192.355 49.5112" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M192.161 35.0977C193.178 35.0977 194.003 34.2693 194.003 33.2473C194.003 32.2254 193.178 31.397 192.161 31.397C191.144 31.397 190.319 32.2254 190.319 33.2473C190.319 34.2693 191.144 35.0977 192.161 35.0977Z" fill="#081027" />
            <path d="M178.492 35.0977C179.509 35.0977 180.334 34.2693 180.334 33.2473C180.334 32.2254 179.509 31.397 178.492 31.397C177.475 31.397 176.65 32.2254 176.65 33.2473C176.65 34.2693 177.475 35.0977 178.492 35.0977Z" fill="#081027" />
            <path d="M1.09692 291.843V300.987C1.09692 310.52 30.2845 318.204 66.2824 318.204C102.28 318.204 131.468 310.52 131.468 300.987V291.843" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M115.804 312.076V361.2C115.804 364.118 113.469 366.55 110.453 366.55C107.535 366.55 105.102 364.216 105.102 361.2V314.703" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.7612 312.076V361.2C16.7612 364.118 19.0962 366.55 22.1123 366.55C25.0311 366.55 27.4634 364.216 27.4634 361.2V314.703" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M37.0952 316.356V341.842C37.0952 344.76 39.4302 347.192 42.4463 347.192C45.365 347.192 47.7973 344.858 47.7973 341.842V317.524" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M75.1365 318.01V341.745C75.1365 344.663 77.4715 347.095 80.4875 347.095C83.4063 347.095 85.8386 344.76 85.8386 341.745V317.426" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M90.8978 302.251L61.0293 293.886V288.536L90.8978 296.804V302.251Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M131.468 287.66L90.8975 302.252V296.804L131.468 282.213V287.66Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M101.6 273.847L131.468 282.116" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M61.0295 293.886V288.439L101.6 273.847" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M129.814 288.147C130.884 289.314 131.371 290.579 131.371 291.94C131.371 301.376 102.183 308.964 66.1855 308.964C30.1875 308.964 1 301.279 1 291.94C1 286.201 11.7021 281.24 28.0471 278.127" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M52.2727 275.306C56.8454 275.014 61.5154 274.917 66.2827 274.917C75.9146 274.917 85.1573 275.501 93.4271 276.473C93.5244 276.473 93.5244 276.473 93.6217 276.473C93.8163 276.473 93.9136 276.473 94.1082 276.571" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M40.5005 256.922C44.5304 256.922 47.7974 255.746 47.7974 254.295C47.7974 252.845 44.5304 251.669 40.5005 251.669C36.4705 251.669 33.2036 252.845 33.2036 254.295C33.2036 255.746 36.4705 256.922 40.5005 256.922Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M47.7974 254.295L53.1484 283.964C53.1484 286.493 47.5055 288.438 40.5005 288.438C33.4955 288.438 27.8525 286.395 27.8525 283.964L33.2036 254.295" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M42.9227 221.376C42.9227 221.376 42.3604 212.567 39.83 212.567C37.2996 212.567 37.6745 223.251 37.6745 223.251C37.6745 223.251 31.489 215.566 25.8659 222.22C24.9287 223.345 25.1161 225.125 26.3345 225.969C27.7403 226.906 30.2707 227.656 34.9566 226.625C34.9566 226.625 28.7712 228.874 26.7094 234.591C26.3345 235.528 27.0842 236.653 28.2089 236.559C30.5518 236.371 34.7692 235.341 38.5179 230.655C38.5179 230.655 36.175 235.715 39.0803 238.714C39.7363 239.37 40.7672 239.464 41.5169 239.089C43.0164 238.34 44.9845 236.371 44.1411 231.779C44.1411 231.779 48.4521 234.966 52.7632 234.029C54.7313 233.654 55.2936 231.123 53.7004 229.999C52.5758 229.155 50.7014 228.405 47.7024 228.124C47.7024 228.124 54.0753 228.218 55.2936 224.094C55.5748 222.97 55.0124 221.845 53.8878 221.47C52.5758 221.095 50.2328 221.095 46.8589 223.251C46.8589 223.251 51.9197 220.158 52.482 215.191C52.5758 213.692 51.17 212.661 49.7642 213.035C47.7961 213.504 44.8908 215.566 42.9227 221.376Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M42.0791 238.714C42.2666 247.805 40.9545 262.05 34.8628 282.481" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <Fade cascade duration={1500} delay={1000}>
            <div>
              <div className="eyesThanks">
                <div id="left-eye" className="eye" style={styleLeftEye}>
                  <div className="pupil" />
                </div>
                <div id="right-eye" className="eye" style={styleRightEye}>
                  <div className="pupil" />
                </div>
              </div>
              <svg className="customLand-guy" width="412" height="513" viewBox="0 0 412 513" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M282.974 86.1216C283.358 90.23 282.335 98.0617 275.178 101.785C264.954 107.177 254.09 101.4 241.82 121.942C229.551 142.484 217.793 179.973 217.793 179.973" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M308.28 82.1414C308.28 82.1414 304.446 96.5209 313.009 99.7306C321.572 102.94 340.999 109.873 354.418 120.144C366.177 129.26 400.429 160.843 409.887 174.453C418.961 187.548 370.267 217.72 347.261 225.936C344.322 226.963 343.044 230.43 344.577 233.126L344.705 233.255" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M279.523 98.1901C279.523 98.1901 295.499 103.326 310.453 97.9333" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M374.484 176.122C374.484 176.122 345.344 156.222 336.397 145.694C336.014 145.18 335.119 145.565 335.119 146.207L336.525 202.955" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M341.638 238.39C343.044 241.728 346.75 244.553 350.584 250.202C354.418 255.851 350.201 255.337 350.201 255.337C346.878 253.797 336.525 246.864 330.774 235.309C325.151 223.754 327.579 214.253 332.563 207.32C337.548 200.387 378.063 173.811 377.679 174.068" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M350.456 250.202C350.456 250.202 356.719 254.182 356.208 249.432C355.696 245.58 350.968 239.674 347.645 236.85" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M353.013 242.884C353.013 242.884 357.102 245.323 358.508 244.039C359.786 242.756 359.403 240.188 350.584 232.613" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M250.639 233.896C250.639 233.896 274.411 237.877 295.883 237.748C317.354 237.62 330.646 235.309 330.646 235.309" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M350.968 224.524C350.968 224.524 351.351 227.22 353.524 229.531C355.569 231.842 358.892 234.41 359.019 236.593C359.147 238.775 355.952 237.748 355.952 237.748" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M368.861 132.341C368.861 132.341 368.861 142.741 350.968 157.634" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M225.717 157.505C225.717 157.505 232.618 171.5 252.173 178.304" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M345.216 252.385C345.216 252.385 350.329 328.005 350.456 369.732C350.584 411.458 349.945 477.835 351.862 488.363C351.862 488.363 366.432 490.93 391.099 504.539C392.633 505.438 392.377 507.749 390.588 508.134C384.964 509.29 371.161 509.803 337.037 504.154C337.037 504.154 326.94 502.87 326.94 496.965L326.045 482.071C325.151 472.699 315.821 365.88 313.904 347.777C312.242 332.114 302.657 273.44 300.101 257.905C299.973 257.392 299.206 257.392 299.206 257.905C298.311 270.744 295.499 311.572 295.116 326.721C294.733 343.412 288.342 473.726 287.32 494.397C287.064 498.634 284.764 502.614 280.929 504.668C276.84 506.722 272.366 507.107 272.366 507.107C238.753 512.243 224.694 512.371 218.815 511.729C216.898 511.472 216.387 509.033 218.048 508.006C241.054 493.883 260.864 493.37 260.864 493.37C260.864 493.37 255.496 411.458 252.428 363.569C250.256 330.702 250 281.4 250 253.797C250 241.857 250.384 229.917 251.023 217.977C252.045 201.8 253.195 177.406 252.301 168.419C251.662 162.513 251.662 153.269 251.917 144.667" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M219.071 182.926C219.071 182.926 203.606 150.444 196.832 134.909C195.171 131.186 194.276 127.206 194.021 123.097C193.893 119.631 193.382 115.394 191.976 112.955C189.803 108.975 181.623 99.8591 176.639 97.2913C174.85 96.3926 172.677 97.6765 172.805 99.7307C172.805 100.116 172.933 100.629 173.06 101.271" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M170.376 130.929C170.376 130.929 176.767 144.025 182.39 165.722C188.269 188.19 200.922 217.206 217.793 220.159C234.663 223.112 252.045 178.176 252.045 178.176" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M147.116 114.495C147.116 114.495 141.748 120.786 144.176 124.253C146.604 127.719 152.611 118.219 152.611 118.219" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M151.844 119.374C151.844 119.374 147.499 124.381 149.416 127.077C151.333 129.774 156.062 122.327 156.062 122.327" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M155.678 122.969C155.678 122.969 152.867 128.618 155.295 130.159C157.723 131.699 161.43 126.564 161.43 126.564" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M160.791 127.334C160.791 127.334 157.979 131.443 161.813 133.882C164.881 135.808 167.82 130.544 167.82 130.544" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M144.304 105.251L156.318 93.568C157.851 92.1557 160.152 92.0273 161.813 93.1828L188.141 112.698C190.186 114.238 190.442 117.32 188.525 119.117L176.511 130.929C174.977 132.341 172.677 132.598 171.015 131.314L144.687 111.799C142.642 110.13 142.387 107.049 144.304 105.251Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M298.567 52.9974C304.002 52.9974 308.408 48.5713 308.408 43.1115C308.408 37.6517 304.002 33.2256 298.567 33.2256C293.132 33.2256 288.726 37.6517 288.726 43.1115C288.726 48.5713 293.132 52.9974 298.567 52.9974Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M274.539 52.9974C279.974 52.9974 284.38 48.5713 284.38 43.1115C284.38 37.6517 279.974 33.2256 274.539 33.2256C269.104 33.2256 264.698 37.6517 264.698 43.1115C264.698 48.5713 269.104 52.9974 274.539 52.9974Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M285.658 33.2256C285.658 33.2256 281.057 49.0174 283.23 57.6194C283.613 59.16 285.275 60.0588 286.808 59.6736C288.726 59.16 291.154 58.3897 291.537 58.2613" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M313.264 57.6193C313.264 57.6193 310.836 80.2156 307.258 83.8105C303.296 87.7906 298.822 90.4867 287.064 88.1757C275.306 85.9931 270.321 56.7206 269.938 43.1114C269.81 38.361 269.81 34.381 269.81 30.6577" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M288.726 44.008C288.726 44.008 286.808 42.8573 284.38 44.008" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M315.948 44.0102C315.948 44.0102 318.377 36.6921 324.639 41.1857C330.902 45.6793 326.045 62.8833 313.137 59.0317" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M322.595 44.6521C322.595 44.6521 318.121 45.0373 316.843 52.0986" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M308.28 41.8275L318.76 40.2869" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M299.078 28.3466C299.078 28.3466 296.65 32.3267 291.026 30.9144C285.403 29.5021 285.531 25.6505 286.553 24.8802C287.576 24.1098 288.087 29.3738 283.741 30.0157C279.396 30.6576 274.539 28.2183 275.05 23.7247C275.05 23.7247 272.75 31.9415 267.765 30.4009C265.337 29.6305 265.465 27.3195 266.232 25.1369C266.232 25.1369 264.442 27.8331 262.781 27.8331C261.119 27.8331 264.826 17.6904 272.878 16.5349" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M275.178 14.0947C275.178 14.0947 271.344 12.1688 274.028 7.54686C274.028 7.54686 277.734 15.3785 286.297 9.47268C286.297 9.47268 285.403 3.05326 291.665 2.02615C297.161 1.12744 300.867 3.05326 304.318 6.13458" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M308.024 7.54781C308.024 7.54781 309.558 2.41227 313.392 1C313.392 1 307.002 8.83169 317.099 12.2982C317.099 12.2982 322.211 7.6762 328.218 11.0143C328.218 11.0143 320.422 12.9401 321.189 14.9943C321.189 14.9943 327.962 14.6092 329.368 20.0015C330.391 23.7248 325.406 24.1099 322.339 23.8531C322.339 23.8531 334.225 26.1641 327.579 37.3339" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M284.38 65.5795C284.38 65.5795 290.004 66.4782 296.394 64.0388" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                {/* <path d="M296.138 45.0372C297.479 45.0372 298.567 43.9451 298.567 42.5978C298.567 41.2506 297.479 40.1584 296.138 40.1584C294.797 40.1584 293.71 41.2506 293.71 42.5978C293.71 43.9451 294.797 45.0372 296.138 45.0372Z" fill="#081027"/>
<path d="M278.118 45.0372C279.459 45.0372 280.546 43.9451 280.546 42.5978C280.546 41.2506 279.459 40.1584 278.118 40.1584C276.777 40.1584 275.689 41.2506 275.689 42.5978C275.689 43.9451 276.777 45.0372 278.118 45.0372Z" fill="#081027"/> */}
                <path d="M1.12793 383.512V395.566C1.12793 408.134 39.6067 418.265 87.0638 418.265C134.521 418.265 173 408.134 173 395.566V383.512" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M152.35 410.186V474.947C152.35 478.794 149.272 482 145.295 482C141.448 482 138.241 478.922 138.241 474.947V413.648" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21.7786 410.186V474.947C21.7786 478.794 24.8569 482 28.833 482C32.6809 482 35.8875 478.922 35.8875 474.947V413.648" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M48.5854 415.828V449.427C48.5854 453.274 51.6637 456.48 55.6399 456.48C59.4877 456.48 62.6943 453.402 62.6943 449.427V417.367" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M98.7361 418.008V449.299C98.7361 453.146 101.814 456.352 105.791 456.352C109.638 456.352 112.845 453.274 112.845 449.299V417.239" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M119.515 397.233L80.1382 386.205V379.152L119.515 390.052V397.233Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M173 377.997L119.514 397.233V390.052L173 370.816V377.997Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M133.623 359.787L173 370.688" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M80.1382 386.205V379.023L133.624 359.787" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M170.819 378.639C172.23 380.178 172.872 381.845 172.872 383.64C172.872 396.079 134.393 406.082 86.9358 406.082C39.4787 406.082 1 395.951 1 383.64C1 376.074 15.1089 369.534 36.657 365.43" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M68.5942 361.711C74.6226 361.326 80.7792 361.198 87.064 361.198C99.762 361.198 111.947 361.967 122.849 363.249C122.978 363.249 122.978 363.249 123.106 363.249C123.362 363.249 123.491 363.249 123.747 363.378" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M53.0745 337.474C58.3873 337.474 62.6942 335.923 62.6942 334.011C62.6942 332.099 58.3873 330.549 53.0745 330.549C47.7617 330.549 43.4548 332.099 43.4548 334.011C43.4548 335.923 47.7617 337.474 53.0745 337.474Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M62.6942 334.01L69.7487 373.124C69.7487 376.458 62.3094 379.023 53.0745 379.023C43.8396 379.023 36.4004 376.33 36.4004 373.124L43.4548 334.01" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M56.2679 290.614C56.2679 290.614 55.5265 279 52.1906 279C48.8547 279 49.3489 293.085 49.3489 293.085C49.3489 293.085 41.1945 282.954 33.7814 291.726C32.5459 293.208 32.793 295.556 34.3991 296.668C36.2524 297.903 39.5883 298.892 45.7659 297.533C45.7659 297.533 37.6115 300.498 34.8933 308.035C34.3991 309.27 35.3876 310.753 36.8702 310.629C39.959 310.382 45.5188 309.023 50.4609 302.846C50.4609 302.846 47.3721 309.517 51.2022 313.471C52.0671 314.336 53.4262 314.459 54.4146 313.965C56.3914 312.977 58.986 310.382 57.874 304.328C57.874 304.328 63.5574 308.529 69.2408 307.293C71.8354 306.799 72.5767 303.463 70.4764 301.981C68.9937 300.869 66.5227 299.88 62.569 299.51C62.569 299.51 70.9706 299.633 72.5767 294.197C72.9474 292.714 72.2061 291.232 70.7235 290.737C68.9937 290.243 65.9049 290.243 61.4571 293.085C61.4571 293.085 68.1289 289.008 68.8702 282.459C68.9937 280.483 67.1404 279.124 65.2872 279.618C62.6926 280.236 58.8625 282.954 56.2679 290.614Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M55.1558 313.471C55.403 325.456 53.6732 344.235 45.6423 371.17" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

            </div>
          </Fade>
        </div>


        <div className="customLand-footer">
          <ul>
            <li>Okki © 2020</li>
            <li>
              <a target="_blank" href="https://www.instagram.com/okkico/">
                Instagram
       </a>
            </li>
            <li>
              <a target="_blank" href="https://www.twitter.com/okkico/">
                Updates
       </a>
            </li>
          </ul>
        </div>
      </div>
      <div id="myNav" className="overlayCustomLand">
        <a className="button button-alpha closebtn customLand-button" onClick={closeNav}>
          Cerrar
    </a>

        <div id="overlayContent" className="overlayCustomLand-content">
          <div id="myBox" className="overlayCustomLand-content-box">
            <h2>Así haces las cosas más simples, como eran antes</h2>
            <ul>
              <li>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 9.55556L8 14L17 6"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
        No pagas comisiones por ventas.
       </li>
              <li>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 9.55556L8 14L17 6"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
        Evitas intermediarios y pones tus propias reglas.
       </li>
              <li>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 9.55556L8 14L17 6"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
        Das un servicio más personalizado.
       </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
