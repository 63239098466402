import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import venderThumb from "../../images/vender-1.svg";
import selectArrow from "../../images/selectArrow.svg";
import profileTable from "../../images/profileTable.svg";
import profileThumb from "../../images/profileThumb.png";
import itemThumb from "../../images/itemThumb.svg";
import itemProfile from "../../images/itemProfile.svg";
import MaterialTable from "material-table";
import Modal from "react-modal";
import Sidebar from "../Sidebar";
import { CSVReader } from "react-papaparse";
import { getCiudades, postCiudad, deleteCiudad, putCiudad, loggedIn } from "../API Functions";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        padding: "50px 40px",
        border: "none",
        borderRadius: 12,
    },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Ciudades() {
    var subtitle;


    const [modalIsOpenDelete, setIsOpenDelete] = React.useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpenEdit, setIsOpenEdit] = React.useState(false);

    const [ciudades, setCiudades] = useState([]);
    const [ciudades_csv, setCiudadesCSV] = useState([]);

    const [detalle, setDetalle] = useState("");
    const [detalle_edit, setDetalleEdit] = useState("");

    const [ciudad_delete, setCiudadDelete] = useState("");
    const [ciudad_edit, setCiudadEdit] = useState("");

    const [detalleError, setDetalleError] = useState("");

    const [loading, setLoading] = useState(false);

    const buttonRef = React.createRef()

    useEffect(() => {
        loggedIn();
        setLoading(true);
        loadCiudades();
    }, [])


    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function openModalEdit(ciudad) {
        setCiudadEdit(ciudad.id);
        setDetalleEdit(ciudad.nombre);
        setIsOpenEdit(true);
    }

    function closeModalEdit() {
        setIsOpenEdit(false);
        setDetalleEdit("");
        setCiudadEdit("");
    }

    function handleEdit() {
        const data = {
            id: ciudad_edit,
            nombre: detalle_edit
        }
        putCiudad(data).then(res => {
            setLoading(true);
            closeModalEdit();
            loadCiudades();
        })
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function validateFields() {
        let detalleError = ""

        if (detalle == "") {
            detalleError = "Requerido"
        }
        if (detalleError) {
            setDetalleError(detalleError);
            return false
        } else {
            setDetalleError(detalleError);
            return true
        }
    }

    function handleCreate() {
        const data = {
            ciudades: ciudades_csv
        }
        const isValid = validateFields()
        if (isValid) {
            postCiudad(data).then(res => {
                setLoading(true);
                closeModal();
                loadCiudades();
            })
        }
    }

    function openModalDelete(id_ciudad) {
        setCiudadDelete(id_ciudad);
        setIsOpenDelete(true);
    }

    function closeModalDelete() {
        setCiudadDelete("");
        setIsOpenDelete(false);
    }

    function handleDelete() {
        deleteCiudad(ciudad_delete).then(res => {
            setLoading(true);
            closeModalDelete();
            loadCiudades();
        })
    }

    function loadCiudades() {
        setLoading(true);
        getCiudades().then(res => {
            if (!res) {
                //window.location.replace("/login")
            } else {
                setCiudades(res);
            }
            setTimeout(() => {
                setLoading(false);
            }, 700);
        })
    }

    function handleOpenDialog(e) {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    function handleOnFileLoad(data) {
        //console.log(data)
        var ciudades = []
        data.map((ciudad, index) => {
            if (index > 0 && ciudad.data[0] !== "") {
                const ciudad_n = {
                    nombre: ciudad.data[4],
                    estado_id: parseInt(ciudad.data[0])
                }
                ciudades.push(ciudad_n);
                setCiudadesCSV(ciudades);
            }
        })
        console.log(ciudades)
    }

    function handleOnError(err, file, inputElem, reason) {
        console.log(err)
    }

    function handleOnRemoveFile(data) {
        //console.log(data)
    }

    function handleRemoveFile(e) {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    return (
        <div className="backoffice">
            <Sidebar />
            <div className="backofficeContainer">
                <div className="backofficeContainer-header">
                    <div className="backofficeContainer-header__about">
                        <h3 className="backofficeContainer-header__title">Ciudades</h3>
                        <h3 className="backofficeContainer-header__titleAlt">{ciudades ? ciudades.length : 0}</h3>
                    </div>
                </div>
                <div className="backofficeContainer-headerMin">
                    <div>
                    </div>
                    <div className="backofficeContainer-headerMin__item">
                        <a className="button" onClick={openModal}>
                            Agregar
      </a>
                    </div>
                </div>
                <div className="backofficeTable">
                    <hr />
                    {/*<table>
      <thead>
       <tr>
        <th>Categoría</th>
        <th style={{ textAlign: "center" }}>Negocios</th>
        <th style={{ textAlign: "center" }}>Eliminar</th>
       </tr>
      </thead>
      <tbody>
       <tr>
        <td>Hamburguesa</td>
        <td style={{ textAlign: "center" }}>31</td>
        <td>
         <div className="approvedBtns">
          <a>
           <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M14.2422 5.75735L5.75691 14.2426"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
            <path
             d="M14.2431 14.2426L5.75781 5.75735"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
           </svg>
          </a>
         </div>
        </td>
       </tr>
      </tbody>
     </table>*/}
                    <MaterialTable
                        columns={[
                            { title: "Ciudad", field: "ciudad" },
                            { title: "Negocios", field: "negocios" },
                            { title: "Eliminar", field: "actions" }
                        ]}
                        title={"Solicitudes"}
                        data={ciudades.map((ciudad, index) => ({
                            ciudad: ciudad.nombre,
                            negocios: ciudad.negocios,
                            actions: (
                                <div className="approvedBtns">
                                    <a onClick={() => { openModalDelete(ciudad.id) }}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M14.2422 5.75735L5.75691 14.2426"
                                                stroke="#ACACB5"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M14.2431 14.2426L5.75781 5.75735"
                                                stroke="#ACACB5"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </a>
                                    <a onClick={() => { openModalEdit(ciudad) }}>
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                </div>
                            )
                        }))}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [15, 20, 30, 50],
                            emptyRowsWhenPaging: false,
                            paging: ciudades.length > 20 ? true : false,
                            showTitle: false,
                            search: true
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage:

                                    loading ?
                                        <>
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{
                                                    marginRight:
                                                        "5px"
                                                }}
                                            />

                                            <span>Cargando...</span>
                                        </>
                                        :
                                        'No hay datos para mostrar.'

                            }
                        }}
                    />
                </div>
                <CSVReader
                    ref={buttonRef}
                    onFileLoad={handleOnFileLoad}
                    onError={handleOnError}
                    noClick
                    noDrag
                    onRemoveFile={handleOnRemoveFile}
                >
                    {({ file }) => (
                        <aside
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: 10
                            }}
                        >
                            <button
                                type='button'
                                onClick={handleOpenDialog}
                                style={{
                                    borderRadius: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    width: '40%',
                                    paddingLeft: 0,
                                    paddingRight: 0
                                }}
                            >
                                Browe file
            </button>
                            <div
                                style={{
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#ccc',
                                    height: 45,
                                    lineHeight: 2.5,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    paddingLeft: 13,
                                    paddingTop: 3,
                                    width: '60%'
                                }}
                            >
                                {file && file.name}
                            </div>
                            <button
                                style={{
                                    borderRadius: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    paddingLeft: 20,
                                    paddingRight: 20
                                }}
                                onClick={handleRemoveFile}
                            >
                                Remove
            </button>
                        </aside>
                    )}
                </CSVReader>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Agregar Ciudad
    </p>
                <br />
                <div className="modalInput">
                    <label>Nombre</label>
                    <input
                        type="text"
                        class="input registerInput"
                        placeholder="Mexicana"
                        onChange={e => setDetalle(e.target.value)}
                    />
                </div>
                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModal}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleCreate}>
                        Agregar
     </button>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpenDelete}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalDelete}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Deseas eliminar esta Ciudad?
    </p>
                <br />

                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModalDelete}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleDelete}>
                        Eliminar
     </button>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpenEdit}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalEdit}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Editar Ciudad
    </p>
                <br />
                <div className="modalInput">
                    <label>Nombre</label>
                    <input
                        type="text"
                        class="input registerInput"
                        placeholder="Mexicana"
                        value={detalle_edit}
                        onChange={e => setDetalleEdit(e.target.value)}
                    />
                </div>
                <div className="backofficeContainer__optionTotal modalRow">
                    <p className="button button-alpha" onClick={closeModalEdit}>
                        Cancelar
     </p>
                    <button className="button u-pull-right aButton" onClick={handleEdit}>
                        Editar
     </button>
                </div>
            </Modal>
        </div>
    );
}

export default Ciudades;
