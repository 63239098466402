import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import profileThumb2 from "../images/emptyThumb.svg";
import itemThumb from "../images/emptyItem.svg";

function EmptyStateCatalogo() {
    return (
        <div>
            <Navbar negocio={[]} />
            <div className="settings-headerAlt settings-headerAlt-catalogo container">
                <div className="settings-header__breadcrumbCatalog">
                    <div className="settings-header__breadcrumbCatalogTitle">
                        <h3 className="settings-header__title">Catálogo</h3>
                    </div>
                    <Link className="button">
                        Nuevo producto
                    </Link>
                </div>
            </div>
            <hr style={{ maxWidth: "none" }} />
            <div className="home-gallery container home-galleryProfile">
                <div className="">
                    <hr />

                    <div className="homeGrid">
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                        <div className="homeGrid-item">
                            <div className="homeGrid-item__top">
                                <img className="homeGrid-item__cover" src={itemThumb} />
                            </div>
                            <div className="rowProfile-productEmpty">
                                <div className="rowProfile-subEmpty"></div>
                                <div className="rowProfile-subEmpty"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default EmptyStateCatalogo;
