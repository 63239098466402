import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Navbar from "./Navbar";
import graphImg from "../images/graph.svg";
import itemProfile from "../images/itemProfile.svg";
import Fade from "react-reveal/Fade";
import itemThumb from "../images/itemThumb.svg";
import Select, { components } from "react-select";
import { getStats, getVisitStats, bucket_route } from "./API Functions";
import { Image } from 'use-image-color';
import { Chart } from "react-charts";
import Footer from "./Footer";

import selectIcon from "../images/select_icon.svg";
import { anchor } from "react-laag";

const options = [
    { value: "week", label: "Últimos 7 días" },
    { value: "month", label: "Último mes" },
    { value: "year", label: "Último año" },
];

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

function Stats() {
    const [loading, setLoading] = useState(false);
    const [loadingVisits, setLoadingVisits] = useState(false);
    const [isToggled, setToggled] = useState(false);
    const [products, setProducts] = useState([]);
    const [clicks, setClicks] = useState([
        {code: "phone", name: "Teléfono", counter: 0},
        {code: "map", name: "Mapa", counter: 0},
        {code: "web", name: "Web", counter: 0},
        {code: "facebook", name: "Facebook", counter: 0},
        {code: "instagram", name: "Instagram", counter: 0},
    ]);

    useEffect(() => {
        setLoading(true);
        loadStats();
    }, [])

    function loadStats() {
        let newClicks = clicks;
        let newProducts = [];
        getStats("placeholder").then(res => {
            if (!res) {
                console.log("error stats");
            } else {
                console.log("stats", res);
                newClicks.find(el => el.code == "phone").counter = res.phone_click_counter || 0;
                newClicks.find(el => el.code == "map").counter = res.maps_click_counter || 0;
                newClicks.find(el => el.code == "web").counter = res.website_click_counter || 0;
                newClicks.find(el => el.code == "facebook").counter = res.facebook_click_counter || 0;
                newClicks.find(el => el.code == "instagram").counter = res.instagram_click_counter || 0;
                if (res.productos) {
                    const orderedProducts = res.productos.sort(function(a, b) { return (b.click_counter || 0) - (a.click_counter || 0) });
                    orderedProducts.map((producto) => {
                        newProducts.push({
                            id: producto.id, 
                            name: producto.nombre, 
                            price: producto.precio, 
                            click_counter: producto.click_counter, 
                            purchase_counter: producto.purchase_counter,
                            principal_image: producto.principal_image
                        });
                    });
                }
            }
            setTimeout(() => {
                setLoading(false);
            }, 700);
        });
        setProducts(newProducts);
        setClicks(newClicks);
    }

    function loadVisitStats(timeSpan) {
        setLoadingVisits(true);
        getVisitStats(timeSpan.value).then(res => {
            if (!res) {
                console.log("error stats");
            } else {
                console.log("visitStats", res);
            }
            setTimeout(() => {
                setLoadingVisits(false);
            }, 700);
        });
    } 

    const toggleTrueFalse = () => setToggled(!isToggled);

    const renderLoading = (
        <div className="statsContainer-grid__item-row">
            <p>
                <i className="fa fa-refresh fa-spin" style={{marginRight: "10px"}}/>
                <span>Cargando...</span>
            </p>
        </div>
    );

    const renderClicks = (
        (clicks.length > 0) ?
            <div>
                {clicks.map((element) => {
                    return (
                        <div className="statsContainer-grid__item-row " key={element.code}>
                            <p><span>{element.name}</span></p>
                            <p>{element.counter}</p>
                        </div>
                    )
                })}
            </div>
        :
        <div className="statsContainer-grid__item-row">
            <p>Sin información</p>
        </div>
    );

    const renderTop5 = ( 
        (products.length > 0) ?
            <div>
                {products.slice(0, 5).map((product) => {
                    return (
                        <div className="statsContainer-grid__item-row " key={product.id}>
                            <p class="homeGrid-item__name">{product.name}</p>
                            <p>${product.price}</p>
                        </div>
                    )
                })}
            </div>
        :
            <div className="statsContainer-grid__item-row">
                <p>Sin productos</p>
            </div>
    );

    const renderProducts = ( 
        (products.length > 0) ?
            <div className="container homeGrid">
                {products.map((product) => {
                    return (
                    <div className="homeGrid-item" key={product.id}>
                        {/*<img src={itemThumb} />*/}
                        <Image className="homeGrid-item__cover" src={product.principal_image ? `${bucket_route}${product.principal_image.path}` : itemThumb} thumbnail={product.principal_image ? `${bucket_route}${product.principal_image.path}` : itemThumb} />
                        <p className="homeGrid-stats__title homeGrid-item__name">{product.name}</p>
                        <div className="homeGrid-item__info homeGrid-stats__info">
                            <div>
                                <p>Visitas</p>
                                <p>{product.click_counter || 0}</p>
                            </div>
                            <div>
                                <p>Comprar</p>
                                <p>{product.purchase_counter || 0}</p>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </div>
        :
            <div className="container homeGrid">
                <p>Sin productos</p>
            </div>
    );

    const chartSeries = React.useMemo(
        () => ({
            type: "line"
        }),
        []
    );

    const chartAxes = React.useMemo(
        () => [
            { primary: true, position: "bottom", type: "time", show: false },
            { position: "left", type: "linear", stacked: true, show: false, showGrid: false }
        ],
        []
    );

    const chartStyles = React.useCallback((data) => {
        const colorPalette = {
            series1: "#000000"
        };
        return {
            stroke: colorPalette[data.label], fill: colorPalette[data.label]
        };
    }, 
    []);

    const chartData = React.useMemo(
        () => [
            {
                label: "series1",
                datums: [
                    {
                        x: new Date("2020-03-18T11:00:00.000Z"),
                        y: 60
                    },
                    {
                        x: new Date("2020-04-18T11:30:00.000Z"),
                        y: 10
                    },
                    {
                        x: new Date("2020-05-18T12:00:00.000Z"),
                        y: 65
                    },
                    {
                        x: new Date("2020-06-18T12:30:00.000Z"),
                        y: 84
                    },
                    {
                        x: new Date("2020-07-18T13:00:00.000Z"),
                        y: 87
                    },
                    {
                        x: new Date("2020-08-18T13:30:00.000Z"),
                        y: 84
                    },
                    {
                        x: new Date("2020-09-18T14:00:00.000Z"),
                        y: 96
                    },
                    {
                        x: new Date("2020-10-18T14:30:00.000Z"),
                        y: 88
                    },
                    {
                        x: new Date("2020-11-18T15:00:00.000Z"),
                        y: 63
                    },
                    {
                        x: new Date("2020-12-18T15:30:00.000Z"),
                        y: 60
                    }
                ]
            }
        ],
        []
    );

    const chartTooltip = React.useMemo(
        () => ({
            render: ({ datum, primaryAxis, getStyle }) => {
                //if (datum) {
                //    datum.anchor = {x: 0, y: 0};
                //}
                return <CustomTooltip {...{ getStyle, primaryAxis, datum }}/>;
            }
        }),
        []
    );

    function CustomTooltip({ getStyle, primaryAxis, datum }) {
        const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
                            'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

        return datum ? (
          <div
            style={{
              color: "white",
              pointerEvents: "none"
            }}
          >
            <div
              style={{
                width: "120px",
                height: "40px",
                textAlign: "center"
              }}
            >
                <p>{datum.yValue} visitantes</p>
                <p>{datum.xValue.getDate() + " " + (monthNames[datum.xValue.getMonth()]) + " " + datum.xValue.getFullYear()}</p>
            </div>
          </div>
        ) : null;
    };

    function handleTimeOption(selectedOption) {
        //console.log(selectedOption);
        loadVisitStats(selectedOption);
    }

    return (
        <div className="statsContainer">
            <Navbar negocio={[]} />
            <div className="home-gallery search-header ">
                <div className="statsContainer-header container row">
                    <div className="four columns search-header__breadcrumbAlt2">
                        <h3 className="titleMin">Categoría</h3>
                        <div className="onlyMobile moreOptionsToggle" onClick={toggleTrueFalse}>
                            {isToggled ? 
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="42" height="42" rx="6" fill="#081027" />
                                    <path d="M25.2422 16.7573L16.7569 25.2426" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M25.2431 25.2426L16.7578 16.7573" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                : 
                                <svg onClick={toggleTrueFalse} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="41" height="41" rx="5.5" fill="white" stroke="#E8E8EE" />
                                    <path d="M14 17H28" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16 21H26" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18 25H24" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>}
                        </div>
                    </div>
                    <div className="eight columns home-gallery__headerSelects onlyDesktop">
                        <Select
                            classNamePrefix="inputSelect"
                            className="inputSelect"
                            options={options}
                            onChange={handleTimeOption}
                            placeholder="Últimos 7 dias"
                            components={{ DropdownIndicator }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                colors: {
                                    ...theme.colors,
                                    primary50: "#ccc",
                                    primary25: "#F6F6F6",
                                    primary: "black",
                                },
                            })}
                            styles={{
                                dropdownIndicator: (provided, state) => ({
                                    ...provided,
                                    transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                })
                            }}
                        />
                    </div>

                </div>

                <hr />

                <div className="container statsContainer-grid row">
                    <div className="four columns statsContainer-grid__item">
                        <div className="statsContainer-grid__item-head">
                            <h5 className="titleMinSub">Perfil</h5>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19 10C19 10 17 3 10 3C3 3 1 10 1 10C1 10 3 17 10 17C17 17 19 10 19 10Z" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <hr />
                        { (loading || loadingVisits) ? renderLoading :
                            <div className="statsContainer-grid__item-graph">
                                <Chart data={chartData} series={chartSeries} axes={chartAxes} tooltip={chartTooltip} getSeriesStyle={chartStyles} />
                            </div>
                        }
                    </div>
                    <div className="four columns statsContainer-grid__item">
                        <div className="statsContainer-grid__item-head">
                            <h5 className="titleMinSub">Clicks</h5>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" fill="white" />
                                <path d="M17 8.21111L3 3L8.21111 17L10.3111 12.1L13.5 15.2111L15.2111 13.5L12.1 10.3111L17 8.21111Z" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <hr />
                        { loading ? renderLoading : renderClicks }
                    </div>
                    <div className="four columns statsContainer-grid__item">
                        <div className="statsContainer-grid__item-head ">
                            <h5 className="titleMinSub">Top 5</h5>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 2V5" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 15V18" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2 10H5" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15 10H18" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.2998 15.7L6.4998 13.5" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.5 6.4998L15.7 4.2998" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.2998 4.2998L6.4998 6.4998" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.5 13.5L15.7 15.7" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <hr />
                        { loading ? renderLoading : renderTop5 }
                    </div>
                </div>

                <div className="statsContainer-header container row">
                    <div className="four columns search-header__breadcrumbAlt2">
                        <h3 className="titleMin">Productos</h3>
                    </div>
                </div>
                
                { loading ? <div className="container homeGrid">{renderLoading}</div> : renderProducts }
                
            </div>
            <Footer />
        </div>
    );
}

export default Stats;
