import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import vender2 from "../images/registro2.svg";
import Fade from "react-reveal/Fade";
import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import NavbarMin from "./NavbarMin";
import selectIcon from "../images/select_icon.svg";

import Select, { components } from "react-select";
import { registerUser, loggedIn } from "./API Functions";
import { Helmet } from "react-helmet";

const options = [
  { value: null, label: "-" },
  { value: true, label: "Si" },
  { value: false, label: "No" },
];

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 8L10 12L14 8"
          stroke="#081027"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const Vender2 = (props) => {
  const [Loading, setLoading] = useState(false);
  const [tipo_id, setTipoID] = useState("");
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [nombre_negocio, setNombreNegocio] = useState("");
  const [url_negocio, setUrlNegocio] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [envio_id, setEnvioID] = useState(options[0]);
  const [whatsapp_option, setWhatsappOption] = useState(options[0]);

  const [nombreError, setNombreError] = useState("");
  const [postEmailError, setPostEmailError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nombre_negocioError, setNombre_negocioError] = useState("");
  const [url_negocioError, setUrl_negocioError] = useState("");
  const [envio_idError, setEnvio_idError] = useState("");
  const [whatsappError, setWhatsappError] = useState("");
  const [whatsappOptionError, setWhatsappOptionError] = useState("");


  const [Regresar, setRegresar] = useState(false);

  useEffect(() => {
    const tipo_id =
      props.location.state == undefined
        ? ""
        : props.location.state.tipo_id
          ? props.location.state.tipo_id
          : "";
    tipo_id ? setTipoID(tipo_id) : setRegresar(true);
    console.log(tipo_id);
  }, []);

  function validateFields() {
    let nombreError = "";
    let emailError = "";
    let nombre_negocioError = "";
    let url_negocioError = "";
    let envio_idError = "";
    let whatsappError = "";
    let whatsappOptionError = "";


    if (nombre == "") {
      nombreError = "Requerido";
    }
    if (email == "") {
      emailError = "Requerido";
    } else {
      emailError = validateEmailFormat(email);
    }
    if (nombre_negocio == "") {
      nombre_negocioError = "Requerido";
    }
    /*if (url_negocio == "") {
      url_negocioError = "Requerido";
    }
    */
    if (whatsapp == "") {
      whatsappError = "Requerido"
    }
    // if (whatsapp_option.value == null) {
    //   whatsappOptionError = "Selecciona una opción";
    // }
    if (whatsapp_option.value == true) {
      whatsappError = validateNumberLength(whatsapp);
    }
    // if (envio_id.value == null) {
    //   envio_idError = "Selecciona una opción";
    // }

    if (
      nombreError ||
      emailError ||
      nombre_negocioError ||
      url_negocioError ||
      envio_idError ||
      whatsappError ||
      whatsappOptionError
    ) {
      setNombreError(nombreError);
      setEmailError(emailError);
      setNombre_negocioError(nombre_negocioError);
      setUrl_negocioError(url_negocioError);
      setEnvio_idError(envio_idError);
      setWhatsappError(whatsappError);
      setWhatsappOptionError(whatsappOptionError);
      return false;
    } else {
      setNombreError(nombreError);
      setEmailError(emailError);
      setNombre_negocioError(nombre_negocioError);
      setUrl_negocioError(url_negocioError);
      setEnvio_idError(envio_idError);
      setWhatsappError(whatsappError);
      setWhatsappOptionError(whatsappOptionError);
      return true;
    }
  }

  function handleWhatsappOption(selectedOption) {
    setWhatsappOption(selectedOption);
  }

  function handleEnvioOption(selectedOption) {
    setEnvioID(selectedOption);
  }
  function handleSubmit() {
    setLoading(true);
    const isValid = validateFields();
    const data = {
      nombre: nombre,
      correo: email,
      apellidos: nombre,
      nombre_negocio: nombre_negocio,
      url_negocio: url_negocio,
      whatsapp: whatsapp,
      envio_id: envio_id.value ? 1 : 2,
      tipo_id: tipo_id,
      password: "",
      role_name: "user",
      landing: false,
      new_register: true,
    };
    if (isValid) {
      registerUser(data).then((res) => {
        if (res) {
          window.location.replace("/vender3");
        } else {
          setPostEmailError("Este correo ya existe");
        }
      });
    }
    setTimeout(() => {
      setLoading(false);
      setPostEmailError("");
    }, 2000);
  }
  function validateNumberLength(value) {
    var msg = ""
    if (value.length > 10 || value.length < 10) {
      msg = "El número debe ser de 10 dígitos";
    }
    setWhatsappError(msg);
    return msg
  }

  function validateEmailFormat(email) {
    const reg_format_email = /\S+@\S+\.\S+/;
    var msg = ""
    if (email == "") {
      msg = "Requerido"
    }
    if (reg_format_email.test(email) === false) {
      msg = "El formato del correo no es válido";
    }
    setEmailError(msg)

    return msg
  }

  return (
    <div className="signupContainer">
      <Helmet>
        <title>Registro | Okki</title>
      </Helmet>
      {Regresar ? (
        <Redirect
          to={{
            pathname: "/vender",
          }}
        />
      ) : null}
      <div className="onlyDesktop">
        <NavbarMin />
      </div>
      <div className="onlyMobile">
        <div className="modalItem-respNav navResponsive-vender">


          <Link to="/" className="okkiLogoMobile">
            <div>
              <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M45.0822 39.9559C46.2785 40.0157 47.2804 39.0587 47.2804 37.8625V35.8139C47.2804 35.5597 47.5794 35.4251 47.7738 35.5896L52.5439 39.4924C52.9327 39.8064 53.3963 39.9709 53.8748 39.9709C54.4879 39.9709 55.086 39.7017 55.4897 39.2083C56.2224 38.3111 56.0878 36.9952 55.1907 36.2625L51.1533 32.9578C50.8542 32.7185 50.8542 32.2699 51.1533 32.0307L55.1907 28.726C56.0878 27.9933 56.2224 26.6774 55.4897 25.7802C54.757 24.883 53.4411 24.7485 52.5439 25.4812L47.7738 29.384C47.5794 29.5485 47.2804 29.3989 47.2804 29.1597V27.1559C47.2804 26.0344 46.4131 25.0625 45.2916 25.0027C44.0953 24.9428 43.0935 25.8999 43.0935 27.0961V37.8027C43.0935 38.9242 43.9607 39.8961 45.0822 39.9559ZM36.9178 42.0494C35.7215 41.9896 34.7196 42.9466 34.7196 44.1428V46.1914C34.7196 46.4456 34.4206 46.5802 34.2262 46.4157L29.4561 42.5129C29.0673 42.1989 28.6037 42.0344 28.1252 42.0344C27.5121 42.0344 26.914 42.3036 26.5103 42.797C25.7776 43.6942 25.9121 45.0101 26.8093 45.7428L30.8467 49.0475C31.1458 49.2868 31.1458 49.7354 30.8467 49.9746L26.8093 53.2793C25.9121 54.012 25.7776 55.3279 26.5103 56.2251C27.243 57.1223 28.5589 57.2569 29.4561 56.5241L34.2262 52.6213C34.4206 52.4569 34.7196 52.6064 34.7196 52.8456V54.8344C34.7196 55.9559 35.5869 56.9279 36.7084 56.9877C37.9047 57.0475 38.9065 56.0905 38.9065 54.8942V44.1877C38.9065 43.0812 38.0393 42.1092 36.9178 42.0494ZM32.4766 25.0027C28.3495 25.0027 25 28.3522 25 32.4793C25 36.6064 28.3495 39.9559 32.4766 39.9559C36.6037 39.9559 39.9533 36.6064 39.9533 32.4793C39.9533 28.3522 36.6037 25.0027 32.4766 25.0027ZM32.4766 35.769C30.6673 35.769 29.1869 34.2886 29.1869 32.4793C29.1869 30.6699 30.6673 29.1896 32.4766 29.1896C34.286 29.1896 35.7664 30.6699 35.7664 32.4793C35.7664 34.2886 34.286 35.769 32.4766 35.769ZM54.9065 47.4326H44.1402C42.9888 47.4326 42.0467 48.3746 42.0467 49.526C42.0467 50.6774 42.9888 51.6195 44.1402 51.6195H54.9065C56.0579 51.6195 57 50.6774 57 49.526C57 48.3746 56.0579 47.4326 54.9065 47.4326Z" fill="black" />
              </svg>


            </div>
          </Link>
          <Link to="/">
            <svg className="modalCloseButton" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" fill="white" stroke="#EEEEEE" />
              <path d="M25.2422 16.7573L16.7569 25.2426" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M25.2431 25.2426L16.7578 16.7573" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </Link>
        </div>
      </div>
      <div className="planContainer">
        <div className="planContainer-cover">
          <Fade>
            <img src={vender2} />
          </Fade>
        </div>
        <div className="planContainer-form vender2-container">
          {/* <div className="planContainer-form__optionTotal onlyDesktop">

            <button className="button u-pull-right aButton" onClick={handleSubmit}>
              {Loading && <i className="" style={{ marginRight: "5px" }} />}
              {Loading && "Enviando..."}
              {!Loading && (
                <span>
                  Enviar solicitud{" "}

                </span>
              )}
            </button>
          </div> */}
          <Fade>
            <div className="container">
              <div className="row">
                <div className="twelve columns">
                  <div className="planContainer-form__container planContainer-form__containerLarge">
                    <p className="planContainer-form__title">Compartenos tu información.</p>
                    <p className="planContainer-form__subtitleAlt">
                      Te enviaremos la confirmación y tu acceso por correo.
                     </p>
                    <hr className="hrMin" />
                    <div className="registerForm">
                      <div>
                        <label>Nombre completo</label>
                        <input
                          type="text"
                          className={nombreError ? "input registerInput inputError" : "input registerInput"}
                          value={nombre}
                          onChange={(e) => setNombre(e.target.value)}
                        />
                        <div className="fieldError">{nombreError}</div>
                      </div>
                      <div className="registerForm-inputRow">
                        <label>Correo electrónico</label>
                        <input
                          type="email"
                          className={emailError ? "input registerInput inputError" : "input registerInput"}
                          value={email}
                          onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        />
                        <div className="fieldError">{emailError}</div>
                      </div>
                      <div className="registerForm-inputRow">
                        <label>Nombre de tu negocio o servicio</label>
                        <input
                          type="text"
                          className={nombre_negocioError ? "input registerInput inputError" : "input registerInput"}
                          value={nombre_negocio}
                          onChange={(e) => setNombreNegocio(e.target.value)}
                        />
                        <div className="fieldError">{nombre_negocioError}</div>
                      </div>
                      <div className="registerForm-inputRow">
                        <label>Dirección de tu página Web (sitio web, facebook u otros)</label>
                        <input
                          type="text"
                          className={nombre_negocioError ? "input registerInput inputError" : "input registerInput"}
                          value={url_negocio}
                          onChange={(e) => setUrlNegocio(e.target.value)}
                        />
                        <div className="fieldError">{nombre_negocioError}</div>
                      </div>
                      <div className="registerForm-inputRow" style={{ position: "relative" }}>
                        <label>¿Cuál es tu Whatsapp?</label>
                        <div>
                          <p className="phoneInput-placeholder">+52</p>
                          <input
                            className={whatsappError ? "settings-form__input phoneInput inputError" : "settings-form__input phoneInput"}
                            type="number"
                            maxlength="10"
                            style={{ marginBottom: 0 }}
                            value={whatsapp}
                            onChange={(e) => {
                              setWhatsapp(e.target.value)
                              validateNumberLength(e.target.value)
                            }
                            }
                          />
                        </div>
                        <br />
                        <div className="fieldError">{whatsappError}</div>
                      </div>

                      <br />
                      <br />

                    </div>
                    {/* <div className="planContainer-form__optionTotal planContainer-form__optionTotalMobile onlyMobile">

                      <button className="button u-pull-right aButton" onClick={handleSubmit}>
                        {Loading && <i className="" style={{ marginRight: "5px" }} />}
                        {Loading && "Enviando..."}
                        {!Loading && (
                          <span>
                            Enviar solicitud{" "}

                          </span>
                        )}
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade delay={300}>
            <div className="planContainer-form__optionTotal">
              <button
                className="button u-pull-right aButton buttonArrow"
                onClick={handleSubmit}
              >
                {Loading && <i className="" /*style={{ marginRight: "5px" }}*/ />}
                {Loading && "Cargando..."}
                {!Loading && "Enviar Solicitud"}
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 14L15 10L11 6" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15 10L5 10" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
            </div>

          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Vender2;
