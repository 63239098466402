import React, { useEffect, useState, useRef } from "react";
import profileThumb from "../images/profileThumb.png";
import profileThumb2 from "../images/profileThumb2.svg";

import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import Navbar from "./Navbar";
import { CopyToClipboard } from 'react-copy-to-clipboard'
// import { useModal, Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import { loggedIn, getNegocioByDomain, getProductosProfile, route, getSubcategoriasProfile, user_token, bucket_route, postMediaClickCounter } from "./API Functions";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { Redirect } from "react-router-dom";
import ScrollTop from "./ScrollTop";
import Modal from "react-modal";
import Bounce from "react-reveal/Bounce";
import NumberFormat from 'react-number-format';
import { Image } from 'use-image-color'
import {
   FacebookShareButton,
   TwitterShareButton,
   WhatsappShareButton,
} from "react-share";
import Footer from "./Footer";
import EmptyStateProfile from "./EmptyStateProfile";
import ModalProductos from "./ModalProductos";
import useModalProductos from './useModalProductos';
import EmptyStateFree from "./EmptyStateFree";
import ProductList from "./Products/ProductsList";
import Fade from "react-reveal/Fade";

const customStyles = {
   content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      padding: "40px",
      border: "none",
      borderRadius: 12,
      boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.1)"
   },
};

const Perfil = (props) => {

   const [modalIsOpen, setIsOpen] = React.useState(false);
   const [subcategorias, setSubcategorias] = useState([]);
   const [subcategorias_productos, setSubcategoriasProductos] = useState([]);
   const [productos, setProductos] = useState([]);
   const [inHover, setHover] = useState(false);
   const [logged, setLogged] = useState(false);
   const [currentProduct, setCurrentProduct] = useState([]);

   const [negocio, setNegocio] = useState([]);
   const [negocio_id, setNegocioId] = useState("");
   const [filter_subcategoria_id, setFilterSubcategoriaId] = useState("");
   const [detalle, setDetalle] = useState("");
   const [tags, setTags] = useState([]);

   const [detalleError, setDetalleError] = useState("");

   const [shareModalIsOpen, shareIsOpen] = React.useState(false);

   const [copySuccess, setCopySuccess] = useState('');
   const textAreaRef = useRef(null);

   const { isShowing, toggle } = useModalProductos();
   const { flagsShowing, flags } = useModalProductos();
   const { shareShowing, share } = useModalProductos();

   function copyToClipboard(e) {
      textAreaRef.current.select();
      document.execCommand('copy');
      // This is just personal preference.
      // I prefer to not show the whole text area selected.
      e.target.focus();
      setCopySuccess('Copied!');
   };


   function shareModal() {
      shareIsOpen(true);
   }

   function afterOpenModal() {
      // references are now sync'd and can be accessed.
   }

   function closeShareModal() {
      shareIsOpen(false);
   }

   useEffect(() => {
      const negocio = JSON.parse(localStorage.getItem('negocio'));
      if (user_token) {
         loadNegocio(negocio.user_id);
         setNegocio(negocio);
      } else {
         loadNegocio();
      }
   }, [])

   function loadNegocio(user_id) {
      const data = {
         dominio: props.match.params.nombre_negocio
      }
      getNegocioByDomain(data).then(res => {
         const negocio = JSON.parse(localStorage.getItem('negocio'));
         if (res && res.status_id == 2) {
            setNegocio(res);
            loadSubcategorias(res)
            if (user_id == res.user_id) {
               setLogged(true);
            }
         } else if (negocio && negocio.id == res.id) {
            setNegocio(res);
            loadSubcategorias(res)
            if (user_id == res.user_id) {
               setLogged(true);
            }
         }
         else {
            window.location.replace('/404')
         }
      })
   }

   function loadSubcategorias(negocio) {
      getSubcategoriasProfile(negocio.id).then(res => {
         if (res) {
            //setSubcategorias(res);
            loadProductos(res, negocio.id);
         }
      })
   }

   function loadProductos(subcategorias, negocio_id) {
      getProductosProfile(negocio_id).then(res => {
         if (res) {
            const productos = res
            const subcategorias_productos = []
            var no_detail = ""
            const length = subcategorias.length
            //console.log(subcategorias)
            subcategorias.map((subcategoria, index_p) => {
               const subcategoria_productos = []
               productos.map((producto, index) => {
                  if (producto.subcategoria_id == subcategoria.id) {
                     subcategoria_productos.push(producto)
                  }
               })
               if (subcategoria.detalle == "Sin subcategoria") {
                  subcategoria = {
                     ...subcategoria,
                     detalle: "",
                     productos: subcategoria_productos
                  }
                  no_detail = subcategoria;
               } else {
                  subcategoria = {
                     ...subcategoria,
                     productos: subcategoria_productos
                  }
                  subcategorias_productos[subcategoria.arrange_index + 1] = subcategoria;
               }
               //subcategorias_productos[subcategoria.arrange_index + 1] = subcategoria;
            })
            //console.log(subcategorias_productos, '?')
            subcategorias_productos.push(no_detail);
            setProductos(res)
            setSubcategorias(subcategorias_productos)
            setSubcategoriasProductos(subcategorias_productos)
         } else {
            console.log(subcategorias_productos, 'test')
         }
      })
   }

   const refs = subcategorias.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
   }, {});

   const ScrollToSubcategoria = id =>
      refs[id].current.scrollIntoView({
         behavior: 'smooth',
         block: 'start',
      });

   const url = window.location.href

   function copiedPopUp() {
      const copiedPop = document.getElementById("copiedPop");
      copiedPop.classList.add("copiedPop-show");
      copiedPop.classList.add("copiedPop-exit");
   }

   function showProducts(products){
      return(
         <ProductList
            productos = {products}
            disabled_logo = {true}
         />
      )
   }
   function mediaClickCounter(media) {
      const data={
         negocio_id : negocio.id,
         media : media
      }
      postMediaClickCounter(data).then(res=>{
         if(res){
            console.log('updated_clicks');
         }
      })
   }
   

   return (
      <div>
         {subcategorias_productos.length > 0 ?
            <div>
               {/* <Helmet>
            <title>{negocio.nombre} | Okki</title>
         </Helmet> */}
               <Navbar negocio={negocio} />
               <div className=" container settings-headerAlt">
                  <div className="row rowProfile rowProfile-header">
                     <div className={negocio.pro ? "rowProfile-thumbPro" : "rowProfile-thumb"}>
                        <img className="rowProfile-thumb" src={negocio.imagen_url !== '' ? `${bucket_route}${negocio.imagen_url}` : profileThumb2} />
                     </div>
                     <div className="rowProfile-headerInfo">
                        <div className="rowProfile-top">
                           <div className="rowProfile-logo">
                              <p className="rowProfile-title rowProfile-responsive">{negocio.nombre}</p>
                           </div> 
                           <div className="rowProfile-actions">
                              {negocio.telefono ?
                                 <a href={`tel:+${negocio.telefono}`} onClick={()=>{mediaClickCounter('phone')}}>
                                    <svg
                                       width="20"
                                       height="20"
                                       viewBox="0 0 20 20"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M5.7069 1.76386C5.01564 1.0763 3.9469 1.07872 3.2583 1.77114L2.56215 2.47114C1.72741 3.3105 1.25 4.50017 1.25 5.6V6C1.25 6.00753 1.25011 6.01506 1.25034 6.02259C1.46096 13.0116 6.94362 18.5372 13.8113 18.7496C13.819 18.7499 13.8268 18.75 13.8345 18.75H14.3318C15.5658 18.75 16.7427 18.1393 17.5487 17.3289L18.0893 16.7853C19.0084 16.1523 18.8775 14.9073 18.2448 14.2711L16.1564 12.1711C15.4654 11.4763 14.3915 11.4763 13.7005 12.1711L12.6643 13.2131C11.8705 12.8653 11.1073 12.4483 10.418 11.9092C10.3942 11.8906 10.3693 11.8734 10.3434 11.8578C9.45651 11.3227 8.63459 10.5034 7.98582 9.5715C7.97039 9.54934 7.95378 9.52803 7.93606 9.50767C7.4033 8.89543 6.99394 8.21215 6.65367 7.38418L7.80262 6.22886C8.49137 5.53631 8.49137 4.46369 7.80262 3.77114L7.78957 3.75832L5.7069 1.76386ZM4.48624 2.75C4.44415 2.75 4.38496 2.76544 4.32189 2.82886L3.62574 3.52886C3.06819 4.0895 2.75 4.89983 2.75 5.6V5.98784C2.94252 12.1896 7.79446 17.0568 13.8463 17.25H14.3318C15.0867 17.25 15.8988 16.8607 16.4851 16.2711L17.0818 15.6711C17.1251 15.6276 17.1736 15.5895 17.2262 15.5578C17.2368 15.5514 17.2387 15.5476 17.2401 15.5452C17.2426 15.5406 17.2486 15.5271 17.2498 15.502C17.2525 15.4454 17.2287 15.3765 17.1813 15.3289L15.0928 13.2289C15.0297 13.1654 14.9705 13.15 14.9285 13.15C14.8864 13.15 14.8272 13.1654 14.7641 13.2289L13.3718 14.6289C13.1593 14.8425 12.8397 14.9083 12.5601 14.7958C11.5404 14.3857 10.4898 13.8622 9.5295 13.1185C8.45492 12.461 7.50942 11.5042 6.77784 10.4615C6.02384 9.5867 5.49174 8.61079 5.07379 7.45506C4.97511 7.18219 5.04268 6.87688 5.2473 6.67113L6.73904 5.17114C6.8022 5.10763 6.81918 5.04618 6.81918 5C6.81918 4.95499 6.80305 4.89547 6.74374 4.83367L4.66365 2.84168L4.6506 2.82886C4.58753 2.76544 4.52834 2.75 4.48624 2.75Z"
                                          fill="#66666A"
                                       />
                                    </svg>
                                 </a>
                                 :
                                 null
                              }
                              {
                                 negocio.website ?
                                    <a target='_blank' href={negocio.website.includes('http') ? `${negocio.website}` : `http://${negocio.website}`} rel="noopener noreferrer"  >
                                       <svg
                                          onClick={()=>{mediaClickCounter('website')}}
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                       >
                                          <path
                                             fill-rule="evenodd"
                                             clip-rule="evenodd"
                                             d="M10 2.75C9.33904 2.75 8.53236 3.31232 7.84582 4.68541C7.26248 5.85209 6.85741 7.4479 6.76842 9.25H13.2316C13.1426 7.4479 12.7375 5.85209 12.1542 4.68541C11.4676 3.31232 10.661 2.75 10 2.75ZM13.2316 10.75H6.76842C6.85741 12.5521 7.26248 14.1479 7.84582 15.3146C8.53236 16.6877 9.33904 17.25 10 17.25C10.661 17.25 11.4676 16.6877 12.1542 15.3146C12.7375 14.1479 13.1426 12.5521 13.2316 10.75ZM5.26678 9.25C5.35672 7.24793 5.80386 5.41523 6.50418 4.01459C6.59209 3.83877 6.6857 3.66679 6.78487 3.50009C4.60632 4.57976 3.04809 6.72244 2.78832 9.25H5.26678ZM2.78832 10.75H5.26678C5.35672 12.7521 5.80386 14.5848 6.50418 15.9854C6.59209 16.1612 6.6857 16.3332 6.78488 16.4999C4.60632 15.4202 3.04809 13.2776 2.78832 10.75ZM14.7332 10.75H17.2117C16.9519 13.2776 15.3937 15.4202 13.2151 16.4999C13.3143 16.3332 13.4079 16.1612 13.4958 15.9854C14.1961 14.5848 14.6433 12.7521 14.7332 10.75ZM17.2117 9.25H14.7332C14.6433 7.24793 14.1961 5.41523 13.4958 4.01459C13.4079 3.83877 13.3143 3.66679 13.2151 3.50009C15.3937 4.57976 16.9519 6.72244 17.2117 9.25ZM18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10Z"
                                             fill="#66666A"
                                          />
                                       </svg>
                                    </a>
                                    :
                                    null
                              }
                              {negocio.direccion ?
                                 <a target='_blank' href={`${negocio.direccion}`} rel="noopener noreferrer" onClick={()=>{mediaClickCounter('maps')}} >
                                    <svg
                                       width="20"
                                       height="20"
                                       viewBox="0 0 20 20"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M15.4161 3.37798C12.4307 0.539108 7.48729 0.540675 4.50407 3.38268C1.49864 6.24584 1.49864 11.0201 4.50407 13.8833L9.50066 18.5482C9.79097 18.8193 10.2422 18.817 10.5298 18.543L15.4211 13.8833C18.5189 10.9321 18.5354 6.24845 15.4161 3.37798ZM14.3864 4.46873C11.9809 2.17709 7.94422 2.17709 5.53871 4.46873C3.15465 6.73994 3.15376 10.5224 5.53605 12.7947L10.0073 16.9691L14.3864 12.7972C16.877 10.4246 16.8621 6.74318 14.3964 4.47803L14.3864 4.46873Z"
                                          fill="#66666A"
                                       />
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M10 6.75C8.75736 6.75 7.75 7.75736 7.75 9C7.75 10.2426 8.75736 11.25 10 11.25C11.2426 11.25 12.25 10.2426 12.25 9C12.25 7.75736 11.2426 6.75 10 6.75ZM6.25 9C6.25 6.92893 7.92893 5.25 10 5.25C12.0711 5.25 13.75 6.92893 13.75 9C13.75 11.0711 12.0711 12.75 10 12.75C7.92893 12.75 6.25 11.0711 6.25 9Z"
                                          fill="#66666A"
                                       />
                                    </svg>
                                 </a>
                                 :
                                 null
                              }
                              {negocio.facebook ?
                                 <a target='_blank' href={`https://www.facebook.com/${negocio.facebook}`} rel="noopener noreferrer" onClick={()=>{mediaClickCounter('facebook')}} >
                                    <svg
                                       width="20"
                                       height="20"
                                       viewBox="0 0 20 20"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M6 2.75C4.21421 2.75 2.75 4.21421 2.75 6V14C2.75 15.7858 4.21421 17.25 6 17.25H14C15.7858 17.25 17.25 15.7858 17.25 14V6C17.25 4.21421 15.7858 2.75 14 2.75H6ZM1.25 6C1.25 3.38579 3.38579 1.25 6 1.25H14C16.6142 1.25 18.75 3.38579 18.75 6V14C18.75 16.6142 16.6142 18.75 14 18.75H6C3.38579 18.75 1.25 16.6142 1.25 14V6Z"
                                          fill="#66666A"
                                       />
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M10.25 8C10.25 6.48579 11.4858 5.25 13 5.25H14C14.4142 5.25 14.75 5.58579 14.75 6C14.75 6.41421 14.4142 6.75 14 6.75H13C12.3142 6.75 11.75 7.31421 11.75 8V18C11.75 18.4142 11.4142 18.75 11 18.75C10.5858 18.75 10.25 18.4142 10.25 18V8Z"
                                          fill="#66666A"
                                       />
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M8.25 10C8.25 9.58579 8.58579 9.25 9 9.25H13C13.4142 9.25 13.75 9.58579 13.75 10C13.75 10.4142 13.4142 10.75 13 10.75H9C8.58579 10.75 8.25 10.4142 8.25 10Z"
                                          fill="#66666A"
                                       />
                                    </svg>
                                 </a>
                                 :
                                 null
                              }
                              {negocio.instagram ?
                                 <a target='_blank' href={`https://www.instagram.com/${negocio.instagram}`} rel="noopener noreferrer" onClick={()=>{mediaClickCounter('instagram')}} >
                                    <svg
                                       width="20"
                                       height="20"
                                       viewBox="0 0 20 20"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M1.25 6C1.25 3.38579 3.38579 1.25 6 1.25H14C16.6142 1.25 18.75 3.38579 18.75 6V14C18.75 16.6142 16.6142 18.75 14 18.75H6C3.38579 18.75 1.25 16.6142 1.25 14V6ZM6 2.75C4.21421 2.75 2.75 4.21421 2.75 6V14C2.75 15.7858 4.21421 17.25 6 17.25H14C15.7858 17.25 17.25 15.7858 17.25 14V6C17.25 4.21421 15.7858 2.75 14 2.75H6Z"
                                          fill="#66666A"
                                       />
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M10 7.75C8.75736 7.75 7.75 8.75736 7.75 10C7.75 11.2426 8.75736 12.25 10 12.25C11.2426 12.25 12.25 11.2426 12.25 10C12.25 8.75736 11.2426 7.75 10 7.75ZM6.25 10C6.25 7.92893 7.92893 6.25 10 6.25C12.0711 6.25 13.75 7.92893 13.75 10C13.75 12.0711 12.0711 13.75 10 13.75C7.92893 13.75 6.25 12.0711 6.25 10Z"
                                          fill="#66666A"
                                       />
                                       <path
                                          d="M14 7C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5C13.4477 5 13 5.44772 13 6C13 6.55228 13.4477 7 14 7Z"
                                          fill="#66666A"
                                       />
                                    </svg>
                                 </a>
                                 :
                                 null
                              }
                              <a onClick={shareModal}>
                                 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.9643C4.41421 10.9643 4.75 11.3001 4.75 11.7143V15.25H15.25V11.7143C15.25 11.3001 15.5858 10.9643 16 10.9643C16.4142 10.9643 16.75 11.3001 16.75 11.7143V16C16.75 16.4142 16.4142 16.75 16 16.75H4C3.58579 16.75 3.25 16.4142 3.25 16V11.7143C3.25 11.3001 3.58579 10.9643 4 10.9643Z" fill="#66666A" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.76859 3.28638C9.65923 3.32173 9.55638 3.38282 9.46953 3.46967L6.04096 6.89824C5.74807 7.19113 5.74807 7.66601 6.04096 7.9589C6.33385 8.25179 6.80873 8.25179 7.10162 7.9589L9.25 5.81052V11.7143C9.25 12.1285 9.58579 12.4643 10 12.4643C10.4142 12.4643 10.75 12.1285 10.75 11.7143V5.8108L12.8981 7.9589C13.191 8.25179 13.6659 8.25179 13.9588 7.9589C14.2517 7.66601 14.2517 7.19113 13.9588 6.89824L10.5389 3.47837C10.4025 3.33754 10.2115 3.25 10 3.25C9.99298 3.25 9.98598 3.2501 9.97901 3.25029" fill="#66666A" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                        <div className="">
                        <p className="rowProfile-about">{negocio.descripcion}</p>
                     </div>
                        <div className="rowProfile-top rowProfile-bottom">
                           <div style={{ display: "flex", flexWrap: "wrap" }} className="rowProfile-bottom__tags">
                              <p className="itemTagAlt">{negocio.categoria ? negocio.categoria.detalle : ''}</p>
                              <p className="itemTagAlt">{negocio.rango_precio ? negocio.rango_precio.detalle : ''}</p>
                              {negocio.envios[0].id == 1 ?
                                 <>
                                    <p className="itemTagAlt">{negocio.envios ? negocio.envios[0].nombre : ''}</p>
                                    <p className="itemTagAlt">
                                       {"Envío "}
                                       <NumberFormat decimalScale={2} isNumericString={true} value={negocio.precio_envio} displayType={'text'} thousandSeparator={true} prefix={' $'} />
                                    </p>
                                 </>
                                 :
                                 <p className="itemTagAlt">{'A convenir'}</p>

                              }
                              <p className="itemTagAlt">{negocio.estado ? negocio.estado.name : ''}</p>
                              <p className="itemTagAlt">{negocio.ciudad ? negocio.ciudad.nombre : ''}</p>
                           </div>
                           <div style={{ display: "flex" }}>
                              {logged &&
                                 <a className="itemTagButton" href="/catalogo" >
                                    Editar
                                 </a>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {negocio.pro ?
                  <div className="home-gallery__filters home-gallery__filtersProfile">
                     <ul className="home-gallery__filters-list">
                        {subcategorias_productos.map((subcategoria, index) => (
                           <li onClick={() => { ScrollToSubcategoria(subcategoria.id) }}>
                              <a>
                                 {subcategoria.productos.length > 0 ? subcategoria.detalle : null}
                              </a>
                           </li>
                        ))
                        }
                     </ul>
                  </div> :
                  <hr style={{ maxWidth: "none" }} />}

               <div className="home-gallery container home-galleryProfile">
                  {subcategorias_productos.map((subcategoria, index) => (
                     <div key={index} ref={refs[subcategoria.id]} className={subcategoria.productos.length > 0 ? '' : ''}>
                        <hr />
                        <h3 className="titleMin">{subcategoria.detalle}</h3>
                        {showProducts(subcategoria.productos)}
                     </div>
                  ))
                  }
               </div>
               <ScrollTop />
               <Footer />
               <Modal
                  isOpen={shareModalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeShareModal}
                  style={customStyles}
               >
                  <div className="shareModal">
                     {/* <svg onClick={closeShareModal} className="flagModal-close" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="41" height="41" rx="20.5" stroke="white" stroke-opacity="0.25" />
                  <path d="M25.2422 16.7573L16.7569 25.2426" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M25.2431 25.2426L16.7578 16.7573" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
               </svg> */}
                     <FacebookShareButton resetButtonStyle={false} url={url} className="shareModal-button facebook">
                        Facebook
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M14 18H6C3.8 18 2 16.2 2 14V6C2 3.8 3.8 2 6 2H14C16.2 2 18 3.8 18 6V14C18 16.2 16.2 18 14 18Z" stroke="#66666A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M14 6H13C11.9 6 11 6.9 11 8V18" stroke="#66666A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M9 10H13" stroke="#66666A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                     </FacebookShareButton>

                     <TwitterShareButton resetButtonStyle={false} url={url} className="shareModal-button twitter">
                        Twitter
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M16.2222 5C16.0131 4.8 15.8039 4.6 15.5948 4.5C15.0719 4.2 14.4444 4 13.817 4C12.5621 4 11.5163 4.8 10.9935 5.8C10.7843 6.2 10.6797 6.6 10.6797 7C10.6797 7.2 10.6797 7.3 10.6797 7.5C10.4706 7.5 10.2614 7.5 10.1569 7.5C7.85621 7.5 5.76471 6.3 4.71895 4.5C4.19608 5.4 3.88235 6.4 3.88235 7.5C3.88235 10.1 5.66013 12.4 8.16993 13.2C7.01961 14.1 5.55556 14.5 3.88235 14.5C3.2549 14.5 2.62745 14.4 2 14.3C3.56863 15.4 5.45098 16 7.54248 16C12.7712 16 16.9542 12 16.9542 7L18 5H16.2222V5Z" stroke="#66666A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                     </TwitterShareButton>
                     <WhatsappShareButton resetButtonStyle={false} url={url} className="shareModal-button whatsapp">
                        Whatsapp
                  <svg
                           width="20"
                           height="20"
                           viewBox="0 0 20 20"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.7069 1.76386C5.01564 1.0763 3.9469 1.07872 3.2583 1.77114L2.56215 2.47114C1.72741 3.3105 1.25 4.50017 1.25 5.6V6C1.25 6.00753 1.25011 6.01506 1.25034 6.02259C1.46096 13.0116 6.94362 18.5372 13.8113 18.7496C13.819 18.7499 13.8268 18.75 13.8345 18.75H14.3318C15.5658 18.75 16.7427 18.1393 17.5487 17.3289L18.0893 16.7853C19.0084 16.1523 18.8775 14.9073 18.2448 14.2711L16.1564 12.1711C15.4654 11.4763 14.3915 11.4763 13.7005 12.1711L12.6643 13.2131C11.8705 12.8653 11.1073 12.4483 10.418 11.9092C10.3942 11.8906 10.3693 11.8734 10.3434 11.8578C9.45651 11.3227 8.63459 10.5034 7.98582 9.5715C7.97039 9.54934 7.95378 9.52803 7.93606 9.50767C7.4033 8.89543 6.99394 8.21215 6.65367 7.38418L7.80262 6.22886C8.49137 5.53631 8.49137 4.46369 7.80262 3.77114L7.78957 3.75832L5.7069 1.76386ZM4.48624 2.75C4.44415 2.75 4.38496 2.76544 4.32189 2.82886L3.62574 3.52886C3.06819 4.0895 2.75 4.89983 2.75 5.6V5.98784C2.94252 12.1896 7.79446 17.0568 13.8463 17.25H14.3318C15.0867 17.25 15.8988 16.8607 16.4851 16.2711L17.0818 15.6711C17.1251 15.6276 17.1736 15.5895 17.2262 15.5578C17.2368 15.5514 17.2387 15.5476 17.2401 15.5452C17.2426 15.5406 17.2486 15.5271 17.2498 15.502C17.2525 15.4454 17.2287 15.3765 17.1813 15.3289L15.0928 13.2289C15.0297 13.1654 14.9705 13.15 14.9285 13.15C14.8864 13.15 14.8272 13.1654 14.7641 13.2289L13.3718 14.6289C13.1593 14.8425 12.8397 14.9083 12.5601 14.7958C11.5404 14.3857 10.4898 13.8622 9.5295 13.1185C8.45492 12.461 7.50942 11.5042 6.77784 10.4615C6.02384 9.5867 5.49174 8.61079 5.07379 7.45506C4.97511 7.18219 5.04268 6.87688 5.2473 6.67113L6.73904 5.17114C6.8022 5.10763 6.81918 5.04618 6.81918 5C6.81918 4.95499 6.80305 4.89547 6.74374 4.83367L4.66365 2.84168L4.6506 2.82886C4.58753 2.76544 4.52834 2.75 4.48624 2.75Z"
                              fill="#66666A"
                           />
                        </svg>
                     </WhatsappShareButton>
                     <CopyToClipboard text={url}>
                        <a onClick={copiedPopUp} className="shareModal-button link">
                           Copiar
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.1973 8.76479C9.7236 7.30073 7.421 7.30073 5.94733 8.76479L4.10525 10.6864C2.63158 12.1504 2.63158 14.438 4.10525 15.9021C5.57892 17.3661 7.88152 17.3661 9.35519 15.9021" stroke="#66666A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8.71094 11.2353C10.1846 12.6994 12.4872 12.6994 13.9609 11.2353L15.8951 9.31374C17.3687 7.84968 17.3687 5.5621 15.8951 4.09804C14.4214 2.63399 12.1188 2.63399 10.6451 4.09804" stroke="#66666A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                           </svg>
                        </a>
                     </CopyToClipboard>

                  </div>
               </Modal>
               <div id="copiedPop" className="draftView-bottom copiedPop">
                  <Bounce bottom>
                     <p className="draftView-message">¡El enlace se ha copiado al portapapeles!</p>
                  </Bounce>
               </div>
            </div>
            :
            negocio.id ?
               negocio.pro ?
                  <EmptyStateProfile />
                  :
                  <EmptyStateFree />
               :
               null
         }
         
      </div>
   );
}

export default Perfil;
