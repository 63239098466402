import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import welcomeCover from "../images/welcomeCover.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Select, { components } from "react-select";

import selectIcon from "../images/select_icon.svg";

const options = [
    { value: "new", label: "Lo más nuevo" },
    { value: "old", label: "Lo más antiguo" },
    { value: "relevant", label: "Relevante" },
];

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

function Location() {

    return (
        <>
            <div className="modalOverlay locationModal">

                <div>
                    <div className="modal-content">
                        <div className="modal-text">
                            <p>
                                Al darnos tu ubicación, podemos localizar los negocios más cerca de ti, y solamente la utilizaremos para eso.
                            </p>
                            <br />
                            <Select
                                classNamePrefix="inputSelect"
                                className="inputSelect"
                                options={options}
                                placeholder="Reciente"
                                components={{ DropdownIndicator }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 12,
                                    colors: {
                                        ...theme.colors,
                                        primary50: "#ccc",
                                        primary25: "#F6F6F6",
                                        primary: "black",
                                    },
                                })}
                                styles={{
                                    dropdownIndicator: (provided, state) => ({
                                        ...provided,
                                        transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                    })
                                }}
                            />
                            <br />
                            <a className="button buttonIconRight buttonCenter">
                                <span>Comenzar</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Location;