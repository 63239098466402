import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import profileThumb from "../images/profileThumb.png";
import Navbar from "./Navbar";
import { getNegocios, putNegocios, deleteAccount, getCurrentNegocio } from "./API Functions";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import Settings5 from "./Settings-5";
import Settings3 from "./Settings-3";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        padding: "50px 40px",
        border: "none",
        borderRadius: 12,
    },
};

function Settings4(props) {
    var subtitle;

    const [Loading, setLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const [negocio, setNegocio] = useState([]);
    const [nuevoNegocio, setNewNegocio] = useState(false);

    const [presona_contacto, setPersonaContacto] = useState("");
    const [email, setEmail] = useState("");

    const [presona_contactoError, setPersonaContactoError] = useState("");
    const [passwordConfirmationError, setPasswordConfirmationError] = useState("");

    const [isToggledNotifications, setToggledNotifications] = useState(true);
    const toggleTrueFalseNotifications = () => setToggledNotifications(!isToggledNotifications);

    const [isToggledNews, setToggledNews] = useState(true);
    const toggleTrueFalseNews = () => setToggledNews(!isToggledNews);

    const [settings5, setSettings5] = useState(false);
    const [settings3, setSettings3] = useState(false);

    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [toggleEye, setToggleEye] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
        toggle();
    };
    function toggle() {
        setToggleEye(toggleEye ? false : true);
    }

    const [passwordShown2, setPasswordShown2] = useState(false);
    const [toggleEye2, setToggleEye2] = useState(false);
    const togglePasswordVisiblity2 = () => {
        setPasswordShown2(passwordShown2 ? false : true);
        toggle2();
    };
    function toggle2() {
        setToggleEye2(toggleEye2 ? false : true);
    }

    useEffect(() => {
        if (props.negocio) {
            const negocio = props.negocio;
            if (negocio.nuevo) {
                setPersonaContacto(negocio.user.nombre);
                setEmail(negocio.user.correo);
            } else {
                setEmail(negocio.user.correo);
                setPersonaContacto(negocio.persona_contacto)
                setToggledNews(negocio.news);
                setToggledNotifications(negocio.notifications);
            }
            setNegocio(negocio);
        } else {
            getCurrentNegocio().then(res => {
                if (res) {
                    const negocio = res;
                    if (negocio.nuevo) {
                        setPersonaContacto(negocio.user.nombre);
                        setEmail(negocio.user.correo);
                    } else {
                        setEmail(negocio.user.correo);
                        setPersonaContacto(negocio.persona_contacto);
                        setToggledNews(negocio.news);
                        setToggledNotifications(negocio.notifications);
                    }
                    setNegocio(negocio);
                }
            })
        }

    }, [])

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function validateFields() {
        let presona_contactoError = "";
        let passwordConfirmationError = "";
        /*let instagramError = "";
        let facebookError = "";
      
        if (web == "") {
         webError = "Requerido";
        }
        if (instagram == "" || instagram == null) {
         instagramError = "Requerido";
        } */

        if ([presona_contacto] == "") {
            presona_contactoError = "Requerido";
        }

        if (password !== "" && passwordConfirmation !== password) {
            passwordConfirmationError = "Las contraseñas no coinciden"
        }


        if (
            presona_contactoError ||
            passwordConfirmationError
        ) {
            setPersonaContactoError(presona_contactoError);
            setPasswordConfirmationError(passwordConfirmationError)
            //setInstagramError(instagramError);
            //setFacebookError(facebookError);
            return false;
        } else {
            setPersonaContactoError(presona_contactoError);
            setPasswordConfirmationError(passwordConfirmationError)
            //setInstagramError(instagramError);
            //setFacebookError(facebookError);
            return true;
        }
    }

    function handleSubmit() {
        setLoading(true)
        const data_content = {
            id: negocio.id,
            tags_ids: negocio.tags_ids,
            nombre: negocio.nombre,
            codigo_postal: negocio.codigo_postal,
            direccion: negocio.direccion,
            whatsap: negocio.whatsap,
            telefono: negocio.whatsap,
            categoria_id: negocio.categoria_id,
            descripcion: negocio.descripcion,
            dominio_okki: negocio.dominio_okki,
            rango_id: negocio.rango_id,
            ciudad_id: negocio.ciudad_id,
            zona: negocio.zona,
            precio_envio: negocio.precio_envio,
            envios: [negocio.envios[0].id],
            tipos: [negocio.tipos[0].id],
            instagram: negocio.instagram,
            facebook: negocio.facebook,
            website: negocio.website,
            //EDITED FIELDS ON VIEW
            persona_contacto: presona_contacto,
            new_password: passwordConfirmation !== "" ? passwordConfirmation : false,
            notifications: isToggledNotifications,
            news: isToggledNews
        }
        const isValid = validateFields()
        if (isValid) {
            const data = {
                id: negocio.id,
                data: data_content,

            }
            putNegocios(data).then(res => {
                if (res) {
                    if (res.id) {
                        localStorage.setItem('negocio', JSON.stringify(res));
                        //setNegocio(res);
                        changeSettings(res);
                    } else {
                        localStorage.setItem('negocio', JSON.stringify(res));
                        window.location.reload();
                    }

                }
            })
        }
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    function changeSettings(negocio) {
        setSettings5(true);
    }

    function handleDelete() {
        deleteAccount().then(res => {
            if (res) {
                window.location.replace("/login")
            }
        })
    }
    return (
        <>
            {settings5 ?
                <Settings5 negocio={negocio} />
                :
                settings3 ?
                    <Settings3 negocio={negocio} />
                    :
                    negocio.id ?
                        <>
                            {negocio.nuevo ?
                                <div className="four columns settings-form__sidebar onlyDesktop" style={{ marginLeft: 0 }}>

                                    <div className="settings-header settings-headerModal">
                                        <div className="settings-header__breadcrumb settings-header__breadcrumb-title">
                                            <h3 className="settings-header__title">Configuración</h3>

                                            <hr className="settingsDivider" />
                                        </div>
                                    </div>
                                    <ul className="settings-form__menu settings-form__menuList">
                                        <li>
                                            <Link className="">
                                                Perfil
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Negocio
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Dirección y Envíos
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Redes Sociales
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="active">
                                                Cuenta
                                    </Link>
                                        </li>
                                        <li>
                                            <Link className="">
                                                Plan
                                    </Link>
                                        </li>
                                        {/* <li>
                        <Link to="/pro" target="_blank" activeClassName="active">
                          PRO
                                    </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: `/${this.state.negocio.dominio_okki}` }} activeClassName="active">
                          Mi perfil
                                    </Link>
                      </li> */}
                                    </ul>

                                </div>
                                : null}
                            <div className="eight columns settings-form__container" style={{ maxHeight: "620px" }}>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>Persona contacto</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <input
                                            className={presona_contactoError ? "settings-form__input inputError" : "settings-form__input"}
                                            type="text"
                                            placeholder="Persona contacto"
                                            value={presona_contacto}
                                            onChange={e => setPersonaContacto(e.target.value)}
                                        />
                                        <div className="fieldError">{presona_contactoError}</div>
                                    </div>
                                </div>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>Correo Electrónico</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <input
                                            className="settings-form__input"
                                            type="email"
                                            placeholder="correo@ejemplo.com"
                                            value={email}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <hr style={{ marginBottom: "20px" }} />
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>Nueva contraseña</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <div className="pass-wrapper">
                                            <input
                                                placeholder="Password"
                                                name="password"
                                                placeholder="Contraseña"
                                                type={passwordShown ? "text" : "password"}
                                                value={password}
                                                className={passwordConfirmationError ? "settings-form__input inputError" : "settings-form__input"}

                                                onChange={(e) => setPassword(e.target.value)}
                                            // onKeyDown={handleKeyDown}
                                            // ref={register({ required: "This is required." })}
                                            />
                                            {toggleEye ? (
                                                <svg onClick={togglePasswordVisiblity} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.0002 1L1.09961 19" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7.16603 12.7999C5.57487 11.1999 5.57487 8.6999 7.16603 7.0999C8.75719 5.4999 11.2434 5.4999 12.8345 7.0999" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7.36523 16.6C8.16081 16.8 9.05584 17 9.95087 17C16.9122 17 18.9011 9.99995 18.9011 9.99995C18.9011 9.99995 18.4039 8.39995 17.2105 6.69995" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M15.221 4.7C13.9282 3.7 12.2376 3 9.95028 3C2.98895 3 1 10 1 10C1 10 1.89503 13.2 4.67956 15.3" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            ) : (
                                                    <svg onClick={togglePasswordVisiblity} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M19 10C19 10 17 3 10 3C3 3 1 10 1 10C1 10 3 17 10 17C17 17 19 10 19 10Z" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                )}

                                        </div>
                                        {/* <input
                                    className="settings-form__input"
                                    type="password"
                                    placeholder="Nueva contraseña"
                                /> */}
                                    </div>
                                </div>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabel">
                                        <label>Confirmar contraseña</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnField">
                                        <div className="pass-wrapper">
                                            <input
                                                placeholder="Password"
                                                name="password"
                                                placeholder="Confirmar Contraseña"
                                                className={passwordConfirmationError ? "settings-form__input inputError" : "settings-form__input"}

                                                type={passwordShown ? "text" : "password"}
                                                value={passwordConfirmation}
                                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                            // onKeyDown={handleKeyDown}
                                            // ref={register({ required: "This is required." })}
                                            />
                                            {toggleEye2 ? (

                                                <svg onClick={togglePasswordVisiblity2} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.0002 1L1.09961 19" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7.16603 12.7999C5.57487 11.1999 5.57487 8.6999 7.16603 7.0999C8.75719 5.4999 11.2434 5.4999 12.8345 7.0999" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7.36523 16.6C8.16081 16.8 9.05584 17 9.95087 17C16.9122 17 18.9011 9.99995 18.9011 9.99995C18.9011 9.99995 18.4039 8.39995 17.2105 6.69995" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M15.221 4.7C13.9282 3.7 12.2376 3 9.95028 3C2.98895 3 1 10 1 10C1 10 1.89503 13.2 4.67956 15.3" stroke="#081027" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            ) : (
                                                    <svg onClick={togglePasswordVisiblity2} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M19 10C19 10 17 3 10 3C3 3 1 10 1 10C1 10 3 17 10 17C17 17 19 10 19 10Z" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                )}

                                        </div>
                                        <div className="fieldError">{passwordConfirmationError}</div>
                                        {/* <input
                                    className="settings-form__input"
                                    type="password"
                                    placeholder="Confirmar contraseña"
                                /> */}
                                    </div>
                                </div>
                                <hr style={{ marginBottom: "30px" }} />
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabelAlt">
                                        <label>Notificaciones por correo</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnSwitch">
                                        <p>Recibe notificaciones cuando alguien visita tu perfil o alguno de tus productos.</p>
                                        <label class="switch">
                                            <input onClick={toggleTrueFalseNotifications} value={isToggledNotifications} type="checkbox" checked={isToggledNotifications} />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabelAlt">
                                        <label>Boletín de noticias</label>
                                    </div>
                                    <div className="settings-form__column settings-form__columnSwitch">
                                        <p>Recibe noticias y notificaciones de actualizaciones en la plataforma.</p>
                                        <label class="switch">
                                            <input onClick={toggleTrueFalseNews} value={isToggledNews} type="checkbox" checked={isToggledNews} />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <hr />
                                <div className="settings-form__row">
                                    <div className="settings-form__column settings-form__columnLabelDelete">
                                        <label>Eliminar cuenta </label>
                                        <svg data-tip="Permanentemente elimina tu cuenta<br/> y todo su contenido. Esta acción<br/>no se puede deshacer." width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z" fill="#66666A" fill-opacity="0.25" />
                                            <path d="M6.83154 8.8877C6.83154 8.65495 6.87093 8.4598 6.94971 8.30225C7.03206 8.14469 7.14665 8.01042 7.29346 7.89941C7.44385 7.78841 7.64258 7.67204 7.88965 7.55029C8.11165 7.43929 8.28174 7.28711 8.3999 7.09375C8.51807 6.89681 8.57715 6.64258 8.57715 6.33105C8.57715 6.0625 8.52344 5.82975 8.41602 5.63281C8.30859 5.43587 8.15999 5.28727 7.97021 5.18701C7.78044 5.08317 7.56559 5.03125 7.32568 5.03125C7.08219 5.03125 6.86556 5.08675 6.67578 5.19775C6.48958 5.30876 6.34277 5.46273 6.23535 5.65967C6.13151 5.85303 6.07959 6.07503 6.07959 6.32568C6.07959 6.46175 6.09749 6.61393 6.1333 6.78223L5.03223 6.65869C5.01074 6.5083 5 6.36328 5 6.22363C5 5.79395 5.09668 5.41081 5.29004 5.07422C5.48698 4.73405 5.75911 4.47087 6.10645 4.28467C6.45736 4.09489 6.8584 4 7.30957 4C7.76074 4 8.16536 4.09489 8.52344 4.28467C8.88151 4.47445 9.1626 4.74479 9.3667 5.0957C9.5708 5.44303 9.67285 5.84587 9.67285 6.3042C9.67285 6.84489 9.55111 7.27816 9.30762 7.604C9.06771 7.92985 8.76335 8.16618 8.39453 8.31299C8.20833 8.39176 8.07227 8.48307 7.98633 8.58691C7.90039 8.68717 7.85742 8.82682 7.85742 9.00586V9.51611H6.83154V8.8877ZM7.37402 11.8794C7.23796 11.8794 7.11084 11.8454 6.99268 11.7773C6.87809 11.7129 6.78678 11.6234 6.71875 11.5088C6.65072 11.3906 6.6167 11.2635 6.6167 11.1274C6.6167 10.9914 6.65072 10.866 6.71875 10.7515C6.79036 10.6369 6.88346 10.5456 6.99805 10.4775C7.11263 10.4095 7.23796 10.3755 7.37402 10.3755C7.50651 10.3755 7.62826 10.4095 7.73926 10.4775C7.85384 10.5456 7.94515 10.6369 8.01318 10.7515C8.08122 10.866 8.11523 10.9914 8.11523 11.1274C8.11523 11.2635 8.08122 11.3906 8.01318 11.5088C7.94515 11.6234 7.85384 11.7129 7.73926 11.7773C7.62826 11.8454 7.50651 11.8794 7.37402 11.8794Z" fill="white" />
                                        </svg>
                                        <ReactTooltip className="tooltipStyle" multiline="true" place="top" type="dark" effect="solid" />
                                    </div>
                                    <div className="settings-form__column settings-form__columnField settings-form__columnFieldDelete">
                                        <a className="button btnDelete" onClick={openModal}>Eliminar Cuenta</a>
                                        <p style={{ color: "#888888" }}>
                                            Tu cuenta será eliminada de nuestra base de datos, ya no tendrás acceso a la información que se encuentra en ésta tu cuenta ni los clientes podrán ver tus productos ni tu perfil.
                                        </p>
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                {negocio.nuevo ?
                                    <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                                        <a className="button button-alphaAlt" onClick={() => { setSettings3(true) }}>Regresar</a>
                                        <a className="button" onClick={handleSubmit}>
                                            {Loading && (
                                                <i className="" style={{ marginRight: "5px" }} />
                                            )}
                                            {Loading && (
                                                'Guardando...'
                                            )}
                                            {!Loading && (
                                                negocio.nuevo ?
                                                    'Continuar'
                                                    :
                                                    'Guardar'
                                            )}
                                        </a>
                                    </div>
                                    :
                                    <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                                        <a>{" "}</a>
                                        <a className="button" onClick={handleSubmit}>
                                            {Loading && (
                                                <i className="" style={{ marginRight: "5px" }} />
                                            )}
                                            {Loading && (
                                                'Guardando...'
                                            )}
                                            {!Loading && (
                                                negocio.nuevo ?
                                                    'Continuar'
                                                    :
                                                    'Guardar'
                                            )}
                                        </a>
                                    </div>
                                }
                            </div>

                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >
                                <p
                                    ref={(_subtitle) => (subtitle = _subtitle)}
                                    className="titleMin modalTitle"
                                    style={{ textAlign: "center" }}
                                >
                                    ¿Deseas eliminar tu cuenta?
                        </p>
                                <br />

                                <div className="backofficeContainer__optionTotal modalRow">
                                    <p className="button button-alpha" onClick={closeModal}>
                                        Cancelar
                        </p>
                                    <button className="button u-pull-right aButton" onClick={handleDelete}>
                                        Eliminar
                         </button>
                                </div>
                            </Modal>
                        </>
                        :
                        null
            }
        </>
    );
}

export default Settings4;
