import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select, { components } from "react-select";
import { putNegocios, CheckOkkiDomain, getCurrentNegocio } from "./API Functions";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css"
import ReactTooltip from "react-tooltip";
import Settings2 from "./Settings-2";

import selectIcon from "../images/select_icon.svg";

import 'react-image-crop/lib/ReactCrop.scss';

import Settings from "./Settings";

const customStylesModal = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: 900,
        maxHeight: 700,
        height: "90%",
        padding: 40,
        border: "none",
        borderRadius: 12,
    },
};

const options = [
    { value: 1, label: "$1 - $99" },
    { value: 2, label: "$100 - $199" },
    { value: 3, label: "$200 - $399" },
];

export const serverDelay = (func) => (duration) =>
    new Promise((resolve, reject) =>
        setTimeout(() => {
            resolve(func());
        }, duration || 1000)
    );

export const getWhitelistFromServer = serverDelay(() => [
    "aaa",
    "aaa1",
    "aaa2",
    "aaa3",
    "bbb1",
    "bbb2",
    "bbb3",
    "bbb4",
]);

export const getValue = serverDelay(() => ["foo", "bar", "baz"]);

const baseTagifySettings = {
    blacklist: ["xxx", "sex", "porno", "pene", "p3n3", "pito", "puto", "pendejo"],
    maxTags: 5,
    pattern: /^.{0,16}$/,
    //backspace: "edit",
    placeholder: "Etiquetas (máx 5)",
    /* dropdown: {
         enabled: 0, // a;ways show suggestions dropdown
     },*/
};

// callbacks props (for this demo, the same callback reference is assigned to every event type)
const tagifyCallbacks = {
    // add: callback,
    // remove: callback,
    // input: callback,
    // edit: callback,
    // invalid: callback,
    // click: callback,
    // keydown: callback,
    // focus: callback,
    // blur: callback,
    // "edit:input": callback,
    // "edit:updated": callback,
    // "edit:start": callback,
    // "edit:keydown": callback,
    // "dropdown:show": callback,
    // "dropdown:hide": callback,
    // "dropdown:select": callback
};

const pixelRatio = 4;

function getResizedCanvas(canvas, newWidth, newHeight) {
    const tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx = tmpCanvas.getContext("2d");
    ctx.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        newWidth,
        newHeight
    );

    return tmpCanvas;
}

function generateDownload(previewCanvas, crop) {
    if (!crop || !previewCanvas) {
        return;
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

    canvas.toBlob(
        blob => {
            const previewUrl = window.URL.createObjectURL(blob);

            const anchor = document.createElement("a");
            anchor.download = "cropPreview.png";
            anchor.href = URL.createObjectURL(blob);
            anchor.click();

            window.URL.revokeObjectURL(previewUrl);
        },
        "image/png",
        1
    );
}

const customStyles = {
    dropdownIndicator: base => ({
        ...base,
        color: "#081027"
    })
};

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 8L10 12L14 8"
                    stroke="#081027"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </components.DropdownIndicator>
    );
};

function SettingsNegocio(props) {
    const tagifyRef = useRef();
    // just a name I made up for allowing dynamic changes for tagify settings on this component
    const [tagifySettings, setTagifySettings] = useState([]);
    const [tagifyProps, setTagifyProps] = useState({});


    const [negocio, setNegocio] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [rangos, setRangos] = useState([]);


    const [Loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    //const [nombre_negocio, setNombreNegocio] = useState("");
    const [url_negocio, setUrlNegocio] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [categoria_id, setCategoria] = useState("");
    const [tags, setTags] = useState([]);
    const [rango, setRango] = useState("");
    const [imagen, setImagen] = useState("");
    const [file_preview, setFilePreview] = useState("");
    const [file, setFile] = useState([]);

    const [settings2, setSettings2] = useState(false);
    const [settings1, setSettings1] = useState(false);



    // crop
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: "px", width: 360, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(false);
    const [crop_completed, setCropCompleted] = useState(false);
    const [completedCropImg, setCroppedImage] = useState("");

    //data 

    const [nombreError, setNombreError] = useState("");
    const [postEmailError, setPostEmailError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [nombre_negocioError, setNombre_negocioError] = useState("");
    const [url_negocioError, setUrl_negocioError] = useState("");
    const [whatsappError, setWhatsappError] = useState("");
    const [descripcionError, setDescripcionError] = useState("");
    const [tagsError, setTagsError] = useState("");
    const [categoriaError, setCategoriaError] = useState("");
    const [rangoError, setRangoError] = useState("");
    const [imagenError, setImagenError] = useState([]);
    const [is_editing, setEditing] = useState(false);
    const [newCategory, setNewCategory] = useState("");
    const [dominio_okkiError, setDominio_okkiError] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);


    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (props.negocio) {
            const negocio = props.negocio;

            console.log(negocio)
            setNombre(negocio.nombre);
            setEmail(negocio.user.correo);
            setWhatsapp(negocio.whatsap);
            if (negocio.nuevo && negocio.tags_ids == null) {
                setTags("");
                //setDescripcion("");
                //setUrlNegocio("");
            } else {
                setTags(JSON.parse(negocio.tags_ids));
                //setDescripcion(negocio.descripcion);
                //setUrlNegocio(negocio.dominio_okki);
                if (negocio.imagen_url) {
                    setImagen(negocio.imagen_url);
                    setFilePreview(negocio.imagen_url);
                }
            }
            const categorias = [];
            negocio.categorias.map((categoria, index) => {
                if (categoria.tipo_id == negocio.tipos[0].id) {
                    categoria = {
                        ...categoria,
                        value: categoria.id,
                        label: categoria.detalle
                    }
                    categorias.push(categoria);
                    if (categoria.id == negocio.categoria_id) {
                        setCategoria(categoria);
                    }
                }
            })
            setCategorias(categorias);

            const rangos = [];
            negocio.rangos.map((rango, index) => {
                rango = {
                    ...rango,
                    value: rango.id,
                    label: rango.detalle
                }
                rangos.push(rango);
                if (rango.id == negocio.rango_id) {
                    setRango(rango);
                }
            });
            setRangos(rangos);

            setNegocio(negocio);

        } else {


            getCurrentNegocio().then(res => {
                console.log(props)
                const negocio = res;
                console.log(negocio)
                setNombre(negocio.nombre);
                setEmail(negocio.user.correo);
                setWhatsapp(negocio.whatsap);
                if (negocio.nuevo && negocio.tags_ids == null) {
                    setTags("");
                    //setDescripcion("");
                    //setUrlNegocio("");
                } else {
                    setTags(JSON.parse(negocio.tags_ids));
                    //setDescripcion(negocio.descripcion);
                    //setUrlNegocio(negocio.dominio_okki);
                    if (negocio.imagen_url) {
                        setImagen(negocio.imagen_url);
                        setFilePreview(negocio.imagen_url);
                    }
                }
                const categorias = [];
                negocio.categorias.map((categoria, index) => {
                    if (categoria.tipo_id == negocio.tipos[0].id) {
                        categoria = {
                            ...categoria,
                            value: categoria.id,
                            label: categoria.detalle
                        }
                        categorias.push(categoria);
                        if (categoria.id == negocio.categoria_id) {
                            setCategoria(categoria);
                        }
                    }
                })
                setCategorias(categorias);

                const rangos = [];
                negocio.rangos.map((rango, index) => {
                    rango = {
                        ...rango,
                        value: rango.id,
                        label: rango.detalle
                    }
                    rangos.push(rango);
                    if (rango.id == negocio.rango_id) {
                        setRango(rango);
                    }
                });
                setRangos(rangos);

                setNegocio(negocio);
            })
        }

        //setTagifyProps({ loading: true });

        getWhitelistFromServer(2000).then((response) => {
            setTagifySettings((lastState) => ({
                ...lastState,
                whitelist: response,
            }));

            //setTagifyProps({ showDropdown: "a", loading: false });
        });
    }, [])

    // merged tagify settings (static & dynamic)
    const settings = {
        ...baseTagifySettings,
        ...tagifySettings,
        callbacks: tagifyCallbacks,
    };

    const onChange = useCallback((e) => {
        e.persist();
        console.log("CHANGED:", e.target.value);
        const tags = tagifyRef.current.getTagElms()
        console.log(tags)
    }, []);

    // access Tagify internal methods example:
    const clearAll = () => {
        tagifyRef.current && tagifyRef.current.removeAllTags();
    };

    function validateFields() {
        let nombreError = ""
        let imagenError = ""
        let emailError = ""
        let nombre_negocioError = ""
        let url_negocioError = ""
        let envio_idError = ""
        let whatsappError = ""
        let descripcionError = ""
        let tagsError = ""
        let categoriaError = ""
        let rangoError = ""

        /*
         if (nombre == "") {
             nombreError = "Requerido"
         }
         if (imagen == "") {
             imagenError = "Agrega una imagen de logotipo"
         }
         if (email == "") {
             emailError = "Requerido"
         }
         if (url_negocio == "") {
             url_negocioError = "Requerido"
         }
         if (whatsapp == "") {
             whatsappError = "Requerido"
         }
         if (descripcion == "") {
             descripcionError = "Requerido"
         }
         */
        if (tags == "") {
            tagsError = "Requerido"
        }
        if (categoria_id == "") {
            categoriaError = "Selecciona una opción"
        }
        if (rango == "") {
            rangoError = "Selecciona una opción"
        }

        if (nombreError || imagenError || emailError || nombre_negocioError || url_negocioError || envio_idError || whatsappError || descripcionError || rangoError || tagsError) {
            setNombreError(nombreError)
            setImagenError(imagenError)
            setEmailError(emailError)
            setNombre_negocioError(nombre_negocioError)
            setUrl_negocioError(url_negocioError)
            setWhatsappError(whatsappError)
            setDescripcionError(descripcionError)
            setTagsError(tagsError)
            setCategoriaError(categoriaError)
            setRangoError(rangoError)
            return false
        } else {
            setNombreError(nombreError)
            setImagenError(imagenError)
            setEmailError(emailError)
            setNombre_negocioError(nombre_negocioError)
            setUrl_negocioError(url_negocioError)
            setWhatsappError(whatsappError)
            setDescripcionError(descripcionError)
            setTagsError(tagsError)
            setCategoriaError(categoriaError)
            setRangoError(rangoError)
            return true
        }
    }

    function handleCategoria(selectedOption) {
        setCategoria(selectedOption);
    }

    function handleRango(selectedOption) {
        setRango(selectedOption);
    }



    async function handleSubmit() {
        setLoading(true)
        /*
        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }

        const data = {
            id: negocio.id,
            tags_ids: JSON.stringify(tags),
            nombre: nombre,
            codigo_postal: "0000",
            direccion: "provicional",
            website: negocio.website,
            whatsap: negocio.whatsap,
            facebook: "provicional",
            instagram: "provicional",
            telefono: negocio.whatsap,
            imagen_url: "provicional",
            categoria_id: categoria_id.id,
            descripcion: descripcion,
            dominio_okki: url_negocio,
            rango_id: rango.id,
            zona: negocio.zona,
            persona_contacto: negocio.persona_contacto,
            precio_envio: negocio.precio_envio,
            tipos: [negocio.tipos[0].id],
            envios: [negocio.envios[0].id],
            ciudad_id: 0
        }
        */

        const isValid = validateFields()
        console.log(newCategory)
        if (isValid) {

            var formData = new FormData();
            if (is_editing) {
                const croppedImg = await getCroppedImg(imgRef.current, completedCrop, "producto_image");
                for (let i = 0; i < imagen.length; i++) {
                    formData.append("image[]", completedCropImg);
                }
            } else {
                for (let i = 0; i < imagen.length; i++) {
                    formData.append("image[]", completedCropImg);
                }
            }
            formData.append("id", negocio.id);
            formData.append("tags_ids", JSON.stringify(tags));
            formData.append("nombre", negocio.nombre);
            formData.append("codigo_postal", negocio.codigo_postal);
            formData.append("direccion", negocio.direccion);
            formData.append("website", negocio.website);
            formData.append("whatsap", negocio.whatsap);
            formData.append("facebook", negocio.facebook);
            formData.append("instagram", negocio.instagram);
            formData.append("telefono", negocio.whatsap);
            formData.append("categoria_id", categoria_id.id);
            formData.append("descripcion", negocio.descripcion);
            formData.append("dominio_okki", negocio.dominio_okki);
            formData.append("rango_id", rango.id);
            formData.append("zona", negocio.zona);
            formData.append("persona_contacto", negocio.persona_contacto);
            formData.append("precio_envio", negocio.precio_envio);
            formData.append("tipos", [negocio.tipos[0].id]);
            formData.append("envios", [negocio.envios[0].id]);
            formData.append("ciudad_id", negocio.ciudad_id);
            if (newCategory.new) {
                formData.append("new_category", newCategory.detalle)
            }

            const data = {
                id: negocio.id,
                data: formData
            }
            putNegocios(data).then(res => {
                if (res) {
                    if (negocio.nuevo) {
                        localStorage.setItem('negocio', JSON.stringify(res))
                        setNegocio(res);
                        changeSettings(res);
                    } else {
                        localStorage.setItem('negocio', JSON.stringify(res))
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000);
                    }
                }
            })
        }
        setTimeout(() => {
            setLoading(false);
            setDominio_okkiError("");
        }, 2000);
    }

    function changeSettings(negocio) {
        setSettings2(true);
    }

    async function finished_crop() {
        const croppedImg = await getCroppedImg(imgRef.current, completedCrop, "producto_image");
        var fileData = new FileReader();
        fileData.readAsDataURL(croppedImg)
        fileData.onloadend = () => {
            setFilePreview(fileData.result);
        };
        setCropCompleted(true);
        setCroppedImage(croppedImg);
    }

    function clearPreview() {
        setFilePreview("")
        setCropCompleted(false)
        setImagen([])
    }

    function getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        // As Base64 string
        // const base64Image = canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/png', 1);
        });
    }

    const handleInputChange = (value) => {
        console.log(value);
        const category = {
            new: true,
            detalle: value
        }
        if (value) {
            setNewCategory(category);
        }
    };

    return (
        <>{settings2 ?
            <Settings2 negocio={negocio} />
            :
            settings1 ?
                <Settings negocio={negocio} />
                :
                negocio.id ?
                    <>
                        {negocio.nuevo ?
                            <div className="four columns settings-form__sidebar onlyDesktop" style={{ marginLeft: 0 }}>

                                <div className="settings-header settings-headerModal">
                                    <div className="settings-header__breadcrumb settings-header__breadcrumb-title">
                                        <h3 className="settings-header__title">Configuración</h3>

                                        <hr className="settingsDivider" />
                                    </div>
                                </div>
                                <ul className="settings-form__menu settings-form__menuList">
                                    <li>
                                        <Link className="">
                                            Perfil
                                    </Link>
                                    </li>
                                    <li>
                                        <Link className="active">
                                            Negocio
                                    </Link>
                                    </li>
                                    <li>
                                        <Link className="">
                                            Dirección y Envíos
                                    </Link>
                                    </li>
                                    <li>
                                        <Link className="">
                                            Redes Sociales
                                    </Link>
                                    </li>
                                    <li>
                                        <Link className="">
                                            Cuenta
                                    </Link>
                                    </li>
                                    <li>
                                        <Link className="">
                                            Plan
                                    </Link>
                                    </li>
                                </ul>

                            </div>
                            : null}
                        <div className="eight columns settings-form__container">

                            <div className="settings-form__row">
                                <div className="settings-form__column settings-form__columnLabel">
                                    <label>Tipo de negocio</label>
                                </div>
                                <div className="settings-form__column settings-form__columnField">
                                    <input
                                        className="settings-form__input"
                                        type="text"
                                        placeholder="Comida"
                                        disabled
                                        value={negocio.tipos ? negocio.tipos[0].detalle : null}
                                    />
                                </div>
                            </div>
                            <div className="settings-form__row">
                                <div className="settings-form__column settings-form__columnLabel">
                                    <label>
                                        Categoría
                                        <svg data-tip="Selecciona una categoría de nuestro catálogo.<br/>En caso de no encontrarla puedes crearla." width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z" fill="#66666A" fill-opacity="0.25" />
                                            <path d="M5.46523 7.11011C5.46523 6.92391 5.49674 6.76779 5.55977 6.64175C5.62565 6.51571 5.71732 6.40828 5.83477 6.31948C5.95508 6.23068 6.11406 6.13758 6.31172 6.04019C6.48932 5.95138 6.62539 5.82964 6.71992 5.67495C6.81445 5.5174 6.86172 5.31401 6.86172 5.06479C6.86172 4.84995 6.81875 4.66375 6.73281 4.5062C6.64688 4.34865 6.52799 4.22977 6.37617 4.14956C6.22435 4.06649 6.05247 4.02495 5.86055 4.02495C5.66576 4.02495 5.49245 4.06935 5.34063 4.15815C5.19167 4.24696 5.07422 4.37013 4.98828 4.52769C4.90521 4.68237 4.86367 4.85998 4.86367 5.0605C4.86367 5.16935 4.87799 5.2911 4.90664 5.42573L4.02578 5.3269C4.00859 5.20659 4 5.09058 4 4.97886C4 4.63511 4.07734 4.3286 4.23203 4.05933C4.38958 3.78719 4.60729 3.57664 4.88516 3.42769C5.16589 3.27586 5.48672 3.19995 5.84766 3.19995C6.20859 3.19995 6.53229 3.27586 6.81875 3.42769C7.10521 3.57951 7.33008 3.79578 7.49336 4.07651C7.65664 4.35438 7.73828 4.67664 7.73828 5.04331C7.73828 5.47586 7.64089 5.82248 7.44609 6.08315C7.25417 6.34383 7.01068 6.53289 6.71563 6.65034C6.56667 6.71336 6.45781 6.78641 6.38906 6.86948C6.32031 6.94969 6.28594 7.06141 6.28594 7.20464V7.61284H5.46523V7.11011ZM5.89922 9.50347C5.79036 9.50347 5.68867 9.47625 5.59414 9.42183C5.50247 9.37026 5.42943 9.29865 5.375 9.20698C5.32057 9.11245 5.29336 9.01076 5.29336 8.9019C5.29336 8.79305 5.32057 8.69279 5.375 8.60112C5.43229 8.50946 5.50677 8.43641 5.59844 8.38198C5.6901 8.32756 5.79036 8.30034 5.89922 8.30034C6.00521 8.30034 6.1026 8.32756 6.19141 8.38198C6.28307 8.43641 6.35612 8.50946 6.41055 8.60112C6.46497 8.69279 6.49219 8.79305 6.49219 8.9019C6.49219 9.01076 6.46497 9.11245 6.41055 9.20698C6.35612 9.29865 6.28307 9.37026 6.19141 9.42183C6.1026 9.47625 6.00521 9.50347 5.89922 9.50347Z" fill="white" />
                                        </svg>
                                        <ReactTooltip className="tooltipStyle" multiline="true" place="top" type="dark" effect="solid" />
                                    </label>
                                </div>
                                <div className="settings-form__column settings-form__columnField">
                                    <CreatableSelect
                                        classNamePrefix="inputSelect"
                                        className="inputSelect settings-form__input"
                                        options={categorias}
                                        value={categoria_id}
                                        formatCreateLabel={labelInput => `Crear ${labelInput}`}
                                        onInputChange={handleInputChange}
                                        onChange={handleCategoria}
                                        components={{ DropdownIndicator }}
                                        placeholder="Selecciona una Categoría"
                                        styles={customStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 6,
                                            colors: {
                                                ...theme.colors,
                                                primary50: "#ccc",
                                                primary25: "#F6F6F6",
                                                primary: "black",
                                            },
                                            icon: {
                                                fill: "#081027",
                                            },
                                        })}
                                    />
                                    <div className="fieldError">
                                        {categoriaError}
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form__row">
                                <div className="settings-form__column settings-form__columnLabel">
                                    <label>
                                        Etiquetas
                                        <div>
                                            <svg data-tip="Escribe las palabras clave que mejor describan tu Negocio,<br/>entre más asignes más oportunidad a que te encuentren." width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z" fill="#66666A" fill-opacity="0.25" />
                                                <path d="M5.46523 7.11011C5.46523 6.92391 5.49674 6.76779 5.55977 6.64175C5.62565 6.51571 5.71732 6.40828 5.83477 6.31948C5.95508 6.23068 6.11406 6.13758 6.31172 6.04019C6.48932 5.95138 6.62539 5.82964 6.71992 5.67495C6.81445 5.5174 6.86172 5.31401 6.86172 5.06479C6.86172 4.84995 6.81875 4.66375 6.73281 4.5062C6.64688 4.34865 6.52799 4.22977 6.37617 4.14956C6.22435 4.06649 6.05247 4.02495 5.86055 4.02495C5.66576 4.02495 5.49245 4.06935 5.34063 4.15815C5.19167 4.24696 5.07422 4.37013 4.98828 4.52769C4.90521 4.68237 4.86367 4.85998 4.86367 5.0605C4.86367 5.16935 4.87799 5.2911 4.90664 5.42573L4.02578 5.3269C4.00859 5.20659 4 5.09058 4 4.97886C4 4.63511 4.07734 4.3286 4.23203 4.05933C4.38958 3.78719 4.60729 3.57664 4.88516 3.42769C5.16589 3.27586 5.48672 3.19995 5.84766 3.19995C6.20859 3.19995 6.53229 3.27586 6.81875 3.42769C7.10521 3.57951 7.33008 3.79578 7.49336 4.07651C7.65664 4.35438 7.73828 4.67664 7.73828 5.04331C7.73828 5.47586 7.64089 5.82248 7.44609 6.08315C7.25417 6.34383 7.01068 6.53289 6.71563 6.65034C6.56667 6.71336 6.45781 6.78641 6.38906 6.86948C6.32031 6.94969 6.28594 7.06141 6.28594 7.20464V7.61284H5.46523V7.11011ZM5.89922 9.50347C5.79036 9.50347 5.68867 9.47625 5.59414 9.42183C5.50247 9.37026 5.42943 9.29865 5.375 9.20698C5.32057 9.11245 5.29336 9.01076 5.29336 8.9019C5.29336 8.79305 5.32057 8.69279 5.375 8.60112C5.43229 8.50946 5.50677 8.43641 5.59844 8.38198C5.6901 8.32756 5.79036 8.30034 5.89922 8.30034C6.00521 8.30034 6.1026 8.32756 6.19141 8.38198C6.28307 8.43641 6.35612 8.50946 6.41055 8.60112C6.46497 8.69279 6.49219 8.79305 6.49219 8.9019C6.49219 9.01076 6.46497 9.11245 6.41055 9.20698C6.35612 9.29865 6.28307 9.37026 6.19141 9.42183C6.1026 9.47625 6.00521 9.50347 5.89922 9.50347Z" fill="white" />
                                            </svg>
                                            <ReactTooltip className="tooltipStyle" multiline="true" place="top" type="dark" effect="solid" />        
                                        </div>
                                    </label>
                                </div>
                                <div className="settings-form__column settings-form__columnField">
                                    <Tags
                                        tagifyRef={tagifyRef}
                                        settings={settings}
                                        value={tags}
                                        {...tagifyProps}
                                        onChange={e => setTags(e.target.value)}
                                    />
                                    <div className="fieldError">
                                        {tagsError}
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form__row">
                                <div className="settings-form__column settings-form__columnLabel">
                                    <label>Rango de Precios</label>
                                </div>
                                <div className="settings-form__column settings-form__columnField">
                                    <Select
                                        classNamePrefix="inputSelect"
                                        className="inputSelect settings-form__input"
                                        options={rangos}
                                        value={rango}
                                        onChange={handleRango}
                                        components={{ DropdownIndicator }}
                                        placeholder="Selecciona un Rango de Precios"
                                        styles={customStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 6,
                                            colors: {
                                                ...theme.colors,
                                                primary50: "#ccc",
                                                primary25: "#F6F6F6",
                                                primary: "black",
                                            },
                                        })}
                                        styles={{
                                            dropdownIndicator: (provided, state) => ({
                                                ...provided,
                                                transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
                                            })
                                        }}
                                    />
                                    <div className="fieldError">
                                        {rangoError}
                                    </div>
                                </div>
                            </div>




                        </div>
                        {negocio.nuevo ?
                            <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                                <a className="button button-alphaAlt" onClick={() => { setSettings1(true) }}>Regresar</a>
                                <a className="button" onClick={handleSubmit}>
                                    {Loading && (
                                        <i className="" style={{ marginRight: "5px" }} />
                                    )}
                                    {Loading && (
                                        'Guardando...'
                                    )}
                                    {!Loading && (
                                        negocio.nuevo ?
                                            'Continuar'
                                            :
                                            'Guardar'
                                    )}
                                </a>
                            </div>
                            :
                            <div className="settings-form__rowLast settings-form__rowLast-modal settings-form__rowLastMobile">
                                <a>{" "}</a>
                                <a className="button" onClick={handleSubmit}>
                                    {Loading && (
                                        <i className="" style={{ marginRight: "5px" }} />
                                    )}
                                    {Loading && (
                                        'Guardando...'
                                    )}
                                    {!Loading && (
                                        negocio.nuevo ?
                                            'Continuar'
                                            :
                                            'Guardar'
                                    )}
                                </a>
                            </div>
                        }

                    </>
                    :
                    null

        }
        </>
    );
}

export default SettingsNegocio;
