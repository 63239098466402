import { useState } from 'react';

const useModalProductos = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [flagsShowing, setFlags] = useState(false);
  const [shareShowing, setShare] = useState(false);
  const [image_loaded, setImageLoaded] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);
    console.log(isShowing,'lol')
    console.log(!isShowing,'loleo')

    if(!isShowing){
      window.history.replaceState(null,null, sessionStorage.getItem('product_path'))
    }else{
      if(sessionStorage.getItem('previous_path') !== window.location.pathname){
        window.history.replaceState(null,null,   sessionStorage.getItem('previous_path'))
      }else{
        window.location.replace('/');
      }
    }
  }

  function flags() {
    setFlags(!flagsShowing);
  }

  function share() {
    setShare(!shareShowing);
  }

  function loadImage() {
    setImageLoaded(true);
  }

  return {
    isShowing,
    toggle,
    flags,
    flagsShowing,
    share,
    shareShowing,
    image_loaded,
    loadImage
  }
};

export default useModalProductos;
